export const validate = values => 
{
    const errors = {}
    if (!values.frgtpwdEmail) {
      errors.frgtpwdEmail = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.frgtpwdEmail)) {
        errors.frgtpwdEmail = 'Make sure that you entered valid Email address.!'
    }  

    //Reset password
    if (!values.newpwd) {
      errors.newpwd = 'Password can not be left blank.!'
    }
    if (!values.confirmpwd) {
        errors.confirmpwd = 'RetypePassword can not be left blank.!'
    }
    if (values.confirmpwd!=values.newpwd) {
        errors.confirmpwd = 'Please Enter a valid password!'
    }   
  return errors
}