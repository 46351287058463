// import { userConstants } from '../constants';
// import { userService } from '../services';
// import { history } from '../helpers';
import * as types from './index';

export const userlistAction = (payload) => {
    return {
        type: types.USER_LIST,
        payload: payload
    }
};

export const adduserAction = (payload) => {
    return {
        type: types.ADD_USER_LIST,
        payload: payload
    }
};

export const changepasswordaction = (payload) => {
    return {
        type: types.CHANGE_PASSWORD,
        payload: payload
    }
};

export const driveraddaction = (payload) => {
    return {
        type: types.DRIVER_ADD,
        payload: payload
    }
};

export const drivereditaction = (payload) => {
    return {
        type: types.DRIVER_EDIT,
        payload: payload
    }
};

export const driverdeleteaction = (payload) => {
    return {
        type: types.DRIVER_DELETE,
        payload: payload
    }
};
export const profileview = (payload) => {
    return {
        type: types.PROFILE_VIEW,
        payload: payload
    }
};
export const profileedit = (payload) => {
    return {
        type: types.PROFILE_EDIT,
        payload: payload
    }
};
export const shipperList = (payload) => {
    return {
        type: types.SHIPPER_LIST,
        payload: payload
    }
};
export const shipperAdd = (payload) => {
    return {
        type: types.SHIPPER_ADD,
        payload: payload
    }
};

export const menuaddAction = (payload) => {
    return {
        type: types.MENU_ADD,
        payload: payload
    }
};
export const roleaddAction = (payload) => {
    return {
        type: types.ROLE_ADD,
        payload: payload
    }
};
// function userRegister(user) {
//     return dispatch => {
//         dispatch(request(user));

//         userService.userRegister(user)
//         .then(
//             user => { 
//                 dispatch(success());
//                 history.push('/login');
//                 //dispatch(alertActions.success('Registration successful'));
//             },
//             error => {
//                 dispatch(failure(error.toString()));
//                 //dispatch(alertActions.error(error.toString()));
//             }
//         );
//     };

//     function request(user) { 
//     	return { 
//     		type: userConstants.REGISTER_REQUEST, user 
//     	} 
//     }
//     function success(user) {
//     	return { 
//     		type: userConstants.REGISTER_SUCCESS, user 
//     	} 
//     }
//     function failure(error) { 
//     	return { 
//     		type: userConstants.REGISTER_FAILURE, error 
//     	} 
//     }
// }

// function userLogin(User_Name, Password){

// 	return dispatch=>{

// 		dispatch(request({ User_Name }));

// 		userService.userLogin(User_Name, Password).then(
// 	 		user=>{
// 	 			dispatch(success(user));
//                 history.push('/');
// 	 		},
// 	 		error => {
//                 dispatch(failure(error.toString()));
//                 //dispatch(alertActions.error(error.toString()));
//             }
// 	 	);
// 	};

// 	function request(user) { 
// 		return { 
// 			type: userConstants.LOGIN_REQUEST, user 
// 		} 
// 	}
//     function success(user) { 
//     	return { 
//     		type: userConstants.LOGIN_SUCCESS, user 
//     	} 
//     }
//     function failure(error) { 
//     	return { 
//     		type: userConstants.LOGIN_FAILURE, error 
//     	} 
//     }
// }

// function logout() {
//     userService.logout();
//     return { type: userConstants.LOGOUT };
// }