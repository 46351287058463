import React, { Component, Fragment } from 'react';
import {formData} from '../../helpers/formData';
import logo from '../../truckergig-logo.png';
class CarrierOnboardHome extends Component{
    constructor(props)
	{
        super(props);
        console.log(this.props);
    }

    

    submitForm = (e) => {
        let form = document.getElementById("formHome");

        let formHomeData = formData(form);
        this.props.onAllow(formHomeData);
        console.log(formHomeData);
    }
    render = () => {
        return(
            <Fragment>
                <header className="header-section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="row carrier">
                                <div className="col-sm-2">
                                    <a className="navbar-brand" href="#">
                                        <img src={logo} />
                                    </a>
                                </div>
                                <div className="col-sm-8 text-center">
                                    <h3 className="onboard-title">CARRIER ONBOARDING</h3>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <article className="table-data truck-data bg-white rounded carrier-board">
                    <div className="p-t-5">
                        <div className="container">
                            <div className="row" id="formHome">                                
                                <div className="col form-group">
                                    <label >Carrier</label>
                                    <input type="text" className="form-control json-col" defaultValue={this.props.onboardData.carrier_name} name="carrier" placeholder="Carrier" />
                                </div>                                
                                <div className="col form-group">
                                    <label >MC #</label>
                                    <input type="text" className="form-control json-col" defaultValue={this.props.onboardData.mc_no} name="mc_no" placeholder="MC #" />
                                </div>
                                <div className="col form-group">
                                    <label >DOT #</label>
                                    <input type="text" className="form-control json-col" defaultValue={this.props.onboardData.dot_no} name="dot_no" placeholder="DOT #" />
                                </div>
                            </div>
                            <h4 className="text-center mt-3 truck-font">TruckerCloud Request Permission to:</h4>
                            <h5 className="text-center board-font">Allow TruckerCloud to connect to our ELD account to perform the following action on our behalf</h5>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="bg-color-1 mt-3 mb-3 text-center">
                                        <h5>Allow read access to ELD APIs</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>
                                    <label className="checkbox-custom-label"><img src="../../images/pic1.jpg"/> HOS Hours of Service</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>
                                    <label className="checkbox-custom-label"><img src="../../images/pic2.jpg"/> Location Data</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>
                                    <label className="checkbox-custom-label"><img src="../../images/pic2.jpg"/> Engine Data</label>
                                    </div>
                                </div>            
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>
                                        <label className="checkbox-custom-label"><img src="../../images/pic3.jpg"/> Authentication</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>                               
                                        <label className="checkbox-custom-label"><img src="../../images/pic4.jpg"/> Driver Data</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div>
                                        <label className="checkbox-custom-label"><img src="../../images/pic4.jpg"/> DVIR Data</label>
                                    </div>
                                </div>
                            </div>
                                        
                            <div className="row mt-5">
                                <div className="col-sm-12">
                                
                                 <h6 className="text-center font-size-1"><input type="checkbox" class="mr-3"></input>By checking the boxes and clicking ALLOW, for the ELD components identified above, I/We grant TruckerCloud access to my/our ELD account for the purposes of sending instructions, performing actions associated with the components, or otherwise using the components on my/our behalf. </h6>
                                </div>
                            </div>
                            <div className="row mt-4 mb-3">
                                <div className="col-md-12 text-center">
                                    <button type="button" className="btn btn-link ml-6 text-upper" onClick={(e) =>{window.close();}} >CLOSE</button>
                                    <button type="submit" className="btn btn-primary btn-lg text-upper" onClick={(e) =>{this.submitForm(e)}}>ALLOW</button>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </article>
                <footer className="footer p-4"> 
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4">
                            <div className="footer-lbl"><h3>Get In Touch</h3></div>
                            <div className="footer-adress">Address: 133 Peachtree St NE #3050 Atlanta, GA, 30303.</div>
                            <div className="footer-phones">Telephones: +1 800-603-9175</div>
                            <div className="footer-email">E-mail: support@intellitrans.com</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default CarrierOnboardHome;