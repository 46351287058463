import * as types from '../actions';

const initialState = {
    preferredCompanyList: [],
    loading: false,
    type:'',
    error: '',
    preference_changes:null,
    data: [],
    carrierAddFlag:false,
    carriersearchFlag:false,
    carrierbulkuploadflag:false
};

export default function(state = initialState, action) {
    state.preference_changes = null;
    switch(action.type) {
        case types.PREFERRED_COMPANY_LIST_BY_TYPE:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS:
            return { 
                ...state,
                preferredCompanyList: action.data,
                data:[],
                loading: false,
            };
        case types.PREFERRED_COMPANY_LIST_BY_TYPE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.PREFERRED_COMPANY_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.PREFERRED_COMPANY_LIST_SUCCESS:            
            return { 
                ...state,
                preferredCompanyList: action.data.preferredCompanyList,
                loading: false,
            };
        case types.PREFERRED_COMPANY_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CHANGE_PREFERRED_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case types.CHANGE_PREFERRED_RESPONSE_SUCCESS:
                return {
                    ...state,
                    loading: true,
                    error: '',
                    preference_changes:action.data
                }
        case types.CARRIER_SEARCH:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.CARRIER_SEARCH_SUCCESS:            
            return { 
                ...state,
                data: action.data,
                carriersearchFlag:true,
                loading: false,
            };
        case types.CARRIER_SEARCH_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_SEARCH_FLAG_ACTION:
            return { 
                ...state,
                carriersearchFlag:false,
            };
        case types.CARRIER_ADD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.CARRIER_ADD_SUCCESS:            
            return { 
                ...state,
                data: action.data,
                carrierAddFlag:true,
                loading: false,
            };
        case types.CARRIER_ADD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_ADD_FLAG_ACTION:
            return { 
                ...state,
                carrierAddFlag:false,
                data: action.data,
            };
        case types.COMPANY_NAME_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.COMPANY_NAME_LIST_SUCCESS:            
            return { 
                ...state,
                companyNamelist:action.data,
                data:action.data,
                loading: false,
            };
        case types.COMPANY_NAME_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_BULK_UPLOAD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.CARRIER_BULK_UPLOAD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                type:action.type,
                loading: false,
                carrierbulkuploadflag:true
            };
        case types.CARRIER_BULK_UPLOAD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CARRIER_BULK_UPLOAD_FlAG:
            return { 
                ...state,
                carrierbulkuploadflag:false,
            };
        case types.PLAN_UPGRADE:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.PLAN_UPGRADE_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.PLAN_UPGRADE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        
       default:
            return {
                ...state
            }
    }
};
