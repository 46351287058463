import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MainMenu extends Component{
	componentDidMount() {

	}
	render(){
		var userType=this.props.userType;
		var userMenu =this.props.userMenu;
		var mnu;

		if(userMenu) {
			//userMenu =userMenu.substring(1, userMenu.indexOf(']')-1);
			userMenu =userMenu.substring(1, userMenu.indexOf(']'));
			mnu      =userMenu.split(",").map(String);
			//if(mnu.length<=1) mnu=null;
		}
		let menuList = '';
		if(mnu){
			if(this.props.activeMainMenu == "Driver" || 
			this.props.activeMainMenu == "Carrier" || 
			this.props.activeMainMenu == "Broker" || 
			this.props.activeMainMenu == "Shipper" || 
			this.props.activeMainMenu == "Reports" || 
			this.props.activeMainMenu == "Users" ){
				menuList = mnu.map(menu => {
					let menu1 = menu.replace(/_/g, " ");
					let linkPath = '/'+userType+'/';
					linkPath += this.props.activeMainMenu.toLowerCase();
					linkPath += '/'+menu.trim();
					return (
						<li className="nav-item" key={menu}>
							<NavLink className="nav-link" activeClassName="active" exact={true} to={linkPath}>{menu1}</NavLink>
						</li>
					);
				});
			} else {
				menuList = mnu.map(menu => {
					menu=(menu.trim()=='hos')? 'hours-of-services': menu.trim();
					menu=(menu=='contact')? 'contacts': menu;
					menu=(menu=='document')? 'documents': menu;
					menu=(menu=='assets')? 'trucks': menu;
					
					if(userType=='carrier')
						menu=(menu=='drivers')? 'drivers': menu;
					
					let menu1 = menu.replace("_", " ");
					return (
						<li className="nav-item" key={menu}>
							<NavLink className="nav-link" activeClassName="active" exact={true} to={'/'+userType+'/'+menu.trim()}>{menu1}</NavLink>
						</li>
					);
				});
			}
		}
		else {
			menuList = <span></span>;
		}
		/*		
		const menuList = (mnu) ? (
            mnu.map(menu => {
				menu=(menu.trim()=='hos')? 'hours-of-services': menu.trim();
				menu=(menu=='contact')? 'contacts': menu;
				menu=(menu=='document')? 'documents': menu;
				menu=(menu=='assets')? 'trucks': menu;
				if(userType=='carrier')
					menu=(menu=='drivers')? 'drivers': menu;
					let menu1 = menu.replace("_", " ");
				return (
					<li className="nav-item" key={menu}>
						<NavLink className="nav-link" activeClassName="active" exact={true} to={'/'+userType+'/'+menu.trim()}>{menu1}</NavLink>
					</li>
				)
			})
		) : (
			<span></span>
				// <li className="nav-item">
				// 	 <NavLink className="nav-link" activeClassName="" exact={true} to='/dashboard'>Dashboard</NavLink>  
				// </li>
		)
		*/
		return(
			<article className="main-nav">
				<ul className="nav nav-tabs">
					{menuList}		
				</ul>
			</article>
		)
	}
}

export default MainMenu