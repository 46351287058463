export const validate = values => {    
    const errors = {}
    if (!values.firstname) {
        errors.firstname = 'First name can not be left blank.!'
    } else if (values.firstname.length > 15) {
        errors.firstname = 'Must be 15 characters or less.!'
    } else if (values.firstname.length < 3) {
        errors.firstname = 'Must be 3 characters or greater.!'
    }
    if (!values.license) {
        errors.license = 'License can not be left blank.!'
    } else if (values.license.length > 15) {
        errors.license = 'Must be 15 characters or less.!'
    } else if (values.license.length < 3) {
        errors.license = 'Must be 3 characters or greater.!'
    }
    if (!values.drivertype) {
        errors.drivertype = 'Driver type can not be left blank.!'
    }
    if (!values.state) {
        errors.state = 'State can not be left blank.!'
    } 
    if (!values.email) {
        errors.email = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Make sure that you entered valid email address.!'
    }
    if (!values.driver) {
        errors.driver = 'Driver can not be left blank.!'
    } 
    if (!values.mobilenumber) {
        errors.mobilenumber = 'Mobile No. can not be left blank.!'
    }else if(values.mobilenumber.length<10)
    {
       errors.mobilenumber = 'Please enter a valid mobile number.!'
    }
    return errors
}
