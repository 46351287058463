import * as types from './index';

export const preferredCompanyListByType = (payload) => {
	return {
		type: types.PREFERRED_COMPANY_LIST_BY_TYPE,
		payload: payload
	}
};

export const preferredCompanyList = (payload) => {
	return {
		type: types.PREFERRED_COMPANY_LIST,
		payload: payload
	}
};

export const changePreference = (payload) => {
	return {
		type: types.CHANGE_PREFERRED_REQUEST,
		payload: payload
	}
};

export const carrierSearch= (payload) => {
	return {
		type: types.CARRIER_SEARCH,
		payload: payload
	}
};

export const carrieradd = (payload) => {
	return {
		type: types.CARRIER_ADD,
		payload: payload
	}
};
export const carrierAddFlagAction = () => {
	return {
		type: types.CARRIER_ADD_FLAG_ACTION
	}
};
export const carrierSearchFlagAction = () => {
	return {
		type: types.CARRIER_SEARCH_FLAG_ACTION
	}
};

export const companyNameListAction = (payload) => {
	return {
		type: types.COMPANY_NAME_LIST,
		payload: payload
	}
};

export const bulkuploadaction = (payload) => {
    return {
        type: types.CARRIER_BULK_UPLOAD,
        payload: payload
    }
};

export const bulkuploadFlagAction = () => {
    return {
		type: types.CARRIER_BULK_UPLOAD_FlAG,
	}
};

export const planUpgradeAction = (payload) => {
	return {
		type: types.PLAN_UPGRADE,
		payload: payload
	}
};






