import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.css';
import './fontAwesomeFree5.0.1.css';
import './icofont.css';
import * as serviceWorker from './serviceWorker';
import ReduxToastr from 'react-redux-toastr'
const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <App />
        <div>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>, 
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
