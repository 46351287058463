import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DashboardTrucks from './DashboardTrucks';
import Eldprovider from './Eldprovider';
import DashboardBreadcrump from './DashboardBreadcrump';
import GoogleMapBreadcrump from './GoogleMapBreadcrump';
import DashMap from './DasboardMapGl';
import DashboardGoogleMap from './DashboardGoogleMap';
import { eldAssetList, dashboardAction } from '../../actions/dashboardActions';
import { Row, Col } from 'react-bootstrap';
import {      
    eldAssetListService, 
} from './../../services/dashboardService';

require('dotenv').config();

class Dashboard extends Component{
	constructor(props) {
		super(props);
		this.state={
			selectedData : [],
			checkedData : [],
			viewFlag : false,
			routeData : [],
			updated : 0,
			TrucksNum :[],
			mapZoom : 7,
			mapCenter : [-96,37],
			truckState : 'active',
			mapViewMode:'static',
			truckInfo:{},
			carriers : [],			
			eldList : [],
			eldProvider: '',
			eldlistflag:true,
			trucks:null,
			trucksflag:true,
			currentEld:null,
			mapFlag:0
		}
		this.selectedMenu=0;
		this.userType= localStorage.getItem('userType');
		
		this.SUBSCRIPTION_PLAN_ID=localStorage.getItem('SUBSCRIPTION_PLAN_ID');

	}
	componentDidMount() {
		document.title = 'Dashboard';
		if (this.state.eldlistflag && this.props.eldproviders && this.props.eldproviders.length > 0) {
			this.getEldAssetListService(this.props.eldproviders[0].id, this.props.eldproviders[0].eldProviderDispName);
		}
		
	}

	getEldAssetListService = (id, eldProviderDispName) => {
		this.setState({
			...this.state,
			currentEld:id,
			trucks:null,
			eldlistflag:false,
			trucksflag:true,
			eldProvider:'',
			checkedData:[]
		});
		let param = {
			payload : id
		}		
		eldAssetListService(param).then((response) => {
			let trucks = [];
			if(response && response.dashboard){
				trucks = response.dashboard;
			}
			if(this.state.truckState == 'active'){			// For Active Trucks 			
				this.setState({
					...this.state,
					trucks:trucks,
					eldlistflag:false,
					trucksflag:false,
					eldProvider:eldProviderDispName,
					updated : 1,
					selectedData: trucks.active,
					checkedData : trucks.active,
					viewFlag : false,
					routeData : [],
					TrucksNum : trucks.active,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(this.state.truckState == 'ideal'){		// For Idle Trucks			
				this.setState({
					...this.state,
					trucks:trucks,
					eldlistflag:false,
					trucksflag:false,
					eldProvider:eldProviderDispName,
					updated : 1,
					selectedData: trucks.idle,
					checkedData : trucks.idle,
					viewFlag : false,
					routeData : [],
					TrucksNum : trucks.idle,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(this.state.truckState == 'stopped'){		// For Stopped Trucks			
				this.setState({
					...this.state,
					trucks:trucks,
					eldlistflag:false,
					trucksflag:false,
					eldProvider:eldProviderDispName,
					updated : 1,
					selectedData : trucks.stopped,
					checkedData : trucks.stopped,
					viewFlag : false,
					routeData : [],
					TrucksNum : trucks.stopped,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}
			
			
		});
	}
	
	componentDidUpdate (){		
		if (this.state.eldlistflag && this.props.eldproviders && this.props.eldproviders.length > 0) {            
		
		 	this.getEldAssetListService(this.props.eldproviders[0].id, this.props.eldproviders[0].eldProviderDispName);
                
        }
		

		
	}
	handleTabClicked = (val,stopped) => {	
		if(this.state.trucks){
			if(val == 1){			// For Active Trucks 			
				this.setState({
					updated : 1,
					selectedData: this.state.trucks.active,
					checkedData : this.state.trucks.active,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.state.trucks.active,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'active',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(val == 2){		// For Idle Trucks			
				this.setState({
					updated : 1,
					selectedData: this.state.trucks.idle,
					checkedData : this.state.trucks.idle,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.state.trucks.idle,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'ideal',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(val == 3){		// For Stopped Trucks			
				this.setState({
					updated : 1,
					selectedData : this.state.trucks.stopped,
					checkedData : this.state.trucks.stopped,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.state.trucks.stopped,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'stopped',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}
		} else{
			this.setState({
				updated : 1,
				selectedData : [],
				checkedData : [],
				viewFlag : false,
				routeData : [],
				TrucksNum : [],
				mapZoom : 3.5,
				mapCenter : [-96,37],
				truckState : 'stopped',
				mapViewMode:'static',
				mapFlag:this.state.mapFlag+1
			});
		}		
	   }
	handletruckClicked = (selectedTruck) => {			
		
		if(selectedTruck.length == 1){					// change to route Map			
			this.setState({
				updated : 1,
				viewFlag: true,
				routeData : selectedTruck,
				mapFlag:this.state.mapFlag+1
			});									
		}else{											// restore original map
			this.setState({
				updated : 1,
				viewFlag: false,
				routeData : [],
				mapFlag:this.state.mapFlag+1
			});			
		}			
	}
	handlecheckList = (filteredEle) =>{
		//console.log(filteredEle);
		switch(filteredEle.length) {
		case 0:				
			this.setState({
				updated : 1,
				viewFlag: false,
				checkedData : filteredEle,
				routeData: [],
				mapFlag:this.state.mapFlag+1
			});
			break; 
		case 1:				
			this.setState({
				updated : 1,
				viewFlag : true,
				routeData : filteredEle,
				checkedData : filteredEle,
				mapFlag:this.state.mapFlag+1				
			});
			break;
		default:					
			this.setState({
				updated : 1,
				viewFlag : false,
				checkedData : filteredEle,
				routeData : [],
				mapFlag:this.state.mapFlag+1
			});
			break;
		}	
	   }	
	
	   _truckClick = (truckInfo) => {
		   //console.log('Ideal Trucks Info : --',this.state.selectedData);
		   console.log(truckInfo);
		   let mapViewMode = 'static';
		   if(this.state.truckState == 'active'){
			mapViewMode = 'moving';
		   }
		   this.setState({
			   ...this.state,
			   checkedData:[truckInfo],
			   mapZoom:12,
			   mapCenter:[truckInfo.longitude, truckInfo.latitude],
			   mapViewMode:mapViewMode,
			   truckInfo:truckInfo,
			   mapFlag:this.state.mapFlag+1
		   });
	   }
	showBreadcrump = () => {			
			return <GoogleMapBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} currentEld={this.state.currentEld} />	
	}
		

	getDashboardMap = (TOKEN) => {
		console.log(this.state.checkedData);
			let mapCenter={
				lat:parseFloat(this.state.mapCenter[1]), 
                lng:parseFloat(this.state.mapCenter[0])
			}
			let mapZoom = 5;
			return <DashboardGoogleMap mapZoom={mapZoom} mapCenter={mapCenter} mapData={this.state.checkedData} truckstate={this.state.truckState} mapViewMode = {this.state.mapViewMode} truckInfo={this.state.truckInfo} location={this.props.history.location} 
			mapFlag={this.state.mapFlag}/>

		
	}
	
	render(){		
		const TOKEN=process.env.REACT_APP_MAPBOX_TOKEN;
		var dashboard ={};
		var stopped   ={};
		var idle      ={};
		var active    ={};

		if(this.state.trucks) { 
			dashboard = this.state.trucks;
			stopped   = dashboard.stopped;
			idle      = dashboard.idle;
			active    = dashboard.active;
		}

		

				return(			
					<article className="dashboard-data shadow bg-white rounded">
						<Row className="no-gutters">
							<Col md={3} className="pos-rel">
								<DashboardTrucks loading = {this.state.trucksflag}  stopped={ stopped } idle={ idle } active={ active }
								tabClicked={(val)=> {this.handleTabClicked(val)}}
								truckClicked={(selectedTruck)=> {this.handletruckClicked(selectedTruck)}}
								selectedData={ this.state.selectedData } truckstate={this.state.truckState}
								checkList={(filteredEle)=> {this.handlecheckList(filteredEle)}}
								_truckClick = {(truckInfo) => { this._truckClick(truckInfo); }}
								/>
								{this.showBreadcrump()}
							
							</Col>
							<Col md={9}>
								<Eldprovider handleEldprovider={(id, eldProviderDispName)=> {this.getEldAssetListService(id, eldProviderDispName)}} eldprovider={this.props.eldproviders}/>								
								{this.getDashboardMap(TOKEN)}							
							</Col>
						</Row>
					</article>
				)
			
			
	}
}
const mapStateToProps = (state) => {	

	let eldproviderlist=[];
	if(state.commonReducer.eldproviderlist && state.commonReducer.eldproviderlist.data){
		eldproviderlist=state.commonReducer.eldproviderlist.data;
	}
	
	return{
		dashboard: state.dashboardReducer.eldAssets,
		eldproviders: eldproviderlist
		//dashboard : state.dashboardReducer.eldAssets,
		//trucks: state.truckReducer.trucks
	}    
}
export default connect(mapStateToProps)(Dashboard)


