import { put, call, takeEvery } from 'redux-saga/effects';
import { loadListService,truckListService } from '../services/adminService';
import * as types from '../actions';


function* loadList(payload) {
    try {   
            const response = yield call(loadListService, payload);
            yield put({type: types.ADMIN_LOAD_LIST_SUCCESS, data: response,responseData: payload.payload});
    } catch (e) {
            yield put({type: types.ADMIN_LOAD_LIST_ERROR, error: e.message});
    }
}

function* TruckList(payload) {
    try {   
            const response = yield call(truckListService, payload);
            yield put({type: types.ADMIN_TRUCK_LIST_SUCCESS, data: response});
    } catch (e) {
            yield put({type: types.ADMIN_TRUCK_LIST_ERROR, error: e.message});
    }
}

export function* adminSaga() {  
    yield takeEvery(types.ADMIN_LOAD_LIST, loadList);
    yield takeEvery(types.ADMIN_TRUCK_LIST, TruckList);
}
