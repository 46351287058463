import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { STATUS } from '../../../helpers/status';
import Loader from '../../common/loader';
import AdminCarrierRequestedLoad from './requestedLoad';
import AdminCarrierToBeDispatchLoad from './tobeDispatchLoad';
import AdminCarrierDispatchedLoad from './dispatchedLoad';
import AdminCarrierDeliveredLoad from './deliveredLoad'
class AdminCarrierLoadList extends Component{

    constructor(props){
        super(props);

        this.state ={
            isReady:false,
            currentStatus:STATUS.LOAD.REQUESTED
		}
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            isReady:true
        });
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{        
        if(this.props.selectedComapny != prevProps.selectedComapny){            
            this.statusChange(STATUS.LOAD.REQUESTED);
        }
    }

    statusChange = (status) => {
        this.setState({
            ...this.state,
            currentStatus:status
        });        
    }

    loadTabs = () => {
        if(this.state.currentStatus==STATUS.LOAD.REQUESTED){
            return <AdminCarrierRequestedLoad selectedComapny={this.props.selectedComapny} statusData={this.state.currentStatus} />
        } else if(this.state.currentStatus==STATUS.LOAD.DELIVERY_CONFIRMED){
            return <AdminCarrierToBeDispatchLoad selectedComapny={this.props.selectedComapny} statusData={this.state.currentStatus} />
        } else if(this.state.currentStatus==STATUS.LOAD.DISPATCHEDENROUTE){
            return <AdminCarrierDispatchedLoad selectedComapny={this.props.selectedComapny} statusData={this.state.currentStatus} />
        } else if(this.state.currentStatus==STATUS.LOAD.DELIVERED){
            return <AdminCarrierDeliveredLoad selectedComapny={this.props.selectedComapny} statusData={this.state.currentStatus} />
        }
    }

    render = () => {
        if(this.state.isReady){
			return(
                <article className="table-data truck-data shadow bg-white rounded">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className={this.state.currentStatus==STATUS.LOAD.REQUESTED ? "nav-link active" : "nav-link"} href="#" role="tab" onClick={(e) => {this.statusChange(STATUS.LOAD.REQUESTED)}}>
                                        REQUESTED
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.currentStatus==STATUS.LOAD.DELIVERY_CONFIRMED ? "nav-link active" : "nav-link"} href="#" role="tab" onClick={(e) => {this.statusChange(STATUS.LOAD.DELIVERY_CONFIRMED)}}>
                                        TO BE DISPATCHED
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.currentStatus==STATUS.LOAD.DISPATCHEDENROUTE ? "nav-link active" : "nav-link"} href="#" role="tab" onClick={(e) => {this.statusChange(STATUS.LOAD.DISPATCHEDENROUTE)}}>
                                        DISPATCHED
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.currentStatus==STATUS.LOAD.DELIVERED ? "nav-link active" : "nav-link"} href="#" role="tab" onClick={(e) => {this.statusChange(STATUS.LOAD.DELIVERED)}}>
                                        DELIVERED
                                    </a>
                                </li>
                                                               
                            </ul>
                            <div className="tab-content">
                                {this.loadTabs()}
                            </div>
                        </div>
                    </div>
                </article>
            )
		}
		else{
			return(<Loader />);
		}
    }
}
export default AdminCarrierLoadList;