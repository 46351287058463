export const validate = values => {    
    const errors = {}
    if (!values.moduleName) {
        errors.moduleName = 'Module name can not be left blank.!'
    }
    if (!values.menuName) {
        errors.menuName = 'Menu Name can not be left blank.!'
    } 
    if (!values.menuType) {
        errors.menuType = 'Menu type can not be left blank.!'
    }
    if (!values.state) {
        errors.state = 'State can not be left blank.!'
    } 
    
    if (!values.displayText) {
        errors.displayText = 'Display text can not be left blank.!'
    } 
    if (!values.menuUrl) {
        errors.menuUrl = 'Menu url can not be left blank.!'
    }
    if (!values.parentMenuId) {
        errors.parentMenuId = 'Parent menu id can not be left blank.!'
    }
    return errors
}
