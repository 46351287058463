import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Loader from '../../common/loader';
import {activeTuckCountReportService} from '../../../services/reportService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastr } from 'react-redux-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import {formData} from '../../../helpers/formData';
import ReactTooltip from 'react-tooltip';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ActiveTruckCount extends Component {
    constructor(props){
        super(props);
        this.state={
            isReady:false,
            loadList:[],
            rowExpanded:false,
            truckList:[],
            mailBoxOpen:false,
            reportData:{},
            mailBodyContent:'',
        }
    }

    componentDidMount = () => {
        document.title = 'Active Trucks';
        this.loadInitial();
    }

    loadInitial = () => {
        activeTuckCountReportService().then((data) =>{
            console.log("data----",data);
            let loadList = [];
            if(data){
                if(data.truckCountList){
                    loadList=data.truckCountList.activeTrucks;
                }
            }
            this.setState({
                ...this.state,
                loadList:loadList,
                isReady:true
            });
        });
    }

    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {            
            let loadList= this.state.loadList.map(
                (row,index) =>{
                    return {
                        //id:index,
                        truckNo:row.truckNo,
                        location:row.location,
                        speed:row.speed,
                        companyName:row.companyName,
                        latitude:Math.round(row.latitude * 100) / 100,
                        longitude:Math.round(row.longitude * 100) / 100,
                        lat_lang:<span>
                        <i data-tip data-for={"latlong"+index} id="lanehistoryclass" className="icofont-truck-loaded"></i>
                        <ReactTooltip id={"latlong"+index} >
                            <p>Latitude : {Math.round(row.latitude * 100) / 100}</p>
                            <p>Longitude : {Math.round(row.longitude * 100) / 100}</p>
                        </ReactTooltip>
                        </span>,
                        date:row.convertedDate
                        
                    };
                }
            );
            return loadList;
        }
    }

    getTableColumns = () => {
        return [           	
            {
                text:'Truck No',
                sort:true,
                dataField:'truckNo'
            },   
            {
                text:'Company Name',
                sort:true,
                dataField:'companyName',             
            },         
            {
                text:'Speed',
                sort:true,
                dataField:'speed',             
            },
            {
                text:'Location',
                sort:true,
                dataField:'location'
            },
            {
                text:'Lat Long',
                sort:true,
                dataField:'lat_lang',              
            },
            {
                text:'Tracking Date & Time', 
                sort:true,
                dataField:'date'
            }            
        ];
    }

    exportData = () => {        
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.loadList.map(row =>{
            return {               
                Truck_Number:row.truckNo,
                Location:row.location,
                Speed:row.speed,
                Company_Name:row.companyName,
                Latitude:Math.round(row.latitude * 100) / 100,
                Longitude:Math.round(row.longitude * 100) / 100,               
                Tracking_Date_Time:row.convertedDate
            }
        });
        let fileName = 'Active Trucks';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

   

    expandRow = () =>{
        //console.log('Hi');
        return {
             showExpandColumn: false,
             expandByColumnOnly: false,
             onlyOneExpanding:true              
        };
    }

   
    //{truck_count: 54, Company_Name: "FreightCo Dedicated, LLC."}
    render() {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
              
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };
            const defaultSorted = [{
                dataField: 'Company_Name',
                order: 'asc'
            }];
            return(
                <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                    <h3>Active Trucks</h3> <br></br>
                    <ToolkitProvider
                        keyField="id"
                        data={ this.getRowData() }
                        columns={ this.getTableColumns() }
                        search
                        >
                        {
                            props => (
                                <div>
                                    <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {this.state.loadList.length}</div>
                                    <SearchBar { ...props.searchProps } />
                                    <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div> 
                                    
                                    <BootstrapTable
                                        { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                        bordered={ false } defaultSorted={defaultSorted} expandRow={ this.expandRow()} id="truckCount"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                    
                </article>
            );
        } else {
            return(<Loader />);
        }
    }
}

export default ActiveTruckCount;