import { fork, takeLatest } from 'redux-saga/effects';
import watchUserAuthentication from './watchers';
import * as types from '../actions';
import { dashboardSaga, trackingListSaga, eldAssetsListSaga, visualFenceSaga, fetchvisualFenceSaga, deletevisualFenceSaga, updatevisualFenceSaga } from './dashboardSaga';
import { truckSaga, trucklistSaga } from './truckSaga';
import { loadSaga, updateLoadStatus,ChangeLoadStatus } from './loadSaga';
import { contactSaga } from './contactSaga';
import { carrierSaga } from './carrierSaga';
import { documentSaga } from './documentSaga';
import { commonlist } from './commonSaga';
import { userlist } from './userSaga';
import { assetSaga } from './assetSaga';
import {companySaga} from './companySaga';
import {forgotpwdsaga} from './authSaga';
import {reportSaga} from './reportSaga';
import {adminSaga} from './adminSaga';

export default function* startForman() {
    yield fork(watchUserAuthentication);
    yield fork(dashboardSaga);
    yield fork(truckSaga);
    yield fork(loadSaga);
    yield fork(userlist);
    yield fork(commonlist);
    yield fork(updateLoadStatus);
    yield fork(contactSaga);
    yield fork(carrierSaga);
    yield fork(documentSaga);
    //yield fork(trucklistSaga);
    yield fork(trackingListSaga); 
    yield fork(eldAssetsListSaga);
    yield fork(ChangeLoadStatus);   
    yield fork(assetSaga);
    yield fork(companySaga);
    yield fork(forgotpwdsaga);
    yield fork(reportSaga);
    yield fork(adminSaga);
    yield fork(visualFenceSaga);
    yield fork(fetchvisualFenceSaga);
    yield fork(deletevisualFenceSaga);
    yield fork(updatevisualFenceSaga);
}
