import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {formData} from '../../../helpers/formData';
import { TruckReportAction } from '../../../actions/reportAction';
import {getMinDate, getMaxDate} from '../../../helpers/appUtil';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class truckSummaryReport extends Component {
   
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state = {
            loadStatus:{
                data:[
                    {id:"Success",name:"Success"},
                    {id:"Failed",name:"Failed"}
                ]
            },
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1
        };
    }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){            
            fromDate=moment().startOf('day').toDate();
            toDate=moment().endOf('day').toDate();
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    componentDidMount() {		
        document.title = 'Truck Summary';
        $("#frmTruckRpt .range-fields").hide();
        this.submitForm(null);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevState.fromDate != this.state.fromDate) || (prevState.toDate != this.state.toDate) || (prevState.activeFilter != this.state.activeFilter)) {
            this.submitForm(null);
        }
    }

    submitForm = (e) =>{
        let loadData={};
            loadData.fromDate=(this.state.fromDate) ? (moment(this.state.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            loadData.toDate=(this.state.toDate) ? (moment(this.state.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            
            console.log(loadData);
            this.props.dispatch(TruckReportAction(loadData));
            
    }

    getDateLabel = () => {
        
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        return(
            <div className="row mt-4 mb-4">
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                        Date {fromDate}
                    </div>
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                        Date {fromDate} to {toDate}
                    </div>
                </div> 
        );
    
}

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate(),
            activeFilter:'RANGE'
        });
    }
    
    render() {
        console.log(this.props)
        if(this.props.data.status == 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
                var indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.assetsList !== undefined)
            {
                if(this.props.data.assetsList.length > 0)
                {
                    LoadReports = this.props.data.assetsList.map(
                        row =>{   
                            return({
                                id:row.assetId,
                                assetnumber:row.assetNumber,
                                license:row.licensePlateNo,
                                vin:row.identificationNo,
                                make:row.make,
                                model:row.model,
                                year:row.year,
                                color:row.color
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    var indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;        
        var indicationhtml = "No Data Found"
		const columns=[	
        {
            text:'Asset Number',
            dataField:'assetnumber',
            sort:true
        },
		{
			text:'Licence Plate Number',
			dataField:'license',
			sort:true
		},
		{
			text:'VIN',
			dataField:'vin',
			sort:true
        },
        {
			text:'Make',
			dataField:'make',
			sort:true
		},
		{
			text:'Model',
			dataField:'model',
			sort:true
		},
		{
			text:'Year',
			dataField:'year',
			sort:true
        },
        {
			text:'Color',
			dataField:'color',
			sort:true
		}
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Truck Summary</h3> <br></br>
                <form id="frmTruckRpt">
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} 
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} >Date Range</button>
                            </DateRangePicker>
                        </div>
                    </div>
                    {this.getDateLabel()}
                    <div className="row">
                        {/* <div className="form-group col col-lg-4 date-picker range-fields">
                            <label>From Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="fromDate" placeholder="Enter from date" value={this.state.fromDate} onChange={value => this.setState({ ...this.state, fromDate:value })} max={getMaxDate(this.state.toDate)}/>                    
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className="form-group col col-lg-3 date-picker range-fields">
                            <label>To Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="toDate" placeholder="Enter to date" value={this.state.toDate} onChange={value => this.setState({ ...this.state, toDate:value })} min={getMinDate(this.state.fromDate)} />                        
                            <input hidden className="error_place" defaultValue=""/>
                        </div>                 
                        <div className="form-group col col-lg-2">
                            <button type="button" onClick={(e) =>{this.submitForm(e)}} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                        </div>*/}
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {LoadReports.length}</div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
    }
  }
  
  export default connect(mapStateToProps)(truckSummaryReport);