import React, { Component, Fragment } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer, Polyline } from '@react-google-maps/api';
import {polyLien1, mapStyle, Clusterers1} from './../../helpers/GoogleUtils';
import {getUrlLastSegments} from './../../helpers/appUtil';
import {getActiveTruckTracking} from './../../services/assetService';
class DashboardGoogleMap extends Component{
    constructor(props) {
		super(props);
		this.state={
            markerPopupShow:false,
            mapZoom:5,
            currentMarker:null,
            infwCenter:{
                lat:41.89101, 
                lng:-87.62342            
            },
            infwData:{},
            activeMovingData:[],
            movingMarkers:[]
        }
        this.GOOGLE_MAP_KEY=localStorage.getItem('GOOGLE_MAP_KEY');
        this.map=null;
        this.activeMovingTimer=null;
        this.activeTruckId=null;
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        if(prevProps.mapCenter != this.props.mapCenter){
            this.mapCenter=this.props.mapCenter;
        }

        if(this.map){
            this.map.setZoom(this.mapZoom);
            this.map.setCenter(this.mapCenter);
        }
        

        if(this.props.mapFlag != prevProps.mapFlag){           

            this.activeTruckId=null;

            if(this.activeMovingTimer!=null){
                clearInterval(this.activeMovingTimer);
                this.activeMovingTimer=null;
            }

            if(this.props.mapViewMode == "moving"){
                this.mapZoom = 12;
                this.getLastTrackingData(this.props.truckInfo.id);
            } else{
                this.mapZoom = this.props.mapZoom;
            }

            this.setState({
                ...this.state,
                markerPopupShow:false,
                currentMarker:null,
                activeMovingData:[]
            });



        }

        
    }

    getLastTrackingData = (activeTruckId) => {
        let path1=getUrlLastSegments(this.props.location.pathname);
        let path2=getUrlLastSegments(window.location.toString());
        path1 = path1.replace('#','');
        path2 = path2.replace('#','');        
        if(activeTruckId == this.props.truckInfo.id && this.props.mapViewMode == "moving" && path1 == path2)
        {
            this.activeTruckId=activeTruckId;
            let rndval=Date.now();
            getActiveTruckTracking({rndval:rndval,assetId:activeTruckId}).then(response =>{ 
            
                if(this.activeTruckId == this.props.truckInfo.id && response && response.TrackingData && response.TrackingData.length>0)
                {
                    let lat = parseFloat(response.TrackingData[0].latitude);
                    let lng = parseFloat(response.TrackingData[0].longitude);
                    let lastLat = 0;
                    let lastLng = 0;
                    let len1 = this.state.activeMovingData.length;
                    if(len1>0){
                        lastLat = this.state.activeMovingData[len1-1].lat;
                        lastLng = this.state.activeMovingData[len1-1].lng;
                    }

                    let activeMovingData = [];
                    
                    if(!(isNaN(lat) || isNaN(lng))){
                        if(lastLat != lat || lastLng != lng){
                            activeMovingData = [...this.state.activeMovingData, {lat:lat, lng:lng}];
                            let movingMarkers = (len1==0 ? [response.TrackingData[0]] : [this.state.movingMarkers[0], response.TrackingData[0]]);                           

                            let bounds = this.map.getBounds().contains({lat: lat, lng: lng});
                            if(!bounds){
                                this.mapZoom--;
                            }
                            this.setState({
                                ...this.state,
                                activeMovingData:activeMovingData,
                                movingMarkers:movingMarkers
                            });
                        }                
                    }
                }   
                if(this.activeTruckId == this.props.truckInfo.id){
                    this.activeMovingTimer = setTimeout(() => {
                        this.getLastTrackingData(this.props.truckInfo.id);
                    }, 15000);
                }
          
            });
        }
    }

    loadMarker = () => {
        let mapData = [];
        if(this.props.mapViewMode == "moving") {
            mapData = [...this.state.movingMarkers];
            let len1=mapData.length;
            if(len1>0){
                if(len1>1){
                    let icon1 = 'http://maps.google.com/mapfiles/ms/icons/red.png';
                    let icon2 = 'http://maps.google.com/mapfiles/ms/icons/green.png';
                    let pos1 = {
                        lat:parseFloat(mapData[0].latitude),
                        lng:parseFloat(mapData[0].longitude)
                    };

                    let pos2 = {
                        lat:parseFloat(mapData[1].latitude),
                        lng:parseFloat(mapData[1].longitude)
                    };

                    let data1 = { truckNo : this.props.truckInfo.truckNo,
                        convertedDate:mapData[0].convertedDate,
                        speed:mapData[0].speed,
                        location:mapData[0].location,
                        latitude:mapData[0].latitude,
                        longitude:mapData[0].longitude
                    }

                    let data2 = { truckNo : this.props.truckInfo.truckNo,
                        convertedDate:mapData[1].convertedDate,
                        speed:mapData[1].speed,
                        location:mapData[1].location,
                        latitude:mapData[1].latitude,
                        longitude:mapData[1].longitude
                    }

                    return [<Marker key={0}
                            icon={icon1}
                            position={pos1}
                            onMouseOver={(e) =>{this.onClickMarker(e,data1, 0)}}
                        />, <Marker key={1}
                            icon={icon2}
                            position={pos2}
                            onMouseOver={(e) =>{this.onClickMarker(e, data2, 1)}}
                        />];

                } else{
                    let icon1 = 'http://maps.google.com/mapfiles/ms/icons/green.png';
                    let pos1 = {
                        lat:parseFloat(mapData[0].latitude),
                        lng:parseFloat(mapData[0].longitude)
                    };
                    let data1 = { truckNo : this.props.truckInfo.truckNo,
                        convertedDate:mapData[0].convertedDate,
                        speed:mapData[0].speed,
                        location:mapData[0].location,
                        latitude:mapData[0].latitude,
                        longitude:mapData[0].longitude
                    }
                    return [<Marker key={0}
                        icon={icon1}
                        position={pos1}
                        onMouseOver={(e) =>{this.onClickMarker(e, data1, 0)}}
                    />];
                }

            } else{
                return <Fragment></Fragment>
            }

        } else {
            mapData = [...this.props.mapData];
            let icon = 'http://maps.google.com/mapfiles/ms/icons/green.png';
            if(this.props.truckstate == 'stopped')
            {
                icon = 'http://maps.google.com/mapfiles/ms/icons/red.png';
            } else if(this.props.truckstate == 'ideal'){
                icon = 'http://maps.google.com/mapfiles/ms/icons/yellow.png';
            }
            // stopped, ideal, active

            

            return <MarkerClusterer options={Clusterers1}>
                {(clusterer) =>
                    mapData.map((row, index) => {
                    let pos = {
                        lat:parseFloat(row.latitude),
                        lng:parseFloat(row.longitude)
                    };
                        return <Marker key={index}
                            icon={icon}
                            position={pos}
                            onMouseOver={(e) =>{this.onClickMarker(e, row, index)}}
                            clusterer={clusterer}
                        />
                    })
                }</MarkerClusterer>
        }

        
    }

    onClickMarker = (e, data, index) =>{
        if(index == this.state.currentMarker)   return;
        let speed = parseFloat(data.speed);
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }     
        let infwCenter={
            lat:parseFloat(data.latitude), 
            lng:parseFloat(data.longitude)
        }
        let infwData={
            truckNo:data.truckNo,
            date:data.convertedDate,
            speed:speed,
            location:data.location
        }
        this.setState({
            ...this.state,
            markerPopupShow:true,
            currentMarker:index,
            infwCenter:infwCenter,
            infwData:infwData
        })
    }

    loadMarker = () => {
        let icon = 'http://maps.google.com/mapfiles/ms/icons/green.png';
        if(this.props.truckstate == 'stopped')
        {
            icon = 'http://maps.google.com/mapfiles/ms/icons/red.png';
        } else if(this.props.truckstate == 'ideal'){
            icon = 'http://maps.google.com/mapfiles/ms/icons/yellow.png';
        }
        // stopped, ideal, active

        let mapData = [...this.props.mapData];

        return <MarkerClusterer options={Clusterers1}>
            {(clusterer) =>
                mapData.map((row, index) => {
                let pos = {
                    lat:parseFloat(row.latitude),
                    lng:parseFloat(row.longitude)
                };
                    return <Marker key={index}
                        icon={icon}
                        position={pos}
                        onMouseOver={(e) =>{this.onClickMarker(e, row, index)}}
                        clusterer={clusterer}
                    />
                })
            }</MarkerClusterer>
    }

    infClose = () => {
        this.setState({
            ...this.state,
            markerPopupShow:false,
            currentMarker:null,
		});
    }

    render = () => {
        return (
            <div>
                <LoadScript
                    googleMapsApiKey={this.GOOGLE_MAP_KEY}>
                    <GoogleMap key={'B1'} 
                        mapContainerStyle={mapStyle}
                        zoom={this.props.mapZoom}
                        center={this.props.mapCenter}                                        
                        onLoad={(map) => {this.map=map}}
                    >
                        {this.loadMarker()}                        
                        { this.state.markerPopupShow && 
                        <InfoWindow                            
                            position={this.state.infwCenter}
                            onCloseClick={()=>{this.infClose()}}
                        ><span>                                            
                            <p>Truck No: {this.state.infwData.truckNo}</p>
                            <p>Date: {this.state.infwData.date}</p>
                            <p>Speed: {this.state.infwData.speed}</p>
                            <p>Location: {this.state.infwData.location}</p>                                        
                            </span>
                        </InfoWindow>
                        }
                        <Polyline                        
                            path={this.state.activeMovingData}
                            options={polyLien1}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    }
}

export default DashboardGoogleMap;
