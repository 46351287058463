import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Loader from './loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import {getMyCarriersService, getMyCarriersZipRangeService, getMyCarriersDateRangeService, importLeadCarriersService, downloadSampleService, getAllMyCarriersService} from '../../services/adminService';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Icofont from 'react-icofont';
import {formatDecimal} from '../../helpers/appUtil';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AdminMyCarrierEntry from './myCarrierEntry';
import AdminMyCarrierView from './myCarrierView';

import $ from 'jquery';
import 'jquery-validation';
import {formData} from '../../helpers/formData';
class AdminMyCarrier extends Component{
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state={
            isReady:false,
            carrierList:[],
            pageFlag:'LIST',
            carrierData:{},
            viewStatus:false,
            statusData:'',
            activeFilter:'TOP10',
            fromDate:date1,
            toDate:date1,
            importPopup:false,
            radiusVal:'',
            zipVal:''
        }
    }

    componentDidMount = () => {
        $.validator.addMethod(
            "regex",
            function(value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            },
            "Please check your input."
        );
        document.title = 'Lead Carrier';
        this.getMyCarriers();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{
        //console.log(prevState.fromDate, this.state.fromDate);
        if(prevState.fromDate != this.state.fromDate || prevState.toDate != this.state.toDate || prevState.activeFilter != this.state.activeFilter){
            this.getMyCarriers();
        }
    }

    getZipRangeData = (e) => {
        if(e){
            e.preventDefault();        
        }
        $("#frmZipRange").validate({
            rules:{
                zipVal:{
                    required:true,
                    regex:/(^\d{5}$)|(^\d{5}-\d{4}$)/,
                },
                radiusVal:{
                    required:true,
                    number: true
                }
            },
            messages:{
                zipVal:{
                    required:'Zip Code can not be left blank.!',
                    regex:'Zip Code not valid'
                },
                radiusVal:{
                    required:'Radius can not be left blank.!',
                    number:'Radius not valid'
                }
            }
        });
        
        
        if( $("#frmZipRange").valid()){
            this.setState({
                ...this.state,
                isReady:false
            });
            let form = document.getElementById("frmZipRange");
            let loadData = formData(form);
            console.log(loadData);
            let param={
                payload:{
                    zipCode:loadData.zipVal,
                    radius:loadData.radiusVal
                }
            }
            getMyCarriersZipRangeService(param).then((response) =>{
                console.log(response);
                let myCarrierList =[];
                if(response.myCarrierList){
                    myCarrierList=response.myCarrierList;
                }
                
                this.setState({
                    ...this.state,
                    isReady:true,
                    carrierList:myCarrierList,
                    pageFlag:'LIST',
                    carrierData:{}
                });
            });
        }
        
    }

    getMyCarriers = () => {
        this.setState({
            ...this.state,
            isReady:false
        });

        if(this.state.activeFilter == "TOP10")
        {
            getMyCarriersService().then((response) =>{
                console.log(response);
                let myCarrierList=[];
                if(response.myCarrierList){
                    myCarrierList=response.myCarrierList;
                }

                this.setState({
                    ...this.state,
                    isReady:true,
                    carrierList:myCarrierList,
                    pageFlag:'LIST',
                    carrierData:{},
                    radiusVal:'',
                    zipVal:''
                });
            });
        } else if(this.state.activeFilter == "ZIPRANGE") {
            this.setState({
                ...this.state,
                isReady:true,
                carrierList:[],
                pageFlag:'LIST',
                carrierData:{}
            });
        } else if(this.state.activeFilter == "ALL") {
            getAllMyCarriersService().then((response) =>{
                console.log(response);
                let myCarrierList=[];
                if(response.myCarrierList){
                    myCarrierList=response.myCarrierList;
                }

                this.setState({
                    ...this.state,
                    isReady:true,
                    carrierList:myCarrierList,
                    pageFlag:'LIST',
                    carrierData:{},
                    radiusVal:'',
                    zipVal:''
                });
            });
        } else {
            let param={
                payload:{
                    fromDate:moment(this.state.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                    toDate:moment(this.state.toDate).format('YYYY-MM-DD HH:mm:ss')
                }
            }
            getMyCarriersDateRangeService(param).then((response) =>{
                console.log(response);
                let myCarrierList=[];
                if(response.myCarrierList){
                    myCarrierList=response.myCarrierList;
                }

                this.setState({
                    ...this.state,
                    isReady:true,
                    carrierList:myCarrierList,
                    pageFlag:'LIST',
                    carrierData:{}
                });
            });
        }
    }

    viewCarrier = (e, index) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            pageFlag:'VIEW',
            carrierData:this.state.carrierList[index]
        });
    }

    viewStatus = (e, index) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            viewStatus:true,
            statusData:this.state.carrierList[index].status
        });
    }

    getRowData = () => {
        if(this.state.carrierList.length==0){
            return [];
        } else {
            return this.state.carrierList.map((row, index) =>{
                let addDate="";
                if(row.addDate!="" && row.addDate != null)
                {
                    addDate=moment(row.addDate).format('MM-DD-YYYY');
                }
                return({
                id:row.myCarrierId,
                legalName:row.legalName,
                dotNumber:row.dotNumber,
                phone:<span>
                        <i data-tip data-for={"phone"+row.myCarrierId} id="lanehistoryclass" className="icofont-phone-circle"></i>
                        <ReactTooltip id={"phone"+row.myCarrierId} >
                            <p>Phone : {row.telphone}</p>
                        </ReactTooltip>
                    </span>,
                email:<span>
                        <i data-tip data-for={"email"+row.myCarrierId} id="lanehistoryclass" className="icofont-email"></i>
                        <ReactTooltip id={"email"+row.myCarrierId} >
                            <p>Email : {row.emailAddress}</p>
                        </ReactTooltip>
                    </span>,
                addDate:addDate,
                phyZip:row.phyZip,
                distance:(row.hasOwnProperty('distance')?formatDecimal(row.distance):''),
                nbrPowerUnit:row.nbrPowerUnit,
                driverTotal:row.driverTotal,
                statusIcon:<a href="#" title="Status" onClick={(e) => {this.viewStatus(e, index)}}><Icofont icon="info-circle"/></a>,                
                viewCarrier:<span>
                    <a href="#" className="tableIcon" title="View" onClick={(e) => {this.viewCarrier(e, index)}}><Icofont icon="eye"/></a> &nbsp;
                    <a href="#" className="tableIcon" title="Edit" onClick={(e) => {this.editCarrier(e, index, null)}}><Icofont icon="edit-alt"/></a>
                    </span>
                });
            });
        }
    }

    getTableColumns = () => {
        let distanceFlag = true;
        if(this.state.carrierList.length>0){
            distanceFlag = !this.state.carrierList[0].hasOwnProperty('distance');
        }
        return [
            {
				text:'Legal Name',
				dataField:'legalName',
                sort:true,
                style:{width: '25%'}
            },
            {
				text:'DOT Number',
                dataField:'dotNumber',
                sort:true
            },
            {
				text:'Phone',
                dataField:'phone'
            },
            {
				text:'Email',
                dataField:'email'
            },
            {
                text:'Zip',
                dataField:'phyZip',
                sort:true                
            },
            {
                text:'Distance',
                dataField:'distance',
                sort:true,
                hidden:distanceFlag
            },
            {
				text:'Add Date',
                dataField:'addDate',
                sort:true
            },
            {
				text:'NBR Power Unit',
                dataField:'nbrPowerUnit',
                sort:true
            },
            {
				text:'Driver Total',
                dataField:'driverTotal',
                sort:true
            },
            {
				text:'Status',
                dataField:'statusIcon'
            },
            {
				text:'',
                dataField:'viewCarrier'
            }
        ]
    }

    exportData = () => {        
        if(this.state.carrierList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.carrierList.map(row =>{
            let data = {...row};
            let dateVar="";
            if(row.addDate!="" && row.addDate != null)
            {
                dateVar=moment(row.addDate).format('MM-DD-YYYY');
            }
            data.addDate=dateVar;

            dateVar="";
            if(row.mcs150Date!="" && row.mcs150Date != null)
            {
                dateVar=moment(row.mcs150Date).format('MM-DD-YYYY');
            }
            data.mcs150Date=dateVar;

            dateVar="";
            if(row.createdTime!="" && row.createdTime != null)
            {
                dateVar=moment(row.createdTime).format('MM-DD-YYYY hh:mm A');
            }
            data.createdTime=dateVar;

            dateVar="";
            if(row.updatedTime!="" && row.updatedTime != null)
            {
                dateVar=moment(row.updatedTime).format('MM-DD-YYYY hh:mm A');
            }
            data.updatedTime=dateVar;

            return(data);
        });
        let fileName = 'Lead Carrier';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    addNewCarrier = (index) => {
        this.setState({
            ...this.state,
            pageFlag:'NEW',
            carrierData:{}
        });
    }

    editCarrier = (e, index, carrierData) => {
        let carrier_Data = carrierData;
        if(index || index==0){
            carrier_Data = this.state.carrierList[index];
        }
        this.setState({
            ...this.state,
            pageFlag:'EDIT',
            carrierData:carrier_Data
        });
    }
    
    closeForm = (rowsAffected) => {        
        console.log(rowsAffected);
        if(rowsAffected>0){
            this.getMyCarriers();
        } else {
            this.setState({
                ...this.state,
                pageFlag:'LIST',
                carrierData:{}
            });
        }
    }

    closeViewStatus = () => {
        this.setState({
            ...this.state,
            viewStatus:false,
            statusData:''
        });
    }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){
            let date1 = new Date();
            fromDate=date1;
            toDate=date1;
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        }else if(type=="RANGE"){
            return;            
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate(),
            activeFilter:'RANGE',
            radiusVal:'',
            zipVal:''
        });
    }
    
    setRadiusVal = (e) => {
        this.setState({
            ...this.state,
            radiusVal:e.target.value
        });
    }

    setZipVal = (e) => {
        this.setState({
            ...this.state,
            zipVal:e.target.value
        });
    }

    getDateLabel = () => {

        if(this.state.activeFilter == "TOP10" || this.state.activeFilter == "ALL") return <Fragment/>;
        else if(this.state.activeFilter == "ZIPRANGE"){
            return(<form id="frmZipRange">
            <div className="row mt-4 mb-4">
                <div className="form-group col col-lg-4">
                    <label>Zip Code</label>
                    <input type="text" className="form-control requiredfield json-col" id="zipVal" name="zipVal" placeholder="Zip Code" value={this.state.zipVal} onChange={(e) =>{this.setZipVal(e)}} />
                </div>
                <div className="form-group col col-lg-3 date-picker range-fields">
                    <label>Radius</label>
                    <input type="text" className="form-control requiredfield json-col" id="radiusVal" name="radiusVal" placeholder="Radius" value={this.state.radiusVal} onChange={(e) =>{this.setRadiusVal(e)}} />
                </div>                
                <div className="form-group col col-lg-2">
                    <button type="button" onClick={(e) =>{this.getZipRangeData(e)}} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                </div>
            </div></form>);
        } else {
        
            let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
            let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
            return(
                <div className="row mt-4 mb-4">
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                            Date {fromDate}
                        </div>
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                            Date {fromDate} to {toDate}
                        </div>
                    </div> 
            );
        }
    }

    importData = () =>{
        this.setState({
            ...this.state,
            importPopup:true
        });
    }

    closeImportPopup = () =>{
        this.setState({
            ...this.state,
            importPopup:false
        });
    }

    uploadData = () => {
        let importLeadCarrierFiles = document.getElementById('importLeadCarrier').files;
        
        if(importLeadCarrierFiles.length==0){
            toastr.info('Oops!', 'Please select file');
            return;
        }

        const selectedFile = importLeadCarrierFiles[0];
        let importedFile = new FormData();
        importedFile.append("file", selectedFile, selectedFile.name);

        let param ={
            payload:importedFile
        }

        importLeadCarriersService(param).then((response) =>{
            console.log(response);
            if(response && response.message)
            {
                toastr.success('Success', response.message);
                this.setState({
                    ...this.state,
                    importPopup:false
                });
            } else {
                toastr.error('Error', 'Unable to import data');
            }
        });

    }

    downloadSample = (e) =>{
        e.preventDefault();
        downloadSampleService().then((response) =>{
            if(response && response.LEAD_CARRIER_UPLOAD_FILE_PATH){
                window.open(response.LEAD_CARRIER_UPLOAD_FILE_PATH);
            } else {
                toastr.error('Error', 'Unable to download sample file');
            }
            console.log(response);
        });
    }

    render = () => {
        if(this.state.isReady){
            if(this.state.pageFlag=='LIST'){
                const { SearchBar } = Search;
                const customTotal = (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                    Showing { from } to { to } of { size } Results
                    </span>
                );
                
                const options = {
                    paginationSize: 4,
                    pageStartIndex: 1,      
                    firstPageText: 'First',
                    prePageText: 'Back',
                    nextPageText: 'Next',
                    lastPageText: 'Last',
                    nextPageTitle: 'First page',
                    prePageTitle: 'Pre page',
                    firstPageTitle: 'Next page',
                    lastPageTitle: 'Last page',
                    showTotal: true,
                    paginationTotalRenderer: customTotal,
                };

                const defaultSorted = [{
                    dataField: 'legalName',
                    order: 'asc'
                }];
                return(
                    <article className="shadow bg-white rounded" id="onboardpage">
                        <div className="row">
                            <div className="col">
                                <h3>Lead Carrier</h3>
                            </div>
                        </div>
                        <article className="table-data truck-data shadow bg-white rounded">
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <button type="button" className={(this.state.activeFilter == "TOP10")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TOP10')}}>Top 30 Power Unit</button> &nbsp; &nbsp;
                                    {/* <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                                    <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                                    <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp; */}
                                    <DateRangePicker
                                        initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                        onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} 
                                    >
                                        <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'RANGE')}}>Date Range</button>
                                    </DateRangePicker> &nbsp; &nbsp;
                                    <button type="button" className={(this.state.activeFilter == "ZIPRANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'ZIPRANGE')}}>Zip Range</button> &nbsp; &nbsp;
                                    <button type="button" className={(this.state.activeFilter == "ALL")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'ALL')}}>All</button>
                                </div>
                            </div>
                            {this.getDateLabel()}
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={ this.getRowData() }
                                        columns={ this.getTableColumns() }
                                        search
                                        >
                                        {
                                        props => (
                                            <div>                                            
                                                <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>
                                                <div className="btn-import float-right d-inline-block pr-2"><button onClick={(e)=>{this.importData()}} className='btn btn-primary' >Import</button></div>                                            
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div>
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.addNewCarrier(0)}} className='btn btn-primary' >Add Carrier</button></div>
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.getMyCarriers()}} className='btn btn-primary' >Refresh</button></div>
                                            <BootstrapTable
                                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                                defaultSorted={defaultSorted}
                                            />
                                            </div>
                                        )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </article>
                        <Modal show={this.state.viewStatus} id="verificationData">
                            <ModalHeader>
                                <ModalTitle>Carrier Status</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                {this.state.statusData}
                            </ModalBody>
                            <ModalFooter>					  
                                <button type="button" className="btn btn-primary" onClick={() => {this.closeViewStatus()}}>Close</button>
                            </ModalFooter>
                        </Modal>

                        <Modal show={this.state.importPopup} id="importPopup">
                            <ModalHeader>
                                <ModalTitle>Import</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                <input type="file" id="importLeadCarrier" className="form-contol" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                            </ModalBody>
                            <ModalFooter>
                                <a href="#" onClick={(e) =>{this.downloadSample(e)}} title="Download sample file">Download sample file</a>
                                <button type="button" className="btn btn-primary" onClick={() => {this.closeImportPopup()}}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => {this.uploadData()}}>Upload</button>
                            </ModalFooter>
                        </Modal>
                    </article>
                );
            } else if(this.state.pageFlag=='NEW' || this.state.pageFlag=='EDIT'){               
                return <AdminMyCarrierEntry carrierData={this.state.carrierData} flag={this.state.pageFlag} closeForm={this.closeForm} />
            } else if(this.state.pageFlag=='VIEW'){               
                return <AdminMyCarrierView carrierData={this.state.carrierData} closeForm={this.closeForm} editCarrier={this.editCarrier} />
            }
        } else {
            return(<Loader />);
        }
    }
}

export default AdminMyCarrier;