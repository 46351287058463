import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from '../../common/loader';
import {adminTrucksService} from '../../../services/trucksService';
class AdminCarrierTruckList extends Component{

    constructor(props){
        super(props);

        this.state ={
            isReady:false,
            trucks:[]
		}
    }

    componentDidMount = () => {
        this.getTrucks();
    }

    getTrucks = () => {
        this.setState({
            ...this.state,
            isReady:true,
            trucks:[]
        });
        let param = {
            payload:{
                assettype:'truck',
                companyid:this.props.selectedComapny
            }
        }
        adminTrucksService(param).then((response) =>{
            console.log(response);
            if(response.assetList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    trucks:response.assetList
                });
            }            
        });
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{        
        if(this.props.selectedComapny != prevProps.selectedComapny){
            this.getTrucks(); 
        }
    }

    getRowData = () => {
        return this.state.trucks.map(
            row =>{
                return({
                    id:row.assetId,
                    Truck:row.licensePlateNo,
                    Year:row.modelYear,
                    Make:row.make,
                    Model:row.model,
                    VIN:row.identificationNo,
                    Plate:row.licensePlateNo,
                    Color:row.color
                });
            }
        );
    }

    getTableColumns = () => {
        return [				
            {
              dataField: 'Truck',
              text: 'Asset #',
              sort: true
            },
            {
              dataField: 'Year',
              text: 'Year',
              sort: true
            },
            {
              dataField: 'Make',
              text: 'Make',
              sort: true
            },
            {
              dataField: 'Model',
              text: 'Model',
              sort: true
            },
            {
              dataField: 'VIN',
              text: 'VIN',
              sort: true
            },
            {
              dataField: 'Plate',
              text: 'Plate #',
              sort: true
            },
            {
              dataField: 'Color',
              text: 'Color',
              sort: true
            }];
    }

    render = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
              
              const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
              };
            return(
                <article className="table-data truck-data shadow bg-white rounded">			
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							  <li className="nav-item">
								<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i> My Trucks</a>
							  </li>							  
						</ul>
						<div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                    >
                                    {
                                    props => (
                                        <div>
                                        <SearchBar { ...props.searchProps } />
                                        <BootstrapTable
                                            { ...props.baseProps } noDataIndication={'No data found'}  pagination={ paginationFactory(options) } hover bordered={ false }/>
                                        </div>
                                    )
                                    }
                                </ToolkitProvider>
                            </div>							  														 
						</div>
					</div>
				</div>	
			    </article>
            );
        } else {
			return(<Loader />);
		}
    }
}

export default AdminCarrierTruckList;