import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Icofont from 'react-icofont';
import './widgets.css';
import $ from 'jquery';
class RoleMenu extends Component{

	state={
		userType:localStorage.getItem('userType')
	}

	loadSubMenu = (e, menu1) => {
		e.preventDefault();
		console.log(menu1);
		this.props.roleMenuClick(menu1);
	}

	componentDidMount(){
        // Header
        $("#navbarSupportedContent").on({
            mouseenter: function () {
                $(".navbar-nav").find('.nav-item > a').removeClass('active');
                $(this).find('a.nav-link').addClass('active');
                $(this).find('ul').toggle();
            },
            mouseleave:function () {
               $(this).find('a.nav-link').removeClass('active');
                $(this).find('ul').toggle();
            }
        },'li.dropdown-submenu');

        // $(".open-third").click(function(e) {
        //     let db=$(this).attr("data-open");
        //     $(db).slideToggle("fast", function() {
        //         // Animation complete.
        //     });
        // });    
    }

	render(){
		const userType=this.state.userType;
		
				return(
					<ul className="navbar-nav mr-auto">
						<li className="nav-item" id="admin_menu">
							<a className={this.props.activeMainMenu=="Admin"? "nav-link active": "nav-link" } href="#" role="button" onClick={(e) => {this.loadSubMenu(e, 'Admin')}}>
								<span><Icofont icon="architecture-alt"/></span>
								<span>Admin</span>
							</a>							
						</li>
						<li className="nav-item dropdown-submenu" id="admin_menu">
							<a className={this.props.activeMainMenu=="Reports"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Reports')}}>
								<span><Icofont icon="address-book"/></span>
								<span>Reports</span>                        
							</a>
							<ul className="dropdown-menu">
								<li>																		
									<a className="open-third" data-open="#two" href="#">Billing</a>
									<div className="dropdown-third" id="two">
										<NavLink to="/adminuser/reports/load_count" href="#">Load Count</NavLink>
										<NavLink to="/adminuser/reports/truck_count" href="#">Truck Count</NavLink>										
										<NavLink to="/adminuser/reports/hos_count" href="#">HOS Count</NavLink>
										
									</div>
								</li>
								<li>
									<a className="open-third" data-open="#one" href="#">Audit</a>
									<div className="dropdown-third" id="one">
										<NavLink to="/adminuser/reports/load_summary" href="#">Load</NavLink>
										<NavLink to="/adminuser/reports/truck_per_carrier" href="#">Truck Per Carrier</NavLink>
										<NavLink to="/adminuser/reports/active_trucks" href="#">Active Trucks</NavLink>
										<NavLink to="/adminuser/reports/trip_registration_summary" href="#">Trip Registration</NavLink>
										<NavLink to="/adminuser/reports/truck_summary" href="#">Truck</NavLink>
										<NavLink to="/adminuser/reports/database_summary" href="#">Data Base Summary</NavLink>
										{/* <NavLink to="/adminuser/reports/db_records_summary" href="#">DB Size & Records</NavLink> */}
										<NavLink to="/adminuser/reports/hos_summary" href="#">HOS</NavLink>
										<NavLink to="/adminuser/reports/login_report" href="#">Login logs</NavLink>
										<NavLink to="/adminuser/reports/onboarded_carriers" href="#">Active Carriers</NavLink>
									</div>
								</li>						
							</ul>
						</li>
						<li className="nav-item">
							<a className={this.props.activeMainMenu=="Users"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Users')}}>
								<span><Icofont icon="group"/></span>
								<span>Users </span>		        		
							</a>
						</li>
						{/* <li className="nav-item">
						 	<a className={this.props.activeMainMenu=="Driver"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Driver')}}>
						 		<span><Icofont icon="boy"/></span>
						 		<span>Driver</span>		        		
						 	</a>
						 </li>*/}
						<li className="nav-item">
							<a className={this.props.activeMainMenu=="Carrier"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Carrier')}}>
								<span><Icofont icon="truck-alt"/></span>
								<span>Carrier</span>	
							</a>
						</li>
						<li className="nav-item">
							<a className={this.props.activeMainMenu=="Broker"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Broker')}}>
								<span><Icofont icon="briefcase-1"/></span>
								<span>Broker</span>	
							</a>
						</li>
						<li className="nav-item">
							<a className={this.props.activeMainMenu=="Shipper"? "nav-link active": "nav-link" } href="#" onClick={(e) => {this.loadSubMenu(e, 'Shipper')}}>
								<span><Icofont icon="vehicle-delivery-van"/></span>
								<span>Shipper</span>	
							</a>
						</li>
					</ul>
				)
	}
}

export default RoleMenu
