import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { isEmpty } from '../../helpers/appUtil';
import ReactTooltip from 'react-tooltip';
import greenMarker from '../../images/facebook.png';
import webicon from '../../images/internet.png';
import webdefaulticon from '../../images/internetdefault.png';
import twittericon from '../../images/twitter.png';
import twitterdefaulticon from '../../images/twitter-sign.png';
import linkedinicon from '../../images/linkedin.png';
import linkedindefaulticon from '../../images/linkedindefault.png';
import instagramicon from '../../images/instagram.png';
import instagramdefaulticon from '../../images/instadefault.png';
import fbicon from '../../images/facebook.png';
import fbdefaulticon from '../../images/fb-default.png';




class AdminMyCarrierView extends Component {
    constructor(props) {
        super(props);
    }

    getWebsite = () => {
        if(isEmpty(this.props.carrierData.website)){
            return <img src={webdefaulticon} />
        } else {
            return <a href={this.props.carrierData.website} target="_blank" data-tip='' data-for="weblink">
                    <img src={webicon} />
                    <ReactTooltip id="weblink" >
                        <p>{this.props.carrierData.website}</p>
                    </ReactTooltip>
                </a> 
        }
    }

    getFacebook = () => {
        if(isEmpty(this.props.carrierData.facebook)){
            return <img src={fbdefaulticon} />
        } else {
            return <a href={this.props.carrierData.facebook} target="_blank" data-tip='' data-for="fblink">
                        <img src={fbicon} />
                        <ReactTooltip id="fblink" >
						    <p>{this.props.carrierData.facebook}</p>
					    </ReactTooltip>
                    </a>
        }
    }

    getTwitter = () => {
        if(isEmpty(this.props.carrierData.twitter)){
            return <img src={twitterdefaulticon} />
        } else {
            return <a href={this.props.carrierData.twitter} target="_blank" data-tip='' data-for="twitterlink">
                        <img src={twittericon} />                                        
                        <ReactTooltip id="twitterlink" >
                            <p>{this.props.carrierData.twitter}</p>
                        </ReactTooltip>
                    </a>
        }
    }

    getLinkedin = () => {
        if(isEmpty(this.props.carrierData.linkedin)){
            return <img src={linkedindefaulticon} />
        } else {
            return <a href={this.props.carrierData.linkedin} target="_blank" data-tip='' data-for="linkedinlink">
                        <img src={linkedinicon} />             
                        <ReactTooltip id="linkedinlink" >
                            <p>{this.props.carrierData.linkedin}</p>
                        </ReactTooltip>
            </a>
            
        }
    }

    getInstagram = () => {
        if(isEmpty(this.props.carrierData.instagram)){
            return <img src={instagramdefaulticon} />
        } else {
            return <a href={this.props.carrierData.instagram} target="_blank" data-tip='' data-for="instagramlink">
                        <img src={instagramicon} />
                        <ReactTooltip id="instagramlink" >
                            <p>{this.props.carrierData.instagram}</p>
                        </ReactTooltip>
            </a>
            
        }
    }

    render = () => {
        console.log(this.props.carrierData);
        let addDate = "";
        if (this.props.carrierData.addDate != "" && this.props.carrierData.addDate != null) {
            addDate = moment(this.props.carrierData.addDate).format('MM-DD-YYYY');
        }

        let MCS150Date = "";
        if (this.props.carrierData.mcs150Date != "" && this.props.carrierData.mcs150Date != null) {
            MCS150Date = moment(this.props.carrierData.mcs150Date).format('MM-DD-YYYY');
        }

        return (

            <article className="shadow bg-white rounded" id="onboardpage">
                <div className="row">
                    <div className="col">
                        <h3>Carrier Details</h3>
                    </div>
                </div>
                <p>&nbsp;</p>
                <table className="table myTable">
                    <tbody>
                        <tr>
                            <td className="tableCellBold">Legal Name: </td>
                            <td>{this.props.carrierData.legalName}</td>
                            <td className="tableCellBold">DOT Number: </td>
                            <td>{this.props.carrierData.dotNumber}</td>
                            <td className="tableCellBold">Phone: </td>
                            <td>{this.props.carrierData.telphone}</td>
                            <td className="tableCellBold">Email: </td>
                            <td>{this.props.carrierData.emailAddress}</td>
                        </tr><tr>
                            <td className="tableCellBold">Website: </td>
                            <td>{this.getWebsite()}</td>
                            <td className="tableCellBold" >Facebook: </td>
                            <td>{this.getFacebook()}</td>                        
                            <td className="tableCellBold">Twitter: </td>
                            <td>{this.getTwitter()}</td>
                            <td className="tableCellBold">Linkedin: </td>
                            <td>{this.getLinkedin()}</td>
                        </tr><tr>
                            <td className="tableCellBold">Instagram: </td>
                            <td>{this.getInstagram()}</td> 
                            <td className="tableCellBold">ELD: </td>
                            <td>{this.props.carrierData.eld}</td>
                            <td className="tableCellBold">Street: </td>
                            <td>{this.props.carrierData.phyStreet}</td>
                            <td className="tableCellBold">City: </td>
                            <td>{this.props.carrierData.phyCity}</td>
                        </tr><tr>
                            <td className="tableCellBold">State: </td>
                            <td>{this.props.carrierData.phyState}</td>
                            <td className="tableCellBold">Zip: </td>
                            <td>{this.props.carrierData.phyZip}</td>
                            <td className="tableCellBold">Country: </td>
                            <td>{this.props.carrierData.phyCountry}</td>
                            <td className="tableCellBold">Operation Status: </td>
                            <td>{this.props.carrierData.operationStatus}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">Carrier Operation: </td>
                            <td>{this.props.carrierData.carrierOperation}</td>
                            <td className="tableCellBold">MCS 150 Mileage: </td>
                            <td>{this.props.carrierData.mcs150Mileage}</td>
                            <td className="tableCellBold">MCS 150 Mileage Year: </td>
                            <td>{this.props.carrierData.mcs150MileageYear}</td>
                            <td className="tableCellBold">OIC State: </td>
                            <td>{this.props.carrierData.oicState}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">DBA Name: </td>
                            <td>{this.props.carrierData.dbaName}</td>
                            <td className="tableCellBold">Mailing Street: </td>
                            <td>{this.props.carrierData.mailingStreet}</td>
                            <td className="tableCellBold">Mailing City: </td>
                            <td>{this.props.carrierData.mailingCity}</td>
                            <td className="tableCellBold">Mailing State: </td>
                            <td>{this.props.carrierData.mailingState}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">Mailing Zip: </td>
                            <td>{this.props.carrierData.mailingZip}</td>
                            <td className="tableCellBold">Mailing Country: </td>
                            <td>{this.props.carrierData.mailingCountry}</td>
                            <td className="tableCellBold">Fax: </td>
                            <td>{this.props.carrierData.fax}</td>
                            <td className="tableCellBold">HM Flag: </td>
                            <td>{this.props.carrierData.hmFlag}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">PC Flag: </td>
                            <td>{this.props.carrierData.pcFlag}</td>
                            <td className="tableCellBold">NBR Power Unit: </td>
                            <td>{this.props.carrierData.nbrPowerUnit}</td>
                            <td className="tableCellBold">Driver Total: </td>
                            <td>{this.props.carrierData.driverTotal}</td>
                            <td className="tableCellBold">Add Date: </td>
                            <td>{addDate}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">MCS 150 Date: </td>
                            <td>{MCS150Date}</td>
                            <td className="tableCellBold">Status: </td>
                            <td>{this.props.carrierData.status}</td>
                            <td className="tableCellBold"></td>
                            <td></td>
                            <td className="tableCellBold"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="8" className="text-center">
                                <button type="button" className="btn btn-secondary" onClick={(e) => { this.props.closeForm(0) }}>Close</button>
                                &nbsp;
                                <button type="button" className="btn btn-secondary" onClick={(e) => { this.props.editCarrier(null, null, this.props.carrierData) }}>Edit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </article>
        );
    }
}
export default AdminMyCarrierView;