import React, { Component } from 'react';
import Icofont from 'react-icofont';
import ReactTooltip from 'react-tooltip';
import loadVehicleicon from '../../images/loadedtruck.png';
import {formatLocation} from '../../helpers/appUtil';
import LoadingSpinner from '../../helpers/LoadingSpinner';
import moment from 'moment';
import $ from 'jquery';

class DashboardTrucks extends Component{

	constructor(props){
		super(props);
		this.timerSrc=null;
		this.srcData='';
	}

	handleClick = (arg1,arg2) =>{
		var checked = document.getElementById("cb"+arg1.id).checked;
		console.log(arg1,checked);
		var arr = [];
		if(arg2.target.className  == 'icofont-circled-right'){
			arg2.target.className = "icofont-circled-down";
			arr.push(arg1);
			if(checked) this.props.truckClicked(arr);		
		}else{
			arg2.target.className = "icofont-circled-right";
			this.props.truckClicked(arr);	
		}		
	}	
	handleChecked = (arg) => {
		var remainingEle = this.props.selectedData.filter(function(truck){
			var checked = document.getElementById("cb"+truck.id).checked;
			return checked !== false; 
		});		
		this.props.checkList(remainingEle);		
	}

	// truckClick = (e, truck) => {
	// 	let arg2 = e.target.closest('label').firstElementChild.firstElementChild;
	// 	if(arg2.className  == 'icofont-circled-right'){
	// 		arg2.className = "icofont-circled-down";					
	// 	}else{
	// 		arg2.className = "icofont-circled-right";
	// 	}
	// 	this.props._truckClick(truck);		
	// }

	truckClick = (e, truck) => {
		let activeTag = e.target.tagName.toLowerCase();
		let activeEl = null;
		if(activeTag=='a'){
			activeEl = e.target;
		} else {
			activeEl = e.target.parentElement;
			activeTag = activeEl.tagName.toLowerCase();
			if(activeTag!='a'){
				activeEl = activeEl.parentElement;
			}
		}
		//console.log(activeEl.querySelector('i').className);
		try{
			let activeClass = activeEl.querySelector('i').className;
			activeEl.querySelector('i').className = (activeClass == 'icofont-circled-right') ? "icofont-circled-down" : 'icofont-circled-right';
		} catch (error) {
			console.log(error);
		} 
		this.props._truckClick(truck);
	}

	searchTrucks = (e) => {		
		this.srcData=$(e.target).val().trim();
		if(this.timerSrc){
			clearTimeout(this.timerSrc);
		}
		this.timerSrc = setTimeout(
			function() {				
				clearTimeout(this.timerSrc);
				this.timerSrc=null;
				this.setState({});
			}
			.bind(this),
			500
		);
		//console.log(this.props.stopped);
	}

	filterData = (data) => {
		if(!(data.length)) return [];

		if(this.srcData.length==0) return data;

		let userType = localStorage.getItem('userType').toLowerCase();

		let expr = RegExp(this.srcData, 'i');
		let row = data.filter((item,index) =>{			
			return expr.test((userType=="shipper" ? item.shipperRefNumber : item.truckNo));
		});
		return row;
	}

	render(){
		console.log(this.props.loading);
		//console.log($('.menu-item-wrapper.active > button').html());
		//console.log(this.filterData(this.props.stopped));
		let userType = localStorage.getItem('userType').toLowerCase();
		console.log(userType);
		let label = userType == "shipper"?"Load":"Trucks";
		label = $('.menu-item-wrapper.active > button').html() + " " + label;

		let stopped = this.filterData(this.props.stopped);
		let idle    = this.filterData(this.props.idle);
		let active  = this.filterData(this.props.active);

		let activeData = active.map((row, index) =>{
			return (
				<div className="card" key={index}>
					<div className="card-header" id={"activeHedaing" + row.id}>
						<a href="#" onClick={(e) =>{e.preventDefault(); this.truckClick(e, row)}} data-toggle="collapse" data-target={"#activeCollapse" + row.id} aria-expanded="true" aria-controls={"activeCollapse" + row.id}>
							<i className="icofont-circled-right" ></i>&nbsp;
							{`Truck No: ${row.truckNo?row.truckNo:''}`}
							<span className="float-right" style={{color:'green'}}>Active <Icofont icon="ui-press"/></span>
						</a>
					</div>
					<div id={"activeCollapse" + row.id} className="collapse" aria-labelledby={"activeHedaing" + row.id} data-parent="#active_stats">
						<div className="card-body">
							<ul>
								<li>
									<span>Date:</span><span>{row.convertedDate}</span>
								</li>
								<li>
									<span>Location:</span><span>{row.location}</span>
								</li>
								<li>
									<span>Reason:</span><span>-</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		});

		let idleData = idle.map((row, index) =>{
			return (
				<div className="card" key={index}>
					<div className="card-header" id={"idleHeading" + row.id}>
						<a href="#" onClick={(e) =>{e.preventDefault(); this.truckClick(e, row)}} data-toggle="collapse" data-target={"#idleCollapse" + row.id} aria-expanded="true" aria-controls={"idleCollapse" + row.id}>
							<i className="icofont-circled-right" ></i>&nbsp;
							{`Truck No: ${row.truckNo?row.truckNo:''}`}
							<span className="float-right" style={{color:'#fdc800'}}>Idle <Icofont icon="ui-press"/></span>
						</a>
					</div>
					<div id={"idleCollapse" + row.id} className="collapse" aria-labelledby={"idleHeading" + row.id} data-parent="#idle_stats">
						<div className="card-body">
							<ul>
								<li>
									<span>Date:</span><span>{row.convertedDate}</span>
								</li>
								<li>
									<span>Location:</span><span>{row.location}</span>
								</li>
								<li>
									<span>Reason:</span><span>-</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		});

		let stoppedData = stopped.map((row, index) =>{
			return (
				<div className="card" key={index}>
					<div className="card-header" id={"stoppedHedaing" + row.id}>
						<a href="#" onClick={(e) =>{e.preventDefault(); this.truckClick(e, row) }} data-toggle="collapse" data-target={"#stoppedCollapse" + row.id} aria-expanded="true" aria-controls={"stoppedCollapse" + row.id}>
							<i className="icofont-circled-right" ></i>&nbsp;
							{`Truck No: ${row.truckNo?row.truckNo:''}`}
							<span className="float-right" style={{color:'red'}} > Stopped <Icofont icon="ui-press"/></span>
						</a>
					</div>
					<div id={"stoppedCollapse" + row.id} className="collapse" aria-labelledby={"stoppedHedaing" + row.id} data-parent="#stopped_stats">
						<div className="card-body">
							<ul>
								<li>
									<span>Date:</span><span>{row.convertedDate}</span>
								</li>
								<li>
									<span>Location:</span><span>{row.location}</span>
								</li>
								<li>
									<span>Reason:</span><span>-</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className="truck-status">
				<h4 className="page-title">{label} Status</h4>
				<ul className="nav nav-tabs flex-column flex-sm-row" id="myTab" role="tablist">
					<li className="nav-item flex-sm-fill text-sm-center">
						<a className="nav-link active" onClick={()=>{this.props.tabClicked(1);}} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Active [{this.props.active.length}]</a>
					</li>
					<li className="nav-item flex-sm-fill text-sm-center">
						<a className="nav-link" onClick={()=>{this.props.tabClicked(2);}} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Idle [{this.props.idle.length}] </a>
					</li>
					<li className="nav-item flex-sm-fill text-sm-center">
						<a className="nav-link" onClick={()=>{this.props.tabClicked(3);}} id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Stopped [{this.props.stopped.length}] </a>
					</li>
				</ul>
				<div className="tab-search">
					<form onSubmit={e => { e.preventDefault(); }}>
						<div className="form-group form-icon" id="searchicondashboard">
							<input type="text" onKeyUp={(e) => {this.searchTrucks(e)}} className="form-control control-custom" id="truckSearch" placeholder={"Search "+label} />  
							<span><Icofont icon="search"/></span>  						
							</div>
					</form>
				</div>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="accordion" id="active_stats">
							{this.props.loading ? <LoadingSpinner /> : activeData}
						</div>
					</div>
					<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
						<div className="accordion" id="idle_stats">
							{this.props.loading ? <LoadingSpinner /> : idleData}
						</div>
					</div>
					<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<div className="accordion" id="stopped_stats">
							{this.props.loading ? <LoadingSpinner /> : stoppedData}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DashboardTrucks