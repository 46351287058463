import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { loadActionfrcancelled } from '../../actions/loadActions';
import moment from 'moment';
import { STATUS } from '../../helpers/status';

class cancelledLoadpopup extends Component { 
   
    render(){   
        var cancelledloads = [];
        var indicationhtml = "No Data Found";			
        if(this.props.loading){
			var indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}
		else{
            if(this.props.cancelledloadlist)
            {
                cancelledloads = []	;
                cancelledloads = this.props.cancelledloadlist.LoadList.map(
                    row =>{
                        var stringLoadDate="";
						if(row.stringLoadDate!="")
						{
							stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else
						{
							stringLoadDate= "";
                        }
                        var pickUpLocation = "";
                        if(row.orginCityName!==null && row.orginStateCode!==null && row.orginZipValue!==null)
                        {
                            var pickUpLocation = row.orginCityName+', '+row.orginStateCode+', '+row.orginZipValue
                        }
                        else if(row.orginCityName!==null && row.orginStateCode!==null)
                        {
                            var pickUpLocation = row.orginCityName+', '+row.orginStateCode
                        }
                        else if(row.orginCityName!==null && row.orginZipValue!==null)
                        {
                            var pickUpLocation = row.orginCityName+', '+row.orginZipValue
                        }
                        else if(row.orginCityName!==null)
                        {
                            var pickUpLocation = row.orginCityName
                        }
                        else if(row.orginStateCode!==null)
                        {
                            var pickUpLocation =  row.orginStateCode
                        }
                        else if(row.orginZipValue!==null)
                        {
                            var pickUpLocation =  row.orginZipValue
                        }
                        
                        var DeliveryLocation = "";
                        if(row.destinationCityName!==null && row.destinationStateCode!==null && row.destinationZipValue!==null)
                        {
                            var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode+', '+row.destinationZipValue
                        }
                        else if(row.destinationCityName!==null && row.destinationStateCode!==null)
                        {
                            var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                        }
                        else if(row.destinationCityName!==null && row.destinationZipValue!==null)
                        {
                            var DeliveryLocation = row.destinationCityName+', '+row.destinationZipValue
                        }
                        else if(row.destinationCityName!==null)
                        {
                            var DeliveryLocation = row.destinationCityName
                        }
                        else if(row.destinationStateCode!==null)
                        {
                            var DeliveryLocation =  row.destinationStateCode
                        }
                        else if(row.destinationZipValue!==null)
                        {
                            var DeliveryLocation =  row.destinationZipValue
                        }
                        return({
                            id:row.loadId,
                            loadNumber:row.loadNumber,
                            stringLoadDate:stringLoadDate,						
                            customerName:row.customerName,
                            shipperName:row.shipperName,
                            brokerName:row.brokerName,
                            weight:row.weight,
                            equipmentType:row.truckTypeName,
                            shipperref:row.shipperReferenceNumber,
                            pickUpLocation:pickUpLocation,
                            DeliveryLocation:DeliveryLocation,
                        });
                    }
                );
                
            }
            else
            {
                cancelledloads = [];
                var indicationhtml = "No Data Found";				
            }
        }
        const { SearchBar } = Search;
        const columns=[
            {
                dataField:'loadSelInput',
                text:''
            },	
			{
				text:'Load #',
				dataField:'loadNumber',
				sort:true
			},
			{
				text:'Date',
				dataField:'stringLoadDate',
				sort:true
			},
			{
				text:'Shipper',
				dataField:'shipperName',
				sort:true
            },
            {
				text:'Shipper Ref',
				dataField:'shipperref',
				sort:true
			},           
            {
				text:'Broker',
				dataField:'brokerName'
			},
			{
				text:'Weight',
				dataField:'weight',
				sort:true
			},			
            {
				text:'Equipment',
				dataField:'equipmentType',
				sort:true
            },            
            {
				text:'Origin',
				dataField:'pickUpLocation',
				sort:true
            },
			{
				text:'Destination',
				dataField:'DeliveryLocation',
				sort:true
			},
		
         ];
	
      const customTotal = (from, to, size) => (
       <span className="react-bootstrap-table-pagination-total">
         Showing { from } to { to } of { size } Results
       </span>
     );
     
     const options = {
       paginationSize: 4,
       pageStartIndex: 1,      
       firstPageText: 'First',
       prePageText: 'Back',
       nextPageText: 'Next',
       lastPageText: 'Last',
       nextPageTitle: 'First page',
       prePageTitle: 'Pre page',
       firstPageTitle: 'Next page',
       lastPageTitle: 'Last page',
       showTotal: true,
       paginationTotalRenderer: customTotal,
     };
    return (
        <div className="modal fade cancelledloadmodal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title doc-color" id="exampleModalLabel">Cancelled Loads</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body table-data">
                <ToolkitProvider
									keyField="id"
									data={ cancelledloads }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover  bordered={false}
										/>
										</div>
									)
									}
								</ToolkitProvider>
                 
                </div>
               
            </div>
        </div>
       </div>
      )
    }
}
  const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error,
	}
}
export default connect(mapStateToProps)(cancelledLoadpopup);