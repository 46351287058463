export const polyLien1 = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,  
    zIndex: 1
};

export const mapStyle = {
    width: '100%',
    height: '80vh'
  };

export const TrackPoints1 = {
    strokeColor: '#1C7EEF',
    strokeOpacity: 0.8,
    strokeWeight: 0,
    fillColor: '#1C7EEF',
    fillOpacity: 0.8,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 1500,
    zIndex: 1
}

export const Clusterers1 = {
    imagePath:
      'http://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
  };