import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { loadReportService,loadStatusService,tripRegistarionReportService,truckSummaryReportService,LoadSummaryReportService,DBFileSizeReport,DBFileRecordSizeReport,HOSreportService, LoginreportService } from '../services/reportService';
import * as types from '../actions';


function* loadReports(payload) {
    try {   
            const response = yield call(loadReportService, payload);
            yield put({type: types.GET_LOAD_REPORTS_SUCCESS, data: response,responseData: payload.payload});
    } catch (e) {
            yield put({type: types.GET_LOAD_REPORTS_ERROR, error: e.message});
    }
}

function* loadStatus(payload) {
    try {   
            const response = yield call(loadStatusService, payload);
            yield put({type: types.GET_LOAD_STATUS_SUCCESS, data: response});
    } catch (e) {
            yield put({type: types.GET_LOAD_STATUS_ERROR, error: e.message});
    }
}

function* tripRegStatus(payload) {
        try {   
                const response = yield call(tripRegistarionReportService, payload);
                yield put({type: types.TRIP_REG_REPORT_STATUS_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.TRIP_REG_REPORT_STATUS_ERROR, error: e.message});
        }
    }

 function* truckSummaryStatus(payload) {
        try {   
                const response = yield call(truckSummaryReportService, payload);
                yield put({type: types.TRUCK_SUMMARY_REPORT_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.TRUCK_SUMMARY_REPORT_ERROR, error: e.message});
        }
        }

function* LoadSummaryStatus(payload) {
        try {   
                const response = yield call(LoadSummaryReportService, payload);
                yield put({type: types.LOAD_SUMMARY_REPORT_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.LOAD_SUMMARY_REPORT_ERROR, error: e.message});
        }
        }

function* DBFileSizeSaga(payload) {
        try {   
                const response = yield call(DBFileSizeReport, payload);
                yield put({type: types.FILE_SIZE_DB_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.FILE_SIZE_DB_ERROR, error: e.message});
        }
        }

function* DBFileRecordsSizeSaga(payload) {
        try {   
                const response = yield call(DBFileRecordSizeReport, payload);
                yield put({type: types.FILE_RECORDS_SIZE_DB_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.FILE_RECORDS_SIZE_DB_ERROR, error: e.message});
        }
        }

function* HosReportSaga(payload) {
        try {   
                const response = yield call(HOSreportService, payload);
                yield put({type: types.HOS_REPORT_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.HOS_REPORT_ERROR, error: e.message});
        }
        }
        
function* LoginReportSaga(payload) {
        try {   
                const response = yield call(LoginreportService, payload);
                yield put({type: types.LOGIN_SUMMARY_REPORT_SUCCESS, data: response});
        } catch (e) {
                yield put({type: types.LOGIN_SUMMARY_REPORT_ERROR, error: e.message});
        }
        }


export function* reportSaga() {  
    yield takeEvery(types.GET_LOAD_REPORTS, loadReports);
    yield takeEvery(types.GET_LOAD_STATUS, loadStatus);
    yield takeEvery(types.TRIP_REG_REPORT_STATUS, tripRegStatus);
    yield takeEvery(types.TRUCK_SUMMARY_REPORT, truckSummaryStatus); 
    yield takeEvery(types.LOAD_SUMMARY_REPORT, LoadSummaryStatus);
    yield takeEvery(types.FILE_SIZE_DB, DBFileSizeSaga);
    yield takeEvery(types.FILE_RECORDS_SIZE_DB, DBFileRecordsSizeSaga);
    yield takeEvery(types.HOS_REPORT, HosReportSaga);
    yield takeEvery(types.LOGIN_SUMMARY_REPORT, LoginReportSaga);
}