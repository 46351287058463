import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import moment from 'moment';
import AddMenuPopUp from './AddMenuPopUp';
import { menuListService } from '../../services/userService';



//import AddDriverPopUp from '../carriers/drivers/AddDriverPopUp';



class AdminMenu extends Component {

    constructor(props) {
        super(props);
        this.app_url = process.env.REACT_APP_URL;
        this.state = {
            menuList: [],

        }


    }
    componentDidMount = () => {
        document.title = 'Menu List';
        this.getMenuList();

    }



    getMenuList = () => {
        menuListService({ companytypeid: 1 }).then((response) => {
            console.log(response);
            if (response.MenuList) {
                this.setState({
                    ...this.state,
                    isReady: true,
                    menuList: response.MenuList
                });
            }

        });
        console.log(this.state);
    }

    getMenuListData = () => {
        let menuData = [];
        console.log(this.state.menuList);
        menuData = this.state.menuList.map((data, index) => {
            console.log(data);
            return {
                menuId: data.menuId,
                moduleName: data.moduleName,
                menuName: data.menuName,
                menuType: data.menuType,
                menuUrl: data.menuUrl,
                parentMenuId: data.parentMenuId,

                // isVerified:(data.isVerified==1?'Yes':'No') ,  
                // actions:(!data.isVerified?<Fragment>
                //     <button className="btn btn-primary btn-sm" onClick={(e) =>{this.verifyEmail(e,data)}}>Verify</button>
                // </Fragment>:'')
            }
        });
        return menuData;
    }

    render() {
        let menu = this.state.menulist;
        var menuLists = [];

        const { SearchBar } = Search;
        const columns = [
            {
                text: 'Module Id',
                dataField: 'menuId',
                sort: true
            },
            {
                text: 'Module Name',
                dataField: 'moduleName',
                sort: true
            },
            {
                text: 'Menu Name',
                dataField: 'menuName',
                sort: true
            },
            {
                text: 'Menu Type',
                dataField: 'menuType',
                sort: true
            },
            // {
            //     text: 'Display Text',
            //     dataField: 'displayText',
            //     sort: true
            // },
            {
                text: 'Menu Url',
                dataField: 'menuUrl',
                sort: true
            },
            {
                text: 'Menu Parent Id',
                dataField: 'menuParentId',
                sort: true
            }
        ]
        
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
        );
        
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,      
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };

        const defaultSorted = [{
            dataField: 'companyName',
            order: 'asc'
          }];

        return (

            <article className="table-data truck-data shadow bg-white rounded">
                <div className="row no-gutters">
                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-md-12">
                                <h2>Menu</h2>
                            </div>
                        </div>


                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="drivers" role="tabpanel" aria-labelledby="carriers-tab">
                                <ToolkitProvider
                                    keyField="id"
                                    data={this.getMenuListData()}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                                <div className="btn-export float-right d-inline-block pr-2">
                                                    <a href="#" className="btn btn-primary" data-toggle="modal" data-target=".bd-trucks-modal-lg">Add Menu</a>
                                                </div>

                                                <BootstrapTable
                                                    {...props.baseProps} pagination={paginationFactory(options)} noDataIndication={'No data found'} hover bordered={false}
                                                    defaultSorted={defaultSorted}
                                                />
                                            </div>
                                        )
                                    }

                                </ToolkitProvider>

                            </div>

                            <div>
                                <AddMenuPopUp statelist={this.props.statelist} refreshParent={this.refreshMenu} />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        )

    }



}


export default AdminMenu;