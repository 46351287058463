import React, { Component } from 'react';
import logo from '../../truckergig-logo.png';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../helpers/FormControls';
import { validate } from './forgtpwdvalidation';
import { SaveNewPwd,SavePwdFlag } from '../../actions/authActions';
import { history } from '../../helpers/history';
import { toastr } from 'react-redux-toastr';

require('dotenv').config();

function submit(values)
{
	const frmData = {};
    frmData.token = this.state.tokenVal;
    frmData.newpassword = (values.confirmpwd) ? (values.confirmpwd) : ("");
	this.props.dispatch(SaveNewPwd(frmData));
}

class Resetpwd extends Component{
	constructor(props){
		super(props);
    }

    state={
        tokenVal:''
    }
    
	componentDidMount() {
        document.title = "Reset Password";
        if(this.props.location.search){
            let urlparam = this.getUrlParams();
            if(urlparam.params){               
               this.setState({
                 tokenVal:urlparam.params
               })
            }
            else{
                this.props.history.push("/login");
            } 
        }
        else{
            this.props.history.push("/login");
        } 
    }
    
    getUrlParams = () => {
        let urlparam = this.props.location.search.substring(1);
        urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        return urlparam;
    }

    componentDidUpdate=()=>
	{
		if(this.props.savepwdflag==true)
		{
			if(this.props.savepasswordVal.message=="Email has been Valid"){
				toastr.info('Info', "Password Changed Successfully");
				this.props.dispatch(SavePwdFlag())
				history.push("/login");
			}
			else if(this.props.savepasswordVal.message=="Mail has been expired"){
                toastr.error('Error', "Mail has been expired");
                this.props.dispatch(SavePwdFlag())
                history.push("/login");
            }
            else if(this.props.savepasswordVal.message=="Mail Already Send"){
                toastr.error('Error', "Mail Already Send");
                this.props.dispatch(SavePwdFlag())
                history.push("/login");
			}
		}
	}

	render(){
        const { handleSubmit } = this.props
		return(	
			<html lang="en">			
			<body>
			  <div className ="container">
			      <div className="mx-auto frgtpwd">
                         <div className="column columnclass">
						     <div className="img text-center" >
								<img src={logo} />
							</div>
							<div className="text text-center" style ={{padding: '1rem'}}>
								<h5>Reset Password? </h5>
							</div>
							<form onSubmit={handleSubmit(submit.bind(this))}>
							    <div className="form-group">
                                    <Field type="password" className="form-control"  controlClass="requiredfield" name="newpwd" id="email" component={renderField} label="New Password" />                          
                                </div>
                                <div className="form-group">
                                    <Field type="password" className="form-control"  controlClass="requiredfield" name="confirmpwd" id="email" component={renderField} label="Confirm Password" />                          
                                </div>
								<div className="row">
                                    <div className="col col-md-12">
                                    <div className="form-group btnresetpwd">
                                        <button type="submit" className="btn btn-primary btn-sm">Reset Password</button>&nbsp;</div>
                                    </div>
                                </div>
							</form>
						 </div>
				    </div>
              </div>		
			</body>
	    </html>
		)
	}
}


const mapStateToProps = state => {
return {
        data: state.authReducer.data,
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        savepasswordVal:state.authReducer.data,
        savepwdflag:state.authReducer.SavePwdFlag
    }
}
export default connect(mapStateToProps) (reduxForm({
    form: 'addtruckValidation',  
    validate                
})(Resetpwd))