import React, { Component, Fragment } from 'react';
import Loader from '../../common/loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {carrierLoadListService} from '../../../services/adminService';

import ReactTooltip from 'react-tooltip';
import moment from 'moment';
class AdminBrokerAssignedLoad extends Component{
    constructor(props){
        super(props);

        this.state ={
            isReady:false,
            loadList:[]
        }
    }

    componentDidMount = () => {
        this.getLoadList();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{        
        if(this.props.selectedComapny != prevProps.selectedComapny){            
            this.getLoadList();
        }
    }

    getLoadList = () => {
        this.setState({
            isReady:false,
            loadList:[]
        });
        let param = {
            payload:{
                "loadStatusId":this.props.statusData.loadStatusId,
                "companyId":this.props.selectedComapny
            }
        }
        carrierLoadListService(param).then((response) =>{
            if(response.LoadList){
                this.setState({
                    isReady:true,
                    loadList:response.LoadList
                });
            } else {
                this.setState({
                    isReady:true
                });
            }
        });
    }

    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {
            return this.state.loadList.map(
                (row,index) =>{
                    var drivername='';
                    if(row.driverName!==null)
                    {
                        drivername = row.driverName.toUpperCase()
                    }else
                    {
                        drivername = '-'
                    }
                    var stringLoadDate="";
                    if(row.stringLoadDate!="")
                    {
                        stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
                    }
                    else
                    {
                        stringLoadDate= "";
                    }
                    var pickUpLocation = "";
                    if(row.orginCityName!==null && row.orginStateCode!==null && row.orginZipValue!==null)
                    {
                        var pickUpLocation = row.orginCityName+', '+row.orginStateCode+', '+row.orginZipValue
                    }
                    else if(row.orginCityName!==null && row.orginStateCode!==null)
                    {
                        var pickUpLocation = row.orginCityName+', '+row.orginStateCode
                    }
                    else if(row.orginCityName!==null && row.orginZipValue!==null)
                    {
                        var pickUpLocation = row.orginCityName+', '+row.orginZipValue
                    }
                    else if(row.orginCityName!==null)
                    {
                        var pickUpLocation = row.orginCityName
                    }
                    else if(row.orginStateCode!==null)
                    {
                        var pickUpLocation =  row.orginStateCode
                    }
                    else if(row.orginZipValue!==null)
                    {
                        var pickUpLocation =  row.orginZipValue
                    }
                    
                    var DeliveryLocation = "";
                    if(row.destinationCityName!==null && row.destinationStateCode!==null && row.destinationZipValue!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode+', '+row.destinationZipValue
                    }
                    else if(row.destinationCityName!==null && row.destinationStateCode!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                    }
                    else if(row.destinationCityName!==null && row.destinationZipValue!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationZipValue
                    }
                    else if(row.destinationCityName!==null)
                    {
                        var DeliveryLocation = row.destinationCityName
                    }
                    else if(row.destinationStateCode!==null)
                    {
                        var DeliveryLocation =  row.destinationStateCode
                    }
                    else if(row.destinationZipValue!==null)
                    {
                        var DeliveryLocation =  row.destinationZipValue
                    }
                    var pickUpdate = "";
                    if(row.loadPointsList.length>0)
                    {                        
                        if(row.loadPointsList[0].loadPointTypeId==2)
                        {
                            if(row.loadPointsList[0].earliestTime!="")
                            {
                                var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                            }
                        }
                    }
                    return({
                        id:row.loadId,
                        loadNumber:row.loadNumber,
                        stringLoadDate:stringLoadDate,
                        shipperName:row.shipperName,
                        customerName:row.customerName,
                        carrierName:row.carrierName,
                        consigneeName:row.consigneeName,
                        consigneeAddress:((row.consigneeAddress1===null?'':row.consigneeAddress1) + " " + (row.consigneeAddress2===null?'':row.consigneeAddress2)),
                        weight:row.weight,
                        originCityName:row.orginCityName,
                        destinationCityName:row.destinationCityName,
                        commodity:row.commodity,
                        equipmentType:row.truckTypeName,
                        rate:row.rate,
                        carrierTenderPrice:row.carrierTenderPrice,
                        totalBrokerNet:row.totalBrokerNet,                        
                        loadPointsList:row.loadPointsList,
                        basecharge:row.baseCharges,
                        fulecharge:row.fuelCharges,
                        accessiorialcharge:row.accessorialCharges,
                        totalrevenue:row.totalRevenue,
                        loadedmiles:row.loadedMiles,
                        emptymiles:row.emptyMiles,
                        totalmiles:row.totalMiles,
                        driverpay:row.driverPay,
                        tenderprice:row.shipperLoadPrice,
                        originState:row.orginStateName,
                        carrierTenderPrice:row.carrierTenderPrice,
                        destinationState:row.destinationStateName,
                        assetName:row.assetName,
                        driverName:drivername,
                        distance:row.distance,
                        shipperref:row.customerLoadNumber,
                        checkboxvalue:this.state.checkboxval,
                        pickUpLocation:pickUpLocation,
                        DeliveryLocation:DeliveryLocation,
                        pickUpdate:pickUpdate,
                    });
                }
            );
        }
    }

    getTableColumns = () => {
        return [	
            {
                text:'Load #',
                sort:true,
                dataField:'loadNumber'
            },
            {
                text:'Date',
                sort:true,
                dataField:'stringLoadDate'
            },
            {
                text:'Shipper',
                sort:true,
                dataField:'shipperName'
            },	
            {
                text:'Shipper Ref',
                dataField:'shipperref',
                sort:true
            },	
            {
                text:'Carrier',
                sort:true,
                dataField:'carrierName'
            },
            {
                text:'Truck No',
                sort:true,
                dataField:'assetName'
            },
            {
                text:'Driver',
                sort:true,
                dataField:'driverName'
            },
            {
                text:'Origin',
                dataField:'pickUpLocation',
                sort:true
            },
            {
                text:'Destination',
                dataField:'DeliveryLocation',
                sort:true
            },
            {
                text:'Pickup Date / Time',
                sort:true,
                dataField:'pickUpdate'
            }
        ];
    }

    expandRow = () =>{
        return {	
            renderer: row => (	
                <article className="table-data truck-data shadow bg-white rounded">  
                   <div className="row no-gutters">
                    <div className="col-md-12">
                        
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pickup-tab"  data-toggle="tab" href={ `#pickup_${row.id}` } role="tab" aria-controls="pickup" aria-selected="true" >
                                     pickup Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="delivery-tab" data-toggle="tab" href={ `#delivery_${row.id}` } role="tab" aria-controls="delivery" aria-selected="false" >
                                     Delivery Details
                                    </a>
                                </li>
                                
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id={ `pickup_${row.id}` } role="tabpanel" aria-labelledby="pickup-tab">								
                                          <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId==2)                  
                                                                {
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactZipValue
                                                                    }							
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails }</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                        })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>						
                                    </div>
                                    <div className="tab-pane fade" id={ `delivery_${row.id}` } role="tabpanel" aria-labelledby="delivery-tab">								
                                    <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId==3)                  
                                                                {
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactZipValue
                                                                    }								
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails}</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                         })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>					
                                    </div>
                                    <div className="tab-pane fade" id={ `brokertender_${row.id}` } role="tabpanel" aria-labelledby="brokertender-tab">							 	
                                    <table className="table table-sm">
                                                    <tbody>	
                                                        <tr>
                                                            <td>Shipper Load Price</td><td><input className="form-control" type="number" id={"loadRate_"+row.id} name="shipperloadprice" value={row.rate} style={{width:'98px'}}/></td>
                                                            <td>Carrier Tender Price{(row.accessiorialcharge===null)?<i>&nbsp;</i>:(row.accessiorialcharge)}</td><td>
                                                            <input className="form-control" type="number" name="carriertenderprice" id={"carriertenderprice_"+row.id} defaultValue={row.tenderCarrierPrice}
                                                            onChange={(e) => {this.onchangeForbrokerGrossorgincalculation(row.id)}} style={{width:'98px'}} />
                                                            </td>
                                                            <td>Broker Gross Margin{(row.tenderprice===null)?<i>&nbsp;</i>:(row.tenderprice)}</td><td><input className="form-control" type="number" name="brokergrossmargin" style={{width:'98px'}} id={"brokerGross_"+row.id} defaultValue={row.tenderBrokerGross} /></td>
                                                            <td>Base Charge{(row.basecharge===null)?<i>&nbsp;</i>:(row.basecharge)}</td><td><input className="form-control" type="number" id={"baseCharge_"+row.id} name="basecharge" style={{width:'98px'}} defaultValue={row.tenderBaseCharge} /></td>
                                                            <td>Fuel Charge{(row.basecharge===null)?<i>&nbsp;</i>:(row.basecharge)}</td><td><input id={"fuelCharge_"+row.id} className="form-control" type="number" name="fuelcharge" style={{width:'98px'}} defaultValue={row.tenderFuelCharge}/></td>
                                                            <td><button type="button" onClick={ (e) => this.brokerTender(row.id) } className="btn btn-primary btn-sm">Confirm</button></td>
                                                        </tr>
                                                </tbody>	
                                        </table>					
                                    </div>										
                            </div>
                    </div>
                </div>
            </article>
            ),
             showExpandColumn: true,
             expandByColumnOnly: true,
             expandHeaderColumnRenderer: ({ isAnyExpands  }) => {
                if (isAnyExpands ) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              }
       };
    }

    render = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
              
              const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
              };
            return(
                <article className="table-data truck-data shadow bg-white rounded">			
                    <div className="row no-gutters">
                        <div className="col-md-12">					
                            <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <div className="flex-left-auto">
                                                    <ul className="">
                                                        <li className="searchicon">
                                                        <SearchBar { ...props.searchProps } />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <BootstrapTable
                                                    { ...props.baseProps } pagination={ paginationFactory(options) } 
                                                    noDataIndication={'No data found'}  hover expandRow={ this.expandRow() }
                                                    bordered={false}
                                                />
                                            </div>
                                        )
                                    }
                            </ToolkitProvider>
                            
                        </div>
                    </div>
			    </article>
            )
        } else {
			return(<Loader />);
		}
    }

}
export default AdminBrokerAssignedLoad;