import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {formData} from '../../../helpers/formData';
import { tripRegAction } from '../../../actions/reportAction';
import {getMinDate, getMaxDate} from '../../../helpers/appUtil';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class tripRegistration extends Component {
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state = {
            loadStatus:{
                data:[
                    {id:"Success",name:"Success"},
                    {id:"Failed",name:"Failed"},
                    {id:'Error', name:'Error'}
                ]
            },
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1
        };
    }

    // filterChange = (e, type) =>{
    //     let fromDate=null;
    //     let toDate=null;
    //     if(type=="RANGE"){
    //         $("#frmTripReg .range-fields").show();
    //     } else {
    //         $("#frmTripReg .range-fields").hide();
    //         if(type=="TODAY"){
    //             let date1 = new Date();
    //             fromDate=date1;
    //             toDate=date1;
    //         } else if(type=="WEEK"){                
    //             fromDate=moment().startOf('week').toDate();
    //             toDate=moment().endOf('week').toDate();
    //         } else if(type=="MONTH"){                
    //             fromDate=moment().startOf('month').toDate();
    //             toDate=moment().endOf('month').toDate();
    //         }
    //     }
    //     this.setState({
    //         ...this.state,
    //         activeFilter:type,
    //         fromDate:fromDate,
    //         toDate:toDate
    //     });
    // }
    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){            
            fromDate=moment().startOf('day').toDate();
            toDate=moment().endOf('day').toDate();
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    componentDidMount() {		
        document.title = 'Trip Registration Summary';
        $("#frmTripReg .range-fields").hide();
    }
    
    setStatus = () =>{
        let options = [];
        if(this.state.loadStatus.data){
            options = this.state.loadStatus.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
        return(<select className="form-control json-col" name="status">
            <option value="">Select Status</option>
            {options}
        </select>)
    }

    submitForm = (e) =>{
        e.preventDefault();        
        $("#frmTripReg").validate({
            rules:{
                fromDate:{
                 required:true
                },
                toDate:{
                    required:true
                },
                status : {
                    required: true
                }
            },
            messages:{
                fromDate:{
                    required:'From Date can not be left blank.!'
                },
                toDate:{
                    required:'To Date can not be left blank.!'
                },
                status : {
                    digits: 'Select Load Status'
                }
            },errorPlacement: function(error, element) {
                if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        if( $("#frmTripReg").valid()){
            let form = document.getElementById("frmTripReg");
            let loadData = formData(form);
            console.log(loadData);
            loadData.fromDate=(this.state.fromDate) ? (moment(this.state.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            loadData.toDate=(this.state.toDate) ? (moment(this.state.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            
            console.log(loadData);
            this.props.dispatch(tripRegAction(loadData));
            
        }
    }

    getDateLabel = () => {
        
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        console.log(fromDate,toDate)
        return(
            <div className="row mt-4 mb-4">
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                        Date {fromDate}
                    </div>
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                          Date {fromDate} to {toDate}
                    </div>
                </div> 
        );
    
}

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate(),
            activeFilter:'RANGE'
        });
    }

    render() {
        console.log(this.state.fromDate);
        var indicationhtml = "No Data Found"
        if(this.props.data.status == 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
                var indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.tripRegistrationList !== undefined)
            {
                if(this.props.data.tripRegistrationList.length > 0)
                {
                    LoadReports = this.props.data.tripRegistrationList.map(
                        (row,index) =>{   
                            return({
                                id:index,
                                loadNumber:row.loadNumber,
                                stringLoadDate:row.loadDate,
                                registrationDate:row.registrationDate,
                                responseCode:row.responseCode,
                                reason:row.reason,
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    var indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;        
       
		const columns=[	
        // {
        //     text:'Load Id',
        //     dataField:'id',
        //     sort:true
        // },
		{
			text:'Load #',
			dataField:'loadNumber',
			sort:true
		},
		{
			text:'Load Date',
			dataField:'stringLoadDate',
			sort:true
        },
        {
			text:'Reg Date',
			dataField:'registrationDate',
			sort:true
		},
		{
			text:'Response Code',
			dataField:'responseCode',
			sort:true
		},
		{
			text:'Reason',
			dataField:'reason',
			sort:true
		}
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            sizePerPage:10,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Trip Registration Summary</h3> <br></br>
                <form id="frmTripReg">
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} 
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} >Date Range</button>
                            </DateRangePicker>
                        </div>
                    </div>
                    {this.getDateLabel()}  
                    
                    <div className="row">
                        {/* <div className="form-group col col-lg-4 date-picker range-fields">
                            <label>From Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="fromDate" placeholder="Enter from date" value={this.state.fromDate} onChange={value => this.setState({ ...this.state, fromDate:value })} max={getMaxDate(this.state.toDate)}/>                    
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className="form-group col col-lg-3 date-picker range-fields">
                            <label>To Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="toDate" placeholder="Enter to date" value={this.state.toDate} onChange={value => this.setState({ ...this.state, toDate:value })} min={getMinDate(this.state.fromDate)}/>                        
                            <input hidden className="error_place" defaultValue=""/>
                        </div> */}
                        <div className="form-group col col-lg-3">
                            <label>Status</label>
                            {this.setStatus()}                    
                        </div>
                        
                        <div className="form-group col col-lg-2">
                            <button type="button" value="searchbtn" onClick={(e) =>{this.submitForm(e)}} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                        </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {LoadReports.length}</div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
    }
  }
  
  export default connect(mapStateToProps)(tripRegistration);