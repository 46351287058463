import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';   

  const MenuItem = ({text, selected}) => {
    return <button
      className={`btn btn-primary menu-item ${selected ? 'active' : ''}`}
      >{text}</button>;
  };   
  // All items component
  // Important! add unique key
  export const Menu = (list, selected) =>
    list.map((el,index) => {
      return <MenuItem text={el.name} key={index} selected={selected} />;
  });   
  const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
  };
   
   
  const ArrowLeft = Arrow({className: 'icofont-circled-left arrow-prev' });
  const ArrowRight = Arrow({className: 'icofont-circled-right arrow-next' });
class Eldprovider extends Component{	
    state = {
        selected : 0
    };    
    onSelect = key => {        
        this.setState({ selected: key });
        this.props.handleEldprovider(this.props.eldprovider[key].id, this.props.eldprovider[key].eldProviderDispName);
    }
    
	render(){	
    
        const { selected } = this.state;    
        const list = [
          { name: ' ', id : 1 },
          { name: ' ', id : 2  }           
        ];  
    var menu = [];
    var menu = Menu(list, selected);
    if(this.props.eldprovider && this.props.eldprovider.length>0){
        const result = 
          this.props.eldprovider.map((data,index)=>{
            return ({
              name: data.eldProviderDispName,
              id: index
            })
          });

        var menu = Menu(result, selected);
    }
		return(          
                <ScrollMenu
                data={menu}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected}
                onSelect={this.onSelect}
                alignCenter ={false}
                hideSingleArrow={true}
                />           		
		)
	}
}

export default Eldprovider;