import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from '../common/loader';
import { toastr } from 'react-redux-toastr';
import {menuListService} from './../../services/userRoleService';
import AddMenuPopUp from './AddMenuPopUp';
class MenuList extends Component
{
    constructor(props){
        super(props);
        this.state={
            isReady:true,
            menuList:[]
        };
    }

    componentDidMount = () => {
        document.title = "Menu List";
        this.getMenuList();
    }

    getMenuList = () =>{
        menuListService().then((response) =>{   
            let MenuList =[];
            if(response && response.MenuList){
                MenuList=response.MenuList;
            }

            this.setState({
                ...this.state,
                menuList:MenuList
            });
        })
    }

    loadTableData = () =>{
        return this.state.menuList.map((row, index) =>{            
            return {
                id:row.menuId,
                menuName:row.menuName,                    
                displayText:row.displayText,
                active:(row.active?'Yes':'No'),
                menuType:row.menuType,
                menuUrl:row.menuUrl,
                moduleName:row.moduleName
            }
        });
        
    }

    loadTableColumns = () => {
        return [	
            {
                text:'Menu Name',
                dataField:'menuName',
                sort:true
            },
            {
                text:'Display Text',
                dataField:'displayText',
                sort:true
            },            
            {
                text:'Menu Type',
                dataField:'menuType',
                sort:true
            },
            {
                text:'Menu Url',
                dataField:'menuUrl',
                sort:true
            },
            {
                text:'Module Name',
                dataField:'moduleName',
                sort:true
            },
            {
                text:'Is Active',
                dataField:'active',
                sort:false
            }
        ];
    }

    

    render = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing { from } to { to } of { size } Results
            </span>
        );
		  
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };

        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                    <div className="row">
                        <div className="col">
                            <h3>Menu List</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row">
                        <div className="col">
                            <ToolkitProvider
                                keyField="id"
                                data={ this.loadTableData() }
                                columns={ this.loadTableColumns() }
                                search
                            >
                                {
                                props => (
                                    <div>
                                        <div className="row">
                                            
                                            
                                            <div className="col">
                                                <SearchBar { ...props.searchProps } />
                                                <div className="btn-export float-right d-inline-block pr-2">
                                                    <a href="#" className="btn btn-primary" onClick={(e) =>{e.preventDefault(); this.getMenuList()}} >Refresh</a> &nbsp;
                                                        <a href="#" className="btn btn-primary" data-toggle="modal" data-target=".bd-trucks-modal-lg">Add Menu</a>
                                                </div>
                                                

                                            </div>
                                        </div>
                                        	
                                        <BootstrapTable bootstrap4 
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication='No data found'  hover 
                                            bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                            <div>
                                <AddMenuPopUp statelist={this.props.statelist} refreshParent={() => {this.getMenuList()}} />
                            </div>
                        </div>
                    </div> 
                </article>
            );
        }
        return(<Loader />);
    }
}

export default MenuList;