import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { editCarrierService } from '../services/carrierService';
import * as types from '../actions';

async function fetchAsync(func) {
    const response = await func();

    if (response.ok) {
        return await response.json();
    }

    throw new Error("Unexpected error!!!");
}


function* editCarrierData(payload) { 
    try {   
            const response = yield call( editCarrierService, payload );                          
            yield put({type: types.EDIT_CARRIER_DATA_SUCCESS, response });
    } catch (error) {
            yield put({ type: types.EDIT_CARRIER_DATA_ERROR, error })
    }
}
export function* carrierSaga() {
    yield takeLatest(types.EDIT_CARRIER_DATA, editCarrierData);
}