import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { HosReportAction } from '../../../actions/reportAction';
import { companyNameListAction } from '../../../actions/companyActions';
import {formData} from '../../../helpers/formData';
import {getMinDate, getMaxDate} from '../../../helpers/appUtil';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class tripRegistration extends Component {
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state={
            payload:"",
            response:"",
            // customertype:[
            //     {id:"1",name:"Shipper"},
            //     {id:"2",name:"Broker"},
            //     {id:"3",name:"Carrier"},
            //     {id:"7",name:"Tech Partner"}
            // ],
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1
        };
    }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){            
            fromDate=moment().startOf('day').toDate();
            toDate=moment().endOf('day').toDate();
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    componentDidMount() {		
        document.title = 'HOS Summary';
        $("#frmHOSRpt .range-fields").hide();
    }

    submitForm = (e) =>{
        e.preventDefault();        
        $("#frmHOSRpt").validate({
            rules:{
                fromDate:{
                 required:true
                },
                toDate:{
                    required:true
                },
                customerType:{
                    required:true
                },
                companyId:{
                    required:true
                }
            },
            messages:{
                fromDate:{
                    required:'From Date can not be left blank.!'
                },
                toDate:{
                    required:'To Date can not be left blank.!'
                },
                customerType:{
                    required:'Select Company Type'
                },
                companyId:{
                    required:'Select Company'
                }
            },errorPlacement: function(error, element) {
                if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        if( $("#frmHOSRpt").valid()){
            let form = document.getElementById("frmHOSRpt");
            let loadData = formData(form);
            console.log(loadData);
            loadData.fromDate=(this.state.fromDate) ? (moment(this.state.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            loadData.toDate=(this.state.toDate) ? (moment(this.state.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            
            console.log(loadData);
            this.props.dispatch(HosReportAction(loadData));
            
        }
    }
    
	getPayloadInfo(row)
	{
        console.log(row);
        this.setState({
            payload:row.payload,
            response:row.response
        })
	}
    
    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                companytypeid:e.target.value,
             }    
            this.props.dispatch(companyNameListAction(params1));
        }
    }

    setCustomerType = () =>{
        let options = [];
        console.log(this.props.customertype)
        if(this.props.customertype){
            if(this.props.customertype.data){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
    }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="">Select Customer Type</option>
            {options}
        </select>)
    }

    setCustomer = () =>{
        let options = [];
        if(this.props.companyNameListValue && this.props.companyNameListValue.companyList){
            options = this.props.companyNameListValue.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="companyId">
            <option value="">Select Customer</option>
            {options}
        </select>)
    }

    getDateLabel = () => {
        
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        console.log(fromDate,toDate)
        return(
            <div className="row mt-4 mb-4">
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                        Date {fromDate}
                    </div>
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                          Date {fromDate} to {toDate}
                    </div>
                </div> 
        );
    
}

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate(),
            activeFilter:'RANGE'
        });
    }

    render() {
        //console.log(this.props.companyNameListValue)
        var indicationhtml = "No Data Found"
        if(this.props.data.status == 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
                var indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.auditlog !== undefined)
            {
                if(this.props.data.auditlog.length > 0)
                {
                    LoadReports = this.props.data.auditlog.map(
                        (row,index) =>{   
                            return({
                                id:index,
                                timetaken:row.timeTaken,
                                method:row.method,
                                responseStatus:row.responseStatus,
                                payload:row.payload,
                                response:row.payload,
                                popupIcon : <i data-toggle="modal" onClick={() => {this.getPayloadInfo(row);}} data-target=".PayloadAndResponse" id="lanehistoryclass"  className="icofont-info-circle"></i>
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    var indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;        
       
		const columns=[	
        // {
        //     text:'Load Id',
        //     dataField:'id',
        //     sort:true
        // },
		{
			text:'Time Taken',
			dataField:'timetaken',
			sort:true
		},
		{
			text:'Method',
			dataField:'method',
			sort:true
        },
        {
			text:'Response Status',
			dataField:'responseStatus',
			sort:true
        },
        {
			text:'Payload & Response',
			dataField:'popupIcon',
			sort:true
		},
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            sizePerPage:10,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
          var companyList = [];
          if(this.props.companyNameListValue)
          {
             companyList = this.props.companyNameListValue.companyList
          }else{
             companyList = []
          }

         
        
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>HOS Summary</h3> <br></br>
                <form id="frmHOSRpt">
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} 
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} >Date Range</button>
                            </DateRangePicker>
                        </div>
                    </div>
                    {this.getDateLabel()} 
                    {/* <p>&nbsp;</p> */}
                    <div className="row">
                        {/* <div className="form-group col col-lg-2 date-picker range-fields">
                            <label>From Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="fromDate" placeholder="Enter from date" value={this.state.fromDate} onChange={value => this.setState({ ...this.state, fromDate:value })} max={getMaxDate(this.state.toDate)} />                    
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className="form-group col col-lg-2 date-picker range-fields">
                            <label>To Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="toDate" placeholder="Enter to date" value={this.state.toDate} onChange={value => this.setState({ ...this.state, toDate:value })} min={getMinDate(this.state.fromDate)}/>                        
                            <input hidden className="error_place" defaultValue=""/>
                        </div> */}
                        <div className="form-group col col-lg-3">
                            <label>Customer Type</label>
                            {this.setCustomerType()}                         
                        </div>

                        <div className="form-group col col-lg-3">
                            <label>Customer</label>
                            {this.setCustomer()}                        
                        </div>
                   
                        <div className="form-group col col-lg-2">
                            <button type="button" onClick={(e) =>{this.submitForm(e)}} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                        </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {LoadReports.length}</div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    <div id="PayloadAndResponse" className="modal fade PayloadAndResponse"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg proofofdelivery">
                  <div className="modal-content"> 
                        
                  <div className="modal-header">
                     <h5 className="modal-title doc-color" id="exampleModalLabel">Payload And Reponse</h5>
                      <button type="button" id="podcarrier" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                     </div>
                 <div className="modal-body modalbodyclass" id="favourite-loads-container">
                  <div className="col-sm-12">
                      <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                          <label>
                              <b>Payload:</b>
                          </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                          <label className="text-word-wraps">{this.state.payload}</label>
                          </div>
                      </div>
                  </div>
                  <br/>
                  <div className="col-sm-12">
                  <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                          <label>
                             <b>Response:</b> 
                          </label>
                          </div>
                          <div className="displayvalue col-md-8 col-sm-8 col-xs-12">
                          <span className="text-word-wraps" >{this.state.response}</span>
                          </div>
                      </div>
                  </div>
              </div>            
                  </div>
              </div>
          </div>
        </article>
             
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
        companyNameListValue : state.companyReducer.companyNamelist,
    }
  }
  
  export default connect(mapStateToProps)(tripRegistration);