import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import {formData} from '../../helpers/formData';
import {allEldProviderService} from '../../services/commonService';
import logo from '../../truckergig-logo.png';
import './carrieronboard.css';
import $ from 'jquery';
import 'jquery-validation';

require('dotenv').config();

class CarrierOnboardElds extends Component{
    constructor(props)
	{
        super(props);
        console.log(this.props);
        this.state={
            IsOpenEldPop:false,
            eldid:0,
            eldList:[],
            eldlogo:''
        }
    }

    componentDidMount = () => {
        allEldProviderService().then((response) => response.json())
        .then((responseData) => {           
            console.log(responseData);
            this.setState({
                ...this.state,
                eldList:responseData.data
            });
        })
        .catch(error => {
            
        });
    }

    selectEld = (e, eldid) => {
        let el = e.target;
        if(e.target.tagName.toLowerCase() == "img"){
            el = e.target.parentNode;
        }
        $('.radio-custom-label').removeClass('checked');
        $(el).addClass('checked');
        let logo = $('img',el).prop('src');
        console.log(logo);
        this.setState({
            ...this.state,
            IsOpenEldPop:true,
            eldid:eldid,
            eldlogo:logo
        });
    }

    saveEld = () => {

        $("#frmEldCredential").validate({
            rules:{
                username:{
                 required:true
                },
                usrpwd:{
                required:true
                }
            },
            messages:{
                username:{
                 required:'User Name can not be left blank.!'
                },
                usrpwd:{
                    required:'Password can not be left blank.!'
                }
            }
        });

        let v = $("#frmEldCredential").valid();

        if(!v) return;

        let form = document.getElementById("eldCredentials");

        let verifyData = formData(form);
        verifyData = {
            ...verifyData,
            eldid:this.state.eldid
        }
        this.props.onAuthorize(verifyData);
        console.log(verifyData);
        this.setState({
            ...this.state,
            IsOpenEldPop:false
        });
    }

    closeEld = () => {
        this.setState({
            ...this.state,
            IsOpenEldPop:false
        });
    }

    getEldList = (eldlist) => {
        let logopath = process.env.REACT_APP_BASE_URL + "publicresource/images/eldlogo?eldid=";
        let filepath = "";
        let result = eldlist.map((row,index) => {
            filepath = logopath + row.id;
            return(<div className="col-md-3 col-sm-3 col-xs-12 mb-3" key={index}>                                    
                <div className="p-3 border-1 my-radio">
                    <input className="radio-custom radiopopup" name="eldid" type="radio" defaultValue={row.id} />                                        
                    <label htmlFor="radio-3" className="radio-custom-label" onClick={(e) => {this.selectEld(e,row.id);}}><img title={row.name} src={filepath}/></label>
                </div>
            </div>);
        });
        
        return(
            <div className="row">
                {result}
            </div>
        );
    }

    render = () => {
        console.log(this.state);
        return(
            <Fragment>
                <header className="header-section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="row carrier">
                                <div className="col-sm-2">
                                    <a className="navbar-brand" href="#">
                                        <img src={logo} />
                                    </a>
                                </div>
                                <div className="col-sm-8 text-center">
                                    <h3 className="onboard-title">CARRIER ONBOARDING - Opt IN/OUT</h3>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <article className="table-data truck-data bg-white rounded onboard-class">
                    <div className="p-t-5">
                        <div className="container">
                            <div className="row">
                                <div className="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                                    <div className="bg-color-1 p-4 mt-3 mb-3 pos-rel">
                                        <h5 className="text-center">Select Your ELD from 40+ ELD vendors listed on Truckergig.com</h5>                                        
                                    </div>
                                </div>
                            </div>
                            {this.getEldList(this.state.eldList)}
                        </div>
                    </div>
                </article>
                <Modal show={this.state.IsOpenEldPop} id="eldCredentials">
      				<ModalHeader>
        				<ModalTitle style={{margin: '0 auto'}}><img style={{width:'100px', height:'30px'}} src={this.state.eldlogo}/></ModalTitle>
      				</ModalHeader>
      				<ModalBody>
                          <form id="frmEldCredential">
						<div className="row" id="eldCredential">
                            <div className="col form-group">
                                <label >User Name</label>
                                <input type="text" className="form-control json-col" defaultValue="" name="username" placeholder="" />
                            </div>
                        </div>
                        <div className="row">                                
                            <div className="col form-group">
                                <label >Password</label>
                                <input type="password" className="form-control json-col" defaultValue="" name="usrpwd" placeholder="" />
                            </div>                            
						</div>
                        </form>
					</ModalBody>
      				<ModalFooter>
					  <button type="button" className="btn btn-primary"  onClick={() => {this.saveEld()}}>Authorize</button>
					  <button type="button" className="btn btn-primary" onClick={() => {this.closeEld()}}>Close</button>
					</ModalFooter>
    			</Modal>
            </Fragment>
        );
    }
}

export default CarrierOnboardElds;