import React, { Component, Fragment } from 'react';
import Loader from '../../common/loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {carrierLoadListService} from '../../../services/adminService';

import moment from 'moment';
class AdminCarrierRequestedLoad extends Component{
    constructor(props){
        super(props);

        this.state ={
            isReady:false,
            loadList:[]
        }
    }

    componentDidMount = () => {
        this.getLoadList();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{        
        if(this.props.selectedComapny != prevProps.selectedComapny){            
            this.getLoadList();
        }
    }

    getLoadList = () => {
        this.setState({
            isReady:false,
            loadList:[]
        });
        let param = {
            payload:{
                "loadStatusId":this.props.statusData.loadStatusId,
                "companyId":this.props.selectedComapny
            }
        }
        carrierLoadListService(param).then((response) =>{
            if(response.LoadList){
                this.setState({
                    isReady:true,
                    loadList:response.LoadList
                });
            } else {
                this.setState({
                    isReady:true
                });
            }
        });
    }

    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {
            return this.state.loadList.map(          
                (row, index) =>{
                    var stringLoadDate="";
                    if(row.stringLoadDate!="")
                    {
                        stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
                    }
                    else
                    {
                        stringLoadDate= "";
                    }

                    var pickUpLocation = "";
                    if(row.orginCityName!==null && row.orginStateCode!==null && row.orginZipValue!==null)
                    {
                        var pickUpLocation = row.orginCityName+', '+row.orginStateCode+', '+row.orginZipValue
                    }
                    else if(row.orginCityName!==null && row.orginStateCode!==null)
                    {
                        var pickUpLocation = row.orginCityName+', '+row.orginStateCode
                    }
                    else if(row.orginCityName!==null && row.orginZipValue!==null)
                    {
                        var pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
                    }
                    else if(row.orginCityName!==null)
                    {
                        var pickUpLocation = row.orginCityName
                    }
                    else if(row.orginStateCode!==null)
                    {                        
                        var pickUpLocation =  row.orginStateCode
                    }
                    else if(row.orginZipValue!==null)
                    {                        
                        var pickUpLocation =  row.orginZipValue
                    }

                    
                    var DeliveryLocation = "";
                    if(row.destinationCityName!==null && row.destinationStateCode!==null && row.destinationZipValue!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode+', '+row.destinationZipValue
                    }
                    else if(row.destinationCityName!==null && row.destinationStateCode!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                    }
                    else if(row.destinationCityName!==null && row.destinationZipValue!==null)
                    {
                        var DeliveryLocation = row.destinationCityName+', '+row.destinationZipValue
                    }
                    else if(row.destinationCityName!==null)
                    {
                        var DeliveryLocation = row.destinationCityName
                    }
                    else if(row.destinationStateCode!==null)
                    {
                        var DeliveryLocation =  row.destinationStateCode
                    }
                    else if(row.destinationZipValue!==null)
                    {
                        var DeliveryLocation =  row.destinationZipValue
                    }

                    var pickUpdate = "";
                    var pickUpTime = "";
                    if(row.loadPointsList.length>0)
                    {
                        if(row.loadPointsList.length==1)
                        {
                            if(row.loadPointsList[0].loadPointTypeId==2)
                            {
                                if(row.loadPointsList[0].earliestTime!="")
                                {
                                    var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                                    var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                                }
                            }
                        }
                        else if(row.loadPointsList.length==2)
                        {
                            if(row.loadPointsList[0].loadPointTypeId==2)
                            {
                                if(row.loadPointsList[0].earliestTime!="")
                                {
                                    var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                                    var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                                }
                            }
                            else if(row.loadPointsList[1].loadPointTypeId==2)
                            {
                                if(row.loadPointsList[1].earliestTime!="")
                                {
                                    var pickUpdate = moment(row.loadPointsList[1].earliestTime).format('MM-DD-YYYY HH:mm');
                                    var pickUpTime = moment(row.loadPointsList[1].earliestTime).format('HH:mm');
                                }
                            }
                        }
                    }
                    return({                        
                        id:index,
                        loadNumber:row.loadNumber,
                        stringLoadDate:stringLoadDate,
                        shipperName:row.shipperName,
                        customerName:row.customerName,
                        brokerName:row.brokerName,
                        consigneeName:row.consigneeName,
                        consigneeAddress:row.consigneeAddress,
                        weight:row.weight,
                        orginCity:row.orginCity,
                        destinationCity:row.destinationCity,
                        commodity:row.commodity,
                        equipmentType:row.equipmentType,							
                        loadPointsList:row.loadPointsList,
                        basecharge:row.basecharge,
                        fulecharge:row.fulecharge,
                        accessiorialcharge:row.accessiorialcharge,
                        totalrevenue:row.totalrevenue,
                        loadedmiles:row.loadedmiles,
                        emptymiles:row.emptymiles,
                        totalmiles:row.totalmiles,
                        driverpay:row.driverpay,
                        tenderprice:row.tenderprice,
                        orginaddress:row.orginaddress,
                        destaddress:row.destaddress,
                        distance:row.distance,
                        shipperName:row.shipperName,
                        brokerName:row.brokerName,
                        shipperref:row.shipperref,
                        orginState:row.orginStateName,
                        destinationState:row.destinationStateName,
                        pickUpdate:pickUpdate,                        
                        orginCityName:row.orginCityName,
                        destinationCityName:row.destinationCityName,
                        orginState:row.orginState,
                        destinationState:row.destinationState,
                        pickUpLocation:pickUpLocation,
                        DeliveryLocation:DeliveryLocation
                    });
                }					
            );
        }
    }
    

    getTableColumns = () => {
        return [            	
			{
				text:'Load #',
				dataField:'loadNumber',
				sort:true
			},
			{
				text:'Date',
				dataField:'stringLoadDate',
				sort:true
			},
			{
				text:'Shipper',
				dataField:'shipperName',
				sort:true
            },         
            {
				text:'Broker',
				dataField:'brokerName'
            },           
            {
				text:'Origin',
				dataField:'pickUpLocation',
				sort:true
            },
			{
				text:'Destination',
				dataField:'DeliveryLocation',
				sort:true
            },
            {
				text:'Pickup Date / Time',
				sort:true,
				dataField:'pickUpdate'
			}
         ];
    }

    expandRow = () =>{
        return {	
            renderer: row => (	
                <article className="table-data truck-data shadow bg-white rounded">  
                   <div className="row no-gutters">
                    <div className="col-md-12">                        
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pickup-tab"  data-toggle="tab" href={ `#pickup_${row.id}` } role="tab" aria-controls="pickup" aria-selected="true" >
                                     pickup Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="delivery-tab" data-toggle="tab" href={ `#delivery_${row.id}` } role="tab" aria-controls="delivery" aria-selected="false" >
                                     Delivery Details
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id={ `pickup_${row.id}` } role="tabpanel" aria-labelledby="pickup-tab">								
                                          <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId==2)                  
                                                                {
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactZipValue
                                                                    }							
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails }</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                        })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>						
                                    </div>
                                    <div className="tab-pane fade" id={ `delivery_${row.id}` } role="tabpanel" aria-labelledby="delivery-tab">								
                                    <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId==3)                  
                                                                {
                                                                    								
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                        var contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                        var contactDetails =  doc.contactZipValue
                                                                    }
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails }</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                         })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>					
                                    </div>
                                    <div className="tab-pane fade" id={ `brokertender_${row.id}` } role="tabpanel" aria-labelledby="brokertender-tab">							 	
                                    <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Base Charge</th>
                                                            <th scope="col">Fuel Charge</th>
                                                            <th scope="col">Accessorial Charge</th>
                                                            <th scope="col">Tendered Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>	
                                                        <tr>
                                                            <td>{(row.basecharge===null)?<i>&nbsp;</i>:(row.basecharge)}</td>
                                                            <td>{(row.fulecharge===null)?<i>&nbsp;</i>:(row.fulecharge)}</td>
                                                            <td>{(row.accessiorialcharge===null)?<i>&nbsp;</i>:(row.accessiorialcharge)}</td>
                                                            <td>{(row.tenderprice===null)?<i>&nbsp;</i>:(row.tenderprice)}</td>
                                                        </tr>
                                                </tbody>	
                                        </table>					
                                    </div>										
                            </div>
                    </div>
                </div>
            </article>
            ),
             showExpandColumn: true,
             expandByColumnOnly: true,
             expandHeaderColumnRenderer: ({ isAnyExpands  }) => {
                if (isAnyExpands ) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              }
       };
    }

    render = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
              
              const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
              };
            return(
                <article className="table-data truck-data shadow bg-white rounded">			
                    <div className="row no-gutters">
                        <div className="col-md-12">					
                            <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <div className="flex-left-auto">
                                                    <ul className="">
                                                        <li className="searchicon">
                                                        <SearchBar { ...props.searchProps } />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <BootstrapTable
                                                    { ...props.baseProps } pagination={ paginationFactory(options) } 
                                                    noDataIndication={'No data found'}  hover expandRow={ this.expandRow() }
                                                    bordered={false}
                                                />
                                            </div>
                                        )
                                    }
                            </ToolkitProvider>
                            
                        </div>
                    </div>
			    </article>
            )
        } else {
			return(<Loader />);
		}
    }

}
export default AdminCarrierRequestedLoad;