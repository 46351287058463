import React, { Component, Fragment } from 'react';
import Loader from './loader';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import {getCompanyByTypeService, planUpgradeByTypeService, saveCompanyShortCodeService,enableDisableCobStatusService, setDefaultService} from '../../services/companyService';
import { toastr } from 'react-redux-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import $ from 'jquery';

class AdminClients extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            // customertype:[
            //     {id:"1",name:"Shipper"},
            //     {id:"2",name:"Broker"},
            //     {id:"3",name:"Carrier"},
            //     {id:"7",name:"Tech Partner"},
            //     {id:"8",name:"Factor"}
            // ],
            upgradeplan : false,
            modalTitle: "Upgradation",
            companyList:[], 
            companytypeid:1,
            companyId:'',
            showShortCodePopup:false,
            companyShortCode:''
        }
    }

    componentDidMount = () => {
        document.title = 'Client List';
        this.getCompanyList(1);
    }

    // display by default shippers
    getCompanyList = (companytypeid) =>{
        getCompanyByTypeService({companytypeid:companytypeid}).then((response) => {
            if(response.companyList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    companyList:response.companyList
                });
            }
        });
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            let companytypeid = e.target.value;  
               this.setState({
            ...this.state,
            companytypeid:companytypeid
        });        
        getCompanyByTypeService({companytypeid:companytypeid}).then((response) => {
            if(response.companyList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    companyList:response.companyList
                });
            }
        });
        }
    }

    setCustomerType = () =>{
        let options = [];
        if(this.props.customertype){
            if(this.props.customertype.data){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
    }


        return(<select value={this.state.companytypeid}  className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            

            {options}
        </select>)
    }

    setDefault = (row) =>{

        setDefaultService({companyId:row.companyId}).then((response) =>{
            if(response && response.status){
                if(response.status==true)
                {
                    if(response.message){
                        toastr.success('Success!', response.message);
                    } else{
                        toastr.success('Success!', 'Successfully set default settings');
                    }
                } else{
                    toastr.error('Oops!', 'Unable to set default settings');
                }
            } else if(response.message){
                toastr.error('Oops!', response.message);
            } else{
                toastr.error('Oops!', 'Unable to set default settings');
            }
            console.log(response);
        });
    }

    getActionButtons = (row) =>{
        console.log(row);
        let upgardebtn = (row.subscriptionPlanId==1 ? <a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault(); this.openupgradeplanpopup(e,row.companyId,row.subscriptionPlanId)}} >Upgrade</a> : <a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault(); this.openupgradeplanpopup(e,row.companyId,row.subscriptionPlanId)}} >Downgrade</a>);
        let enabledisablebtn = (row.cobstatus==1 ? <a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault(); this.enabledisablebtn(e,row.companyId,row.cobstatus)}} >Disable Cob</a> : <a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault(); this.enabledisablebtn(e,row.companyId,row.cobstatus)}} >Enable Cob</a>);
        return(
            <div className="dropdown">
                <label data-toggle="dropdown">&hellip;</label>
                <div className="dropdown-menu">
                    {upgardebtn}
                    <a className="dropdown-item" onClick={(e) =>{e.preventDefault(); this.updateShortCode(row)}} href="#">Change Short Code</a>
                    {enabledisablebtn}
                    <a className="dropdown-item" onClick={(e) =>{e.preventDefault(); this.setDefault(row)}} href="#">Set Default</a>
                </div>
            </div>
        )
        
    }

    updateShortCode = (row) =>{
        if(row){
            this.setState({
                ...this.state,
                showShortCodePopup:true,
                companyId:row.companyId,
                companyShortCode:row.companyShortCode
            })
        } else {
            let shortCode = this.state.companyShortCode;
            if(shortCode){
                if(shortCode.match(/^[a-z]+$/i)){
                    let param={
                        companyId:this.state.companyId,
                        companyShortCode:shortCode
                    }
                    saveCompanyShortCodeService(param).then((response) =>{
                        if(response && response.status){
                            toastr.success("Success!", response.message);
                            this.getCompanyList(this.state.companytypeid);
                            this.closeShortCodePopup(1);
                        } else {
                            toastr.error("Oops!", response.message);
                        }
                    });
                    
                } else {
                    toastr.error("Oops!", "Short code should be alphabets");
                }
            } else{
                toastr.info("Short Code", "Enter short code");
            }
            
        }
    }

    closeShortCodePopup = (arg) => {
        this.setState({
            ...this.state,
            showShortCodePopup:false,
            companyId:'',
            companyShortCode:''
        })
    }

    getRowData = () => {
        if(this.state.companyList.length==0){
            return [];
        } else {
            return this.state.companyList.map(row =>{
                let brokerRef ="";
                let upgradeButton = "" ;
                let planTitle = "" ;
                if(row.companyMisc){
                    if(row.companyMisc.brokerReferenceNumber){
                        brokerRef =row.companyMisc.brokerReferenceNumber;
                    }
                }
                if(row.subscriptionPlans){
                    if(row.subscriptionPlans.planTitle){
                        planTitle =row.subscriptionPlans.planTitle;
                    }
                }               
                return({        
                    id:row.companyId,           
                    companyId:row.companyId,
                    companyEmail: (row.companyEmail != null ? <span>
					<i data-tip data-for={"driveremail"+row.companyId} id="lanehistoryclass" className="icofont-email"></i>
						<ReactTooltip id={"driveremail"+row.companyId} >
							<p>Email : {row.companyEmail}</p>
						</ReactTooltip>
					</span> :''),
                    companyName:row.companyName,
                    companyShortCode:row.companyShortCode,
                    phoneNumber: (row.phoneNumber != null ? <span>
					<i data-tip data-for={"driverphnnumber"+row.companyId} id="lanehistoryclass" className="icofont-phone-circle"></i>
					<ReactTooltip id={"driverphnnumber"+row.companyId} >
						<p>Phone : {row.phoneNumber}</p>
					</ReactTooltip>
					</span>:''),
                    brokerReferenceNumber:brokerRef,
                    planTitle:planTitle,
                    upgradebtn : this.getActionButtons(row)
                    /*(row.subscriptionPlanId==1 ? <button onClick={(e)=>{this.openupgradeplanpopup(e,row.companyId,row.subscriptionPlanId)}} className='btn btn-primary' >Upgrade</button> : <button onClick={(e)=>{this.openupgradeplanpopup(e,row.companyId,row.subscriptionPlanId)}} className='btn btn-primary' >Downgrade</button>)*/
                });
            });
        }
    }

    getTableColumns = () => {
        return [
            {
				text:'Company Name',
				dataField:'companyName',
				sort:true,
            },
            {
				text:'Short Code',
				dataField:'companyShortCode',
				sort:true,
            },
            {
				text:'Phone Number',
				dataField:'phoneNumber',
				sort:true,
            },
            {
				text:'Email',
				dataField:'companyEmail',
				sort:true,
            },
            {
				text:'Ref. No.',
				dataField:'brokerReferenceNumber',
				sort:true,
            },
            {
                text:'Subscription Plan',
                dataField:'planTitle',
                sort:true,
            },
            {
				text:'Action',
				dataField:'upgradebtn',
				sort:true,
            }
        ]
    }

    exportData = () => {        
        if(this.state.companyList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.companyList.map(row =>{
            let brokerRef ="";
            let planTitle = "" ;
            if(row.companyMisc){
                if(row.companyMisc.brokerReferenceNumber){
                    brokerRef =row.companyMisc.brokerReferenceNumber;
                }
            }
            if(row.subscriptionPlans){
                if(row.subscriptionPlans.planTitle){
                    planTitle =row.subscriptionPlans.planTitle;
                }
            }
            return({
                companyId:row.companyId,
                companyName:row.companyName,
                companyShortCode:row.companyShortCode,
                phoneNumber:row.phoneNumber,
                companyEmail:row.companyEmail,
                SubscriptionPlan:row.planTitle,
                brokerReferenceNumber:brokerRef
            });
        });
        let fileName = 'Client List';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    openupgradeplanpopup = (e,companyId,subscriptionPlanId)=>{
        this.setState({
            ...this.state,
            upgradeplan : true,
            modalTitle: (subscriptionPlanId==1)?"Upgradation":"Downgradation",
            companyId:companyId,
            subscriptionPlanId:subscriptionPlanId
        });
    }

    enabledisablebtn = (e,companyId,cobstatus)=>{
        this.setState({
            ...this.state,
            enabledisableStatus : true,
            modalTitle: (cobstatus==1)?"Disable":"Enable",
            companyId:companyId,
        });
    }
    closeEnableDisablepopup = (e,yesorno)=>{
        this.setState({
            ...this.state,
            enabledisableStatus : false,
        });
        if(yesorno == 'yes')
            {                
                const params={
                    companyId:this.state.companyId,
                }
                enableDisableCobStatusService(params).then( (response) => {   
                    console.log(response);
                    if(response && response.status ){
                        toastr.success('Success', response.message);
                        this.getCompanyList(this.state.companytypeid);
                    }
                    else{
                        toastr.error('Error', "Unable to change status");
                    }
                });

            }
      }
  
    


    closeupgradeplanpopup = (e,yesorno, planId)=>{
        this.setState({
            ...this.state,
            modalTitle: "Upgradation",
            upgradeplan : false,
            pgradeplan : false
        });
          if(yesorno == 'yes')
            {                
                const params={
                    companyId:this.state.companyId,
                    subscriptionPlanId:(planId==1)?2:1
                }
                planUpgradeByTypeService(params).then( (response) => {                    
                    if(response && response.status ){
                        toastr.success('Success', "Plan changed successfully");
                        this.getCompanyList(this.state.companytypeid);
                    }
                    else{
                        toastr.error('Error', "Unable to change plan");
                    }
                });

            }
    }

    componentDidUpdate = ()=>{
        if(this.props.data)
        {
            if(this.props.data.message == 'Update Subscription Plan Successfully') 
            {
                toastr.success('Success', 'Update subscription plan successfully!');
            }
        }
    }

    render = () =>{        
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
            
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,      
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };

            const defaultSorted = [{
                dataField: 'companyName',
                order: 'asc'
              }];

            return(
                <article className="shadow bg-white rounded" id="onboardpage">
                    <div className="row">
                        <div className="col">
                            <h3>Clients</h3>
                        </div>
                    </div>

                    <div className="form-group col col-lg-3">
                            <label>Client Type</label>
                            {this.setCustomerType()}                         
                    </div>
                    
                    <article className="table-data truck-data shadow bg-white rounded">
				        <div className="row no-gutters">
					        <div className="col-md-12">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                    >
                                    {
                                    props => (
                                        <div>                                            
                                            <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>
                                            <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div>
                                        <BootstrapTable
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                            defaultSorted={defaultSorted}
                                        />
                                        </div>
                                    )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </article>
                    <Modal show={this.state.upgradeplan} id="verificationData">
      				<ModalHeader>
                        <ModalTitle show={this.state.subscriptionPlanId==2}>Plan {this.state.modalTitle}</ModalTitle>
      				</ModalHeader>
      				<ModalBody>Are you sure you want to change the plan</ModalBody>
                      <ModalFooter>
                      <input type="hidden" id="companyId" value = {this.state.companyId}/>

					  <button type="button" className="btn btn-primary"  onClick={(e) => {this.closeupgradeplanpopup(e,'yes',this.state.subscriptionPlanId)}}>YES</button>
					  <button type="button" className="btn btn-primary" onClick={(e) => {this.closeupgradeplanpopup(e,'no',this.state.subscriptionPlanId)}}>NO</button>
					</ModalFooter>
    			</Modal>

                <Modal show={this.state.enabledisableStatus} id="verificationData">
      				<ModalHeader>
                        <ModalTitle>COB {this.state.modalTitle}</ModalTitle>
      				</ModalHeader>
      				<ModalBody>Are you sure you want to {this.state.modalTitle} the cob</ModalBody>
                      <ModalFooter>
                      <input type="hidden" id="companyId" value = {this.state.companyId}/>
					  <button type="button" className="btn btn-primary"  onClick={(e) => {this.closeEnableDisablepopup(e,'yes')}}>YES</button>
					  <button type="button" className="btn btn-primary" onClick={(e) => {this.closeEnableDisablepopup(e,'no')}}>NO</button>
					</ModalFooter>
    			</Modal>

                

                <Modal show={this.state.showShortCodePopup} id="companyShortCodePopup">
      				<ModalHeader>
                        <ModalTitle>Change short code </ModalTitle>
      				</ModalHeader>
      				<ModalBody>
                        <div className="col form-group">
                            <label>Short code</label>
                            <input type="text" className="form-control requiredfield" placeholder="Short code" value={this.state.companyShortCode} onChange={(e) =>{this.setState({...this.state, companyShortCode:e.target.value})}} />
                        </div>
                    </ModalBody>
                      <ModalFooter>
                          <button className="btn btn-primary" onClick={(e) =>{this.updateShortCode()}}>Save</button>
                          <button className="btn btn-primary" onClick={(e) =>{this.closeShortCodePopup(0)}}>Cancel</button>					  
					</ModalFooter>
    			</Modal>
                </article>
                 
            );
        }
		else{
			return(<Loader />);
		}
    }
}

export default AdminClients;