import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();
export const dashboardService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    let LOGIN_API_ENDPOINT = BASE_URL+'assetsresource/trackinglist';
    if(request.payload){
        if(request.payload.carrierCompanyId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?carrierCompanyId='+request.payload.carrierCompanyId;
        }
        else if(request.payload.eldProviderId){
            LOGIN_API_ENDPOINT = LOGIN_API_ENDPOINT + '?eldProviderId='+request.payload.eldProviderId;
        }
        console.log(request.payload);
    }

    // return fetch(LOGIN_API_ENDPOINT)
    // .then(response => {
    //     //console.log("service-" + response.json());
    //     return response.json();
    // })
    // .then(json => {
    //     return json;
    // });
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};
//tracking list
// export const trackingListService = (request) => {
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const TRACKING_LIST_END_POINT = BASE_URL+'trackingresource/datelist?fromDate='+request.payload.fromDate+'&toDate='+request.payload.toDate+'&truckNo='+request.payload.truckNo;
//     return fetch(TRACKING_LIST_END_POINT, {
//         method: 'GET',
//         headers: authHeader()
//    });
// };
export const trackingListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const TRACKING_LIST_END_POINT = BASE_URL+'trackingresource/datelist';
    return fetch(TRACKING_LIST_END_POINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
   })
   .then((response) => response.json())
   .then((responseData) => {
       checkUnauthorized(responseData.status, responseData.message);             
       return responseData;
   })
   .catch(error => {
       console.warn(error);
       return {};
   });
};
//eld Assets List
export const eldAssetListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ELD_ASSEST_END_POINT = BASE_URL+'assetsresource/trackinglist?eldProviderId='+request.payload;
    return fetch(ELD_ASSEST_END_POINT, {
        method: 'GET',
        headers: authHeader()
   })
   .then((response) => response.json())
   .then((responseData) => {
       checkUnauthorized(responseData.status, responseData.message);             
       return responseData;
   })
   .catch(error => {
       console.warn(error);
       return {};
   });
};

export const saveVisualGeofence = (request) => {
    const ADD_FENCE_API = process.env.REACT_APP_BASE_URL+'loadresource/load/visualgeofence/add';
    const parameters = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request.fenceData)
    };
    return fetch(ADD_FENCE_API, parameters)
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const fetchVisualGeofence = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const FETCH_FENCE_API = BASE_URL+'loadresource/load/visualgeofence/get?assetid='+request.fetchFenceData;
    return fetch(FETCH_FENCE_API,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const updateVisualGeofence = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_FENCE_API = BASE_URL+'loadresource/load/visualgeofence/update';
    return fetch(UPDATE_FENCE_API,{
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(request.updateData)
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const deleteVisualGeofence = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const DELETE_FENCE_API = BASE_URL+'loadresource/load/visualgeofence/delete?loadTrackerNotificationId='+request.deleteData;
    return fetch(DELETE_FENCE_API,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};