import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from '../common/loader';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import {companyNameListService} from './../../services/companyService';
import {userListByCompanyService} from './../../services/userService';
import {getRolesAndAccessService} from './../../services/userRoleService';
import NewUser from './../UserRights/NewUser';
class UserList extends Component
{
    constructor(props){
        super(props);
        this.state={
            isReady:true,
            companyList:[],
            selCustomer:'',
            userList:[],
            newUserPopup:false,
            title:'Add New User',
            userRoles:[]
        };
    }

    closeNewUser = (flag) =>{        
        if(flag){
            this.getUserList();
        } else {
            this.setState(
                {
                    ...this.state,
                    newUserPopup:false
                }
            );
        }
    }

    openNewUser = () =>{
        console.log(this.state.selCustomer);
        if(this.state.selCustomer){
            this.setState(
                {
                    ...this.state,
                    newUserPopup:true
                }
            );
        } else {
            toastr.info('Select', 'Please select customer');
        }
        
    }

    getUserList = () =>{
        if(this.state.selCustomer){
            console.log(this.state.selCustomer);
            let param = {
                payload:{
                    companyId:this.state.selCustomer
                }
            }
            userListByCompanyService(param).then((response) =>{
                console.log(response);
                let userList=[];
                if(response && response.userList)
                {
                    userList= response.userList;
                }
                this.setState({
                    ...this.state,
                    userList:userList
                });
            })
        } else {
            toastr.info('Select', 'Please select customer');
        }

    }

    loadTableData = () =>{
        //console.log(this.state.userList);
        return this.state.userList.map((row, index) =>{            
            return {
                id:row.User_Id,
                First_Name:row.First_Name,                    
                Last_Name:row.Last_Name,
                Email_Id:(row.Email_Id != null && row.Email_Id !== ''?<span>
                <i data-tip data-for={"carriersemail"+row.User_Id} id="lanehistoryclass" className="icofont-email"></i>
                    <ReactTooltip id={"carriersemail"+row.User_Id} >
                        <p>Email : {row.Email_Id}</p>
                    </ReactTooltip>
                </span>:''),                
                Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
                <i data-tip data-for={"carriers"+index} id="lanehistoryclass" className="icofont-phone-circle"></i>
                    <ReactTooltip id={"carriers"+index} >
                        <p>Phone : {row.Phone_Number}</p>
                    </ReactTooltip>
                </span>:''),
                Role_Name:row.Role_Name,
                // city:row.city,
                // state:row.state,
                // zip:row.zip,
                Is_Deleted:(row.Is_Deleted?'Yes':'No'),
            }
        });
        
    }

    loadTableColumns = () => {
        return [	
            {
                text:'First Name',
                dataField:'First_Name',
                sort:true
            },
            {
                text:'Last Name',
                dataField:'Last_Name',
                sort:true
            },            
            {
                text:'Email',
                dataField:'Email_Id',
                sort:true
            },
            {
                text:'Phone',
                dataField:'Phone_Number',
                sort:true
            },
            {
                text:'Role Name',
                dataField:'Role_Name',
                sort:true
                
            },
            // {
            //     text:'City',
            //     dataField:'city',
            //     sort:true
            // },
            // {
            //     text:'State',
            //     dataField:'state',
            //     sort:true
            // },
            // {
            //     text:'Zip',
            //     dataField:'zip',
            //     sort:true
            // },
            {
                text:'Is Active',
                dataField:'Is_Deleted',
                sort:false
            }
        ];
    }

    setCustomerType = () =>{
        let options = [];
        if(this.props.customertype && this.props.customertype.data){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="">Select Customer Type</option>
            {options}
        </select>)
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                payload:{
                    companytypeid:e.target.value
                }
             }
             companyNameListService(params1).then((response) =>{
                let companyList=[];
                if(response.companyList){
                    companyList = response.companyList;
                }
                this.setState({
                    ...this.state,
                    companyList:companyList,
                    selCustomer:''
                });
             });            
        } else {
            this.setState({
                ...this.state,
                companyList:[],
                selCustomer:''
            });
        }
    }

    setCustomer = () =>{
        let options = [];
        if(this.state.companyList){
            options = this.state.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="companyId" onChange={(e) =>{this.set_Customer(e)}}>
            <option value="">Select Customer</option>
            {options}
        </select>)
    }

    set_Customer = (e) =>{
        let selCustomer = e.target.value;
        let userRoles = [];
        this.setState({
            ...this.state,
            selCustomer:selCustomer,
            userRoles:userRoles
        });
        if(selCustomer){
            let param = {
                companyId:selCustomer
            };
            getRolesAndAccessService(param).then((response) =>{
                if(response && response.roleList){
                    userRoles =response.roleList;
                }
                this.setState({
                    ...this.state,
                    userRoles:userRoles
                });
            });
        }
    }

    render = () =>{
        document.title="Users";
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing { from } to { to } of { size } Results
            </span>
        );
		  
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };
        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                    <div className="row">
                        <div className="col">
                            <h3>Users</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row">
                        <div className="form-group col col-lg-3">
                            <label>Customer Type</label>
                            {this.setCustomerType()}                         
                        </div>

                        <div className="form-group col col-lg-3">
                            <label>Customer</label>
                            {this.setCustomer()}                        
                        </div>                        
                        <div className="form-group col col-lg-2">
                            <button type="button" onClick={(e) =>{this.getUserList(e)}} className="btn btn-secondary searchbtn">Get</button>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ToolkitProvider
                                keyField="id"
                                data={ this.loadTableData() }
                                columns={ this.loadTableColumns() }
                                search
                            >
                                {
                                props => (
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                <SearchBar { ...props.searchProps } />
                                                <div className="btn-export float-right d-inline-block pr-2">
                                                    <a href="#" className="btn btn-primary" onClick={(e) =>{this.openNewUser(e)}} >Add User</a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        	
                                        <BootstrapTable bootstrap4 
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication='No data found'  hover 
                                            bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                    <NewUser newUserPopup={this.state.newUserPopup} closeNewUser={(flag)=>{this.closeNewUser(flag)}} statelist={this.props.statelist} userRoles={this.state.userRoles} title={this.state.title} companyId={this.state.selCustomer} />
                </article>
            );
        }
        return(<Loader />);
    }
}

export default UserList;