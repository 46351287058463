import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const loadListService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'admin/loadlist'
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {        
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const carrierLoadListService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'admin/load/list'
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        console.log(responseData);
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const truckListService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'admin/assets/list?assettype=truck&companyId=3'
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getAppSettingsService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'admin/app_settings';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const saveAppSettingsService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'admin/save_app_settings?settingId='+request.payload.settingId+'&dataValue='+request.payload.dataValue;
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getMyCarriersService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/list';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getMyCarriersZipRangeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/listbyzip';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getMyCarriersDateRangeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/listbydate';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getAllMyCarriersService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/get/all';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const saveMyCarrierService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/save_my_carrier';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        headers: authHeader(),
        body:JSON.stringify(request.payload)
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const importLeadCarriersService = (request) => {

    let authToken = localStorage.getItem('authToken');
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'mycarrier/leadcarrier/bulk';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken 
        },
        body:request.payload
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const downloadSampleService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'publicresource/download/leadcarrier/sample';
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

