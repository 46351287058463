import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {loadListActionfrdispatch,QuickDispatch,quickdispatchFlagAction,ChkAuthToken,authtokenflag,canceledloadflag,ChkvalidAuthToken,cancelledloadsbyloadid,QuickDispatchfrintellitrans } from '../../actions/loadActions';
import moment from 'moment';
import queryString from 'query-string';
import logo from '../../truckergig-logo.png';
import intellitranslogo from '../../intelitrans.png';
import { history } from '../../helpers/history';
import { Link } from 'react-router-dom';
import CancelledLoadpopup from './cancelledLoadpopup';
import { loadActionfrcancelled } from '../../actions/loadActions';
import { STATUS } from '../../helpers/status';
import $ from 'jquery';
class QuickLoad extends Component{
	constructor(props){
        super(props);
        this.state={
            isReady:false,
            verification:'',
            InvalidKeyStatus:true,
        }   
    }

    handleGetSelectedData = () => {
		if(this.node.selectionContext.selected.length==0)
		{
			toastr.info('Info', 'Select Load!');
			return;
		}
		else
		{
			const params={
				loadsId:this.node.selectionContext.selected
			}

			this.props.dispatch(cancelledloadsbyloadid(params))
		}
      }
      
     
    cancelleddata = () => {
        const params={
            ...STATUS.LOAD.CANCELLED,
        }
        this.props.dispatch(loadActionfrcancelled(params));
    }

    componentDidMount = () => {
        if(this.props.location.search){
            let urlparam = this.getUrlParams();
            if(urlparam.parameter && urlparam.verification){
                localStorage.clear();                
                const params={
                    verification:urlparam.verification
                }                
                this.props.dispatch(ChkAuthToken(params)); 
            }
            else{
                this.props.history.push("/login");
            } 
        }
        else{
            this.props.history.push("/login");
        } 
    }

    getUrlParams = () => {
        let urlparam = this.props.location.search.substring(1);
        urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        return urlparam;
    }

    componentDidUpdate = (nextProps) => { 
        console.log(this.props.authtokenval);
        if(this.props.authtokenval)
        {
          if (nextProps.data !== this.props.authtokenval) { 
            if(this.props.authtokenval.status == 0)
                {
                    this.setState({
                        InvalidKeyStatus: false
                    })
                }
            }
        }
        
        if(!this.state.isReady){
            if(this.props.authtokenval.AuthToken)
            {                
                if(this.props.authtokenval.status == 0)
                {                    
                    toastr.error('Invalid', 'Verification key not valid');
                    setTimeout(
                        function() {
                            localStorage.clear();
                            this.props.history.push("/login");
                        }
                        .bind(this),
                        1000
                    );
                }
                else 
                {
                    if(this.props.authtokenval.AuthToken!=="" && this.props.authtokenval.AuthToken!=null)
                    {   
                        localStorage.setItem('authToken', this.props.authtokenval.AuthToken);
                        this.setState({
                            ...this.state,
                            isReady:true
                        });
                        this.props.dispatch(loadListActionfrdispatch());
                    }
                }
            }
        }
        
        if(this.props.canceledloadflag==true)
		{
			if(this.props.data.message=="Loads Cancelled Sucessfully")
			{
				toastr.success('Success', 'Loads Cancelled Sucessfully!');
                this.props.dispatch(canceledloadflag());
                this.props.dispatch(loadListActionfrdispatch());
		   }
	   }
       
        if(this.props.quickdispatchflagAction==true)
        {
            if(this.props.quickDispatchStatus.status === "ACCEPTED")
            {
                toastr.success('Success', 'Quick dispatch saved Successfully!'); 
                this.props.dispatch(quickdispatchFlagAction());
                let paramsval = queryString.parse(this.props.location.search)
                const params={
                    verification:paramsval.verification
                }
                this.props.dispatch(loadListActionfrdispatch(params));     
                }
            else if(typeof this.props.quickDispatchStatus.status != "undefined")
            {
                toastr.error('error', 'Unable to save Quick dispatch!');
                this.props.dispatch(quickdispatchFlagAction());
                let paramsval = queryString.parse(this.props.location.search)
                const params={
                    verification:paramsval.verification
                }
                this.props.dispatch(loadListActionfrdispatch(params)); 
            }
        }
    }

    
    

	assignLoad = (row,index) => {  console.log(row);
        var a = document.querySelector('#assetvalue'+[index]);
        //var b= document.querySelector('#driveridval'+[index]);

        var concatValfrtruckname = '#assetvalue'+[index];
        var truckname = $( concatValfrtruckname+' option:selected').text();
        ///alert(truckname)

        // var concatValfrdrivername = '#driveridval'+[index];
        // var drivername = $( concatValfrdrivername+' option:selected').text();
        ///alert(drivername)

        if(a.value==="")
        {
            toastr.info('Info', 'Please choose a truck!');
        }
        // else if(b.value==="")
        // {
        //     toastr.info('Info', 'Please choose a driver!');
        // }
        else{
            const params={
                loadId:row.loadId,
                assetId:a.value,
                //driverId:b.value,               
            }
            this.props.dispatch(QuickDispatch(params)); 
            //For intellitransurl
            const params1={
                loadNo:row.loadNumber,
                vehicleNumber:truckname,
                //driverName:drivername              
            }
            this.props.dispatch(QuickDispatchfrintellitrans(params1)); 
        }
    }

    render = () => {
        //console.log(this.state);
        if(this.state.isReady && this.state.InvalidKeyStatus){
            var data=[];		
            if(this.props.loading){
                var indicationhtml = 
                <div className="spinner-border" role="status">
                 <div className="row react-bs-table-no-data"></div>
                 </div>
            }
            else{
                if(this.props.loadlistStatus.LoadInfoList)
                {
                    data =  this.props.loadlistStatus.LoadInfoList.map(
                        (row,index) =>{		
                            var loadDate="";
                            if(row.loadDate!=null)
                            {
                                loadDate=moment(row.loadDate).format('MM-DD-YYYY');
                            }
                            else
                            {
                                loadDate= "";
                            }	
                            var pickUpLocation = "";
                            if(row.orginCityName!==null && row.orginStateCode!==null && row.orginZipValue!==null)
                            {
                                var pickUpLocation = row.orginCityName+', '+row.orginStateCode+', '+row.orginZipValue
                            }
                            else if(row.orginCityName!==null && row.orginStateCode!==null)
                            {
                                var pickUpLocation = row.orginCityName+', '+row.orginStateCode
                            }
                            else if(row.orginCityName!==null && row.orginZipValue!==null)
                            {
                                var pickUpLocation = row.orginCityName+', '+row.orginZipValue
                            }
                            else if(row.orginCityName!==null)
                            {
                                var pickUpLocation = row.orginCityName
                            }
                            else if(row.orginStateCode!==null)
                            {
                                var pickUpLocation =  row.orginStateCode
                            }
                            else if(row.orginZipValue!==null)
                            {
                                var pickUpLocation =  row.orginZipValue
                            }
                            
                            var DeliveryLocation = "";
                            if(row.destinationCityName!==null && row.destinationStateCode!==null && row.destinationZipValue!==null)
                            {
                                var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode+', '+row.destinationZipValue
                            }
                            else if(row.destinationCityName!==null && row.destinationStateCode!==null)
                            {
                                var DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                            }
                            else if(row.destinationCityName!==null && row.destinationZipValue!==null)
                            {
                                var DeliveryLocation = row.destinationCityName+', '+row.destinationZipValue
                            }
                            else if(row.destinationCityName!==null)
                            {
                                var DeliveryLocation = row.destinationCityName
                            }
                            else if(row.destinationStateCode!==null)
                            {
                                var DeliveryLocation =  row.destinationStateCode
                            }
                            else if(row.destinationZipValue!==null)
                            {
                                var DeliveryLocation =  row.destinationZipValue
                            }		
                            return({
                                id:row.loadId,
                                loadNumber:row.loadNumber,
                                stringLoadDate:loadDate,
                                origincity:row.orginCityName,
                                destcity:row.destinationCityName,	
                                orginStateName:row.orginStateName,
                                destinationStateName:row.destinationStateName,						
                                customerName:row.shipperName,
                                shipperref:row.shipperReferenceNumber,
                                selindex:'',
                                pickUpLocation:pickUpLocation,
								DeliveryLocation:DeliveryLocation,
                                seltruckInput:<select name="assetId" defaultValue={row.assetId} id={"assetvalue"+index} className="form-control assetidval">
                                <option value="">Select Truck</option> 
                                {
                                    this.props.loadlistStatus.AssetsNumber.AssetNumberList.map(
                                        (assets, index) => 
                                         <option key={index} value={assets.assetId}>{assets.assetNumber}</option>
                                        
                                    )
                                }          
                                </select>, 
                                selDriverInput:<select name="driverId" id={"driveridval"+index} className="form-control driveridval">
                                <option value="">Select Driver</option>
                                {
                                    this.props.loadlistStatus.DriverNumber.UserList.map(
                                        (driver, index) => 
                                         <option key={index} value={driver.userId}>{driver.name}</option>
                                        
                                    )
                                }          
                                </select>,
                                action:<button className="btn btn-primary assignbtn" id={"truckval"+index} onClick={ (e) => this.assignLoad(row,index) } >Dispatch</button>,
                            });
                        }					
                    );
                }
                else
                {
                   data=[];
                   var indicationhtml = "No Data Found";
                   //toastr.error('Not found', 'Data not available!!!!!!!');
                    // setTimeout(function(){
                    //     window.location = '/login'
                    // }, 2000);
                }
            }
            const { SearchBar } = Search;
            const columns=[	
                {
                    text:'Load #',
                    dataField:'loadNumber',
                    sort:true
                },
                {
                    text:'Date',
                    dataField:'stringLoadDate',
                    sort:true
                },
                {
                    text:'Customer',
                    dataField:'customerName',
                    sort:true
                },
                {
                    text:'Shipper Ref',
                    dataField:'shipperref',
                    sort:true
                },
                {
                    text:'Origin',
                    dataField:'pickUpLocation',
                    sort:true
                },
                {
                    text:'Destination',
                    dataField:'DeliveryLocation',
                    sort:true
                }, 
                {
                    text:'Vehicle Name',
                    dataField:'seltruckInput',
                    sort:true,
                    headerStyle:{width:'10%'}
                },
                // {
                //     text:'Driver Name',
                //     dataField:'selDriverInput',
                //     sort:true,
                //     headerStyle:{width:'10%'}
                // },
                {
                    text:'Action',
                    dataField:'action'
                },			
             ]
             const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
              
              const options = {
                paginationSize: 4,
                pageStartIndex: 1,		
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal			
              };

              var companyName='';
              if(this.props.loadlistStatus.CompanyName)
              {
                  if(this.props.loadlistStatus.CompanyName!==null)
                  {
                      companyName = this.props.loadlistStatus.CompanyName.toUpperCase()
                  }else
                  {
                      companyName = '';
                  }
              }

            return(<article className="table-data truck-data shadow bg-white rounded">	
            <Fragment>				
            <nav id="user_nav" className="navbar navbar-expand-lg navbar-light bg-light">
               <a className="navbar-brand" href="#">
               <img src={logo} />
               </a>
               <span className="nav-item homelabel" href="#"><b>WELCOME  {companyName}</b></span>
               <a className="navbar-brand flex-left-auto" href="#">
               <img src={intellitranslogo} />
               </a>
            </nav>
        <br/>
        <div className="row no-gutters">
            <div className="col-md-12">
                <ul className="nav nav-tabs" id="availableTrucks" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i> AVAILABLE LOADS</a>
                    </li>	
                    <div className="flex-left-auto">
                        <ul className="ul-horizontal tab-menus">
                            <li>
                                <a href="#" id="reqcancelbtn" onClick={ this.handleGetSelectedData } name="cancelbtn" className="btn_carrier_move btn btn-sm btn-primary">Cancel</a>
                            </li>
                            <li>
                             <a href="#" className="btn btn-primary btn-sm" onClick={()=>{this.cancelleddata();}} data-toggle="modal" data-target=".cancelledloadmodal">Canceled</a>
                            {/* <Link className="btn_carrier_move btn btn-sm btn-primary" to="cancelledLoads"> Cancelled</Link> */}
                            </li>
                        </ul>
                    </div>				  								  
                </ul>
                <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
                        <ToolkitProvider
                                keyField="id"
                                data={ data }
                                columns={ columns }
                                search
                            >
                                {
                                props => (
                                    <div>
                                    <SearchBar { ...props.searchProps } />
                                    <BootstrapTable  ref={ n => this.node = n } selectRow={ { mode: 'checkbox', clickToSelect: false } }
                                        { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover 
                                        bordered={false}
                                    />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                      </div>							  
                </div>
            </div>
        </div>
        </Fragment>
        <CancelledLoadpopup  cancelledloadlist={this.props.cancelledloadlist}/>
    </article>);
        }
        else if(!this.state.InvalidKeyStatus){
            return(<Fragment>
                <section className="masterhead">
		  		<div className="container h-100">
		    		<div className="row h-100 align-items-center">
		      			<div className="col-md-4 offset-md-4 text-center">	        				
							<form name="" className="shadow">
								<div className="login-logo">
									<img src={ logo }/>
								</div>
                                <span className="InvalidKeyhomelabel" href="#"><b>Invalid key</b></span>                             
							</form>
						</div>			      			
		    		</div>
		  		</div>
			</section>
        </Fragment>);
        }
      else{
        return(<Fragment></Fragment>)
      }
    }
}

const mapStateToProps = state => {
	return {
        loadlistStatus: state.loadReducer.loadlistStatus,
        quickDispatchStatus:state.loadReducer.quickDispatchStatus,
        authtokenval:state.loadReducer.authtokenval,
        authtokenvalidval:state.loadReducer.authtokenvalidval,
        data:state.loadReducer.data,
        quickDispatch:state.loadReducer.QuickDispatch,
        quickdispatchflagAction:state.loadReducer.quickdispatchflag,
        authtokenflag:state.loadReducer.authtokenflag,
        loading: state.loadReducer.loading,
        canceledloadflag:state.loadReducer.canceledloadflag,
        cancelledloadlist:state.loadReducer.data.cancelledloadlist
	}
}
export default connect(mapStateToProps)(QuickLoad)