import * as types from '../actions';

const initialState = {
    data: [],
    editvaluesview:[],
    companyList:[],
    truckNoListAndLocation:[],
    LoadAccessiorials:[],
    Shipperandlanedetails:[],
    CityVal:[],
    ZipVal:[],
    quickdispatchflag:false,
    quickdispatchbrokerflag:false,
    loadpostflagaction:false,
    loadstatusupdateflag:false,
    changeloadstatusupdateflag:false,
    DriverList:[],
    loads:{},
    QuickDispatch:{},
    LoadPostOrder:{},
    loading: false,    
    countflag:false,
    error: '',
    bulkuploadflag:false,
    quickDispatchStatus:[],
    loadlistStatus:[],
    authtokenval:[],
    authtokenflag:false,
    canceledloadflag:false,
    loaddataaddflag:false,
    brokerTenderFlag:false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case types.GET_LOAD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_LOAD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_LOAD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_SHIPPER_LOAD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_SHIPPER_LOAD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_SHIPPER_LOAD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_LOAD_DATA_CANCEL:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_LOAD_DATA_CANCEL_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_LOAD_DATA_CANCEL_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_LOAD_DATA_QUICKDISPATCH:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_LOAD_DATA_QUICKDISPATCH_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_LOAD_DATA_QUICKDISPATCH_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.POST_NEW_LOAD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };      
        case types.POST_NEW_LOAD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loaddataaddflag:true,
                loading: false,
            };
        case types.POST_NEW_LOAD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.LOAD_ADD_FLAG:
            return { 
                ...state,
                loaddataaddflag:false
            };
        case types.UPDATE_LOAD_STATUS:
                return { 
                    ...state,
                    loading: true,
                    error: ''
                };
        case types.UPDATE_LOAD_STATUS_SUCCESS:
            return { 
                ...state,
                loading: false,
                error: ''
            };
        case types.UPDATE_STATUS_LOAD_FlAG:
            return { 
                ...state,
                loadstatusupdateflag:false
            };
        case types.UPDATE_LOAD_STATUS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CHANGE_LOAD_STATUS:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.CHANGE_LOAD_STATUS_SUCCESS:
            return { 
                ...state,
                loading: false,
                data: action.data,
                changeloadstatusupdateflag:true,
                error: ''
            };
        case types.CHANGE_LOAD_STATUS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.CHANGE_LOAD_STATUS_FlAG:
            return { 
                ...state,
                changeloadstatusupdateflag:false,
            };
        case types.SAVE_POD_LOAD:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.SAVE_POD_LOAD_SUCCESS:
            return { 
                ...state,
                loading: false,
                data: action.data,
                changeloadstatusupdateflag:true,
                error: ''
            };
        case types.SAVE_POD_LOAD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TENDER_LOAD:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.TENDER_LOAD_SUCCESS:  
            return { 
                ...state,
                loading: false,
                data: action.data,
                error: ''
            };
        case types.TENDER_LOAD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.UPDATE_TENDER_LOAD:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.UPDATE_TENDER_LOAD_SUCCESS:  
            return { 
                ...state,
                loading: false,
                data: action.data,
                changeloadstatusupdateflag:true,
                error: ''
            };
        case types.UPDATE_TENDER_LOAD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
                changeloadstatusupdateflag:true
            };
        case types.COMPANY_LIST_BY_TYPE:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.COMPANY_LIST_BY_TYPE_SUCCESS:  
            return { 
                ...state,
                loading: false,
                companyList:action.data.companyList,
                error: ''
            };
        case types.COMPANY_LIST_BY_TYPE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_ASSET_ORDER:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.GET_ASSET_ORDER_SUCCESS: 
            return { 
                ...state,
                loading: false,
                data: action.data.assetOrderList,
                error: ''
            };
        case types.GET_ASSET_ORDER_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TRUCK_NO_AND_LOCATION:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.TRUCK_NO_AND_LOCATION_SUCCESS:  
            return { 
                ...state,
                loading: false,
                truckNoListAndLocation:action.data.truckNoListAndLocation,
                error: ''
            };
        case types.TRUCK_NO_AND_LOCATION_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.DRIVER_BY_COMPANY:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.DRIVER_BY_COMPANY_SUCCESS:  
            return { 
                ...state,
                loading: false,
                DriverList:action.data.DriverList,
                error: ''
            };
        case types.DRIVER_BY_COMPANY_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.LOAD_ACCESSIORAL:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.LOAD_ACCESSIORAL_SUCCESS:  
            return { 
                ...state,
                loading: false,
                LoadAccessorials:action.data.LoadAccessorials,
                error: ''
            };
        case types.LOAD_ACCESSIORAL_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SAVE_ASSET_ORDER:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.SAVE_ASSET_ORDER_SUCCESS:  
            return { 
                ...state,
                loading: false,
                data: action.data,
                error: ''
            };
        case types.SAVE_ASSET_ORDER_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SAVE_QUICK_DISPATCH:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.SAVE_QUICK_DISPATCH_SUCCESS:  
        
            return { 
                ...state,
                loading: false,
                data: action.data,
                QuickDispatch:action.data,
                quickdispatchflag:true,
                error: ''
            };
        case types.SAVE_QUICK_DISPATCH_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
                QuickDispatch:action.data
            };
        case types.QUICK_DISPATCH_LOAD_ACTION:
            return { 
                ...state,
                quickdispatchflag:false,
            };
        case types.SAVE_BROKER_QUICK_DISPATCH:
            return { 
                ...state,
                loading: true,
                error: ''
            };
        case types.SAVE_BROKER_QUICK_DISPATCH_SUCCESS:  
            return { 
                ...state,
                loading: false,
                data: action.data,
                QuickDispatch:action.data,
                quickdispatchbrokerflag:true,
                error: ''
            };
        case types.QUICK_DISPATCH_BROKER_LOAD_ACTION:
            return { 
                ...state,
                quickdispatchbrokerflag:false,
            };

        case types.SAVE_BROKER_QUICK_DISPATCH_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
                QuickDispatch:action.data
            };
            case types.SHIPPER_AND_LANE_DETAILS:
                return { 
                    ...state,
                    loading: true,
                    error: ''
                };
            case types.SHIPPER_AND_LANE_DETAILS_SUCCESS:  
                return { 
                    ...state,
                    loading: false,
                    data: action.data,
                    Shipperandlanedetails:action.data,
                    error: ''
                };
            case types.SHIPPER_AND_LANE_DETAILS_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.GET_CITY_BY_STATE:
                return { 
                    ...state,
                    loading: true,
                    error: ''
                };
            case types.GET_CITY_BY_STATE_SUCCESS:  
                return { 
                    ...state,
                    loading: false,
                    data: action.data,
                    CityVal:action.data,
                    error: ''
                };
            case types.GET_CITY_BY_STATE_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.GET_ZIP_BY_CITY:
                return { 
                    ...state,
                    loading: true,
                    error: ''
                };
            case types.GET_ZIP_BY_CITY_SUCCESS:  
                return { 
                    ...state,
                    loading: false,
                    data: action.data,
                    ZipVal:action.data,
                    error: ''
                };
            case types.GET_ZIP_BY_CITY_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };

            case types.SAVE_LOAD_POST_ORDER_DISPATCH:
                return { 
                    ...state,
                    loading: true,
                    error: ''
                };
            case types.SAVE_LOAD_POST_ORDER_DISPATCH_SUCCESS:  
                return { 
                    ...state,
                    loading: false,
                    data: action.data,
                    LoadPostOrder:action.data,
                    loadpostflagaction:true,
                    error: ''
                };

            case types.LOAD_POST_ORDER_FlAG:  
                return { 
                    ...state,
                    loadpostflagaction:false,
                };

                
            
            case types.SAVE_LOAD_POST_ORDER_DISPATCH_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error,
                    LoadPostOrder:action.data
                };
            case types.LOAD_BULK_UPLOAD:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.LOAD_BULK_UPLOAD_SUCCESS:
                return { 
                    ...state,
                    loads: action.data,
                    type:action.type,
                    loading: false,
                    bulkuploadflag:true
                };
            case types.LOAD_BULK_UPLOAD_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.BULK_UPLOAD_LOAD_FlAG:
                return { 
                    ...state,
                    bulkuploadflag:false,
                };
            case types.TENDER_CHANGE_LOAD_STATUS:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.TENDER_CHANGE_LOAD_STATUS_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    type:action.type,
                    changeloadstatusupdateflag:true,
                    loading: false,
                };
            case types.TENDER_CHANGE_LOAD_STATUS_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.EDIT_LOAD_VALUES:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.EDIT_LOAD_VALUES_SUCCESS:                
                return { 
                    ...state,
                    //data: action.data,
                    editvaluesview:action.data,
                    countflag:true,
                    type:action.type,
                    loading: false,
                };
            case types.EDIT_LOAD_VALUES_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.UPDATE_LOAD:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.UPDATE_LOAD_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    type:action.type,
                    loading: false,
                };
            case types.UPDATE_LOAD_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.LANE_DETAILS_LIST:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.LANE_DETAILS_LIST_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    type:action.type,
                    loading: false,
                };
            case types.LANE_DETAILS_LIST_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.GET_LOAD_DATA_DISPATCH:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.GET_LOAD_DATA_DISPATCH_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    loadlistStatus:action.loadlistStatus,
                    type:action.type,
                    loading: false,
                };
            case types.GET_LOAD_DATA_DISPATCH_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.QUICK_DISPATCH:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.QUICK_DISPATCH_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    quickDispatchStatus:action.quickDispatchStatus,
                    type:action.type,
                    quickdispatchflag:true,
                    loading: false,
                };
            case types.QUICK_DISPATCH_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.CHK_AUTH_TOKEN:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.CHK_AUTH_TOKEN_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    authtokenval:action.authtokenval,
                    authtokenflag:true,
                    loading: false,
                };
            case types.CHK_AUTH_TOKEN_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.CHK_AUTH_TOKEN_fLAG:
                return { 
                    ...state,
                    authtokenflag:false
                };
            case types.CHK_AUTH_TOKEN_VALID:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.CHK_AUTH_TOKEN_VALID_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    authtokenvalidval:action.authtokenvalidval,
                    loading: false,
                };
            case types.CHK_AUTH_TOKEN_VALID_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.CANCELLED_LOADS_BYLOADID:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.CANCELLED_LOADS_BYLOADID_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    canceledloadflag:true,
                    loading: false,
                };
            case types.CANCELLED_LOADS_BYLOADID_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
            case types.CANCELLED_LOAD_fLAG:
                return { 
                    ...state,
                    canceledloadflag:false
                };
            case types.NOTYETDISPATCHED_EDITDRIVERTRUCK:
                return { 
                    ...state,
                    loading: true,
                    error:''
                };
            case types.NOTYETDISPATCHED_EDITDRIVERTRUCK_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    changeloadstatusupdateflag:true,
                    loading: false,
                };
            case types.NOTYETDISPATCHED_EDITDRIVERTRUCK_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
                case types.CANCELLED_LOADS_BYLOADID_SUCCESS:
                    return { 
                        ...state,
                        data: action.data,
                        canceledloadflag:true,
                        loading: false,
                    };
                case types.CANCELLED_LOADS_BYLOADID_ERROR:
                    return { 
                        ...state,
                        loading: false,
                        error: action.error
                    };
                case types.CANCELLED_LOAD_fLAG:
                    return { 
                        ...state,
                        canceledloadflag:false
                    };
                case types.ONCHANGE_TRUCK:
                    return { 
                        ...state,
                        loading: true,
                        error:''
                    };
                case types.ONCHANGE_TRUCK_SUCCESS:
                    return { 
                        ...state,
                        data: action.data,
                        loading: false,
                        quickdispatchflag:true,
                    };
                case types.ONCHANGE_TRUCK_ERROR:
                    return { 
                        ...state,
                        loading: false,
                        error: action.error
                    };
                case types.ONCHANGE_DRIVER:
                    return { 
                        ...state,
                        loading: true,
                        error:''
                    };
                case types.ONCHANGE_DRIVER_SUCCESS:
                    return { 
                        ...state,
                        data: action.data,
                        loading: false,
                    };
                case types.ONCHANGE_DRIVER_ERROR:
                    return { 
                        ...state,
                        loading: false,
                        error: action.error
                    };
                case types.QUICK_DISPATCH_INTELLITRANS:
                    return { 
                        ...state,
                        loading: true,
                        error:''
                    };
                case types.QUICK_DISPATCH_INTELLITRANS_SUCCESS:
                    return { 
                        ...state,
                        data: action.data,
                        loading: false,
                    };
                case types.QUICK_DISPATCH_INTELLITRANS_ERROR:
                    return { 
                        ...state,
                        loading: false,
                        error: action.error
                    };
                case types.BROKER_TENDER:
                    return { 
                        ...state,
                        loading: true,
                        error:''
                    };
                case types.BROKER_TENDER_SUCCESS:
                    return { 
                        ...state,
                        data: action.data,
                        brokerTenderFlag:true,
                        loading: false,
                    };
                case types.BROKER_TENDER_ERROR:
                    return { 
                        ...state,
                        loading: false,
                        error: action.error
                    };
                case types.BROKER_TENDER_FLAG:
                    return { 
                        ...state,
                        brokerTenderFlag:false
                  };
                    
        default:
            return state;
    }
};