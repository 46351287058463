import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../truckergig-logo.png';

class ThankYouPage extends Component{
    render(){
        return(
            <div className="container-fluid thank-page">
                <div className="row">
                    <div className="col-sm-12 d-table">
                        <div className="d-table-cell align-middle login_div">
                            <div className="col-md-6 offset-md-3">
                                <div className="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel text-center">
                                <Link to="/" className="logo"><img src={logo} /></Link>
                                <h4 className="text-center thank-cred">Thank you for sharing the ELD credentials.</h4>
                                <p className="text-center thank-text">Your credentials will be safe and we will not share to anyone. You will receive one email from TruckerCloud administrator to enable your TruckerCloud account.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ThankYouPage