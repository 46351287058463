import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const loadReportService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    //const LOAD_DATA_API_ENDPOINT = BASE_URL+'report/loadstatus'
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'report/loaddetails'
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const loadStatusService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/reportloadstatuslist'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const tripRegistarionReportService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'report/tripregistrationstatus'
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const truckSummaryReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/trucklist?fromDate='+request.payload.fromDate+'&toDate='+request.payload.toDate
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const LoadSummaryReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/loadlist?date='+request.payload.date
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const DBFileSizeReport = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/dbfilesize'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const DBFileRecordSizeReport = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/tablecount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const HOSreportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/getauditlog'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const LoginreportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'uiauditlog/list'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const loadCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/billing/loadcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const hosCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/clientwise/hoscount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}



export const truckDetailsService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/truckdetails';
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const tuckCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/summary/truckcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const activeTuckCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/active/truckcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const sendMailloadCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/billing/email/loadcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
} 


export const sendMailloadDetailsReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/billing/email/loaddetails'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const sendMailTruckCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/audit/email/truckcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getTruckCountService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/clientwise/truckcount';
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const sendMailBillingTruckCountReportService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/billing/email/truckcount'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getOnboarderCarriersService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'report/activecarriers'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

