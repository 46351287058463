import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastr } from 'react-redux-toastr';
import {formData} from '../../../helpers/formData';
import {getMinDate, getMaxDate} from '../../../helpers/appUtil';
import Loader from '../../common/loader';
import {hosCountReportService, HOSreportService} from '../../../services/reportService';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class HOSCount extends Component {
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state={
            isReady:false,
            loadList:[],
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1,
            rowExpanded:false,
            hosDetails:[],
            reportData:{},
            hosResponse:'',
            openHosResponse:false
        }
    }

    componentDidMount = () => {
        document.title = 'HOS Count Report';
        this.loadInitial();
    }

    loadInitial = () => {
        if($("#frmHosCountRpt").length == 0){
            setTimeout(() => {
                this.loadInitial();
              }, 100);
        } else {
            this.submitForm(null);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevState.fromDate != this.state.fromDate) || (prevState.toDate != this.state.toDate)){
            this.submitForm(null);
        }
    }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){
            let date1 = new Date();
            fromDate=date1;
            toDate=date1;
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        }else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    submitForm = (e) =>{
        // if(e){
        //     e.preventDefault();        
        // }        
        // $("#frmHosCountRpt").validate({
        //     rules:{
        //         fromDate:{
        //          required:true
        //         },
        //         toDate:{
        //             required:true
        //         }
        //     },
        //     messages:{
        //         fromDate:{
        //             required:'From Date can not be left blank.!'
        //         },
        //         toDate:{
        //             required:'To Date can not be left blank.!'
        //         }
        //     },errorPlacement: function(error, element) {
        //         if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
        //             error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
        //         }
        //         else {
        //             error.insertAfter(element);
        //         }
        //     }
        // });
        // if( $("#frmHosCountRpt").valid()){
        //     let form = document.getElementById("frmHosCountRpt");
        //     let loadData = formData(form);
        //     console.log(loadData);
        //     loadData.fromDate=(loadData.fromDate) ? (moment(loadData.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
        //     loadData.toDate=(loadData.toDate) ? (moment(loadData.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            
        //     console.log(loadData);
            
            
        let fromDate = moment(this.state.fromDate).format('YYYY-MM-DD HH:mm:ss');
        let toDate = moment(this.state.toDate).format('YYYY-MM-DD HH:mm:ss');
        let loadData = {
            fromDate:fromDate,
            toDate:toDate
        }
        this.setState({
            ...this.state,
            loadList:[],
            isReady:false,
            reportData:loadData
        });
            hosCountReportService({payload:loadData}).then((data) =>{
                console.log(data);
                let loadList = [];
                if(data){
                    if(data.HosList){
                        loadList=data.HosList;
                    }
                }
                this.setState({
                    ...this.state,
                    loadList:loadList,
                    isReady:true
                });
            });
            
        //}
    }
    
    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {
            let loadList= this.state.loadList.map(
                (row,index) =>{
                    return {
                        id:index,
                        Company_Id:row.Company_Id,
                        companyName:row.companyName,
                        LoadCount:row.LoadCount,
                        emptyField:' ',
                        expandRow:<a href="#" onClick={(e) =>{e.preventDefault();}} className="icofont-plus-circle expandIcon"></a>
                    };
                }
            );
            return loadList;
        }
    }

    getTableColumns = () => {
        return [
            {
                text:' ',
                sort:false,
                dataField:'expandRow'
            },	
            {
                text:'Client',
                sort:true,
                dataField:'companyName'
            },
            {
                text:'HOS Count',
                sort:true,
                dataField:'LoadCount',
                style:{width: '10%'},
                align:'right'
            },
            {
                text:' ',
                dataField:'emptyField',
                style:{width: '10%'}
            }
        ];
    }

    getHOSDetails = (row) => { 
        this.setState({
            ...this.state,
            rowExpanded:false,
            hosDetails:[]
        })       
        let param = {
            payload:{
                ...this.state.reportData,                
                companyId:row.Company_Id
            }
        }
        HOSreportService(param).then( (response) =>{
            let hosDetails = [];
            if(response && response.auditlog){
                hosDetails=response.auditlog;                
            } 
            this.setState({
                ...this.state,
                rowExpanded:true,
                hosDetails:hosDetails
            })
            
        } );
    }

    expandRow = () =>{
        //console.log('Hi');
        return {
             showExpandColumn: false,
             expandByColumnOnly: false,
             onlyOneExpanding:true,            
            renderer:(row, index) =>{                                
                if(this.state.rowExpanded){                    
                    return(this.getChildTable());
                } else {                    
                    return(<Loader />);
                }
            },
            onExpand: (row, isExpand, rowIndex, e) => {
                $("#hosCount a.icofont-minus-circle").removeClass('icofont-minus-circle').addClass('icofont-plus-circle');
                if(isExpand){
                    $(e.target).closest('tr').find('a.expandIcon').removeClass('icofont-plus-circle').addClass('icofont-minus-circle');
                    setTimeout(
                    () =>  this.getHOSDetails(row), 
                    100
                  );                                                    
                }               
            }
        };
    }

    getChildTable = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
        );
          
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        const defaultSorted = [{
            dataField: 'timetaken',
            order: 'asc'
        }];

        return(
        <ToolkitProvider        
                    keyField="id"
                    data={ this.getChildTableRows() }
                    columns={ this.getChildTableColumns() }
                    search
                    >
                    {
                        props => (
                            <div>                                
                                <SearchBar { ...props.searchProps } />
                            
                                <BootstrapTable
                                    { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                    bordered={ false } defaultSorted={defaultSorted} 
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
        );
    }

    getChildTableColumns = () => {
        return [
            {
                text:'Time Taken',
                dataField:'timetaken',
                sort:true
            },
            {
                text:'Method',
                dataField:'method',
                sort:true
            },
            {
                text:'Response Status',
                dataField:'responseStatus',
                sort:true
            },
            {
                text:'Payload & Response',
                dataField:'popupIcon',
                style:{width: '10%'},
                align:'center'
            }
            
        ];
    }

    showHOSResponse=(e, response) =>{
        e.preventDefault();
        this.setState({
            ...this.state,
            hosResponse:response,
            openHosResponse:true
        });
    }

    closeHOSResponse = () => {
        this.setState({
            ...this.state,
            hosResponse:'',
            openHosResponse:false
        });
    }

    getChildTableRows = () => {
        return this.state.hosDetails.map((row,index) =>{            
            return({
                id:index,
                timetaken:row.timeTaken,
                method:row.method,
                responseStatus:row.responseStatus,
                payload:row.payload,
                response:row.payload,
                popupIcon :<a href="#" className="icofont-info-circle tableIcon" onClick={(e) => {this.showHOSResponse(e,row.response);} }></a>
            })
        });
    }

    getBody = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
              
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };
            const defaultSorted = [{
                dataField: 'companyName',
                order: 'asc'
            }];
            return (
                
                <ToolkitProvider
                    keyField="id"
                    data={ this.getRowData() }
                    columns={ this.getTableColumns() }
                    search
                    >
                    {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {this.state.loadList.length}</div>
                                <SearchBar { ...props.searchProps } />
                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div> 
                                <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                bordered={ false } defaultSorted={defaultSorted} expandRow={ this.expandRow()} id="hosCount"
                            />
                            </div>
                        )
                    }
                </ToolkitProvider>
            );
        } else {
            return(<Loader />);
        }
    }

    exportData = () => {        
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.loadList.map(row =>{
            return {
                Client_Name:row.companyName,
                HOS_Count:row.LoadCount
            }
        });
        let fileName = 'HOS Count';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    getDateLabel = () => {
        
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        return(
            <div className="row mt-4 mb-4">
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                        Date {fromDate}
                    </div>
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                        Date {fromDate} to {toDate}
                    </div>
                </div> 
        );
    }

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate()
        });
    }
    
    render = () => {
        return(
            <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>HOS Count</h3> <br></br>
                <form id="frmHosCountRpt">
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} onShow={(event, picker)=>{this.dateRangeChange(event, picker)}}
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'RANGE')}}>Date Range</button>
                            </DateRangePicker>
                            {/* <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'RANGE')}}>Date Range</button> */}
                        </div>
                    </div>
                    {this.getDateLabel()}                    
                    {/* <div className="row">
                        <div className={`form-group col col-lg-2 date-picker ${(this.state.activeFilter == "RANGE")? '':'d-none'}`}>
                            <label>From Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="fromDate" placeholder="Enter from date" value={this.state.fromDate} onChange={value => this.setState({ ...this.state, fromDate:value })} max={getMaxDate(this.state.toDate)} />                    
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className={`form-group col col-lg-2 date-picker ${(this.state.activeFilter == "RANGE")? '':'d-none'}`}>
                            <label>To Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="toDate" placeholder="Enter to date" value={this.state.toDate} onChange={value => this.setState({ ...this.state, toDate:value })} min={getMinDate(this.state.fromDate)}/>                        
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className={`form-group col col-lg-2 ${(this.state.activeFilter == "RANGE")? '':'d-none'}`}>
                            <button type="button" onClick={(e) =>{this.submitForm(e)}} className="btn btn-secondary searchbtn">Get</button>                        
                        </div>
                    </div>                         */}
                </form>                
                {this.getBody()}

                <Modal show={this.state.openHosResponse}>
      				<ModalHeader>
        				<ModalTitle>HOS Response</ModalTitle>
      				</ModalHeader>
      				<ModalBody className="content-overflow">
                        {this.state.hosResponse}
                    </ModalBody>
                    <ModalFooter>
					  <button type="button" className="btn btn-primary" onClick={() => {this.closeHOSResponse()}}>Close</button>
					</ModalFooter>
    			</Modal>
            </article>
        );
    }
}

export default HOSCount;