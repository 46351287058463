export const concat = (inputArray, sep) => {

    if(!Array.isArray(inputArray)) return "";

    var result = "";
    var join = "";
    sep = sep == null ? ", " : ( sep == "" ? ", " : sep);
    for (let str of inputArray)
    {
        result = result + join + (str == null ? "" : str.trim());
        if(result != "")  join =sep
    }
    return result;
}

export const formatLocation = (location) => {
    try{
        let locationArray = location.split('|');
        locationArray = locationArray.reverse();
        return locationArray.filter(x => x.trim().length > 0).join(', ');
    } catch(err) {
        return location;
    }
}

export const checkUnauthorized = (status, msg) => {
    if(status == "UNAUTHORIZED")
    {
        localStorage.clear();
        localStorage.setItem("logmsg",msg);
		window.location = '/login'
    }
}

export const getMaxDate = (date) => {
    if(date){
        return date;
    } else {
        return new Date(2099, 11, 31);
    }
}

export const getMinDate = (date) => {
    if(date){
        return date;
    } else {
        return new Date(1900, 0, 1);
    }
}

export const isEmpty = (str) => {
    return (!str || 0 === str.trim().length);
}

export const formatDecimal = (num, dPlaces) => {
    let val = parseFloat(num);
    return (isNaN(val)?null:val.toFixed(2));
}

export const getMapboxKey = () =>{
    let mapboxKey = localStorage.getItem("mapboxKey");
    return (mapboxKey ? mapboxKey : "");
}

export const getUrlSegments = (url) => {
    let urlSegs=url.split('/');
    if(Array.isArray(urlSegs)){
        let len1 = urlSegs.length;
        let lastSeg = urlSegs[len1-1];
        lastSeg = lastSeg.split('?');
        urlSegs[len1-1]=lastSeg[0];
        return urlSegs;
    } else{
        return [];
    }    
}

export const getUrlLastSegments = (url) => {
    let urlSegs=getUrlSegments(url);
    let len1 = urlSegs.length;
    if(len1 == 0){
        return "";
    } else {
        return urlSegs[len1-1];
    }
}

export default {concat};