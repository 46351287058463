import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from './roles';
import { UserRoutes } from './routes';
import * as Routes from './index';
import TopBar from '../components/widgets/TopBar';
import PageNotFound from '../components/pages/PageNotFound';
import { connect } from 'react-redux';
import { commonlist } from '../actions/commonActions';

class PrivateRoutes extends Component {
	state = {
		allowedRoutes: [],
		mapboxData:[],
		keyExist:(localStorage.getItem('mapboxAccount') !== '' && localStorage.getItem('mapboxKey') !== '' ) ? true : false
	};

	componentDidMount() {
		let roles = localStorage.getItem('userType');
		if (roles) {
			roles = [roles];
			let allowedRoutes = roles.reduce((acc, role) => {
				return [...acc, ...UserRoutes[role].routes];
			}, []);
			let newRoutes = allowedRoutes.map(function(x , exactComp) {
				exactComp='';
				let originalRoutes = {...x,exactComp};
			return originalRoutes
			});
			this.setState({
			allowedRoutes:newRoutes
			});
		}
		else {
		//this.props.history.push('/');
		}
		this.props.dispatch(commonlist());  
	}

	static getDerivedStateFromProps(props, state) {
        if (props.mapboxData !== undefined) {
            return {
                mapboxData: props.mapboxData
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

	render() {
		const { data } = this.props;

		console.log(this.props,"Private routes");

		if (this.state && this.state.mapboxData && this.state.mapboxData.MAPBOX_ACCOUNT) {
			localStorage.setItem('mapboxAccount', this.state.mapboxData.MAPBOX_ACCOUNT);
			localStorage.setItem('mapboxKey', this.state.mapboxData.MAPBOX_API_KEY);
		}

		return (
			<div className="wrapper">
				<TopBar />
					<section className="box-wrap">
						<Switch>
						{
							this.state.allowedRoutes.map((onRoute) => (							
							onRoute.exactComp = Routes[onRoute.component],						
							  	onRoute.component=='Dashboard' ? (
						        <Route
								exact render={() => <onRoute.exactComp history={this.props.history} timeZones={this.props.timeZones} />}
								path={`${this.props.match.path}${onRoute.url}`}
								 key={onRoute.url} />
						      ) : (
						       <Route
								 exact render={() => <onRoute.exactComp history={this.props.history} statelist={this.props.statelist} assets={this.props.assets} country={this.props.country} 
								 loadpoint={this.props.loadpoint} loadstatus={this.props.loadstatus} eldproviderlist={this.props.eldprovider} 
								 trucktypelist={this.props.trucktype}  fueltypelist={this.props.fueltype} drivertypelist={this.props.drivertype} specialitytypelist={this.props.specialitytype} citylist={this.props.citylist} ziplist={this.props.ziplist} subscriptionplanlist={this.props.subscriptionplanlist} documenttypelist={this.props.documenttype} loadlist={this.props.bloadlist} 
								 customertype={this.props.customertype} 
								 timeZones={this.props.timeZones}/>}
								 path={`${this.props.match.path}${onRoute.url}`}
								 key={onRoute.url} />
						      )						
							))
						}  
						<Route path="*" component={PageNotFound} />
						</Switch>
					</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return{
		statelist:state.commonReducer.statelist,
		country:state.commonReducer.country,
		assets:state.commonReducer.assets,
		loadpoint:state.commonReducer.loadpoint,
		loadstatus:state.commonReducer.loadstatus,
		eldprovider:state.commonReducer.eldproviderlist,
		trucktype:state.commonReducer.trucktypelist,
		fueltype:state.commonReducer.fueltypelist,
		drivertype:state.commonReducer.drivertypelist,
		specialitytype:state.commonReducer.specialitytypelist,
		citylist:state.commonReducer.citylist,
		ziplist:state.commonReducer.ziplist,
		subscriptionplanlist:state.commonReducer.subscriptionplanlist,
		documenttype:state.commonReducer.documenttypelist,
		bloadlist:state.commonReducer.loadlist,
		mapboxData:state.commonReducer.mapboxData,
		customertype:state.commonReducer.customertype,
		timeZones:state.commonReducer.timeZones
	}    
}
export default connect(mapStateToProps)(PrivateRoutes)