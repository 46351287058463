import React, { Component, Fragment } from 'react';
import Loader from './loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import {getCompanyByTypeService} from '../../services/companyService';
import { toastr } from 'react-redux-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
class AdminBrokerList extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            brokerList:[]
        }
    }

    componentDidMount = () => {
        document.title = 'Broker List';
        this.getShippers();
    }

    getShippers = () =>{
        getCompanyByTypeService({companytypeid:2}).then((response) => {
            console.log(response);
            if(response.companyList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    brokerList:response.companyList
                });
            }
        });
    }

    getRowData = () => {
        if(this.state.brokerList.length==0){
            return [];
        } else {
            return this.state.brokerList.map(row =>{
                let brokerRef ="";
                if(row.companyMisc){
                    if(row.companyMisc.brokerReferenceNumber){
                        brokerRef =row.companyMisc.brokerReferenceNumber;
                    }
                }
                return({
                    companyId:row.companyId,
                    companyEmail:(row.companyEmail != null && row.companyEmail !== ''?<span>
					<i data-tip data-for={"driveremail"+row.companyId} id="lanehistoryclass" className="icofont-email"></i>
						<ReactTooltip id={"driveremail"+row.companyId} >
							<p>Email : {row.companyEmail}</p>
						</ReactTooltip>
					</span>:''),
                    companyName:row.companyName,
                    companyShortCode:row.companyShortCode,
                    phoneNumber: (row.phoneNumber != null && row.phoneNumber !== ''?<span>
					<i data-tip data-for={"driverphnnumber"+row.companyId} id="lanehistoryclass" className="icofont-phone-circle"></i>
					<ReactTooltip id={"driverphnnumber"+row.companyId} >
						<p>Phone : {row.phoneNumber}</p>
					</ReactTooltip>
					</span>:''),
                    brokerReferenceNumber:brokerRef
                });
            });
        }
    }

    getTableColumns = () => {
        return [
            {
				text:'Company Name',
				dataField:'companyName',
				sort:true,
            },
            {
				text:'Short Code',
				dataField:'companyShortCode',
				sort:true,
            },
            {
				text:'Phone Number',
				dataField:'phoneNumber',
				sort:true,
            },
            {
				text:'Email',
				dataField:'companyEmail',
				sort:true,
            },
            {
				text:'Ref. No.',
				dataField:'brokerReferenceNumber',
				sort:true,
            }
        ]
    }

    exportData = () => {        
        if(this.state.brokerList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.brokerList.map(row =>{
            let brokerRef ="";
            if(row.companyMisc){
                if(row.companyMisc.brokerReferenceNumber){
                    brokerRef =row.companyMisc.brokerReferenceNumber;
                }
            }
            return({
                companyId:row.companyId,
                companyName:row.companyName,
                companyShortCode:row.companyShortCode,
                phoneNumber:row.phoneNumber,
                companyEmail:row.companyEmail,
                brokerReferenceNumber:brokerRef
            });
        });
        let fileName = 'Broker List';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    render = () =>{        
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
            
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,      
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };

            const defaultSorted = [{
                dataField: 'companyName',
                order: 'asc'
              }];

            return(
                <article className="shadow bg-white rounded" id="onboardpage">
                    <div className="row">
                        <div className="col">
                            <h3>Brokers</h3>
                        </div>
                    </div>
                    <article className="table-data truck-data shadow bg-white rounded">
				        <div className="row no-gutters">
					        <div className="col-md-12">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                    >
                                    {
                                    props => (
                                        <div>                                            
                                            <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>
                                            <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div>
                                            
                                        <BootstrapTable
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                            defaultSorted={defaultSorted}
                                        />
                                        </div>
                                    )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </article>
                </article>
            );
        }
		else{
			return(<Loader />);
		}
    }
}

export default AdminBrokerList;