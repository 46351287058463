import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Loader from '../../common/loader';
import { truckDetailsService, sendMailBillingTruckCountReportService, getTruckCountService} from '../../../services/reportService';
import {companyNameListService} from '../../../services/companyService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastr } from 'react-redux-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import {formData} from '../../../helpers/formData';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ReportTruckCount extends Component {
    constructor(props){
        super(props);
        this.state={
            isReady:true,
            loadList:[],
            rowExpanded:false,
            truckList:[],
            mailBoxOpen:false,
            reportData:{},
            mailBodyContent:'',
            customerType:[
                {id:"1",name:"Shipper"},
                {id:"2",name:"Broker"},
                {id:"3",name:"Carrier"}
            ],
            companyList:[]
        }       
    }

    componentDidMount = () => {
        document.title = 'Truck Count Report';
        //this.loadInitial();
    }

    setCustomerType = () =>{
        let options = [];
        console.log(this.props.customertype)
        if(this.props.customertype){
            if(this.props.customertype.data){
                options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
    }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="">Select customer type</option>
            {options}
        </select>)
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                payload:{
                    companytypeid:e.target.value
                }
             }
             companyNameListService(params1).then((response) =>{
                let companyList=[];
                if(response.companyList){
                    companyList = response.companyList;
                }
                this.setState({
                    ...this.state,
                    companyList:companyList
                });
             });            
        } 
        this.setState({
            ...this.state,
            loadList:[],
            companyList:[]
        });
    }

    setCustomer = () =>{
        let options = [];
        if(this.state.companyList){
            options = this.state.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="customerId">
            <option value="">Select customer</option>
            {options}
        </select>)
    }

    

    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {
            let loadList= this.state.loadList.map(
                (row,index) =>{
                    return {
                        id:index,
                        Company_Id:row.Company_Id,
                        Company_Name:row.Company_Name,
                        Truck_Count:row.Truck_Count,
                        emptyField:' ',
                        expandRow:<a href="#" onClick={(e) =>{e.preventDefault()}} className="icofont-plus-circle expandIcon"></a>
                    };
                }
            );
            return loadList;
        }
    }

    getTableColumns = () => {
        return [
            {
                text:' ',
                sort:false,
                dataField:'expandRow'
            },	
            {
                text:'Client',
                sort:true,
                dataField:'Company_Name'
            },
            {
                text:'Truck Count',
                sort:true,
                dataField:'Truck_Count',
                style:{width: '10%'},
                align:'right'
            },
            {
                text:' ',
                dataField:'emptyField',
                style:{width: '10%'}
            }
        ];
    }

    exportData = () => {        
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.loadList.map(row =>{
            return {
                Client_Name:row.Company_Name,
                Truck_Count:row.Truck_Count
            }
        });
        let fileName = 'Truck Count';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    getTruckDetails = (row) => {
        console.log(row) ;
        this.setState({
            ...this.state,
            rowExpanded:false,
            truckList:[]
        })       
        let param = {
            payload:{                
                companyId:row.Company_Id
            }
        }
        truckDetailsService(param).then( (response) =>{
            let truckList = [];
            if(response && response.truckList){
                truckList=response.truckList;                
            } 
            this.setState({
                ...this.state,
                rowExpanded:true,
                truckList:truckList
            })
            
        } );
    }

    expandRow = () =>{
        //console.log('Hi');
        return {
             showExpandColumn: false,
             expandByColumnOnly: false,
             onlyOneExpanding:true,            
            renderer:(row, index) =>{                                
                if(this.state.rowExpanded){                    
                    return(this.getChildTable());
                } else {                    
                    return(<Loader />);
                }
            },
            onExpand: (row, isExpand, rowIndex, e) => {
                $("#truckCount a.icofont-minus-circle").removeClass('icofont-minus-circle').addClass('icofont-plus-circle');
                if(isExpand){
                    $(e.target).closest('tr').find('a.expandIcon').removeClass('icofont-plus-circle').addClass('icofont-minus-circle');
                    setTimeout(
                    () =>  this.getTruckDetails(row), 
                    100
                  );                                                    
                }               
            }
        };
    }

    getChildTable = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
        );
          
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        const defaultSorted = [{
            dataField: 'Asset_Number',
            order: 'asc'
        }];

        return(
        <ToolkitProvider        
                    keyField="id"
                    data={ this.getChildTableRows() }
                    columns={ this.getChildTableColumns() }
                    search
                    >
                    {
                        props => (
                            <div>                                
                                <SearchBar { ...props.searchProps } />
                            
                                <BootstrapTable
                                    { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                    bordered={ false } defaultSorted={defaultSorted} 
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
        );
    }

    getChildTableColumns = () => {
        return [
            	
            {
                text:'Asset Number',
                dataField:'Asset_Number',
                sort:true
            },
            {
                text:'Licence Plate Number',
                dataField:'License_Plate_No',
                sort:true
            },
            {
                text:'VIN',
                dataField:'Identification_No',
                sort:true
            },
            {
                text:'ELD Provider Name',
                dataField:'ELD_Provider_Name',
                sort:true
            }
            
           ];
    }

    getChildTableRows = () => {
        return this.state.truckList.map((row,index) =>{            
            return({
                id:index,
                Asset_Number:row.Asset_Number,
                License_Plate_No:row.License_Plate_No,
                Identification_No:row.Identification_No,
                ELD_Provider_Name:row.ELD_Provider_Name
            })
        });
    }

    openMailBox = () => {
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }        
        this.setState({
            ...this.state,
            mailBoxOpen:true,
            mailBodyContent:''
        });
        
    }

    sendMail = () => {        
        $("#frmEmailSend").validate({
            rules:{
                toEmail:{
                    required:true,
                    multiEmail:true
                },
                subject:{
                    required:true
                },
                bodyContent:{
                    required:true
                }
            },
            messages:{
                toEmail:{
                    required:'Please specify a recipient.!',
                    multiEmail:'Recipient is not valid.!'
                },
                subject:{
                    required:'Subject can not be left blank.!'
                },
                bodyContent:{
                    required:'Description can not be left blank.!'
                }
            }
        });

        if( $("#frmEmailSend").valid()){
            let form = document.getElementById("frmEmailSend");
            let loadData = formData(form);
            loadData["bodyContent"] = this.state.mailBodyContent
            let params={
                payload:{
                    reportData:this.state.reportData,
                    sendEmailData:loadData
                }
            };
            sendMailBillingTruckCountReportService(params).then((response) => {
                console.log(response);
                if(response.status){
                    toastr.success('Email sent', 'Report sent successfully');
                } else {
                    toastr.error('Email not sent', 'Unable to send report');
                }
            });
            this.setState({
                ...this.state,
                mailBoxOpen:false
            });
        }
    }

    closeMail = () => {
        document.getElementById("frmEmailSend").reset();
        this.setState({
            ...this.state,
            mailBoxOpen:false
        });
    }

    mailBodyContentChange = (event, editor) => {
        let mailBodyContent = editor.getData();
        this.setState({
            ...this.state,
            mailBodyContent:mailBodyContent
        });
    }

    submitForm = (e, exports, go) =>{
        e.preventDefault();        
        $("#frmTruckCountRpt").validate({
            rules:{
                customerType:{
                    required:true
                },
                customer:{
                    required:true
                },
            },
            messages:{
                customerType:{
                    required:'Select customer type'
                },
                customer:{
                    required:'Select customer'
                }
            }
        });

        if( $("#frmTruckCountRpt").valid()){
            let form = document.getElementById("frmTruckCountRpt");
            let loadData = formData(form);
            console.log(loadData);
            let param = {
                payload:{
                    companyId:loadData.customerId,
                    customerType:loadData.customerType
                }
            }
            this.setState({
                ...this.state,
                reportData:{
                    companyId:loadData.customerId,
                    customerType:loadData.customerType
                }
            });
            getTruckCountService(param).then((data) =>{
                console.log(data);
                let loadList = [];
                if(data){
                    if(data.truckCountList){
                        loadList=data.truckCountList;
                    }
                }
                this.setState({
                    ...this.state,
                    loadList:loadList,
                    isReady:true,
                });
            });
        }
    }

    //{truck_count: 54, Company_Name: "FreightCo Dedicated, LLC."}
    render() {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
              
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };
            const defaultSorted = [{
                dataField: 'Company_Name',
                order: 'asc'
            }];
            return(
                <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                    <h3>Truck Count</h3> <br></br>
                    <form id="frmTruckCountRpt" >
                        <div className="row">
                            <div className="form-group col col-lg-3">
                                <label>Customer Type</label>
                                {this.setCustomerType()}                         
                            </div>

                            <div className="form-group col col-lg-3">
                                <label>Customer</label>
                                {this.setCustomer()}                        
                            </div>
                            <div className="form-group col col-lg-2">
                                <button type="button" value="searchbtn" onClick={(e) =>{this.submitForm(e)}}  className="btn btn-secondary searchbtn">Get</button>
                            </div>
                        </div>
                    </form>
                    <ToolkitProvider
                        keyField="id"
                        data={ this.getRowData() }
                        columns={ this.getTableColumns() }
                        search
                        >
                        {
                            props => (
                                <div>
                                    <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {this.state.loadList.length}</div>
                                    <SearchBar { ...props.searchProps } />
                                    <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div> 
                                    <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.openMailBox()}} className='btn btn-primary' >Send as Email</button></div>
                                    <BootstrapTable
                                        { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                        bordered={ false } defaultSorted={defaultSorted} expandRow={ this.expandRow()} id="truckCount"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                    <Modal show={this.state.mailBoxOpen}>
      				<ModalHeader>
        				<ModalTitle>Send Email</ModalTitle>
      				</ModalHeader>
      				<ModalBody>
                        <form id="frmEmailSend">
							<div className="row">
								<div className="col form-group">
									<label >To</label>
									<input type="text" className="form-control json-col" placeholder="To" name="toEmail" />                                    
								</div>
							</div>
							<div className="row">
								<div className="col form-group">
									<label >Subject</label>
									<input type="text" className="form-control json-col" placeholder="Subject" name="subject" />
								</div>
							</div>
							<div className="row">
								<div className="col form-group">
									<label >Description</label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={this.state.mailBodyContent}
                                        onChange={this.mailBodyContentChange} 
                                        onReady={editor => {
                                            editor.editing.view.change(writer => {
                                                writer.setStyle(
                                                "height",
                                                "100px",
                                                editor.editing.view.document.getRoot()
                                                );
                                            });
                                        }}
                                    />
								</div>
							</div>
						</form>
					</ModalBody>
      				<ModalFooter>
					  <button type="button" className="btn btn-primary"  onClick={() => {this.sendMail()}}>Submit</button>
					  <button type="button" className="btn btn-primary" onClick={() => {this.closeMail()}}>Close</button>
					</ModalFooter>
    			</Modal>
                </article>
            );
        } else {
            return(<Loader />);
        }
    }
}

export default ReportTruckCount;