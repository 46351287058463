import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Loader from '../../common/loader';
import { truckDetailsService, sendMailBillingTruckCountReportService, getOnboarderCarriersService} from '../../../services/reportService';
import {companyNameListService} from '../../../services/companyService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import moment from 'moment';
import {formData} from '../../../helpers/formData';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class OnboardedCarriers extends Component {
    constructor(props){
        super(props);
        this.state={
            isReady:true,
            carrierList:[],
            rowExpanded:false,
            truckList:[],
            mailBoxOpen:false,
            reportData:{},
            mailBodyContent:'',            
            companyList:[]
        }       
    }

    componentDidMount = () => {
        document.title = 'Active Carriers';
        //this.submitForm(null);
        this.getAllCarriers();
    }

    setCustomerType = () =>{
        let options = [];
        if(this.props.customertype && this.props.customertype.data){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="0">All</option>
            {options}
        </select>)
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                payload:{
                    companytypeid:e.target.value
                }
             }
             companyNameListService(params1).then((response) =>{
                let companyList=[];
                if(response.companyList){
                    companyList = response.companyList;
                }
                this.setState({
                    ...this.state,
                    companyList:companyList
                });
             });            
        } 
        this.setState({
            ...this.state,
            loadList:[],
            companyList:[]
        });
    }

    setCustomer = () =>{
        let options = [];
        if(this.state.companyList){
            options = this.state.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="customerId">
            <option value="0">All</option>
            {options}
        </select>)
    }

    

    getRowData = () => {
        
        if(this.state.carrierList.length==0){
            return [];
        } else {
            let carrierList= this.state.carrierList.map(
                (row,index) =>{
                    return {
                        id:row.Company_Id,
						Company_Id:row.Company_Id,
                        Company_Name:row.Company_Name,
                        Phone_Number: (row.Phone_Number != null && row.Phone_Number !== ''?<span>
							<i data-tip data-for={"carriers"+index} id="lanehistoryclass" className="icofont-phone-circle"></i>
								<ReactTooltip id={"carriers"+index} >
									<p>Phone : {row.Phone_Number}</p>
								</ReactTooltip>
							</span>:''),
                        Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
                        <i data-tip data-for={"carriersemail"+row.Company_Email} id="lanehistoryclass" className="icofont-email"></i>
                            <ReactTooltip id={"carriersemail"+row.Company_Email} >
                                <p>Email : {row.Company_Email}</p>
                            </ReactTooltip>
                        </span>:''),                        
                        Carrier_MC_Number:row.Carrier_MC_Number,
                        Carrier_DOT_Number:row.Carrier_DOT_Number,
                        Invited_By_Company_Name:row.Invited_By_Company_Name,
                        ELD_Provider_Disp_Name:row.ELD_Provider_Disp_Name,
                        Created_Time:(moment(row.Created_Time).format('MM-DD-YYYY hh:mm a')),
                        truck_count:row.truck_count
                    };
                }
            );
            
            return carrierList;

        }
    }

    getTableColumns = () => {
        return [            	
            {
                text:'Carrier',
                sort:true,
                dataField:'Company_Name'
            },
            {
                text:'Phone',
                sort:true,
                dataField:'Phone_Number',
                style:{width: '10%'}
            },
            {
                text:'Email',
                sort:true,
                dataField:'Company_Email',
                style:{width: '10%'}
            },            
            {
                text:'DOT#',
                sort:true,
                dataField:'Carrier_DOT_Number'
            },
            {
                text:'MC#',
                sort:true,
                dataField:'Carrier_MC_Number'
            },
            {
                text:'ELD',
                sort:true,
                dataField:'ELD_Provider_Disp_Name'
            },
            {
                text:'Onboarded By',
                sort:true,
                dataField:'Invited_By_Company_Name'
            },
            {
                text:'Date of Onboarding',
                sort:true,
                dataField:'Created_Time'
            },
            {
                text:'Truck Count',
                sort:true,
                dataField:'truck_count'
            },
        ];
    }

    exportData = () => {        
        if(this.state.carrierList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.carrierList.map(row =>{
            return {
                Client_Name:row.Company_Name,
                Phone_Number:row.Phone_Number,
                Company_Email:row.Company_Email,                
                Carrier_DOT_Number:row.Carrier_DOT_Number,
                Carrier_MC_Number:row.Carrier_MC_Number,
                ELD:row.ELD_Provider_Disp_Name,
                Invited_By:row.Invited_By_Company_Name,
                Date_of_Onboarding:(moment(row.Created_Time).format('MM-DD-YYYY hh:mm a')),
                truck_count:row.truck_count
            }
        });
        let fileName = 'Onboarded Carriers';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    

    // submitForm = (e) =>{
    //     if(e) e.preventDefault();  
    //         let form = document.getElementById("frmOnboardedCarriersRpt");
    //         let loadData = formData(form);            
    //         let param = {
    //             payload:{
    //                 companyId:loadData.customerId,
    //                 customerType:loadData.customerType
    //             }
    //         }
    //         this.setState({
    //             ...this.state,
    //             reportData:{
    //                 companyId:loadData.customerId,
    //                 customerType:loadData.customerType
    //             }
    //         });
    //         getOnboarderCarriersService(param).then((data) =>{                
    //             let carrierList = [];
    //             if(data){
    //                 if(data.companyList){
    //                     carrierList=data.companyList;
    //                 }
    //             }
    //             this.setState({
    //                 ...this.state,
    //                 carrierList:carrierList,
    //                 isReady:true,
    //             });
    //         });
    //     //}
    // }


    getAllCarriers = () =>{
            let param = {
                payload:{
                    companyId:"0",
                    customerType:"0"
                }
            }
            getOnboarderCarriersService(param).then((data) =>{                
                let carrierList = [];
                if(data){
                    if(data.companyList){
                        carrierList=data.companyList;
                    }
                }
                this.setState({
                    ...this.state,
                    carrierList:carrierList,
                    isReady:true,
                });
            });
    }


    //{truck_count: 54, Company_Name: "FreightCo Dedicated, LLC."}
    render() {
        
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
              
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };
            const defaultSorted = [{
                dataField: 'Company_Name',
                order: 'asc'
            }];
            return(
                <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                    <h3>Active Carriers</h3> <br></br>
                    {/* <form id="frmOnboardedCarriersRpt" >
                        <div className="row">
                            <div className="form-group col col-lg-3">
                                <label>Customer Type</label>
                                {this.setCustomerType()}                         
                            </div>

                            <div className="form-group col col-lg-3">
                                <label>Customer</label>
                                {this.setCustomer()}                        
                            </div>
                            <div className="form-group col col-lg-2">
                                <button type="button" value="searchbtn" onClick={(e) =>{this.submitForm(e)}}  className="btn btn-secondary searchbtn">Get</button>
                            </div>
                        </div>
                    </form> */}
                    <ToolkitProvider
                        keyField="id"
                        data={ this.getRowData() }
                        columns={ this.getTableColumns() }
                        search
                        >
                        {
                            props => (
                                <div>
                                    <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {this.state.carrierList.length}</div>
                                    <SearchBar { ...props.searchProps } />
                                    <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div> 
                                    
                                    <BootstrapTable
                                        { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                        bordered={ false } defaultSorted={defaultSorted} id="onboardedCarriers"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                    
                </article>
            );
        } else {
            return(<Loader />);
        }
    }
}

export default OnboardedCarriers;