import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const editDocumentService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'assetsdata/update'; 
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};


export const documentLoadListService=(request)=>{
 const BASE_URL=process.env.REACT_APP_BASE_URL;
 const DOCUMENT_LOAD_LIST_API_ENDPOINT=BASE_URL+'documentresource/brokerdocumentlist'; 
 return fetch(DOCUMENT_LOAD_LIST_API_ENDPOINT,{
    method:'GET',
    headers: authHeader()
 })
 .then((response) => response.json())
 .then((responseData) => {
     checkUnauthorized(responseData.status, responseData.message);             
     return responseData;
 })
 .catch(error => {
     console.warn(error);
     return {};
 });
};

export const addBrokerDocumentService = (request) => {
    var acc = request.payload.refs.files;
      let authToken = localStorage.getItem('authToken');
    
    const formData = new FormData();
    
    const fileField = document.querySelectorAll('input[type="file"]');

            if(fileField!=null)
        {
            var i=0;
            for(let acc of fileField)
            {
                var check = acc.querySelector('input[type="file"]');
                formData.append('refs', acc.files[i]);
            }   
            i++;

        }

    var stringfyvalues = JSON.stringify(request.payload.document);
    
    formData.append('document',stringfyvalues);

    const BASE_URL= process.env.REACT_APP_BASE_URL;

    const ADD_BROKER_DOCUMENT_API_ENDPOINT = BASE_URL+'documentresource/broker/document';
     
        return fetch(ADD_BROKER_DOCUMENT_API_ENDPOINT,{
        method: 'POST',
        body: formData,
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken 
        }       
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const documentBrokerEditService = (request) => {

    var acc = request.payload.refs.files;
    let authToken = localStorage.getItem('authToken');
  
    const formData = new FormData();

    const fileField = document.querySelectorAll('input[type="file"]');
     
            if(fileField!=null)
        {
            var i=0;
            for(let acc of fileField)
            {
                var check = acc.querySelector('input[type="file"]');
                formData.append('refs', acc.files[i]);
            }   
            i++;
        }
  
    var stringfyvalues = JSON.stringify(request.payload.document);
    
    formData.append('document',stringfyvalues);

    const BASE_URL= process.env.REACT_APP_BASE_URL;

    const EDIT_DOCUMENTS_API_ENDPOINT = BASE_URL+'documentresource/brokerdocupdate'; 
        return fetch(EDIT_DOCUMENTS_API_ENDPOINT,{
        method:'POST',
        body:formData,
        "mimeType":"multipart/form-data",
        headers: {
            'Authorization': authToken 
        }       
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}
