const components = {
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	onboarding: {
		component: 'OnBoarding',
		url: '/carrier_onboarding',
		title: 'Onboarding',
		icon: 'menu',
		module: 2
	},
	Driveronboarding: {
		component: 'DriverOnBoarding',
		url: '/driver_onboarding',
		title: 'Driver Onboarding',
		icon: 'menu',
		module: 2
	},
	AdminShipperList: {
		component: 'AdminShipperList',
		url: '/shippers',
		title: 'Shippers',
		icon: 'menu',
		module: 2
	},
	AdminBrokerList: {
		component: 'AdminBrokerList',
		url: '/brokers',
		title: 'Brokers',
		icon: 'menu',
		module: 2
	},
	AdminClients: {
		component: 'AdminClients',
		url: '/clients',
		title: 'Clients',
		icon: 'menu',
		module: 2
	},
	AdminMenu: {
		component: 'AdminMenu',
		url: '/menu',
		title: 'Menu',
		icon: 'menu',
		module: 2
	},
	Reports: {
		component: 'Reports',
		url: '/reports/load_summary',
		title: 'Load Summary',
		icon: 'menu',
		module: 3
	},
	
	TripRegReports: {
		component: 'TripRegReports',
		url: '/reports/trip_registration_summary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	TruckSummaryReport:{
		component: 'TruckSummaryReport',
		url: '/reports/truck_summary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	LoginSummaryReport:{
		component: 'LoginSummaryReport',
		url: '/reports/login_report',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	LoadSummaryReport:{
		component: 'LoadSummaryReport',
		url: '/loadSummary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},	
	driverList: {
		component: 'AdminCarrierLoads',
		url: '/driver/driver_list',
		title: 'Driver List',
		icon: 'menu',
		module: 1
	},
	carrierLoad: {
		component: 'AdminCarrierLoads',
		url: '/carrier/load',
		title: 'Carrier Loads',
		icon: 'menu',
		module: 1
	},
	carrierTrucks: {
		component: 'AdminCarrierTrucks',
		url: '/carrier/trucks',
		title: 'Carrier Trucks',
		icon: 'menu',
		module: 1
	}, 
	carrierDrivers: {
		component: 'AdminCarrierLoads',
		url: '/carrier/drivers',
		title: 'Carrier Drivers',
		icon: 'menu',
		module: 1
	},	
	carrierTrailers: {
		component: 'AdminCarrierLoads',
		url: '/carrier/trailers',
		title: 'Carrier Trailers',
		icon: 'menu',
		module: 1
	},
	carrierOptInOut: {
		component: 'AdminAssetOptInOutList',
		url: '/carrier/opt_in/out',
		title: 'Carrier Opt In/Out',
		icon: 'menu',
		module: 1
	},
	brokerLoad: {
		component: 'AdminBrokerLoads',
		url: '/broker/load',
		title: 'Broker Loads',
		icon: 'menu',
		module: 1
	},
	brokerCarriers: {
		component: 'AdminCarrierLoads',
		url: '/broker/carriers',
		title: 'Broker Carriers',
		icon: 'menu',
		module: 1
	},
	shipperDispatched: {
		component: 'AdminShipperDispatchedLoad',
		url: '/shipper/dispatched',
		title: 'Shipper Dispatched',
		icon: 'menu',
		module: 1
	},
	shipperDelivered: {
		component: 'AdminShipperDeliveredLoad',
		url: '/shipper/delivered',
		title: 'Shipper Delivered',
		icon: 'menu',
		module: 1
	}, 
	DBFileSizeReport:{
		component: 'DBFileSizeReport',
		url: '/reports/database_summary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	DBTabledataRecordsReport:{
		component: 'DBTabledataRecordsReport',
		url: '/reports/db_records_summary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	HosReport:{
		component: 'HosReport',
		url: '/reports/hos_summary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	AdminAppSettings:{
		component: 'AdminAppSettings',
		url: '/app_settings',
		title: 'App. Settings',
		icon: 'menu',
		module: 1
	},
	AdminMyCarrier:{
		component: 'AdminMyCarrier',
		url: '/lead_carrier',
		title: 'My Carrier',
		icon: 'menu',
		module: 1
	},
	ReportLoadCount:{
		component: 'ReportLoadCount',
		url: '/reports/load_count',
		title: 'Load Count',
		icon: 'menu',
		module: 1
	},
	ReportTruckPerCarrier:{
		component: 'ReportTruckPerCarrier',
		url: '/reports/truck_per_carrier',
		title: 'Truck Count',
		icon: 'menu',
		module: 1
	},
	ReportActiveTruckCount:{
		component: 'ReportActiveTruckCount',
		url: '/reports/active_trucks',
		title: 'Active Trucks',
		icon: 'menu',
		module: 1
	},
	ReportHOSCount:{
		component: 'ReportHOSCount',
		url: '/reports/hos_count',
		title: 'HOS Count',
		icon: 'menu',
		module: 1
	},
	EmailAccounts:{
		component: 'EmailAccounts',
		url: '/email_accounts',
		title: 'Email Accounts',
		icon: 'menu',
		module: 1
	},
	RoleAccess:{
		component: 'RoleAccess',
		url: '/users/permissions',
		title: 'Role Access',
		icon: 'menu',
		module: 1
	},
	OnboardedCarriers:{
		component: 'OnboardedCarriers',
		url: '/reports/onboarded_carriers',
		title: 'Onboarded Carriers',
		icon: 'menu',
		module: 1
	},
	ReportTruckCount:{
		component: 'ReportTruckCount',
		url: '/reports/truck_count',
		title: 'Truck Count',
		icon: 'menu',
		module: 1
	},
	UserList:{
		component: 'UserList',
		url: '/users/users',
		title: 'User List',
		icon: 'menu',
		module: 1
	},
	MenuList:{
		component: 'MenuList',
		url: '/users/menus',
		title: 'Menu List',
		icon: 'menu',
		module: 1
	},
	UserRoles:{
		component: 'UserRoles',
		url: '/users/roles',
		title: 'User Roles',
		icon: 'menu',
		module: 1
	}
	
}

const DemoUserRoutes = {
	adminuser: {
		routes: [
			components.dashboard,
			components.onboarding,
			components.Driveronboarding,
			components.Reports,			
			components.TripRegReports,
			components.TruckSummaryReport,
			components.LoginSummaryReport,
			components.LoadSummaryReport,
			components.driverList,
			components.carrierLoad,
			components.carrierTrucks,
			components.carrierDrivers,
			components.carrierTrailers,
			components.brokerLoad,
			components.brokerCarriers,
			components.shipperDispatched,
			components.shipperDelivered,
			components.DBFileSizeReport,
			components.DBTabledataRecordsReport,
			components.HosReport,
			components.AdminShipperList,
			components.AdminBrokerList,
			components.AdminClients,
			components.AdminMenu,
			components.carrierOptInOut,
			components.AdminAppSettings,
			components.AdminMyCarrier,
			components.ReportLoadCount,
			components.ReportTruckPerCarrier,
			components.ReportActiveTruckCount,
			components.ReportHOSCount,
			components.EmailAccounts,
			components.RoleAccess,
			components.ReportTruckCount,
			components.OnboardedCarriers,
			components.UserList,
			components.MenuList,
			components.UserRoles
        ]
    }
};

export { DemoUserRoutes };