import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { EditCarrier } from '../../actions/carrierActions';
import { renderField, renderSelect } from '../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
   const frmdata = {};
   frmdata.name= (values.name) ? (values.name) : ("");
   frmdata.speciality= (values.speciality) ? (values.speciality) : ("");
   frmdata.Carriername=(values.Carriername) ? (values.Carriername) : ("");
   frmdata.currentaddress= (values.currentaddress) ? (values.currentaddress) : ("");
   frmdata.compapermanentaddressny=(values.permanentaddress) ? (values.permanentaddress) : ("");
   frmdata.lastname=(values.lastname) ? (values.lastname) : ("");
   frmdata.email=(values.email) ? (values.email) : ("");
   frmdata.Phone=(values.Phone) ? (values.Phone) : ("");
   frmdata.fax=(values.fax) ? (values.fax) : ("");
   frmdata.website=(values.website) ? (values.website) : ("");
   frmdata.eldprovider=(values.eldprovider) ? (values.eldprovider) : ("");
   frmdata.city=(values.city) ? (values.city) : ("");
   frmdata.state=(values.state) ? (values.state) : ("");
   frmdata.zipcode=(values.zipcode) ? (values.zipcode) : ("");
   frmdata.country=(values.country) ? (values.country) : ("");
   frmdata.nooftrucks=(values.nooftrucks) ? (values.nooftrucks) : ("");
   this.props.dispatch(EditCarrier(frmdata));
}

class EditCarrierPopup extends Component{
    state = {
        speciality : [
			{id: "1", optName: "Dry Van"},
			{id:"2", optName: "Flat Bed"},
			{id:"3", optName: "Reefer"},
			{id:"4", optName: "Intermodal"},
		],
        eldprovider: [
			{id: "1", optName: "Azuga"},
			{id:"2", optName: "Big Road"},
			{id:"3", optName: "Blue Ink"}
        ],
        selstate : [
			{id: "1", optName: "Alaska"},
			{id:"2", optName: "Alabama"},
			{id:"3", optName: "Arkansas"}
        ],
        country: [
			{id: "1", optName: "Canada"},
            {id:"2", optName: "Mexico"},
            {id:"3", optName: "USA"}
        ]
    }
    render(){
        const { handleSubmit, pristine, reset, submitting } = this.props
        return(
            <div className="modal fade bd-editcarrier-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">EDIT CARRIER</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT CARRIER</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab"> 
                                        <form onSubmit={handleSubmit(submit.bind(this))}>
                                            <div className="row"> 
                                                <div className="col col-md-12">
                                                    <h5>Carrier Details</h5>
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-4">
                                                        <div className="form-group">
                                                            <Field type="text" name="name" id="name" component={renderField} label="First Name"/> 
                                                        </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="speciality" id="speciality" component={renderSelect} label="Speciality" data={this.state.speciality}/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="Carriername" id="Carriername" component={renderField} label="Carrier Name"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="currentaddress" id="currentaddress" component={renderField} label="Current Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="permanentaddress" id="permanentaddress" component={renderField} label="Permanent Address"/>
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="lastname" id="lastname" component={renderField} label="Last Name"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="email" id="email" component={renderField} label="Email"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="Phone" id="Phone" component={renderField} label="Primary Phone"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="fax" id="fax" component={renderField} label="Fax"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="website" id="website" component={renderField} label="Website"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="eldprovider" id="eldprovider" component={renderSelect} label="ELD Provider" data={this.state.eldprovider}/> 
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="city" id="city" component={renderField} label="City"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="state" id="state" component={renderSelect} label="State" data={this.state.selstate}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="zipcode" id="zipcode" component={renderField} label="Zip Code"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="country" id="country" component={renderSelect} label="Country" data={this.state.country}/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="nooftrucks" id="nooftrucks" component={renderField} label="No of Trucks"/>
                                                    </div>
                                                </div> 
                                            </div>
                                            </article>
                                            <h5>Description</h5>
                                            <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-12">
                                                        <div className="form-group">
                                                            <textarea className="col-md-12 form-control control-custom"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        </article>
                                        <div className="row">
                                            <div className="col col-md-12">
                                                <div className="form-group form-group-btns">
                                                    {/*<a href="#" className="btn btn-primary btn-sm">Save</a>*/}
                                                    <button type="submit" className="btn btn-primary btn-sm float-right">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        )
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(EditCarrierPopup)


const mapStateToProps = (state,ownProps) => { 
    return{
        form: 'editcarrierValidation',
        initialValues: ownProps.modaldata,
        validate,      
        enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
  
  export default PageOneFormContainer