import React, { Component, Fragment } from 'react';
import logo from '../../truckergig-logo.png';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Eld extends Component{
    componentDidMount(){
       // console.log(this.props.location);
    }
    handleRadioChange = () =>{
        $(document).ready(function(){       
            if($(".provider_modal").is(':checked')){
                window.$('#providerModal').modal('show');
            }
        });
    }
    removeFade() {
        $('.modal-backdrop').remove();
        //window.$('#providerModal').modal('hide');
    }
    render(){             
        return(
            <div>
                <header className="header-section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="row carrier">
                                <div className="col-sm-2">
                                    <a className="navbar-brand" href="#">
                                        <img src={logo} />
                                    </a>
                                </div>
                                <div className="col-sm-8 text-center">
                                    <h3 className="onboard-title">CARRIER ONBOARDING - Opt IN/OUT</h3>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            <article className="table-data truck-data bg-white rounded onboard-class">	
        <Fragment>				            	
            <div className="container-fluid mb-3 mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                            <div className="bg-color-1 p-4 mt-3 mb-3 pos-rel">
                                <h5 className="text-center">Select Your ELD from 40+ ELD vendors listed on Truckergig.com</h5>
                                <div className="float-right pos-abs">
                                    <form id="searchthis">
                                        <input id="namanyay-search-box" name="q" size="40" type="text" placeholder="Search"/>
                                        <a href="#" id="namanyay-search-btn"><i className="fa fa-search"></i></a>
                                    </form>
                                </div>
                            </div>
                            <div className="float-left eld-height">
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-3" className="radio-custom radiopopup provider_modal" name="radio-group" type="radio" value="3" onClick={()=> {
                                                this.handleRadioChange();
                                                }}/>
                                            <label htmlFor="radio-3" className="radio-custom-label"><img src="../../images/provider_images/Tranzlogix-QA.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-4" className="radio-custom radiopopup" name="radio-group" type="radio" value="4"/>
                                            <label htmlFor="radio-4" className="radio-custom-label"><img src="../../images/provider_images/Telogis.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-44" className="radio-custom radiopopup" name="radio-group" type="radio" value="44"/>
                                            <label htmlFor="radio-44" className="radio-custom-label">MyDriver</label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-5" className="radio-custom radiopopup" name="radio-group" type="radio" value="5"/>
                                            <label htmlFor="radio-5" className="radio-custom-label"><img src="../../images/provider_images/simpletruckeld.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-6" className="radio-custom radiopopup" name="radio-group" type="radio" value="6"/>
                                            <label htmlFor="radio-6" className="radio-custom-label"><img src="../../images/provider_images/JJkeller.jpeg"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-7" className="radio-custom radiopopup" name="radio-group" type="radio" value="7"/>
                                            <label htmlFor="radio-7" className="radio-custom-label"><img src="../../images/provider_images/skybitz_logo_s.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-8" className="radio-custom radiopopup" name="radio-group" type="radio" value="8"/>
                                            <label htmlFor="radio-8" className="radio-custom-label"><img src="../../images/provider_images/SimpleElog.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-13" className="radio-custom radiopopup" name="radio-group" type="radio" value="13"/>
                                            <label htmlFor="radio-13" className="radio-custom-label"><img src="../../images/provider_images/MatrackInc.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-14" className="radio-custom radiopopup" name="radio-group" type="radio" value="14"/>
                                            <label htmlFor="radio-14" className="radio-custom-label"><img src="../../images/provider_images/KeepTruckin-logo.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-15" className="radio-custom radiopopup" name="radio-group" type="radio" value="15"/>
                                            <label htmlFor="radio-15" className="radio-custom-label"><img src="../../images/provider_images/Samsara.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-16" className="radio-custom radiopopup" name="radio-group" type="radio" value="16"/>
                                            <label htmlFor="radio-16" className="radio-custom-label"><img src="../../images/provider_images/Trimble.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-17" className="radio-custom radiopopup" name="radio-group" type="radio" value="17"/>
                                            <label htmlFor="radio-17" className="radio-custom-label"><img src="../../images/provider_images/Spireon.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-18" className="radio-custom radiopopup" name="radio-group" type="radio" value="18"/>
                                            <label htmlFor="radio-18" className="radio-custom-label"><img src="../../images/provider_images/fleetup.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-19" className="radio-custom radiopopup" name="radio-group" type="radio" value="19"/>
                                            <label htmlFor="radio-19" className="radio-custom-label"><img src="../../images/provider_images/BlueInk.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-20" className="radio-custom radiopopup" name="radio-group" type="radio" value="20"/>
                                            <label htmlFor="radio-20" className="radio-custom-label"><img src="../../images/provider_images/BigRoad.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-21" className="radio-custom radiopopup" name="radio-group" type="radio" value="21"/>
                                            <label htmlFor="radio-21" className="radio-custom-label"><img src="../../images/provider_images/FleetManager.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-22" className="radio-custom radiopopup" name="radio-group" type="radio" value="22"/>
                                            <label htmlFor="radio-22" className="radio-custom-label"><img src="../../images/provider_images/FleetFreedom.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-23" className="radio-custom radiopopup" name="radio-group" type="radio" value="23"/>
                                            <label htmlFor="radio-23" className="radio-custom-label">Digital Fleet</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-24" className="radio-custom radiopopup" name="radio-group" type="radio" value="24"/>
                                            <label htmlFor="radio-24" className="radio-custom-label"><img src="../../images/provider_images/Azuga.jpg"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-25" className="radio-custom radiopopup" name="radio-group" type="radio" value="25"/>
                                            <label htmlFor="radio-25" className="radio-custom-label"><img src="../../images/provider_images/FleetComplete.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-28" className="radio-custom radiopopup" name="radio-group" type="radio" value="28"/>
                                            <label htmlFor="radio-28" className="radio-custom-label">CarmaSys</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-26" className="radio-custom radiopopup" name="radio-group" type="radio" value="26"/>
                                            <label htmlFor="radio-26" className="radio-custom-label"><img src="../../images/provider_images/ClearPathGPS.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-27" className="radio-custom radiopopup" name="radio-group" type="radio" value="27"/>
                                            <label htmlFor="radio-27" className="radio-custom-label"><img src="../../images/provider_images/Fleetilla_Connect.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-12" className="radio-custom radiopopup" name="radio-group" type="radio" value="12"/>
                                            <label htmlFor="radio-12" className="radio-custom-label"><img src="../../images/provider_images/ThinAir.jpg"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-30" className="radio-custom radiopopup" name="radio-group" type="radio" value="30"/>
                                            <label htmlFor="radio-30" className="radio-custom-label"><img src="../../images/provider_images/LBTechnology.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-29" className="radio-custom radiopopup" name="radio-group" type="radio" value="29"/>
                                            <label htmlFor="radio-29" className="radio-custom-label"><img src="../../images/provider_images/HCSSElog.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-32" className="radio-custom radiopopup" name="radio-group" type="radio" value="32"/>
                                            <label htmlFor="radio-32" className="radio-custom-label"><img src="../../images/provider_images/GPSInsight.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-33" className="radio-custom radiopopup" name="radio-group" type="radio" value="33"/>
                                            <label htmlFor="radio-33" className="radio-custom-label"><img src="../../images/provider_images/m2mDataSmart.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-34" className="radio-custom radiopopup" name="radio-group" type="radio" value="34"/>
                                            <label htmlFor="radio-34" className="radio-custom-label"><img src="../../images/provider_images/StreamLine.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-35" className="radio-custom radiopopup" name="radio-group" type="radio" value="35"/>
                                            <label htmlFor="radio-35" className="radio-custom-label"><img src="../../images/provider_images/rand_mcnally.png"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-37" className="radio-custom radiopopup" name="radio-group" type="radio" value="37"/>
                                            <label htmlFor="radio-37" className="radio-custom-label"><img src="../../images/provider_images/PeopleNet.png"/></label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-38" className="radio-custom radiopopup" name="radio-group" type="radio" value="38"/>
                                            <label htmlFor="radio-38" className="radio-custom-label"><img src="../../images/provider_images/PeopleNet.png"/></label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-31" className="radio-custom radiopopup" name="radio-group" type="radio" value="31"/>
                                            <label htmlFor="radio-31" className="radio-custom-label"><img src="../../images/provider_images/NexTraq.jpg"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-11" className="radio-custom radiopopup" name="radio-group" type="radio" value="11"/>
                                            <label htmlFor="radio-11" className="radio-custom-label"><img src="../../images/provider_images/Zonar.jpg"/></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-39" className="radio-custom radiopopup" name="radio-group" type="radio" value="39"/>
                                            <label htmlFor="radio-39" className="radio-custom-label">Fleetmatics</label>
                                        </div>
                                    </div>                  
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-40" className="radio-custom radiopopup" name="radio-group" type="radio" value="40"/>
                                            <label htmlFor="radio-40" className="radio-custom-label">TMELD</label>
                                        </div>
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-41" className="radio-custom radiopopup" name="radio-group" type="radio" value="41"/>
                                            <label htmlFor="radio-41" className="radio-custom-label">Pedigree</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-1" className="radio-custom radiopopup" name="radio-group" type="radio" value="1"/>
                                            <label htmlFor="radio-1" className="radio-custom-label"><img src="../../images/provider_images/ORBCOMM.jpg"/></label>
                                        </div>
                                    </div>                
                                    <div className="col-md-3 col-sm-3 col-xs-12 mb-3">
                                        <div className="p-3 border-1">
                                            <input id="radio-46" className="radio-custom radiopopup" name="radio-group" type="radio" value="46"/>
                                            <label htmlFor="radio-46" className="radio-custom-label">TGLoad</label>
                                        </div>
                                    </div> 
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade addContactModal" id="providerModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">              
                    <div className="modal-content addcontactcontent">
                        <div className="modal-header">
                        <h5 className="modal-title doc-color text-center" id="exampleModalLabel"><img style={{width:'50%'}} src="../../images/provider_images/Tranzlogix-QA.png"/></h5>
                        <button type="button" className="close" id="addcontact" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form  className="tg-forms">
                        <div className="modal-body">
                            <div className="row form-group">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="row">
                                        <div className="col-md-10 col-sm-10 col-xs-10">
                                        <label className="labl">Username</label>
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-xs-10">
                                        <input type="text" name="eldUserName" id="eldUserName" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 col-sm-10 col-xs-10 mt-10">
                                            <label className="labl">Password</label>
                                        </div>
                                        <div className="col-md-10 col-sm-10 col-xs-10">
                                            <input type="password" name="eldPassword" id="eldPassword" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="modal-footer">
                        <Link to="/thankyou">
                            <button onClick={this.removeFade} type="submit" className="btn btn-primary btn-sm">Authorize</button>
                        </Link>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </Fragment>
</article>
    <div className="clearfix"></div>
        <footer className="footer p-4"> 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                    <div className="footer-lbl"><h3>Get In Touch</h3></div>
                    <div className="footer-adress">Address: 133 Peachtree St NE #3050 Atlanta, GA, 30303.</div>
                    <div className="footer-phones">Telephones: +1 800-603-9175</div>
                    <div className="footer-email">E-mail: support@intellitrans.com</div>
                    </div>
                </div>
            </div>
        </footer>
    </div>);
    }
}

export default Eld