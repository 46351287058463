import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {formData} from '../../../helpers/formData';
import Loader from '../../common/loader';
import {loadCountReportService, sendMailloadCountReportService, loadReportService} from '../../../services/reportService';
import {companyNameListService} from '../../../services/companyService';
import { toastr } from 'react-redux-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

$.validator.addMethod('multiEmail', function(value, element) {
    if (this.optional(element)) {
        return true;
    } else {
        var valid = true;

        $.each($.trim(value).replace(/,$/, '').split(','), $.proxy(function (index, email) {
            if (!$.validator.methods.email.call(this, $.trim(email), element)) {
                valid = false;
            }
        }, this));

        return valid; 
    }
}, 'One or more email addresses are invalid');

class LoadCount extends Component {
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state={
            isReady:true,
            loadList:[],
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1,
            companyList:[],
            mailBoxOpen:false,
            reportData:{},
            mailBodyContent:'',
            rowExpanded:false,
            loadDetails:[]
        }
        // this.customertype=[
        //     {id:"1",name:"Shipper"},
        //     {id:"2",name:"Broker"},
        //     {id:"3",name:"Carrier"}
        // ];
    }

    componentDidMount = () => {
        document.title = 'Load Count Report';
        //this.loadInitial();
    }

    setCustomerType = () =>{
        let options = [];
        console.log(this.props.customertype)
        if(this.props.customertype){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="">Select Customer Type</option>
            {options}
        </select>)
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                payload:{
                    companytypeid:e.target.value
                }
             }
             companyNameListService(params1).then((response) =>{
                let companyList=[];
                if(response.companyList){
                    companyList = response.companyList;
                }
                this.setState({
                    ...this.state,
                    companyList:companyList
                });
             });            
        } else {
            this.setState({
                ...this.state,
                companyList:[]
            });
        }
    }

    setCustomer = () =>{
        let options = [];
        if(this.state.companyList){
            options = this.state.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="companyId">
            <option value="0">All</option>
            {options}
        </select>)
    }

    // loadInitial = () => {
    //     if($("#frmLoadCountRpt").length == 0){
    //         setTimeout(() => {
    //             this.loadInitial();
    //           }, 100);
    //     } else {
    //         this.submitForm(null);
    //     }
    // }

    // componentDidUpdate = (prevProps, prevState) => {
    //     if((prevState.activeFilter != this.state.activeFilter) && this.state.activeFilter!="RANGE"){
    //         this.submitForm(null);
    //     }
    // }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){            
            fromDate=moment().startOf('day').toDate();
            toDate=moment().endOf('day').toDate();
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    submitForm = (e) =>{
        if(e){
            e.preventDefault();        
        }        
        $("#frmLoadCountRpt").validate({
            rules:{                
                customerType:{
                    required:true
                }
            },
            messages:{                
                customerType:{
                    required:'Select customer type'
                }
            },errorPlacement: function(error, element) {                
                    error.insertAfter(element);
            }
        });

        if( $("#frmLoadCountRpt").valid()){
            let form = document.getElementById("frmLoadCountRpt");
            let loadData = formData(form);
            console.log(loadData);
            loadData['fromDate'] = moment(this.state.fromDate).format('YYYY-MM-DD HH:mm:ss');
            loadData['toDate'] = moment(this.state.toDate).format('YYYY-MM-DD HH:mm:ss');
            
            console.log(loadData);
            // if(loadData){
            //     if(loadDataCompanyList
            // }
            this.setState({
                ...this.state,
                loadList:[],
                isReady:false,
                reportData:loadData
            });

            loadCountReportService({payload:loadData}).then((data) =>{
                console.log(data);
                let loadList = [];
                if(data){
                    if(data.CompanyList){
                        loadList=data.CompanyList;
                    }
                }
                this.setState({
                    ...this.state,
                    loadList:loadList,
                    isReady:true
                });
            });
            
        }
    }

    getRowData = () => {
        if(this.state.loadList.length==0){
            return [];
        } else {
            let loadList= this.state.loadList.map(
                (row,index) =>{
                    return {
                        id:index,
                        Company_Id:row.Company_Id,
                        //Company_Id:18,
                        Company_Name:row.Company_Name,
                        Load_Count:row.Load_Count,
                        emptyField:'',
                        expandRow:<a href="#" onClick={(e) =>{e.preventDefault();}} className="icofont-plus-circle expandIcon"></a>
                    };
                }
            );
            return loadList;
        }
    }

    getTableColumns = () => {
        return [
            {
                text:' ',
                sort:false,
                dataField:'expandRow'
            },            	
            {
                text:'Client',
                sort:true,
                dataField:'Company_Name'
            },
            {
                text:'Load Tracked',
                sort:true,
                dataField:'Load_Count',
                style:{width: '10%'},
                align:'right'
            },
            {
                text:' ',
                dataField:'emptyField',
                style:{width: '10%'}
            }
        ];
    }

    openMailBox = () => {
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }        
        this.setState({
            ...this.state,
            mailBoxOpen:true,
            mailBodyContent:''
        });
        
    }

    getLoadDetails = (row) => { 
        this.setState({
            ...this.state,
            rowExpanded:false,
            loadDetails:[]
        })       
        let param = {
            payload:{
                ...this.state.reportData,
                goFlg: true,
                exportFlg: false,
                companyId:row.Company_Id
            }
        }
        loadReportService(param).then( (response) =>{
            let loadInfoList = [];
            if(response && response.loadInfoList){
                loadInfoList=response.loadInfoList;                
            } 
            this.setState({
                ...this.state,
                rowExpanded:true,
                loadDetails:loadInfoList
            })
            
        } );
    }

    expandRow = () =>{
        //console.log('Hi');
        return {
             showExpandColumn: false,
             expandByColumnOnly: false,
             onlyOneExpanding:true,            
            renderer:(row, index) =>{                                
                if(this.state.rowExpanded){                    
                    return(this.getChildTable());
                } else {                    
                    return(<Loader />);
                }
            },
            onExpand: (row, isExpand, rowIndex, e) => {
                $("#loadCount a.icofont-minus-circle").removeClass('icofont-minus-circle').addClass('icofont-plus-circle');
                if(isExpand){
                    $(e.target).closest('tr').find('a.expandIcon').removeClass('icofont-plus-circle').addClass('icofont-minus-circle');
                    setTimeout(
                    () =>  this.getLoadDetails(row), 
                    100
                  );                                                    
                }               
            }
        };
    }

    getChildTable = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
        );
          
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        const defaultSorted = [{
            dataField: 'loadNumber',
            order: 'asc'
        }];

        return(
        <ToolkitProvider        
                    keyField="id"
                    data={ this.getChildTableRows() }
                    columns={ this.getChildTableColumns() }
                    search
                    >
                    {
                        props => (
                            <div>                                
                                <SearchBar { ...props.searchProps } />
                            
                                <BootstrapTable
                                    { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                    bordered={ false } defaultSorted={defaultSorted} 
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
        );
    }

    getChildTableColumns = () => {
        return [
            	
            {
                text:'Load',
                dataField:'loadNumber',
                sort:true
            },
            {
                text:'Shipper Reference',
                dataField:'shipperReferenceNumber',
                sort:true
            },
            {
                text:'Origin',
                dataField:'orginAddress',
                sort:true
            },
            {
                text:'Destination',
                dataField:'destinationAddress',
                sort:true
            },
            {
                text:'Dispatch',
                dataField:'dispatchDateTime',
                sort:true
            },
            {
                text:'Vehicle',
                dataField:'assetNumber',
                sort:true
            }
            
           ];
    }

    getChildTableRows = () => {
        return this.state.loadDetails.map((row,index) =>{            
            return({
                id:index,
                loadNumber:row.loadNumber,
                shipperReferenceNumber:row.shipperReferenceNumber,
                orginAddress:row.originAddress,
                destinationAddress:row.destinationAddress,
                dispatchDateTime:row.dispatchDateTime,
                assetNumber:row.vehicleId
            })
        });
    }

    getBody = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
              
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };
            const defaultSorted = [{
                dataField: 'Company_Name',
                order: 'asc'
            }];
            return (
                
                <ToolkitProvider                    
                    keyField="id"
                    data={ this.getRowData() }
                    columns={ this.getTableColumns() }
                    search
                    >
                    {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {this.state.loadList.length}</div>
                            <SearchBar { ...props.searchProps } />
                            <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div> 
                            <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.openMailBox()}} className='btn btn-primary' >Send as Email</button></div>
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'} hover 
                                bordered={ false } defaultSorted={defaultSorted} expandRow={ this.expandRow()} id="loadCount"
                            />
                            </div>
                        )
                    }
                </ToolkitProvider>
            );
        } else {
            return(<Loader />);
        }
    }

    exportData = () => {        
        if(this.state.loadList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.loadList.map(row =>{
            return {
                Client_Name:row.Company_Name,
                Load_Tracked:row.Load_Count
            }
        });
        let fileName = 'Load Count';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    sendMail = () => {        
        $("#frmEmailSend").validate({
            rules:{
                toEmail:{
                    required:true,
                    multiEmail:true
                },
                subject:{
                    required:true
                },
                bodyContent:{
                    required:true
                }
            },
            messages:{
                toEmail:{
                    required:'Please specify a recipient.!',
                    multiEmail:'Recipient is not valid.!'
                },
                subject:{
                    required:'Subject can not be left blank.!'
                },
                bodyContent:{
                    required:'Description can not be left blank.!'
                }
            }
        });

        if( $("#frmEmailSend").valid()){
            let form = document.getElementById("frmEmailSend");
            let loadData = formData(form);
            loadData["bodyContent"] = this.state.mailBodyContent
            let params={
                payload:{
                    reportData:this.state.reportData,
                    sendEmailData:loadData
                }
            };
            sendMailloadCountReportService(params).then((response) => {
                console.log(response);
                if(response.status){
                    toastr.success('Email sent', 'Report sent successfully');
                } else {
                    toastr.error('Email not sent', 'Unable to send report');
                }
            });
            this.setState({
                ...this.state,
                mailBoxOpen:false
            });

        }
    }

    closeMail = () => {
        document.getElementById("frmEmailSend").reset();
        this.setState({
            ...this.state,
            mailBoxOpen:false
        });
    }

    mailBodyContentChange = (event, editor) => {
        let mailBodyContent = editor.getData();
        this.setState({
            ...this.state,
            mailBodyContent:mailBodyContent
        });
    }

    getDateLabel = () => {
        
            let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
            let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
            return(
                <div className="row mt-4 mb-4">
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                            Date {fromDate}
                        </div>
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                            Date {fromDate} to {toDate}
                        </div>
                    </div> 
            );
        
    }

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate()
        });
    }
    render = () => {
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        return(
            <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Load Count</h3> <br></br>
                <form id="frmLoadCountRpt">
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} onShow={(event, picker)=>{this.dateRangeChange(event, picker)}}
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'RANGE')}}>Date Range</button>
                            </DateRangePicker>
                            
                        </div>
                    </div>
                    {this.getDateLabel()}                                        
                    <div className="row">
                        <div className="form-group col col-lg-3">
                            <label>Customer Type</label>
                            {this.setCustomerType()}                         
                        </div>

                        <div className="form-group col col-lg-3">
                            <label>Customer</label>
                            {this.setCustomer()}                        
                        </div>                        
                        <div className="form-group col col-lg-2">
                            <button type="button" onClick={(e) =>{this.submitForm(e)}} className="btn btn-secondary searchbtn">Get</button>
                            
                        </div>
                    </div>                    
                    <p>&nbsp;</p>                       
                </form>                
                {this.getBody()}
                <Modal show={this.state.mailBoxOpen}>
      				<ModalHeader>
        				<ModalTitle>Send Email</ModalTitle>
      				</ModalHeader>
      				<ModalBody>
                        <form id="frmEmailSend">
							<div className="row">
								<div className="col form-group">
									<label >To</label>
									<input type="text" className="form-control json-col" placeholder="To" name="toEmail" />                                    
								</div>
							</div>
							<div className="row">
								<div className="col form-group">
									<label >Subject</label>
									<input type="text" className="form-control json-col" placeholder="Subject" name="subject" />
								</div>
							</div>
							<div className="row">
								<div className="col form-group">
									<label >Description</label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={this.state.mailBodyContent}
                                        onChange={this.mailBodyContentChange} 
                                        onReady={editor => {
                                            editor.editing.view.change(writer => {
                                                writer.setStyle(
                                                "height",
                                                "100px",
                                                editor.editing.view.document.getRoot()
                                                );
                                            });
                                        }}
                                    />
								</div>
							</div>
						</form>
					</ModalBody>
      				<ModalFooter>
					  <button type="button" className="btn btn-primary"  onClick={() => {this.sendMail()}}>Submit</button>
					  <button type="button" className="btn btn-primary" onClick={() => {this.closeMail()}}>Close</button>
					</ModalFooter>
    			</Modal>
            </article>
        );
    }
}

export default LoadCount;