import React, { Component } from 'react';
import { history } from '../../helpers/history';
import { Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUserAction } from '../../actions/authActions';
import Changepassword from './changepassword';
import {Link,NavLink} from 'react-router-dom';

class AppUser extends Component{
	logoutHandler=(e)=>{
		e.preventDefault();
		this.props.dispatch(logoutUserAction());  
	}
	render(){

		const { userName, companyId, userType } =this.props.loggedUser;
		const userAuthenticated  = this.props.userAuthenticated;

		if ( this.props.userAuthenticated == false && localStorage.getItem('userName')==null) {
			return(
				<Redirect to={{ pathname: '/'}} />
			)
		}

		if(userType=='shipper')
		{
			var userTypeVal = "Customer"
		}else{
			var userTypeVal = userType
		}

		return(
			<div className="my-2 my-lg-0">
		    	<div className="dropdown">
				  	<div className="dropdown-toggle loggedin-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"       aria-expanded="false">
				    	<span>{ userName }</span>
				    	<span>[{ userTypeVal }]</span>
				  	</div>
				  	<div className="dropdown-menu dropdown-menu-right animate slideIn" aria-labelledby="dropdownMenuButton">
						{/* <Link className="dropdown-item" to="profileView">profile</Link> */}
						<a className="dropdown-item" data-toggle="modal" data-target=".changepassword">Change Password</a>				    	
						<Link className="dropdown-item" to="/adminuser/app_settings">App. Settings</Link>
						<a className="dropdown-item" href="#" onClick={ this.logoutHandler }>Logout</a>
				  	</div>
				</div>
				<Changepassword />
		    </div>
			
		)
	}
}

const mapStateToProps = state => ({
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userAuthenticated: state.authReducer.userAuthenticated
});

export default connect(mapStateToProps)(AppUser)