import React, { Component } from 'react';
import { Field, reduxForm,initialize } from 'redux-form'
import { renderField, renderSelect } from '../../helpers/FormControls';
import { menuaddAction } from '../../actions/userActions';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
import { connect } from 'react-redux';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment';
import {toastr} from 'react-redux-toastr'
import { validate } from '../widgets/validation_menu';

function submit(values) {
   const frmdata = {};
  
   frmdata.moduleName=(values.moduleName) ? (values.moduleName) : ("");    
   frmdata.menuName=(values.menuName) ? (values.menuName) : ("");
   
   frmdata.menuType=(values.menuType) ? (values.menuType) : (""); 
   frmdata.displayText=(values.displayText) ? (values.displayText) : ("");
   frmdata.menuUrl=(values.menuUrl) ? (values.menuUrl) : ("");
   frmdata.parentMenuId=(values.parentMenuId) ? (values.parentMenuId) : ("");
   this.props.dispatch(menuaddAction(frmdata));  
}
class AddDriverPopUp extends Component{
    constructor(props){
        super(props);
        this.state={
        };
    }

handleClick = () =>{
    this.props.refreshParent();
}


componentDidUpdate(prevState)
	{
        if (prevState.data.status !== this.props.data.status) {
            if(this.props.data.status=="ALREADY_REPORTED")
            {
               toastr.info('Info', 'Menu Already Exists!');
                const params={	
                    usertype: 'Menu'			
                }
                //this.props.dispatch(userlistAction(params));		
            }
            else if(this.props.data.status=="CREATED")
        {
			toastr.success('Success', 'Menu Created Successfully!');
			const params={	
				usertype: 'Driver'			
            }
           
			// this.props.dispatch(driveraddaction(params));
            document.getElementById("addmenufrm").click();
       //     this.props.refreshParent();
		}
          }
          
		// if(this.props.data.status=="ALREADY_REPORTED")
        // {
		//    toastr.info('Info', 'Driver Already Exists!');
		//     const params={	
		// 		usertype: 'Driver'			
		//     }
		//     //this.props.dispatch(userlistAction(params));		
        // }
        // else if(this.props.data.status=="CREATED")
        // {
		// 	toastr.success('Success', 'Driver Created Successfully!');
		// 	const params={	
		// 		usertype: 'Driver'			
        //     }
           
		// 	this.props.dispatch(driveraddaction(params));
        //     document.getElementById("adddriverfrm").click();
        //     this.props.refreshParent();
		// }
	}

render(){
    const { handleSubmit,pristine, reset, submitting } = this.props;
    console.log(this.props,"COmmonData")

return(
    <div className="modal fade bd-trucks-modal-lg"  data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">     
           <div className="modal-header">
           <h5 className="modal-title doc-color" id="exampleModalLabel">ADD MENU</h5>
            <button type="button" className="close" id="addmenufrm" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
                <span aria-hidden="true">&times;</span>
            </button>
           </div>
            <div className="modal-body table-data">
                             
                               <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="adddriver" role="tabpanel" aria-labelledby="adddriver-tab">
                                      <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                       <div className="row">                 
                                          <div className="col col-md-12">
                                         
                                          <article className="table-data driver-data shadow bg-white rounded">
                                                   <div className="row">
                                                      <div className="col col-md-6">
                                                      <div className="form-group">
                                                         <Field type="text" name="menuName" controlClass="requiredfield" component={renderField} label="Menu Name"/>										    
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="menuType" controlClass="requiredfield" component={renderField} label="Menu Type"/>										    
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="moduleName" controlClass="requiredfield" component={renderField} label="Module Name"/>										    
                                                         </div>
                                                         
                                                        
                                                      </div>
                                                      <div className="col col-md-6">
                                                      <div className="form-group">
                                                         <Field type="text" name="displayText" controlClass="requiredfield" component={renderField} label="Display Text"/>										    
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="menuUrl" controlClass="requiredfield" component={renderField} label="Menu URL"/>										    
                                                         </div>
                                                       
                                                      </div>
                                                                                
                                                   </div>

                                                   
                                                   </article>                                    
                                                   <div className="row">
                                                        <div className="col col-md-12">
                                                        <div className="form-group form-group-btns">
                                                            <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
                                                            <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                         </form>                          
                                      </div>
                                </div>
                      </div>
                 </div>
            </div>
       </div>
)
}
}
const renderDatePicker = ({ input: { onChange, value }, showTime,input, label, controlClass,labelnotavailabe, data,disable,type, meta: { touched, error } }) =>
<div className={`form-group ${(touched && error) ? 'error' : ''}`}>
{(labelnotavailabe) ? '' : <label>{label}</label>}
<div>
<DateTimePicker dropDown={true}
{...input}
    className={`form-group ${(controlClass) ? controlClass : ''}`}
    onChange={onChange}
    min={new Date()}
    type={type}
    text={`${(data) ? data : ''}`}
    format="MM-DD-YYYY"
    placeholder="Date"
    time={false}
    value={!value ? null : new Date(value)}
/> 
{touched && (error && <span className="error">{error}</span>)}
</div>
</div>

const renderDatePickerforhiredate = ({ input: { onChange, value }, showTime,input, label, controlClass,labelnotavailabe, data,disable,type, meta: { touched, error } }) =>
<div className={`form-group ${(touched && error) ? 'error' : ''}`}>
{(labelnotavailabe) ? '' : <label>{label}</label>}
<div>
<DateTimePicker dropDown={true}
{...input}
    className={`form-group ${(controlClass) ? controlClass : ''}`}
    onChange={onChange}
    max={new Date()}
    type={type}
    text={`${(data) ? data : ''}`}
    format="MM-DD-YYYY"
    placeholder="Date"
    time={false}
    value={!value ? null : new Date(value)}
/> 
{touched && (error && <span className="error">{error}</span>)}
</div>
</div>



const mapStateToProps = state => {
    return {
      CityVal:state.loadReducer.CityVal,
      ZipVal:state.loadReducer.ZipVal,
      data: state.userReducer.data,
      loading: state.userReducer.loading,
      error: state.userReducer.error,
        }
    }
  
export default connect(mapStateToProps)(reduxForm({ form: 'adddriverValidation', validate }) (AddDriverPopUp))

