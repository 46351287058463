import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
//import fetchIntercept from 'fetch-intercept';
//import {checkUnauthorized} from './helpers/appUtil';
import { history } from './helpers/history';
import PrivateRoutes from './routes/PrivateRoutes';
import AppLogin from './components/pages/AppLogin';

import QuickLoad from './components/pages/QuickLoad';

import CarrierOnboard from './components/pages/CarrierOnboard';

import EldProviders from './components/pages/EldProviders';
import ThankYouPage from './components/pages/ThankYouPage';

import { authHeader } from '../src/helpers/authHeader';
import Forgetpwd from './components/pages/Forgetpwd';
import Resetpwd from './components/pages/Resetpwd';

import '../src/public/css/map_popup.css';
require('dotenv').config();

// const unregister = fetchIntercept.register({
//     request: function (url, config) {
//         // Modify the url or config here
//         return [url, config];
//     },
 
//     requestError: function (error) {
//         // Called when an error occured during another 'request' interceptor call
//         return Promise.reject(error);
//     },
 
//     response: function (response) {        
//         return response.json().then((result) => {
//             console.log(result);
//             return result;
//         });        
//     },
 
//     responseError: function (error) {
//         // Handle an fetch error
//         return Promise.reject(error);
//     }
// });

    class App extends Component{

        constructor(props){
            super(props);

            this.state={
                isReady:false                
            }
        }
        

        componentDidMount = () => {
            let app_title = process.env.REACT_APP_TITLE;
            document.title = app_title;
            if(!this.state.isReady)
            {                
                if(history.location.pathname === "/quickload"){
                    this.setState({
                        isReady:true
                    });
                }else if(history.location.pathname === "/cob"){
                    this.setState({
                        isReady:true
                    });
                }else if(history.location.pathname === "/forgotpwd"){
                    this.setState({
                        isReady:true
                    });
                }else if(history.location.pathname === "/resetpassword"){
                    this.setState({
                        isReady:true
                    });
                }
                else{
                    let authToken = localStorage.getItem('authToken');
                    if(authToken){
                        this.checkAuthToken();
                    }
                    else
                    { 
                        if(!(history.location.pathname === "/login" || history.location.pathname === "/" || history.location.pathname === "/quickload" || history.location.pathname === "/cob" || history.location.pathname === "/forgotpwd" || history.location.pathname === "/resetpassword" )){
                            history.push("/login");
                        }
                        this.setState({
                            isReady:true
                        });
                    }
                }
            }
        }

        checkAuthToken = () => {
            const BASE_URL= process.env.REACT_APP_BASE_URL;
            const AUTHCHECK_ENDPOINT = BASE_URL+'commonresource/checkauthtoken';
            fetch(AUTHCHECK_ENDPOINT, {
                method: 'GET',
                headers: authHeader()
            })
            .then(response => response.json())
            .then( response => {
                if(response.status){
                    if(response.status === "valid"){
                        if(history.location.pathname === "/login" || history.location.pathname === "/"){
                            let userType = localStorage.getItem('userType');
                            history.push("/" + userType + "/dashboard");
                        }                        
                    }
                    else if(!(history.location.pathname === "/login" || history.location.pathname === "/")){
                        history.push("/login");
                        localStorage.clear();
                    }
                }
                else if(!(history.location.pathname === "/login" || history.location.pathname === "/")){
                    history.push("/login");
                    localStorage.clear();
                }
                this.setState({
                    isReady:true
                });
            } )
            .catch( error => {
                if(!(history.location.pathname === "/login" || history.location.pathname === "/")){
                    history.push("/login");
                }
                this.setState({
                    isReady:true
                });
            });
        }

        // componentWillUnmount = () =>{
        //     unregister();
        // }

        render()
        {
            if(this.state.isReady){
                return(                
                        <Router history={history}>
                            <Switch>   
                                <Route exact path="/login" component={AppLogin} />                                
                                <Route exact path="/quickload" component={QuickLoad} />
                                <Route exact path="/forgotpwd" component={Forgetpwd} />
                                <Route exact path="/resetpassword" component={Resetpwd} />
                                <Route exact path="/cob" component={CarrierOnboard} />                               
                                {/* <Route exact path="/eld" component={EldProviders} />                                
                                <Route exact path="/thankyou" component={ThankYouPage} /> */}
                                <Route path={ "/"+localStorage.getItem('userType') } component={ PrivateRoutes }  />
                                <Route exact path="" component={AppLogin} />
                            </Switch>
                        </Router>               
                );
            }
            else{
                return <Fragment></Fragment>
            }
        }
}
export default App;