import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from '../common/loader';
import { toastr } from 'react-redux-toastr';
import {getRolesAndAccessService, saveRoleAccessService} from '../../services/userRoleService';
import AddRolePopUp from './AddRolePopUp';

import $ from 'jquery';
class UserRoles extends Component{
	constructor(props){
        super(props);
        this.state={
            isReady:false,
            rowSel:-1,
            companyId:'',
            roleId:'',
            company:[],
            roles:[],
            roleAccess:[],
            saveFlag:false,
            companyType:[],
            companyTypeId:'',
            newRolePopup:false
        }
    }

    componentDidMount = () => {
        document.title = "User Roles";
        getRolesAndAccessService().then((response) =>{
            let companyId='';
            let company=[];
            let roleId='';            
            let roles=[];
            let roleAccess=[];
            let companyType=[];
            let companyTypeId='';
            if(response && response.companyList && response.companyList.length>0){
                companyId = response.companyList[0].companyId;
                company = response.companyList.map((row, index) =>{
                    return {
                        companyId:row.companyId,
                        companyName:row.companyName
                    }
                });
            }

            if(response && response.roleList && response.roleList.length>0){
                roleId = response.roleList[0].roleId;
                roles = response.roleList.map((row, index) =>{
                    return {
                        roleId:row.roleId,
                        roleName:row.roleName
                    }
                });
            }
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                                
                roleAccess = response.roleAccessList.map((row, index) =>{                    
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }

            companyType=[
                {companyTypeId:"3",companyTypeName:"Carrier"},                
                {companyTypeId:"2",companyTypeName:"Broker"},
                {companyTypeId:"1",companyTypeName:"Shipper"},
                {companyTypeId:"7",companyTypeName:"Tech Partner"},
                {companyTypeId:"8",companyTypeName:"Factor"}
            ];
            companyTypeId=companyType[0].companyTypeId;

            this.setState({
                isReady:true,
                companyId:companyId,
                roleId:roleId,
                company:company,
                roles:roles,
                roleAccess:roleAccess,
                companyTypeId:companyTypeId,
                companyType:companyType
            });
        });
        
    }
    
    loadTableData = () =>{
        return this.state.roleAccess.map((row, index) =>{            
                return {
                    id:row.roleAccessId,
                    menuName:row.menuName,
                    mode:<input type="checkbox" class="form-check-input" value="" />
                }
        });
        
    }

    loadTableColumns = () => {
        return [	
            {
                text:'Menu',
                dataField:'menuName',
                sort:true
            },            
            {
                text:'Action',
                dataField:'mode',
                sort:false
            },
        ];
    }

    set_company = (e) =>{
        this.setState({
            ...this.state,
            isReady:false,
            companyId:e.target.value
        });
        getRolesAndAccessService({companyTypeId:this.state.companyTypeId, companyId:e.target.value}).then((response) =>{
            let roleId='';            
            let roles=[];
            let roleAccess=[];
            if(response && response.roleList && response.roleList.length>0){
                roleId = response.roleList[0].roleId;
                roles = response.roleList.map((row, index) =>{
                    return {
                        roleId:row.roleId,
                        roleName:row.roleName
                    }
                });
            }
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                
                roleAccess = response.roleAccessList.map((row, index) =>{
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }

            this.setState({
                isReady:true,            
                roleId:roleId,
                roles:roles,
                roleAccess:roleAccess
            });
        });
        
    }

    set_companyType = (e) => {

        this.setState({
            ...this.state,
            isReady:false,
            companyTypeId:e.target.value
        });
        getRolesAndAccessService({companyTypeId:e.target.value}).then((response) =>{
            let companyId='';
            let company=[];
            let roleId='';            
            let roles=[];
            let roleAccess=[];
            
            if(response && response.companyList && response.companyList.length>0){
                companyId = response.companyList[0].companyId;
                company = response.companyList.map((row, index) =>{
                    return {
                        companyId:row.companyId,
                        companyName:row.companyName
                    }
                });
            }

            if(response && response.roleList && response.roleList.length>0){
                roleId = response.roleList[0].roleId;
                roles = response.roleList.map((row, index) =>{
                    return {
                        roleId:row.roleId,
                        roleName:row.roleName
                    }
                });
            }
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                                
                roleAccess = response.roleAccessList.map((row, index) =>{                    
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }            

            this.setState({
                isReady:true,
                companyId:companyId,
                roleId:roleId,
                company:company,
                roles:roles,
                roleAccess:roleAccess
            });
        });
        
    }

    

    renderCompanyType = () => {
        let companyType = this.state.companyType.map((row, index) => {
            return <option key={index} value={row.companyTypeId}>{row.companyTypeName}</option>
        });
        return <Fragment>
        <div className="col-1">Company Type</div>
        <div className="col-2">
            <select className="form-control" value={this.state.companyTypeId} onChange={(e) =>{this.set_companyType(e)}} disabled={this.state.saveFlag}>
                {companyType}
            </select>
        </div>
        </Fragment>
    }

    renderCompany = () => {
        let company = this.state.company.map((row, index) => {
            return <option key={index} value={row.companyId}>{row.companyName}</option>
        });
        return <Fragment>
        <div className="col-1 text-right">Company</div>
        <div className="col-2">
            <select className="form-control" value={this.state.companyId} onChange={(e) =>{this.set_company(e)}} disabled={this.state.saveFlag}>
                {company}
            </select>
        </div>
        </Fragment>
    }

    set_role = (e) => {
        this.setState({
            ...this.state,
            isReady:false,
            roleId:e.target.value
        });
        getRolesAndAccessService({companyTypeId:this.state.companyTypeId, companyId:this.state.companyId,roleId:e.target.value}).then((response) =>{
            
            let roleAccess=[];           
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                
                roleAccess = response.roleAccessList.map((row, index) =>{
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false
                    }
                });
            }
            this.setState({
                isReady:true,  
                roleAccess:roleAccess
            });
        });

        
    }

    renderRoles = () =>{
        let roles = this.state.roles.map((row, index) =>{
            return <option key={index} value={row.roleId}>{row.roleName}</option>
        });
        return <Fragment>
            <div className="col-1 text-right">Roles</div>
            <div className="col-2">
                <select className="form-control" value={this.state.roleId} onChange={(e) =>{this.set_role(e)}} disabled={this.state.saveFlag}>
                    {roles}
                </select>
            </div>
        </Fragment>
    }

    openNewRole = (e) =>{
        e.preventDefault();
        this.setState({
            ...this.state,
            newRolePopup:true
        })
    }

    closeNewRole = () =>{
        this.setState({
            ...this.state,
            newRolePopup:false
        })
    }
    render = () =>{
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing { from } to { to } of { size } Results
            </span>
        );
		const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };
        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded">
                    <div className="row">
                        <div className="col">
                            <h3>Roles</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row">
                        <div className="col">
                            <ToolkitProvider
                                keyField="id"
                                data={ this.loadTableData() }
                                columns={ this.loadTableColumns() }
                                search
                            >
                                {
                                props => (
                                    <div>
                                        <div className="row">
                                            {this.renderCompanyType()}
                                            {this.renderCompany()}
                                            {this.renderRoles()}
                                            
                                            <div className="col">
                                                <SearchBar { ...props.searchProps } />
                                            </div>
                                            <div className="btn-export float-right d-inline-block pr-2">
                                                    <a href="#" className="btn btn-primary" onClick={(e) =>{this.openNewRole(e)}}>Add Role</a>
                                                </div>
                                        </div>
                                        <BootstrapTable bootstrap4 
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication='No data found'  hover 
                                            bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                        </div>
                        <div>
                            <AddRolePopUp companyTypes={this.state.companyType} companyTypeId={this.state.companyTypeId} companies={this.state.company} newRolePopup={this.state.newRolePopup} closeNewRole={() =>{this.closeNewRole()}} />		
						</div>
                    </div>
                </article>
            );
        }
        return(<Loader />);
    }    
    
}
export default UserRoles;