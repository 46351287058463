import React, { Component } from 'react';
import { connect } from 'react-redux';

import CarrierList from '../common/CarrierList';

import './dashboardnew.css';

class OnBoarding extends Component{
	constructor(props) {
        super(props);
    }

    render = () => {
        return(
            <article className="shadow bg-white rounded" id="onboardpage">
                <div className="row">
                    <div className="col">
                        <h3>Carrier Onboarding</h3>
                        <CarrierList companyTypeId={0} statelist={this.props.statelist} country={this.props.country} specialitytypelist={this.props.specialitytypelist} eldproviderlist={this.props.eldproviderlist}/>
                    </div>
                </div>
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return{}
}
export default connect(mapStateToProps)(OnBoarding);