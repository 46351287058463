import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapboxCircle from 'mapbox-gl-circle';
import zipcodes from 'zipcodes';
import $ from 'jquery';
import moment from 'moment';
import {formatLocation, getMapboxKey} from '../../helpers/appUtil';
import {getActiveTruckTracking} from '../../services/assetService';

require('dotenv').config();


class DashboardMap extends Component {
   constructor(props) {
     super(props);
      this.startFlag=true;
      this.timer1=null;
      this.lineCoOrds=[];
      this.currentzoom = this.props.mapZoom;
      this.arrow_up = process.env.REACT_APP_URL+'images/arrow-up.png';
      this.arrow_up_red = process.env.REACT_APP_URL+'images/arrow-up-red.png';
      this.lastLat=0;
      this.lastLont=0;
      this.isTrackingLoaded=false;
      this.mapCircle=null;
      this.activeSourceCircle=null;
      this.activeDestCircle=null;
   }
   state = {
        map : null,
        activemarkers:[],
        loader: false,
        randomgen:Math.random(),
        activeUrl: 'https://maps.google.com/mapfiles/ms/icons/green.png',
        idealUrl: 'https://maps.google.com/mapfiles/ms/icons/yellow.png',
        stoppedUrl: 'https://maps.google.com/mapfiles/ms/icons/red.png'
   }
   componentDidUpdate(){
    
    this.startFlag=true;
    this.lineCoOrds=[];
    this.currentzoom = this.props.mapZoom;
    if(this.timer1!=null)
    {
      clearInterval(this.timer1);
    }
    this.getData();
   }

   componentDidMount() {   
    mapboxgl.accessToken = getMapboxKey();
    //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    let mapobject=new mapboxgl.Map({
        container: 'map', // container id
        style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
        center: this.props.mapCenter, // starting position
        zoom: this.props.mapZoom // st arting zoom
    });

    let popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    mapobject.on('load',() => {

        mapobject.loadImage(this.state.activeUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('activemarkerimage', image);
        })

        mapobject.loadImage(this.state.idealUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('idealmarkerimage', image);
        })

        mapobject.loadImage(this.state.stoppedUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('stoppedmarkerimage', image);
        })

        mapobject.loadImage(this.arrow_up, function(error, image) {
            if (error) throw error;
            mapobject.addImage('arrow_up', image);
        })

        mapobject.loadImage(this.arrow_up_red, function(error, image) {
            if (error) throw error;
            mapobject.addImage('arrow_up_red', image);
        })       

        mapobject.addSource("sourcetrucklocations",
        {
            type: "geojson",
            data: {
                type: 'FeatureCollection',
                features: []
            }, 
        });

        mapobject.addSource("trucklocations",
        {
            type: "geojson",
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true, 
            clusterMaxZoom: 14, 
            clusterRadius: 40 
        });
        
        var layers = [
            [20, '#f28cb1'],
            [10, '#f1f075'],
            [0, '#51bbd6']
        ];    
        layers.forEach(function (layer, i) {
            mapobject.addLayer({
                "id": "cluster-" + i,
                "type": "circle",
                "source": "trucklocations",
                "paint": {
                    "circle-color": layer[1],
                    "circle-radius": 18
                },
                "filter": i === 0 ?
                    [">=", "point_count", layer[0]] :
                    ["all",
                        [">=", "point_count", layer[0]],
                        ["<", "point_count", layers[i - 1][0]]]
            });
        });
        mapobject.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'trucklocations',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });

        mapobject.addLayer({
          id: 'sourcetruckpoint',
          type: 'symbol',
          source: 'sourcetrucklocations',
          "layout": {
              "icon-image": "arrow_up_red",
              "icon-size": 1,
              "icon-rotate":0
          },
        });

        mapobject.addLayer({
            id: 'truckpoint',
            type: 'symbol',
            source: 'trucklocations',
            filter: ['!has', 'point_count'],
            "layout": {
                "icon-image": "activemarkerimage",
                "icon-size": 1,
                "icon-rotate":0
            },
        });

        mapobject.on('mouseenter', 'truckpoint', function(e) {
        
            mapobject.getCanvas().style.cursor = 'pointer';
             
            var coordinates = e.features[0].geometry.coordinates.slice();
            var description = e.features[0].properties;         
            
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            let speed = parseFloat(description.speed);
            if(isNaN(speed))
            {
                speed ='';
            }
            else{
                speed = speed.toFixed(2) + " mph";
            }
            console.log(speed);

            let location = formatLocation(description.location);

            popup
            .setLngLat(coordinates)
            .setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">'+description.truckNo+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+description.date+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>')
            .addTo(mapobject);
        });

        mapobject.on('mouseleave', 'truckpoint', function() {
            mapobject.getCanvas().style.cursor = '';
            popup.remove();
        });

        mapobject.on('mouseenter', 'sourcetruckpoint', function(e) {
        
          mapobject.getCanvas().style.cursor = 'pointer';
           
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties;         
          
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          let speed = parseFloat(description.speed);
          if(isNaN(speed))
          {
              speed ='';
          }
          else{
              speed = speed.toFixed(2) + " mph";
          }
          console.log(speed);

          let location = formatLocation(description.location);

          popup
          .setLngLat(coordinates)
          .setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">'+description.truckNo+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+description.date+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>')
          .addTo(mapobject);
        });
        mapobject.on('mouseleave', 'sourcetruckpoint', function() {
            mapobject.getCanvas().style.cursor = '';
            popup.remove();
        });

        mapobject.addSource('movementroute', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                        'type': 'LineString',
                        'coordinates': []
                        }
                    }
                ]
            }
        });

        mapobject.addLayer({
            'id': 'historypath',
            'type': 'line',
            'source': 'movementroute',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': 'green',
                'line-opacity': 0.75,
                'line-width': 3
            }
        });

        this.getData();
    });

    this.setState({
        map : mapobject 
    });
   }

   getLastTrackingData = () => {
    
    this.isTrackingLoaded=true;
    let rndval=Date.now();
    getActiveTruckTracking({rndval:rndval,assetId:this.props.truckInfo.id}).then(response =>{
      // if(this.startFlag){        
      //   this.setGeofence(response.originZip,response.destinationZip)
      // }
      this.setTracking(response.TrackingData);
    });
   }

   setGeofence = (originZip, destinationZip) => {      

      let point1 = this.getPoint(originZip);

      let point2 = this.getPoint(destinationZip);
      let mapobject = this.state.map;
      if(point1.lat && point1.lont){
        this.activeSourceCircle=new MapboxCircle({lat: point1.lat, lng: point1.lont}, 32186.9, {
              editable: false,
              minRadius: 1609.34,
              fillColor: '#dd1111'
          }).addTo(mapobject);

      }

      if(point2.lat && point2.lont){
        this.activeDestCircle=new MapboxCircle({lat: point2.lat, lng: point2.lont}, 32186.9, {
              editable: false,
              minRadius: 1609.34,
              fillColor: '#11dd11'
          }).addTo(mapobject);
      }
   }

   getPoint = (zipCode) => {
      if(zipCode){
          let point1 = zipcodes.lookup(zipCode);
          if(point1){
              let lont = parseFloat(point1.longitude);
              let lat = parseFloat(point1.latitude);
              if(isNaN(lont) || isNaN(lat))
              {
                  return {};
              }
              return {lont:lont, lat:lat};
          }
          else{
              return {};
          }
      }
      else{
          return {};
      }
  }

   setTracking = (data) =>{
     if(!data){
        this.timer1=setTimeout(() => {
          this.getLastTrackingData();
        }, 15000);
       return;
     } else if(data.length==0){
      this.timer1=setTimeout(() => {
        this.getLastTrackingData();
      }, 15000);
      return;
     }
    let mapobject = this.state.map;
    if(this.startFlag){      
      this.startFlag=false;      
      mapobject.triggerRepaint();
      let lastLat=parseFloat(data[0].latitude);
      let lastLont=parseFloat(data[0].longitude);
      mapobject.setCenter([                            
        lastLont,
        lastLat
      ]);

      let diretion = parseFloat(data[0].heading);
      if(isNaN(diretion))
      {
        mapobject.setLayoutProperty('sourcetruckpoint', 'icon-image', 'stoppedmarkerimage');
        mapobject.setLayoutProperty('truckpoint', 'icon-image', 'activemarkerimage');
        mapobject.setLayoutProperty('truckpoint', 'icon-rotate', 0);        
      } else {
        diretion = (diretion | 0);
        mapobject.setLayoutProperty('truckpoint', 'icon-image', 'arrow_up');
        mapobject.setLayoutProperty('truckpoint', 'icon-rotate', diretion);        
        mapobject.setLayoutProperty('sourcetruckpoint', 'icon-rotate', diretion);
      }

      //let convertedDate = (data[0].eldDateUtc) ? (moment(data[0].eldDateUtc).format('MM-DD-YYYY T: h:mm a')) : ("");
      let convertedDate = data[0].convertedDate

      let dataS={
        "type":"Feature",
        "properties":{
            "id":this.props.truckInfo.id,
            "truckNo":this.props.truckInfo.truckNo,
            "latitude":data[0].latitude,
            "longitude":data[0].longitude,
            "speed":data[0].speed,
            "address":data[0].location,
            "location":data[0].location,
            "date":convertedDate,
        },
        "geometry":{
            "type":"Point",
            "coordinates":[                            
                lastLont,
                lastLat
            ]
        }
        
      };
    
      mapobject.getSource('trucklocations').setData({
          type: 'FeatureCollection',
          features: [dataS]
      });
      mapobject.getSource('sourcetrucklocations').setData({
          type: 'FeatureCollection',
          features: [dataS]
      });

      this.lineCoOrds.push([                            
        lastLont,
        lastLat
      ]);

      mapobject.getSource('movementroute').setData({
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                'type': 'LineString',
                'coordinates': this.lineCoOrds
                }
            }
        ]
      });      

      this.timer1=setTimeout(() => {
        this.getLastTrackingData();
      }, 15000);
      
    } else {
      console.log(data);
      this.setMovement(data, 0);
    }

    this.isTrackingLoaded=false;
    //console.log(data);
  }

  setMovement = (data, index) => {
    console.log(data);
    let mapobject = this.state.map;
    let dataLen = -1;
    if(data){
      dataLen = data.length-1;
    } 
    console.log(index);
    if(dataLen < 0){
      this.timer1=setTimeout(() => {
        this.getLastTrackingData();
      }, 15000);
    }
    else {
      console.log(this.lineCoOrds);
      let lastLat=parseFloat(data[index].latitude);
      let lastLont=parseFloat(data[index].longitude);
      console.log(this.lineCoOrds.length);
      if(this.lineCoOrds.length>0){ 
        let prevLat = parseFloat(this.lineCoOrds[this.lineCoOrds.length-1][1]);
        let prevLont = parseFloat(this.lineCoOrds[this.lineCoOrds.length-1][0]);        
        let previouslat = lastLat.toFixed(5);
        let previouslon = lastLont.toFixed(5);
        let currentlat = prevLat.toFixed(5);
        let currentlon = prevLont.toFixed(5);
        console.log(previouslat , currentlat , previouslon , currentlon);
        if(!((previouslat === currentlat) && (previouslon === currentlon))){
          console.log('A');
          mapobject.triggerRepaint();
          let currentposition = [lastLont, lastLat];

          while(!mapobject.getBounds().contains(currentposition)){
            mapobject.setZoom((this.currentzoom--));
          }

          let diretion = parseFloat(data[index].heading);
          if(isNaN(diretion)){
            mapobject.setLayoutProperty('truckpoint', 'icon-image', 'activemarkerimage');
            mapobject.setLayoutProperty('truckpoint', 'icon-rotate', 0);
          } else {
            diretion = (diretion | 0);
            mapobject.setLayoutProperty('truckpoint', 'icon-image', 'arrow_up');
            mapobject.setLayoutProperty('truckpoint', 'icon-rotate', diretion);
          }

          //let convertedDate = (data[index].eldDateUtc) ? (moment(data[index].eldDateUtc).format('MM-DD-YYYY T: h:mm a')) : ("");
          let convertedDate = data[index].convertedDate;

          this.lineCoOrds.push([                            
            lastLont,
            lastLat
          ]);
          
          let dataS={
            "type":"Feature",
            "properties":{
                "id":this.props.truckInfo.id,
                "truckNo":this.props.truckInfo.truckNo,
                "latitude":data[index].latitude,
                "longitude":data[index].longitude,
                "speed":data[index].speed,
                "address":data[index].location,
                "location":data[index].location,
                "date":convertedDate,
            },
            "geometry":{
                "type":"Point",
                "coordinates":[                            
                    lastLont,
                    lastLat
                ]
            }
            
          };
        
          mapobject.getSource('trucklocations').setData({
              type: 'FeatureCollection',
              features: [dataS]
          });

          mapobject.getSource('movementroute').setData({
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'LineString',
                    'coordinates': this.lineCoOrds
                    }
                }
            ]
          });
        }
      }
      else {
        this.lineCoOrds.push([                            
          lastLont,
          lastLat
        ]);
  
        mapobject.getSource('movementroute').setData({
          'type': 'FeatureCollection',
          'features': [
              {
                  'type': 'Feature',
                  'geometry': {
                  'type': 'LineString',
                  'coordinates': this.lineCoOrds
                  }
              }
          ]
        });
      }
      if(dataLen == index){
        this.timer1 = setTimeout(() => {
          this.getLastTrackingData();
        }, 15000);
      } else {
        this.timer1=setTimeout(() => {
          this.setMovement(data, index+1);
        }, 100);
      }
    }  
  }

  startMovingMap = () => {
    let mapobject = this.state.map;
    
      if(!this.isTrackingLoaded){
        this.currentzoom=12;
        mapobject.setZoom((this.currentzoom));
        mapobject.setCenter(this.props.mapCenter);
        this.getLastTrackingData(); 
      }
      console.log(this.props.truckInfo);        
  }

  startStaticMap = () => {
    let mapobject = this.state.map;
    mapobject.triggerRepaint();
    console.log(this.props.mapZoom);
    mapobject.setCenter(this.props.mapCenter);
    mapobject.setZoom((this.props.mapZoom));
    if(this.props.data.length===0){ 
        try{
            
            mapobject.getSource('trucklocations').setData({
                type: 'FeatureCollection',
                features: []
            });
            $("div.spinner-border").hide();
        }
        catch(e){
            this.state.randomgen = Math.random();
        }
    }
    if(this.props.data.length>0){
            for (var i = this.state.activemarkers.length - 1; i >= 0; i--) {
                this.state.activemarkers[i].remove();
            }
            this.state.activemarkers.length=0;
            let features = this.props.data.map(data => {
                return(
                    {
                        "type":"Feature",
                        "properties":{
                            "id":data.id,
                            "truckNo":data.truckNo,
                            "latitude":data.latitude,
                            "longitude":data.longitude,
                            "speed":data.speed,
                            "address":data.address,
                            "location":data.location,
                            "date":data.convertedDate,
                        },
                        "geometry":{
                            "type":"Point",
                            "coordinates":[                            
                                data.longitude,
                                data.latitude
                            ]
                        }
                    }
                )
            });
            try{
                console.log('Map Object  : ' + mapobject.getSource('trucklocations'));
                mapobject.setCenter(this.props.mapCenter);
                mapobject.setZoom(this.props.mapZoom);
                mapobject.getSource('trucklocations').setData({
                    type: 'FeatureCollection',
                    features: features
                });
                mapobject.setLayoutProperty('truckpoint', 'icon-rotate', 0);
                if(this.props.truckstate == 'active'){
                    
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'activemarkerimage');
                }
                else if(this.props.truckstate == 'ideal'){
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'idealmarkerimage');
                    
                }
                else {
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'stoppedmarkerimage');
                }
                $("div.spinner-border").hide();
            }
            catch(e){
                this.state.randomgen = Math.random();
            }
        }
  }

   getData() {
    if(this.timer1!=null)
    {
      clearInterval(this.timer1);
    }
    
    let mapobject = this.state.map;
    let truckpoint = mapobject.getSource('trucklocations');
    if(truckpoint){
      mapobject.triggerRepaint();
      mapobject.setZoom(this.props.mapZoom);
      if(this.activeDestCircle){
        this.activeDestCircle.remove();
        this.activeDestCircle=null;
      }
  
      if(this.activeSourceCircle){
        this.activeSourceCircle.remove();
        this.activeSourceCircle=null;
      }
      mapobject.setLayoutProperty('truckpoint', 'icon-image', 'activemarkerimage');
      mapobject.setLayoutProperty('truckpoint', 'icon-rotate', 0);

      let dataS={
        "type":"Feature",
        "properties":{
            "id":'',
            "truckNo":'',
            "latitude":'',
            "longitude":'',
            "speed":'',
            "address":'',
            "location":'',
            "date":'',
        },
        "geometry":{
            "type":"Point",
            "coordinates":[]
        }
        
      };
      
      mapobject.getSource('sourcetrucklocations').setData({
        type: 'FeatureCollection',
        features: [dataS]
      });

      mapobject.getSource('trucklocations').setData({
        type: 'FeatureCollection',
        features: [dataS]
      });

      mapobject.getSource('movementroute').setData({
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                'type': 'LineString',
                'coordinates': []
                }
            }
        ]
      });
      
      if(this.props.mapViewMode == 'moving'){
        this.startMovingMap();
      } else {
        this.startStaticMap();
      }

    } else{
      this.timer1 = setTimeout(() => {
        this.getData();
      }, 300);
    } 
   }
   
   handleEldprovider = (id) =>{
    this.props.handleEldprovider(id);
   }

   render(){
     return (
            <div id="map-container">
            <div className="spinner-border spinner-grow-sm maploader" role="status">
            </div>
            <div id="map"></div>
                      {/*  <Eldprovider handleEldprovider={(id)=> {this.handleEldprovider(id)}}/> */}
             </div>      
     )
   }
};
const mapStateToProps = (state,ownProps) => {
	return{		
        eldprovider : state.commonReducer.eldproviderlist
	}    
}
export default connect(mapStateToProps)(DashboardMap)