import React, { Component, Fragment } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
//import zipcodes from 'zipcodes';
import Modal from 'react-bootstrap/Modal';
import MapboxCircle from 'mapbox-gl-circle';
import {formatLocation} from '../../../helpers/appUtil';
import greenMarker from '../../../images/mapmarkergreen.png';
import redMarker from '../../../images/mapmarkerred.png';
import moment from 'moment';
require('dotenv').config();


class DispatchLiveMap extends Component{
    constructor(props)  {
        super(props);
        this.state={
            mapobject:null,
            lng:-83.0978885649376,
            lat:42.5400051498416,
            zoom:12
        }
        this.trackPoint = 0;
        this.img1 = process.env.REACT_APP_URL+'images/mapmarkerred.png';
        this.img2 = process.env.REACT_APP_URL+'images/mapmarkergreen.png';
        this.isMapLoaded=false;
    }
    componentDidMount() {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    }

    initMap = () =>{
        this.trackPoint = 0;
        let map = document.querySelector("#map");

        if(map == null){
            setTimeout(() => {
                this.initMap();                
            }, 1000);
            return;
        }
        
        let point1 = this.getPoint(this.props.pickupPoint);

        let point2 = this.getPoint(this.props.dropPoint);

        console.log(point1, point2);

        let mapCenter = [this.state.lng, this.state.lat];

        if(point1.lont && point1.lat){
            mapCenter = [point1.lont, point1.lat];
        }

        let trackingData = this.getTrackingData();

        let sourceData = this.getMapPointData(point1);
        let destData = this.getMapPointData(point2);

        let mapobject = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: mapCenter,
            zoom: this.state.zoom
        });

        let poupup1 = this.getPopupData(0);

        let poupup2 = this.getPopupData(1);
       
        let img1 = this.img1;

        let img2 = this.img2;

        let trackingRouteData = this.getTrackRoute([]);

        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });        
        
        mapobject.on('load',() => {

            mapobject.loadImage(img1, function(error, image) {
                if (error) throw error;
                mapobject.addImage('sourceMarker', image);
            })

            mapobject.loadImage(img2, function(error, image) {
                if (error) throw error;
                mapobject.addImage('destMarker', image);
            })
            
            if(point1.lat && point1.lont){
                new MapboxCircle({lat: point1.lat, lng: point1.lont}, 32186.9, {
                    editable: false,
                    minRadius: 1609.34,
                    fillColor: '#dd1111'
                }).addTo(mapobject);

            }

            
            if(point2.lat && point2.lont){
                new MapboxCircle({lat: point2.lat, lng: point2.lont}, 32186.9, {
                    editable: false,
                    minRadius: 1609.34,
                    fillColor: '#11dd11'
                }).addTo(mapobject);
            }

            mapobject.addSource('trackRoute', {
                'type': 'geojson',
                'data': trackingRouteData
            });

            mapobject.addLayer({
                'id': 'trackRouteLayer',
                'type': 'line',
                'source': 'trackRoute',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': 'red',
                    'line-opacity': 0.75,
                    'line-width': 3
                }
            });

            mapobject.addSource('sourcePoint', {'type': 'geojson',data:sourceData});

            mapobject.addLayer({
                'id': 'sourcePointLayer',
                'type': 'symbol',
                'source': 'sourcePoint',
                'layout': {
                    'icon-image': 'sourceMarker',
                    'icon-allow-overlap': true
                }
            });

            mapobject.addSource('destPoint', {'type': 'geojson',data:destData});

            mapobject.addLayer({
                'id': 'destPointLayer',
                'type': 'symbol',
                'source': 'destPoint',
                'layout': {
                    'icon-image': 'destMarker',
                    'icon-allow-overlap': true
                }
            });

            mapobject.on('mouseenter', 'sourcePointLayer', function(e) {
                console.log(e.features[0].properties);
                let description = e.features[0].properties;
                mapobject.getCanvas().style.cursor = 'pointer';
                popup
                .setLngLat([description.lont,description.lat])
                .setHTML(poupup1)
                .addTo(mapobject);
            });

            mapobject.on('mouseleave', 'sourcePointLayer', function() {
                mapobject.getCanvas().style.cursor = '';
                popup.remove();
            });

            mapobject.on('mouseenter', 'destPointLayer', function(e) {
                console.log(e.features[0].properties);
                let description = e.features[0].properties;
                mapobject.getCanvas().style.cursor = 'pointer';
                popup
                .setLngLat([description.lont,description.lat])
                .setHTML(poupup2)
                .addTo(mapobject);
            });

            mapobject.on('mouseleave', 'destPointLayer', function() {
                mapobject.getCanvas().style.cursor = '';
                popup.remove();
            });

            if(point2.lont && point2.lat){
                let lastPoint = [point2.lont, point2.lat];
                let zoom = this.state.zoom;

                while((!mapobject.getBounds().contains(lastPoint)) && zoom>0){
                    zoom--;
                    mapobject.setZoom(zoom);
                }
            }
            else{
                mapobject.setZoom(12);
            }            
            
        });
        this.isMapLoaded=true;
        

        this.setState({
            ...this.state,
            mapobject:mapobject
        });
    }

    getPopupData = (flag) => {
        if(!(this.props.trackingData)) return '';

        if(!(this.props.trackingData.length>0)) return '';

        let data=null;
        if(flag == 0)
        {
            data = this.props.trackingData[0]
        }
        else if(this.props.trackingData.length>0){
            data = this.props.trackingData[this.props.trackingData.length-1];
        }
        else{
            return '';
        }
        let speed = parseFloat(data.speed);
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }

        let location = formatLocation(data.location);

        let date='';

        if(data.utc_Date_Time){
            date = moment(data.UTC_Date_Time).format('MM-DD-YYYY T: h:mm a');
        }

        let result = '<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">'+data.license_Plate_No+'</span></br>'
        +'<span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+date+'</span></br>'
        +'<span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br>'
        +'<span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>';

        return result;
    }

    getTrackRoute = (data) => {
       return( {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'LineString',
                    'coordinates': data
                    }
                }
            ]
        });
    }

    getTrackingData = () => {
        let trackingData = [];
        if(this.props.trackingData){
            for(let i=0; i<this.props.trackingData.length; i++){
                trackingData.push([this.props.trackingData[i].longitude, this.props.trackingData[i].latitude]);
            }
        }
        if(trackingData.length==0){
            trackingData.push([this.state.lng, this.state.lat]);
        }
        return trackingData;
    }

    getNTrackingData = () => {
        let trackingData = [];
        if(this.props.trackingData){
            if(this.trackPoint<this.props.trackingData.length){
                this.trackPoint++;
                for(let i=0; i<this.trackPoint; i++){
                    trackingData.push([this.props.trackingData[i].longitude, this.props.trackingData[i].latitude]);
                }
            }
        }
        return trackingData;
    }

    getRadius = () => {
        let radius = [];
        for(let i=0;i<23;i++){
            radius.push([i,i+20]);
        }
        return radius;
    }

    getMapPointData = (properties) => {
        return{
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'properties': properties,
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [properties.lont, properties.lat]                            
                        }
                    }
                ]
            }
    }

    getPoint = (point) => {
        if(point){
            let point1 = "zipcodes.lookup(point);"
            if(point1){
                let lont = parseFloat(point1.longitude);
                let lat = parseFloat(point1.latitude);
                if(isNaN(lont) || isNaN(lat))
                {
                    return {};
                }
                return {lont:lont, lat:lat};
            }
            else{
                return {};
            }
        }
        else{
            return {};
        }
    }

    checkPoin = () => {
        if(this.state.mapobject){
            let mapSource = this.state.mapobject.getSource('trackRoute');
            if(mapSource){
                let data = this.getNTrackingData();
                if(data.length>0)
                {
                    let trackingPoints = this.getTrackRoute(data);
                    mapSource.setData(trackingPoints);
                    setTimeout(() => {
                        this.checkPoin();                
                    }, 100);
                }
            }
            else{
                setTimeout(() => {
                    this.checkPoin();                
                }, 1000);
                return;
            }
                       
        }        
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        this.checkPoin();
        if(this.props.showLiveMap && (!this.isMapLoaded))
        {
            this.initMap();
            return;
        }
        else if(this.props.showLiveMap==false){
            this.isMapLoaded=false;
            return;
        }
        
    }
    
    
    render = () => {
        return(
            <Fragment>
            <Modal show={this.props.showLiveMap} onHide={this.props.closeLiveMap} dialogClassName="modal-90w modal-map">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100">Truck Location</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div id="map" className="map-container"></div>
                </Modal.Body>								
            </Modal>
            <div className="d-none">
                <img src={redMarker} id="redMarker" />
                <img src={greenMarker} id="greenMarker" />
            </div>
            </Fragment>
        );
    } 
}
export default DispatchLiveMap