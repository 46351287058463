import React, { Component, Fragment } from 'react';
import Loader from './loader';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import 'jquery-validation';
import {formData} from '../../helpers/formData';
import moment from 'moment';
import {saveMyCarrierService} from '../../services/adminService';
import { toastr } from 'react-redux-toastr';

class AdminMyCarrierEntry extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:true
        }
        this.rowsAffected=0;
    }

    componentDidMount = () => {
        this.rowsAffected=0;
    }

    saveCarrier = () => {
        $("#myform").validate({
            rules:{
                dotNumber:{
                 required:true,
                 digits: true
                },
                legalName:{
                    required:true,
                },
                nbrPowerUnit : {
                    digits: true
                },
                driverTotal : {
                    digits: true
                }
            },
            messages:{
                dotNumber:{
                 required:'DOT Number can not be left blank.!',
                 digits:'DOT Number should be a number'
                },
                legalName:{
                    required:'Legal Name can not be left blank.!'
                },
                nbrPowerUnit : {
                    digits: 'NBR Power Unit should be a number'
                },
                driverTotal : {
                    digits: 'Driver Total should be a number'
                }
            }
        });

        if( $("#myform").valid()){
            let form = document.getElementById("myform");
            let loadData = formData(form);
            console.log(loadData);
            let varDate = (loadData.mcs150Date) ? (moment(loadData.mcs150Date).format('YYYY-MM-DD')) : ("");
            loadData.mcs150Date=varDate;
            varDate = (loadData.addDate) ? (moment(loadData.addDate).format('YYYY-MM-DD')) : ("");
            loadData.addDate=varDate;
            let param={
                payload:loadData
            }
            saveMyCarrierService(param).then((response) =>{
                if(response.status){
                    if(response.status==1){
                        if(this.props.flag == "EDIT"){
                            toastr.success('Updated', 'Carrier updated successfully');
                            setTimeout(
                                () => {
                                    this.props.closeForm(this.rowsAffected);
                                }, 
                                3000
                              );
                        } else {
                            toastr.success('Saved', 'Carrier saved successfully');
                        }
                        //document.getElementById("myform").reset();
                        $("#myform .json-col, #myform .rw-input").val('');
                        this.rowsAffected++;
                    } else {
                        toastr.error('Not saved', 'Unable to save carrier');
                    }
                } else {
                    toastr.error('Not saved', 'Unable to save carrier');
                }
                
            });
        }
        
    }

    render = () => {
        if(this.state.isReady){
            let label = "New Carrier";
            let myCarrierId = 0;
            let addDate=null;
            let mcs150Date=null;
            if(this.props.flag == "EDIT"){
                label = "Update Carrier";
                myCarrierId = this.props.carrierData.myCarrierId;
                addDate = (this.props.carrierData.addDate?( moment(this.props.carrierData.addDate).toDate()):null);
                mcs150Date = (this.props.carrierData.mcs150Date?( moment(this.props.carrierData.mcs150Date).toDate()):null);
            }
            return(                
                <article className="shadow bg-white rounded" id="onboardpage">                    
                    <div className="row">
                        <div className="col">
                            <h3>{label}</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <form id="myform">
                    <div className="row" >
                        <div className="col">
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Legal Name</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Legal Name" name="legalName" defaultValue={this.props.carrierData?(this.props.carrierData.legalName):''} ></input>
                                    <input type="hidden" defaultValue={myCarrierId} className="json-col" name="myCarrierId"></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >DOT Number</label>
                                    <input type="text" className="form-control json-col" placeholder="Enter DOT Number" name="dotNumber" defaultValue={this.props.carrierData?(this.props.carrierData.dotNumber):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Phone</label>
                                    <input type="text" maxLength="50" className="form-control json-col" placeholder="Enter Phone" name="telphone" defaultValue={this.props.carrierData?(this.props.carrierData.telphone):''}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Email</label>
                                    <input type="email" maxLength="250" className="form-control json-col" placeholder="Enter Email" name="emailAddress" defaultValue={this.props.carrierData?(this.props.carrierData.emailAddress):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Website</label>
                                    <input type="text" maxLength="1000" className="form-control json-col" placeholder="Enter Website" name="website" defaultValue={this.props.carrierData?(this.props.carrierData.website):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Facebook</label>
                                    <input type="text" maxLength="1000" className="form-control json-col" placeholder="Enter Facebook" name="facebook" defaultValue={this.props.carrierData?(this.props.carrierData.facebook):''}></input>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Twitter</label>
                                    <input type="text" maxLength="1000" className="form-control json-col" placeholder="Enter Twitter" name="twitter" defaultValue={this.props.carrierData?(this.props.carrierData.twitter):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Linkedin</label>
                                    <input type="text" maxLength="1000" className="form-control json-col" placeholder="Enter Linkedin" name="linkedin" defaultValue={this.props.carrierData?(this.props.carrierData.linkedin):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Instagram</label>
                                    <input type="text" maxLength="1000" className="form-control json-col" placeholder="Enter Instagram" name="instagram" defaultValue={this.props.carrierData?(this.props.carrierData.instagram):''}></input>
                                </div>
                            </div>
                            <div className="row">                                
                                <div className="col-md-4 form-group">
                                    <label >ELD</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter ELD" name="eld" defaultValue={this.props.carrierData?(this.props.carrierData.eld):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Street</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Street" name="phyStreet" defaultValue={this.props.carrierData?(this.props.carrierData.phyStreet):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >City</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter City" name="phyCity" defaultValue={this.props.carrierData?(this.props.carrierData.phyCity):''}></input>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >State</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter State" name="phyState" defaultValue={this.props.carrierData?(this.props.carrierData.phyState):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >Zip</label>
                                    <input type="text" maxLength="10" className="form-control json-col" placeholder="Enter Zip" name="phyZip" defaultValue={this.props.carrierData?(this.props.carrierData.phyZip):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Country</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Country" name="phyCountry" defaultValue={this.props.carrierData?(this.props.carrierData.phyCountry):''}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Operation Status</label>
                                    <input type="text" maxLength="50" className="form-control json-col" placeholder="Enter Operation Status" name="operationStatus" defaultValue={this.props.carrierData?(this.props.carrierData.operationStatus):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >Carrier Operation</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Carrier Operation" name="carrierOperation" defaultValue={this.props.carrierData?(this.props.carrierData.carrierOperation):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >MCS 150 Mileage</label>
                                    <input type="text" maxLength="50" className="form-control json-col" placeholder="Enter MCS 150 Mileage" name="mcs150Mileage" defaultValue={this.props.carrierData?(this.props.carrierData.mcs150Mileage):''}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >MCS 150 Mileage Year</label>
                                    <input type="text" maxLength="10" className="form-control json-col" placeholder="Enter MCS 150 Mileage Year" name="mcs150MileageYear" defaultValue={this.props.carrierData?(this.props.carrierData.mcs150MileageYear):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >OIC State</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter OIC State" name="oicState" defaultValue={this.props.carrierData?(this.props.carrierData.oicState):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >DBA Name</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter DBA Name" name="dbaName" defaultValue={this.props.carrierData?(this.props.carrierData.dbaName):''}></input>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Mailing Street</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Mailing Street" name="mailingStreet" defaultValue={this.props.carrierData?(this.props.carrierData.mailingStreet):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >Mailing City</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Mailing City" name="mailingCity" defaultValue={this.props.carrierData?(this.props.carrierData.mailingCity):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Mailing State</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Mailing State" name="mailingState" defaultValue={this.props.carrierData?(this.props.carrierData.mailingState):''}></input>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >Mailing Zip</label>
                                    <input type="text" maxLength="10" className="form-control json-col" placeholder="Enter Mailing Zip" name="mailingZip" defaultValue={this.props.carrierData?(this.props.carrierData.mailingZip):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >Mailing Country</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Mailing Country" name="mailingCountry" defaultValue={this.props.carrierData?(this.props.carrierData.mailingCountry):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Fax</label>
                                    <input type="text" maxLength="250" className="form-control json-col" placeholder="Enter Fax" name="fax" defaultValue={this.props.carrierData?(this.props.carrierData.fax):''}></input>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label >HM Flag</label>
                                    <input type="text" maxLength="10" className="form-control json-col" placeholder="Enter HM Flag" name="hmFlag" defaultValue={this.props.carrierData?(this.props.carrierData.hmFlag):''}></input>
                                </div> 
                                <div className="col-md-4 form-group">
                                    <label >PC Flag</label>
                                    <input type="text" maxLength="10" className="form-control json-col" placeholder="Enter PC Flag" name="pcFlag" defaultValue={this.props.carrierData?(this.props.carrierData.pcFlag):''}></input>
                                </div>                                
                                <div className="col-md-4 form-group">
                                    <label >NBR Power Unit</label>
                                    <input type="text" className="form-control json-col" placeholder="Enter NBR Power Unit" name="nbrPowerUnit" defaultValue={this.props.carrierData?(this.props.carrierData.nbrPowerUnit):''}></input>
                                </div>
                            </div>
                            <div className="row">                                
                                <div className="col-md-4 form-group">
                                    <label >Driver Total</label>
                                    <input type="text" className="form-control json-col" placeholder="Enter Driver Total" name="driverTotal" defaultValue={this.props.carrierData?(this.props.carrierData.driverTotal):''}></input>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >Add Date</label>
                                    <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" dropUp name="addDate" placeholder="Enter Add Date" defaultValue={addDate}/>
                                    
                                </div>
                                <div className="col-md-4 form-group">
                                    <label >MCS 150 Date</label>
                                    <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" dropUp name="mcs150Date" placeholder="Enter MCS 150 Date" defaultValue={mcs150Date}/>                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col form-group">
                                    <label >Status</label>
                                    <textarea name="status" className="form-control json-col" row="3" defaultValue={this.props.carrierData?(this.props.carrierData.status):''}></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {this.saveCarrier()}}>Save</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={(e) => {this.props.closeForm(this.rowsAffected)}}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>                    
                </article>
            );
        } else {
            return(<Loader />);
        }
    }
}
export default AdminMyCarrierEntry;