import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { assetListService,assetAddService,assetUpdateService,companyAuthEldService } from '../services/assetService';
import * as types from '../actions';

async function fetchAsync(func) {
    const response = await func();
    if (response.ok) {
        return await response.json();
    }
    throw new Error("Unexpected error!!!");
}

function* assetList(payload) { 
    try {   
            const response = yield call( assetListService, payload );
            yield put({type: types.ASSET_LIST_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.ASSET_LIST_ERROR, error })
    }
} 

function* assetAdd(payload) { 
    try {   
            const response = yield call( assetAddService, payload );   
            yield put({type: types.ASSET_ADD_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.ASSET_ADD_ERROR, error })
    }
} 

function* assetUpdate(payload) { 
    try {   
            const response = yield call( assetUpdateService, payload );  
            yield put({type: types.ASSET_UPDATE_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.ASSET_UPDATE_ERROR, error })
    }
}

function* companyAUthEldSaga(payload) { 
    try {   
            const response = yield call( companyAuthEldService, payload );  
            yield put({type: types.COMPANY_AUTH_ELD_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.COMPANY_AUTH_ELD_ERROR, error })
    }
}

export function* assetSaga() {
    yield takeEvery(types.ASSET_LIST, assetList);
    yield takeEvery(types.ASSET_ADD, assetAdd);
    yield takeEvery(types.ASSET_UPDATE, assetUpdate);
    yield takeEvery(types.COMPANY_AUTH_ELD, companyAUthEldSaga);
}