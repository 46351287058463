import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import moment from 'moment';
import AddDriverPopUp from './AddDriverPopUp';
import { getDriversService, inviteDriverService } from '../../services/userService';

//import AddDriverPopUp from '../carriers/drivers/AddDriverPopUp';


require('dotenv').config();

class DriverList extends Component
{

	constructor(props)
	{
		super(props);
		this.app_url = process.env.REACT_APP_URL;
		this.state={
			driverlist:[],
			driver_nodata:"",
			invited_nodata:"",
			onboarded_nodata:""
		}

		
	}
	componentDidMount=()=>
	{ 
		document.title = 'Driver Onboarding';
		this.refreshDriver();		
	}
	
	refreshDriver=()=>
	{
		let loaded = <div className="spinner-border" role="status">
				<div className="row react-bs-table-no-data"></div>
			</div>

		let nodata = "No data found";
		
		this.setState({
			...this.state,
			driverlist:[],
			driver_nodata:loaded,
			invited_nodata:loaded,
			onboarded_nodata:loaded
		});
        getDriversService().then((response) =>{			
			console.log(response);
			if(response){
				this.setState({
					...this.state,
					driverlist:response,
					driver_nodata:nodata,
					invited_nodata:nodata,
					onboarded_nodata:nodata
				});
			}
		});
	}

	myCrRowSel = (e, row) => {
		$(".btnInvite").addClass('disabled');
		$("#btnInvite_"+row.id).removeClass('disabled');
	}

	InviteDriver = (e, row) => {
		console.log(row);
		let param = {
			userId:row.User_Id
		};
		inviteDriverService(param).then((response) => {
			console.log(response);
			if(response){
				if(response.status == "OK"){
					toastr.success('Success', "Driver invited successfully");
					this.refreshDriver();
				}
				else{
					toastr.error('Error', "Unable to invite");
				}
			}
			else{
				toastr.error('Error', "Unable to invite");
			}
		} );
	}

	getUserName = (name1, name2, name3) => {		
		let name = (name1?name1:'');		
		name = name + " " + (name2?name2:'');		
		name = name + " " + (name3?name3:'');		
		return name;
	}

	render()
	{	
        let drivers = this.state.driverlist;
        var driverlists=[];
        var invitedlists=[];
		var onboarderlists=[];
		
		
            
		driverlists = drivers.map(
			(row,index) =>{

				let inviteBtn="";
				if(row.Is_Invited==0){
					inviteBtn=<button onClick={(e)=>{this.InviteDriver(e, row);}} 
		id={"btnInvite_"+row.User_Id} className="btn btn-primary btn-sm btnInvite disabled">Invite</button>
				}			

				if(row.Is_Invited==1 && row.Is_Onboarded==0){
					inviteBtn=<button className="btn btn-primary btn-sm btnInvited">Invited</button>			
				}

				if(row.Is_Onboarded==1){
					inviteBtn=<button className="btn btn-primary btn-sm btnOnboarded">Onboarded</button>			
				}

				var drivingLicenseExpDate="";
				var physicalExpDate="";
				
				if(row.Driving_License_ExpDate!=null)
				{
					drivingLicenseExpDate=moment(row.Driving_License_ExpDate).format('MM-DD-YYYY');
				}
				else
				{
					drivingLicenseExpDate= "";
				}
				if(row.Physical_Exp_Date!=null)
				{
					physicalExpDate=moment(row.Physical_Exp_Date).format('MM-DD-YYYY');
				}
				else
				{
					physicalExpDate= "";
				}
				
				return({
					id:row.User_Id,
					firstName:this.getUserName(row.First_Name, row.Middle_Name, row.Last_Name),
					driverType:row.Driver_Type_Name,							
					drivingLicenseExpDate:drivingLicenseExpDate,
					drivingLicenseNo:row.Driving_License_No,
					physicalExpDate:physicalExpDate,
					phonenumber:(row.Phone_Number != null  && row.Phone_Number !== ''?<span>
					<i data-tip data-for={"driverphnnumber"+row.User_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
					<ReactTooltip id={"driverphnnumber"+row.User_Id} >
						<p>Phone : {row.Phone_Number}</p>
					</ReactTooltip>
					</span>:''),
					driveremail: (row.Email_Id != null && row.Email_Id !== ''?<span>
					<i data-tip data-for={"driveremail"+row.User_Id} id="lanehistoryclass" className="icofont-email"></i>
						<ReactTooltip id={"driveremail"+row.User_Id} >
							<p>Email : {row.Email_Id}</p>
						</ReactTooltip>
					</span>:''),
					hos:<i data-toggle="modal" id="lanehistoryclass" className="icofont-clock-time"></i>,
					documents:<i data-toggle="modal" id="lanehistoryclass" className="icofont-law-document"></i>,
					btnInvite:inviteBtn
				});
				
			}
		);

		invitedlists = drivers.map(
			(row,index) =>{
				if(row.Is_Invited==1 && row.Is_Onboarded==0){
					var drivingLicenseExpDate="";
					var physicalExpDate="";
					var Invited_On="";

					if(row.Invited_On!=null || row.Invited_On !="")
					{
						Invited_On=moment(row.Invited_On).format('MM-DD-YYYY');
					}

					if(row.Driving_License_ExpDate!=null)
					{
						drivingLicenseExpDate=moment(row.Driving_License_ExpDate).format('MM-DD-YYYY');
					}
					else
					{
						drivingLicenseExpDate= "";
					}
					if(row.Physical_Exp_Date!=null)
					{
						physicalExpDate=moment(row.Physical_Exp_Date).format('MM-DD-YYYY');
					}
					else
					{
						physicalExpDate= "";
					}
					
					return({
						id:row.User_Id,
						firstName:this.getUserName(row.First_Name, row.Middle_Name, row.Last_Name),
						driverType:row.Driver_Type_Name,							
						drivingLicenseExpDate:drivingLicenseExpDate,
						drivingLicenseNo:row.Driving_License_No,
						physicalExpDate:physicalExpDate,
						Invited_by:this.getUserName(row.Invited_By_First_Name, row.Invited_By_Middle_Name, row.Invited_By_Last_Name),
						Invited_On:Invited_On,
						phonenumber: (row.Phone_Number != null && row.Phone_Number !== ''?<span>
						<i data-tip data-for={"driverphnnumber"+row.User_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
						<ReactTooltip id={"driverphnnumber"+row.User_Id} >
							<p>Phone : {row.Phone_Number}</p>
						</ReactTooltip>
						</span>:''),
						driveremail: (row.Email_Id != null && row.Email_Id !== ''? <span>
						<i data-tip data-for={"driveremail"+row.User_Id} id="lanehistoryclass" className="icofont-email"></i>
							<ReactTooltip id={"driveremail"+row.User_Id} >
								<p>Email : {row.Email_Id}</p>
							</ReactTooltip>
						</span>:''),
						hos:<i data-toggle="modal" id="lanehistoryclass" className="icofont-clock-time"></i>,
						documents:<i data-toggle="modal" id="lanehistoryclass" className="icofont-law-document"></i>,
						btnInvite:<button onClick={(e)=>{this.InviteCompany(e, row);}} 
						id={"btnInvite_"+row.Email_Id} className="btn btn-primary btn-sm btnInvite disabled">Invite</button>
					});
				}
			}
		);

		onboarderlists = drivers.map(
			(row,index) =>{
				if(row.Is_Onboarded==1){
					var drivingLicenseExpDate="";
					var physicalExpDate="";
					
					if(row.Driving_License_ExpDate!=null)
					{
						drivingLicenseExpDate=moment(row.Driving_License_ExpDate).format('MM-DD-YYYY');
					}
					else
					{
						drivingLicenseExpDate= "";
					}
					if(row.Physical_Exp_Date!=null)
					{
						physicalExpDate=moment(row.Physical_Exp_Date).format('MM-DD-YYYY');
					}
					else
					{
						physicalExpDate= "";
					}
					
					return({
						id:row.User_Id,
						firstName:this.getUserName(row.First_Name, row.Middle_Name, row.Last_Name),
						driverType:row.Driver_Type_Name,							
						drivingLicenseExpDate:drivingLicenseExpDate,
						drivingLicenseNo:row.Driving_License_No,
						physicalExpDate:physicalExpDate,
						phonenumber: (row.Phone_Number != null && row.Phone_Number !== ''?<span>
						<i data-tip data-for={"driverphnnumber"+row.User_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
						<ReactTooltip id={"driverphnnumber"+row.User_Id} >
							<p>Phone : {row.Phone_Number}</p>
						</ReactTooltip>
						</span>:''),
						driveremail: (row.Email_Id != null && row.Email_Id !== ''?<span>
						<i data-tip data-for={"driveremail"+row.User_Id} id="lanehistoryclass" className="icofont-email"></i>
							<ReactTooltip id={"driveremail"+row.User_Id} >
								<p>Email : {row.Email_Id}</p>
							</ReactTooltip>
						</span>:''),
						hos:<i data-toggle="modal" id="lanehistoryclass" className="icofont-clock-time"></i>,
						documents:<i data-toggle="modal" id="lanehistoryclass" className="icofont-law-document"></i>,
						btnInvite:<button onClick={(e)=>{this.InviteCompany(e, row);}} 
						id={"btnInvite_"+row.Email_Id} className="btn btn-primary btn-sm btnInvite disabled">Invite</button>
					});
				}
			}
		);
		

		const { SearchBar } = Search;
		const columns=[	
            {
                dataField: 'firstName',
                text: 'Driver Name',
                sort: true
              },
              {
                dataField: 'driverType',
                text: 'Type',
                sort: true
              },
              {
                text:'Phone',
                dataField:'phonenumber'
              },
              {
                text:'Email',
                dataField:'driveremail'
			  },
			  {
				text:'Hos',
				dataField:'hos'
			  },                
			  {
				text:'Documents',
				dataField:'documents'
			  },
              {
                dataField: 'drivingLicenseNo',
                text: 'License',
                sort: true
              },
              {
                dataField: 'drivingLicenseExpDate',
                text: 'License Exp. Date',
                sort: true
              },
              {
                dataField: 'physicalExpDate',
                text: 'Physical Exp. Date',
                sort: true
              },
			  {
				  text:'',
				  dataField:'btnInvite',
				  clickToSelect: true
			 }
        ]
        
        
		 const invitedColumns=[
			{
				dataField: 'firstName',
				text: 'Driver Name',
				sort: true
			},
			{
				dataField: 'driverType',
				text: 'Type',
				sort: true
			},
			{
				text:'Phone',
				dataField:'phonenumber'
			},
			{
				text:'Email',
				dataField:'driveremail'
			},
			{
				text:'Hos',
				dataField:'hos'
			},                
			{
				text:'Documents',
				dataField:'documents'
			},
			{
				dataField: 'drivingLicenseNo',
				text: 'License',
				sort: true
			},
			{
				dataField: 'drivingLicenseExpDate',
				text: 'License Exp. Date',
				sort: true
			},
			{
				dataField: 'physicalExpDate',
				text: 'Physical Exp. Date',
				sort: true
			},
			{
				text:'Invited by',
				dataField:'Invited_by',
				sort:true
			},									
			{
				text:'Invited Date',
				dataField:'Invited_On',
				sort:true
			}			
         ];
         
         const onboardColumns=[
			{
                dataField: 'firstName',
                text: 'Driver Name',
                sort: true
              },
              {
                dataField: 'driverType',
                text: 'Type',
                sort: true
              },
              {
                text:'Phone',
                dataField:'phonenumber'
              },
              {
                text:'Email',
                dataField:'driveremail'
			  },
			  {
				text:'Hos',
				dataField:'hos'
			  },                
			  {
				text:'Documents',
				dataField:'documents'
			  },
              {
                dataField: 'drivingLicenseNo',
                text: 'License',
                sort: true
              },
              {
                dataField: 'drivingLicenseExpDate',
                text: 'License Exp. Date',
                sort: true
              },
              {
                dataField: 'physicalExpDate',
                text: 'Physical Exp. Date',
                sort: true
              }		
		 ];

		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: 'First',
			prePageText: 'Back',
			nextPageText: 'Next',
			lastPageText: 'Last',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };
	
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<div className="row no-gutters">
					<div className="col-md-12">
					<ul className="nav nav-tabs" id="myTrucks" role="tablist">
						<li className="nav-item">
							<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#drivers" role="tab" aria-controls="drivers" aria-selected="true">DRIVERS</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="invited-tab" data-toggle="tab" href="#invited" role="tab" aria-controls="invited" aria-selected="false">Invited</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="onboarded-tab" data-toggle="tab" href="#onboarded" role="tab" aria-controls="onboarded" aria-selected="false">Onboarded</a>
						</li>

						
						<div className="flex-left-auto">
						<ul className="ul-horizontal tab-menus">						
								<li>
									<a href="#" className="btn btn-primary btn-sm" onClick={this.refreshDriver} ><i class="icofont-refresh"></i> Refresh</a>
								</li>
								<li>
									<a href="#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-trucks-modal-lg">Add Driver</a>
								</li>
							</ul>
						</div>
					</ul>
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="drivers" role="tabpanel" aria-labelledby="carriers-tab">
						  	<ToolkitProvider
									keyField="id"
									data={ driverlists }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={this.state.driver_nodata}  hover 
											selectRow={ { mode: 'radio',
											onSelect: (row, isSelect, rowIndex, e) => {
												this.myCrRowSel(e,row);
											}, selected:[] } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>								
							<div className="tab-pane fade" id="invited" role="tabpanel" aria-labelledby="invited-tab">
							  <ToolkitProvider
									keyField="id"
									data={ invitedlists }
									columns={ invitedColumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={this.state.invited_nodata}  hover 
											 bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
							</div>
                            <div className="tab-pane fade" id="onboarded" role="tabpanel" aria-labelledby="onboarded-tab">
                            <ToolkitProvider
                                keyField="id"
                                data={ onboarderlists }
                                columns={ onboardColumns }
                                search
                                >
                                {
                                props => (
                                    <div>
                                    <SearchBar { ...props.searchProps } />
                                    <BootstrapTable bootstrap4 
                                        { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={this.state.onboarded_nodata}  hover 
                                        bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                            </div> 
						  	<div>
                                <AddDriverPopUp statelist={this.props.statelist} drivertype={this.props.driver} refreshParent={this.refreshDriver}/>		
						  	</div>
						</div>
					</div>
				</div>
			</article>
		)
	}
}
const mapStateToProps = (state) => {
	return{
		loading : state.userReducer.loading,
		user : state.userReducer.user,
		data:state.userReducer.data,
		CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal
	}    
}
export default connect(mapStateToProps)(DriverList)