import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm } from 'redux-form';
import { getcitybystate, getzipbycity } from '../../actions/loadActions';
import { carrierSearch,carrieradd,preferredCompanyListByType,carrierAddFlagAction } from '../../actions/companyActions';
import { renderField, renderSelect, renderSelectForMultiple, renderSelectText } from '../../helpers/FormControls';
import { validate } from './validation';
import normalizePhone from '../../helpers/normalizePhone';
import Carrierbulkupload from './Carrierbulkupload';

import $ from 'jquery';

function submit(values) {
    const frmdata = {};
    frmdata.companyName= (values.companyName) ? (values.companyName) : ("");
    frmdata.companyEmail= (values.companyEmail) ? (values.companyEmail) : ("");
    frmdata.currentAddress1=(values.currentAddress1) ? (values.currentAddress1) : ("");
    frmdata.city= (values.currentCityId) ? (values.currentCityId) : ("");
    frmdata.state=(values.currentStateCode) ? (values.currentStateCode) : ("");
    frmdata.zipcode=(values.currentZipId) ? (values.currentZipId) : ("");
    frmdata.currentCountryCode=(values.currentCountryCode) ? (values.currentCountryCode) : ("");
    frmdata.websiteAddress=(values.websiteAddress) ? (values.websiteAddress) : ("");
    frmdata.phoneNumber=(values.phoneNumber) ? (values.phoneNumber) : ("");
    frmdata.faxNumber=(values.faxNumber) ? (values.faxNumber) : ("");
    frmdata.eldProviderId=(values.eldProviderId) ? (values.eldProviderId) : ("");
    frmdata.specialityId=(values.specialityId) ? (values.specialityId) : ("");
    frmdata.noOfTrucks=(values.noOfTrucks) ? (values.noOfTrucks) : ("");
    frmdata.instructions=(values.instructions) ? (values.instructions) : ("");
    frmdata.firstName=(values.firstName) ? (values.firstName) : ("");
    frmdata.LastName=(values.LastName) ? (values.LastName) : ("");
    frmdata.permenantAddress=(values.permenantAddress) ? (values.permenantAddress) : ("");
    frmdata.companyShortCode='';
    frmdata.templateId='';

    this.props.dispatch(carrieradd(frmdata));
}

class CarrierNew extends Component {
    
    stateOnchange=(event)=>
    {
        //console.log($(event.target).find(':selected').data('idval'));
        let stateId = $(event.target).find(':selected').data('idval');
        this.props.dispatch(getcitybystate(stateId));
    }

    cityOnchange=(event)=>
    {
        let cityId = $(event.target).find(':selected').data('idval');
        this.props.dispatch(getzipbycity(cityId));
    }

    handleClick = () =>{
        document.getElementById('addcarrierprop').style.display = 'none'
        this.props.initialize('CarrierNewForm')
    }

    searchbtnclk=(e)=>
    {     
        var carriername = document.querySelector('#carrierNameid')
        var mcNumber = document.querySelector('#mcnumberid')
        var dotNumber = document.querySelector('#dotnumberid')
        if(carriername.value||mcNumber.value||dotNumber.value)
        {
            const params = {
                SearchFlag:true,
                companytypeid:3,
                carrierName:carriername.value,
                mcNumber:mcNumber.value,
                dotNumber:dotNumber.value
            }
            //this.props.dispatch(preferredCompanyListByType(params));
            this.props.dispatch(carrierSearch(params));
        }
        else{
            toastr.info('Info', "Please fill atleast one field");
        }
    }

    
	
	render() {
        console.log(this.props.statelist.data);
        const { handleSubmit, pristine, reset, submitting } = this.props
		return (
			<div className="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">ADD CARRIER</h5>
							<button type="button" className="close" id="carrieraddmodalbox" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body table-data">
							<ul className="nav nav-tabs" id="myTrucks" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">ADD CARRIER</a>
								</li>
                                <li className="nav-item">
									<a className="nav-link" id="bulkupload-tab" data-toggle="tab" href="#bulkupload" role="tab" aria-controls="bulkupload" aria-selected="true">BULK UPLOAD</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">
                                    <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                                        <form >
                                            <div className="row">
                                            <div className="form-group col col-lg-4">
                                            <Field type="text" name="companyname" id="carrierNameid" controlClass="requiredfield" component={renderField} label="Carrier Name"/> 
                                            </div>
                                            <div className="form-group col col-lg-3">
                                            <Field type="text" name="mcnumber" id='mcnumberid' component={renderField} label="MC Number"/> 
                                            </div>
                                            <div className="form-group col col-lg-4">
                                            <Field type="text" name="dOTNumber" id="dotnumberid" component={renderField} label="Dot Number"/>
                                            </div>
                                            <div className="form-group col col-lg-1">
                                            <button type="button" value="searchbtn" className="btn btn-secondary searchbtn" onClick={(e) => {this.searchbtnclk(e);}}>Search</button>
                                            </div>
                                            </div>
                                        </form>
                                    </article>
                                    <div className="addcarrierproperties" id="addcarrierprop" style={{display:'none'}}>
                                       <form className="tg-forms"  onSubmit={handleSubmit(submit.bind(this))}  >
                                                    <div className="row"> 
                                                        <div className="col col-md-12">
                                                        <article className="table-data truck-data shadow bg-white rounded">
                                                        <div className="row">
                                                            <div className="col col-md-4">
                                                            <div className="form-group">
                                                            <Field type="text" name="companyName" controlClass="requiredfield" component={renderField} label="Carrier Name"/> 
                                                            </div>
                                                            <div className="form-group">
                                                            <Field type="text" name="companyEmail" controlClass="requiredfield" component={renderField} label="Email"/>                                                             
                                                            </div>
                                                            <div className="form-group">
                                                            <Field type="text" name="specialityId" component={renderSelect} label="Speciality" data={this.props.specialitytypelist.data}/> 
                                                            </div>
                                                            <div className="form-group">                                                       
                                                                <Field name="currentAddress1" label="Current Address" component={renderTextArea}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <Field name="permenantAddress" label="Permenant Address" component={renderTextArea}/>                                                        
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-4">
                                                            <div className="form-group">
                                                            <Field type="text" name="firstName" component={renderField} label="First Name"/> 
                                                            </div>
                                                            <div className="form-group">
                                                            <Field type="text" name="currentStateCode" controlClass="requiredfield" component={renderSelect}  onChange={(e) =>{this.stateOnchange(e)}} className="form-control" data={this.props.statelist.data} label="State" keyColumn='name' keyValue='name' keyId="id" />        

                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="phoneNumber" normalize={normalizePhone} controlClass="requiredfield" component={renderField} label="Primary Phone"/> 
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="faxNumber" component={renderField} label="Fax"/> 
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="websiteAddress" component={renderField} label="Website"/> 
                                                            </div>
                                                        <div className="form-group">
                                                            <Field type="text" name="eldProviderId" component={renderSelect} label="ELD Provider" data={this.props.eldproviderlist.data}/>  
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-4">
                                                            <div className="form-group">
                                                            <Field type="text" name="LastName" component={renderField} label="Last Name"/>
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="currentCityId" component={renderSelect} data={this.props.CityVal}  onChange={(e) =>{this.cityOnchange(e)}} keyColumn='cityName' keyValue='cityName' label="City" keyId="cityId"/>
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="currentZipId" component={renderSelect} label="Zip Code" data={this.props.ZipVal} keyColumn='zipValue' keyValue='zipValue'  /> 
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="currentCountryCode" component={renderSelect} data={this.props.country.data} label="Country" /> 
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="noOfTrucks" component={renderField} label="No of Trucks"/>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    </article>
                                                    <h5>Description</h5>
                                                    <article className="table-data truck-data shadow bg-white rounded">
                                                        <div className="row">
                                                            <div className="col col-md-12">
                                                            <Field name="instructions" label="Instructions" component={renderTextArea}/>
                                                            </div>
                                                        </div>
                                                </article>
                                                <div className="row">
                                                <div className="col col-md-12">
                                                    <div className="form-group form-group-btns">
                                                        <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                                        <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </form> 
                                    </div>

								</div>
                                <div className="tab-pane fade show" id="bulkupload" role="tabpanel" aria-labelledby="bulkupload-tab">
                                       <Carrierbulkupload />
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const renderTextArea = ({input,label, meta: { touched, error, warning }}) => (
    <div>
        <label>{label}</label>
            <textarea {...input} className="form-control level2" rows="3" ></textarea>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);

const mapStateToProps = state => {
  return {
    data: state.companyReducer.data,
    loading: state.companyReducer.loading,
    error: state.companyReducer.error,
    carrierAddFlag:state.companyReducer.carrierAddFlag,
    CityVal:state.loadReducer.CityVal,
    ZipVal:state.loadReducer.ZipVal
  }
}

export default connect(mapStateToProps)(reduxForm({
	form:'CarrierNewForm',validate
})(CarrierNew))