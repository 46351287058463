export const validate = values => {
    
    const errors = {}
    if (!values.companyEmail) {
        errors.companyEmail = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.companyEmail)) {
        errors.companyEmail = 'Make sure that you entered valid email address.!'
    }
    if (!values.companyName) {
        errors.companyName = 'Carriername can not be left blank.!'
    } 
    if (!values.currentStateCode) {
        errors.currentStateCode = 'State can not be left blank.!'
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone Number can not be left blank.!'
    }
    return errors
}