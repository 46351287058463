import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import {formData} from './../../helpers/formData';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import {addNewUser, getUserTypeService} from './../../services/userService';
import {getInviteTemplatetypeService} from './../../services/companyService';
class NewUser extends Component{
    constructor(props) {
        super(props);
            this.state={
                cityData:[],
                zipData:[],
                firstName:'',
                lastName:'',                
                State:'',
                City:'',
                Zip:'',
                roleId:'',
                emailId:'',
                phoneNumber:'',                
                currentAddress1:'',
                userTypeId:'',
                companyId:'',
                userId:null,
                templateId:"",
                templates:[],
                userTypes:[]
        }
    }

    componentDidMount = () =>{
        //console.log(this.props.statelist);
        getUserTypeService().then((response) =>{
            console.log(response);
            if(response && response.UserTypeList){
                this.setState({
                    ...this.state,
                    userTypes:response.UserTypeList
                });
            }
        });
    }

    componentDidUpdate = (prevProps, prevState, snapshot) =>{
        console.log(this.props.companyId, prevProps.companyId);
        if(this.props.companyId != prevProps.companyId){
            if(this.props.companyId){
                this.loadTeplates();
            }
            this.setState({
                ...this.state,
                templates:[]
            });
        }
        setTimeout(() => {
            this.set_roleDesc();
        }, 1000);
            
        
    }

    loadTeplates = () =>{
        const params={
			payload:{
                companyId:this.props.companyId,
                typename:"newuser"
			}
		 }
		getInviteTemplatetypeService(params).then(response =>{
			console.log(response);
			if(response && response.data){
				this.setState({
					...this.state,
					templates:response.data
				});
			}
		});
    }
   
    getTemplates = () =>{		
        return this.state.templates.map((data, index) =>{
            return <option key={index} value={data.carrierEmailTemplateId}>{data.emailTemplateName}</option>;
        });
    }
    
    set_templateId = (e) => {
        this.setState({
            ...this.state,
            templateId:e.target.value
        });
    }
    set_firstName = (e) =>{
        this.setState({
            ...this.state,
            firstName:e.target.value
        });
    }

    set_lastName = (e) =>{
        this.setState({
            ...this.state,
            lastName:e.target.value
        });
    }

    set_State = (e) =>{        
        this.setState({
            ...this.state,
            State:e.target.value
        });
    }

    set_City = (e) =>{
        this.setState({
            ...this.state,
            City:e.target.value
        });
    }

    set_Zip = (e) =>{
        this.setState({
            ...this.state,
            Zip:e.target.value
        });
    }

    set_emailId = (e) =>{
        this.setState({
            ...this.state,
            emailId:e.target.value
        });
    }

    set_phoneNumber = (e) =>{
        this.setState({
            ...this.state,
            phoneNumber:e.target.value
        });
    }

    set_currentAddress1 = (e) =>{
        this.setState({
            ...this.state,
            currentAddress1:e.target.value
        });
    }

    set_roleDesc = () => {
        let ctrl = $("#new_user select[name=roleId]")[0];        
        if(ctrl){
            console.log(ctrl.selectedIndex);
            let roleDesc = ctrl.options[ctrl.selectedIndex].dataset.roledesc;
            console.log(roleDesc);
            $("#roleDesc").html(roleDesc);
        }
        
        
    }

    set_roleId = (e) =>{
        this. set_roleDesc();
        this.setState({
            ...this.state,
            roleId:e.target.value
        });
    }

    set_City = (e) =>{
        this.setState({
            ...this.state,
            City:e.target.value
        });
    }

    set_Zip = (e) =>{
        this.setState({
            ...this.state,
            Zip:e.target.value
        });
    }

    set_userTypeId = (e) =>{
        this.setState({
            ...this.state,
            userTypeId:e.target.value
        });
    }

    

    renderState = () =>{
        let statelist=[];
        if(this.props.statelist && this.props.statelist.data && this.props.statelist.data.length>0){
            statelist=this.props.statelist.data.map((data,index)=>{                
                return <option key={index} data-id={data.id} value={data.name}>{data.name}</option>
            });
        }
        return <select className="form-control json-col" name="State" onChange={(e) =>{this.set_State(e);}} value={this.state.State}>
            <option value="">Select state</option>
            {statelist}
        </select>
    }

    renderRoles = () =>{
        let roles=[];
        if(this.props.userRoles){
            roles=this.props.userRoles.map((data,index)=>{
                return <option key={index} data-roledesc={data.roleDesc} value={data.roleId}>{data.roleName}</option>
            });
        }
        return <select className="form-control requiredfield json-col" name="roleId" onChange={(e) =>{this.set_roleId(e)}} value={this.state.roleId} >
            <option data-roledesc="" value="">Select role</option>
            {roles}
        </select>
    }

    closeThis = (flag) =>{
        this.setState({
            ...this.state,
            firstName:'',
            lastName:'',                
            State:'',
            City:'',
            Zip:'',
            roleId:'',
            emailId:'',
            phoneNumber:'',                
            currentAddress1:'',
            userTypeId:'',
            templateId:''
        });
        this.props.closeNewUser(flag);
    }

    saveNewUser = () =>{
        if(this.props.userPopupMode=="add"){
            $("#new_user").validate({
                rules:{
                    firstName:{
                    required:true
                    },
                    emailId:{
                        required:true,
                        email:true
                    },
                    roleId:{
                        required:true
                    },
                    templateId:{
                        required:true
                    }
                    
                },
                messages:{
                    firstName:{
                        required:'First name can not be left blank.!'
                    },
                    emailId:{
                        required:'Email can not be left blank.!',
                        email:'Email not valid'
                    },
                    roleId:{
                        required:'Select role'
                    },
                    templateId:{
                        required:'Select email template'
                    }
                },errorPlacement: function(error, element) {
                    if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                        error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                    }
                    else {
                        error.insertAfter(element);
                    }
                }
            });
        } else {
            $("#new_user").validate({
                rules:{
                    firstName:{
                    required:true
                    },
                    emailId:{
                        required:true,
                        email:true
                    },
                    roleId:{
                        required:true
                    },
                    userTypeId:{
                        required:true
                    }
                },
                messages:{
                    firstName:{
                        required:'First name can not be left blank.!'
                    },
                    emailId:{
                        required:'Email can not be left blank.!',
                        email:'Email not valid'
                    },
                    roleId:{
                        required:'Select role'
                    },
                    userTypeId:{
                        required:'Select user type'
                    }
                },errorPlacement: function(error, element) {
                    if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                        error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                    }
                    else {
                        error.insertAfter(element);
                    }
                }
            });
        }

        if($("#new_user").valid())
        {
            let form = document.getElementById("new_user");
            let loadData = formData(form);
            console.log(loadData);
            if(loadData.userId == ""){
                loadData.userId=null;
            }
            let param={
                payload:loadData
            }

            addNewUser(param).then((response) =>{
                console.log(response);
                if(response.status){
                    if(response.status == "CREATED"){
                        toastr.success('Success', response.message);
                        this.closeThis(1);
                    } else {
                        toastr.error('Oops!', response.message);
                    }
                }
            });
        }
    }    

    getEmailTempaleField = () =>{
        console.log(this.props.userPopupMode);
        if(this.props.userPopupMode == "add"){
            return(                
                <div className="col form-group">
                    <label>Email Template</label>
                    <select className="form-control requiredfield json-col" name="templateId" onChange={(e) =>{this.set_templateId(e)}} value={this.state.templateId}>
                        <option value="">Select email template</option>
                        {this.getTemplates()}
                    </select>
                </div>                            
            );
        } else{
            return <Fragment/>;
        }
    }

    getUserTypeField = () =>{
        let userTypes = this.state.userTypes.map((data, index) =>{
            return <option key={index} value={data.userTypeId}>{data.userTypeName}</option>;
        });
        return(                
            <div className="col form-group">
                <label>User Type</label>
                <select className="form-control requiredfield json-col" name="userTypeId" onChange={(e) =>{this.set_userTypeId(e)}} value={this.state.userTypeId}>
                    <option value="">Select user type</option>
                    {userTypes}
                </select>
            </div> 
        )
    }
    render = () =>{
        return(
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.props.newUserPopup} id="newuser">
                <ModalHeader>
                <ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form id="new_user">
                        <div className="row">
                            <div className="col form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control requiredfield json-col" placeholder="First Name" name="firstName" onChange={(e) =>{this.set_firstName(e)}} value={this.state.firstName} />
                            </div>
                            <div className="col form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control json-col" placeholder="Last Name" name="lastName" onChange={(e) =>{this.set_lastName(e)}} value={this.state.lastName} />
                            </div>                            
                            <div className="col form-group">
                                <label>Address</label>
                                <input type="text" className="form-control json-col" placeholder="Address" name="currentAddress1" onChange={(e) =>{this.set_currentAddress1(e)}} value={this.state.currentAddress1} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>Email</label>
                                <input type="text" className="form-control requiredfield json-col" placeholder="Email" name="emailId" onChange={(e) =>{this.set_emailId(e)}} value={this.state.emailId} />
                            </div>
                            <div className="col form-group">
                                <label>Role</label>
                                {this.renderRoles()}
                            </div>
                            <div className="col form-group">
                                <label>Mobile Number</label>
                                <input type="text" className="form-control json-col" placeholder="Mobile Number" name="phoneNumber" onChange={(e) =>{this.set_phoneNumber(e)}} value={this.state.phoneNumber} />
                            </div>
                            
                        </div>
                        <div className="row">                            
                            <div className="col-4 form-group">
                                <label>State</label>
                                {this.renderState()}
                            </div>                            
                            <div className="col-8" id="roleDesc">
                                
                            </div>
                        </div>
                        <div className="row">                            
                            <div className="col-4 form-group">
                                <label>City</label>
                                <input type="text" className="form-control json-col" placeholder="City" name="City" onChange={(e) =>{this.set_City(e)}} value={this.state.City} />
                            </div>
                            <div className="col-4 form-group">
                                <label>Zip</label>
                                <input type="text" className="form-control json-col" placeholder="Zip" name="Zip" onChange={(e) =>{this.set_Zip(e)}} value={this.state.Zip} />
                            </div>
                            {this.getEmailTempaleField()}
                            {this.getUserTypeField()}
                        </div>  
                        <input type="hidden" className="json-col" name="companyId" value={this.props.companyId} />
                        <input type="hidden" className="json-col" name="userId" value={this.state.userId} />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary"  onClick={() => {this.saveNewUser()}}>Save</button>
                    <button type="button" className="btn btn-primary" onClick={() => {this.closeThis(0)}}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default NewUser;