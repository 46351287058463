import * as types from '../actions';

const initialState = {
    data: [],
    export :[],
    exportFlg:false,
    goFlg:false,
    eldAssets : [],
    loading: false,
    fetchFenceData:[],
    updateData:[],
    deleteData:[],   
    error: '',
    fenceButtons:false,
};
export default function(state = initialState, action) {
    switch(action.type) {   

        case types.GET_DASHBOARD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_DASHBOARD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_DASHBOARD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_TRACKING_LIST:
            return { 
                ...state,
                exportFlg: false,
                error:''
            };
        case types.GET_TRACKING_LIST_SUCCESS:
            return { 
                ...state,
                export: action.exportData,
                exportFlg: action.responseData.exportFlg,
                goFlg: action.responseData.goFlg
            };
        case types.GET_TRACKING_LIST_ERROR:
            return { 
                ...state,
                exportFlg: false,
                goFlg: false,
                error: action.error
            };
        case types.GET_ELD_ASSEST_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_ELD_ASSEST_LIST_SUCCESS:
            return { 
                ...state,
                eldAssets: action.dashboard,
                loading: false,
            };
        case types.GET_ELD_ASSEST_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SET_GOFLAG:
            return { 
                ...state,
                goFlg: false,
                exportFlg: false
            }; 
        case types.POST_FENCE_NEW:
            return { 
                ...state,
                loading: true,
                error:'',
            }; 
        case types.POST_FENCE_SUCCESS:
            return { 
                ...state,
                loading: false,
                fenceData: action.fenceData,
                fenceButtons:true,
            }; 
        case types.POST_FENCE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
                fenceButtons:false,
            };
            
        case types.FETCH_FENCE:
            return { 
                ...state,
                loading: true,
                error:'',
            }; 
        case types.FETCH_FENCE_SUCCESS:
            return { 
                ...state,
                loading: false,
                fetchFenceData: action.fetchFenceData,
            }; 
        case types.FETCH_FENCE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error,
            };  

        case types.UPDATE_FENCE:
            return { 
                ...state,
                error:'',
            }; 
        case types.UPDATE_FENCE_SUCCESS:
            return { 
                ...state,
                updateData: action.updateData,
            }; 
        case types.UPDATE_FENCE_ERROR:
            return { 
                ...state,
                error: action.error,
            };

        case types.DELETE_FENCE:
            return { 
                ...state,
                error:'',
            }; 
        case types.DELETE_FENCE_SUCCESS:
            return { 
                ...state,
                deleteData: action.deleteData,
            }; 
        case types.DELETE_FENCE_ERROR:
            return { 
                ...state,
                error: action.error,
            }; 
                       
        default:
            return state;
    }
};