import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { changePreference, preferredCompanyListByType, preferredCompanyList, carrierAddFlagAction, carrierSearch, carrierSearchFlagAction,bulkuploadFlagAction } from '../../actions/companyActions';
import EditCarrierPopup from './EditCarrierPopup';
import CarrierNew from './CarrierNew';
import $ from 'jquery';
import moment from 'moment';
import {InviteCompanyService, VerifyCompanyService, EldOnboardedService, getPdfDownloadLink, getOnboardPdfDownloadLink ,verificationcheckService, getVerificationStatus, onboardedCompanyListService, ReInviteCompanyService, setELDActiveStatus, setELDExposableStatus, updateCompanyStatusService, setRedirectionStatusService} from '../../services/companyService';
import {formData} from '../../helpers/formData';
require('dotenv').config();

class BrokersCarrierList extends Component
{	

	getModal = (carrier_list,dispatch) => {
		this.setState({ showModal: true, modaldata: carrier_list });
	 };

	constructor(props)
	{
		super(props);
		this.app_url = process.env.REACT_APP_URL;
		this.state={
			modaldata:[],
			verification_status:[],
			verifiedStatusId:'',
			IsOpenVerify:false,
			Carrier_Invitation_Id:0,
			Eld_Provider_Id:0,
			driver_data_check_status:false,
			location_data_check_status:false,
			vehicle_data_check_status:false,
			hos_data_check_status:false,
			dvir_data_check_status:false,
			statusList:[],
			cobStatus:'',
			verificationYesNo:null,
			onboardedData:[]
		}
	}
	componentDidMount=()=>
	{
		document.title = 'Carrier Onboarding';
		getVerificationStatus().then(response =>{
			//console.log(response);
			if(response && response.verificationStatusList){
				this.setState({
					...this.state,
					statusList:response.verificationStatusList
				});
			}
		}); 
		
		this.getCompany();
	}

	getCompany = () => {
		const params2={
			payload:{
				companyTypeId:3
			}
		 }
		 onboardedCompanyListService(params2).then(response =>{
			if(response && response.companyList){
				this.setState({
					...this.state,
					onboardedData:response.companyList
				});
			} else {
				this.setState({
					...this.state,
					onboardedData:[]
				});
			}			
		});
		const params1={
            companytypeid:3
		 }
		// if(this.props.companyTypeId == 0){
		// 	this.props.dispatch(preferredCompanyList(params1));
		// } else {
		// 	this.props.dispatch(preferredCompanyListByType(params1));
		// }
		this.props.dispatch(preferredCompanyListByType(params1));
	}


	userlist = [];
	userlistformycarriers=[];


	favourite = (e, companyId) => {
		
		const params1={
            companyId:companyId
         }
		this.props.dispatch(changePreference(params1));
	}

	componentDidUpdate()
	{
		const params1={
			companytypeid:3,
			SearchFlag:false,
		} 
		if(this.props.carriersearchFlag==true)
		{
			if(this.props.data.message=="No searchlist Found")
			{
				 document.getElementById('addcarrierprop').style.display = 'block'
				 this.props.dispatch(preferredCompanyListByType(params1));
				 this.props.dispatch(carrierSearchFlagAction());	
			}
			else{
				document.getElementById("carrieraddmodalbox").click();
				this.props.dispatch(carrierSearchFlagAction());	
			}
		}

        if(this.props.carrierAddFlag==true)
        {
            if(this.props.data.status=="ACCEPTED" && this.props.data.message=="Carrier Added Sucessfully")
            {              
                toastr.success('Success', 'Carrier has been Successfully Created!');
                document.getElementById("carrieraddmodalbox").click(); 
				this.props.dispatch(preferredCompanyListByType(params1));
				this.props.dispatch(carrierAddFlagAction());
            }
            else if(this.props.data.status=="ALREADY_REPORTED")
            {
                toastr.error('Error', 'Carrier Already Exists!');
                
                //document.getElementById("carrieraddmodalbox").click(); 
				this.props.dispatch(preferredCompanyListByType(params1));
				this.props.dispatch(carrierAddFlagAction());
            }
		}
		
		if(this.props.carrierbulkuploadFlag==true)
        {
			const BASE_URL= process.env.REACT_APP_BASE_URL;   
			var resultFile='';
            if(this.props.data.status=="OK" && this.props.data.message=="Company imported Successfully")
            {    
				resultFile = this.props.data.result_file;   
				document.querySelector('#companyuploadstatusfile').setAttribute("href",BASE_URL+'publicresource/download/company/import-status?file_name='+resultFile);           
                toastr.success('Success', 'File Processed Successfully!!');
                document.getElementById("carrieraddmodalbox").click(); 
				this.props.dispatch(preferredCompanyListByType(params1));
				this.props.dispatch(bulkuploadFlagAction());
				document.getElementById("companyuploadstatusfile").click(); 
				setTimeout(function(){
					window.location.reload();
				 }, 1000); 
			}else if(this.props.data.message=="File not valid")
			{
				toastr.info('info', 'Please upload a valid file!!');
			}
		}
    }


	UNSAFE_componentWillUpdate = (nextProps, nextState) => {
		if(nextProps.preference_changes != null){
			if(nextProps.preference_changes.status === "1"){
				toastr.success('Success', nextProps.preference_changes.result);
				this.getCompany();
			}
			else{
				toastr.error('Error', nextProps.preference_changes.result);
			}
		}
	}

	myCrRowSel = (row, isSelect, rowIndex, e, flag) => {
		//console.log(flag, row);
		if( flag == "invite"){
			$(".btnInvite").addClass('disabled');
			$("#btnInvite_" + row.Company_Id).removeClass('disabled');
		}
		else if( flag == "verify"){
			$(".btnVerify, .btnReInvite").addClass('disabled');
			$("#btnVerify_" + row.id + ", #btnReInvite_" + row.id).removeClass('disabled');
		}
		else if( flag == "onboard"){
			$(".btnOnBoard").addClass('disabled');
			$("#btnOnBoard_" + row.id).removeClass('disabled');
		}
	}

	ReInviteCompany = (e, row) => {
		// //console.log(row);
		// return;
		if($(e.target).hasClass('disabled')) return;
		const params1={
            Carrier_ELD_Authentication_Id:row.Carrier_ELD_Authentication_Id
         }
		 ReInviteCompanyService(params1).then( (response) => {
			let flag = false;
			if(response){
				if(response.result && response.status){
					flag = true;
				}
			}

			if(flag){
				if(response.status == 1){
					toastr.success('Success', response.result);
					this.getCompany();
				}
				else{
					toastr.error('Error', response.result);
				}
			}
			else{
				toastr.error('Error', "Unable to invite");
			}
			
			$(e.target).addClass('disabled');
		});
				
	}

	InviteCompany = (e, row) => {
		// //console.log(row);
		// return;
		if($(e.target).hasClass('disabled')) return;
		const params1={
			companyId:row.Company_Id,
			templateId:0
         }
		InviteCompanyService(params1).then( (response) => {
			let flag = false;
			if(response){
				if(response.result && response.status){
					flag = true;
				}
			}

			if(flag){
				if(response.status == 1){
					toastr.success('Success', response.result);
					this.getCompany();
				}
				else{
					toastr.error('Error', response.result);
				}
			}
			else{
				toastr.error('Error', "Unable to invite");
			}
			
			$(e.target).addClass('disabled');
		});
				
	}

	VerifyCompany = (e, row) => {
		if($(e.target).hasClass('disabled')) return;		

		const params={
            carrierInvitedId:row.Carrier_Invitation_Id
		 }
		// getVerificationStatus(params).then( (response) => {
		// 	let status = []
		// 	if(response.verificationStatusList){
		// 		status = response.verificationStatusList.map((data,index) =>{
        //             return{
        //               id:data.verificationStatusId,
        //               name:data.verificationStatusName
        //             }
		// 		});
		// 		this.setState({
		// 			...this.state,
		// 			verification_status:status
		// 			});
		// 	}
		// });
		verificationcheckService(params).then( (response) => {
			//console.log(response.carrierAuthenticationData);
			if(response.carrierAuthenticationData){
				this.setState({
					...this.state,
					IsOpenVerify:true,
					Carrier_Invitation_Id:row.Carrier_Invitation_Id,
					Eld_Provider_Id:row.ELD_Provider_Id,
					driver_data_check_status:response.carrierDriverData,
					location_data_check_status:response.carrierLocationData,
					vehicle_data_check_status:response.carrierEngineData,
					hos_data_check_status:response.carrierHosData,
					dvir_data_check_status:response.carrierDvirData
				});
			}
			else{
				this.setState({
					...this.state,
					IsOpenVerify:true,
					Carrier_Invitation_Id:row.Carrier_Invitation_Id,
					Eld_Provider_Id:row.ELD_Provider_Id,
					driver_data_check_status:false,
					location_data_check_status:false,
					vehicle_data_check_status:false,
					hos_data_check_status:false,
					dvir_data_check_status:false
				});
			}
		});
		// this.setState({
		// 	...this.state,
		// 	IsOpenVerify:true,
		// 	Carrier_Invitation_Id:row.Carrier_Invitation_Id,
		// 	Eld_Provider_Id:row.ELD_Provider_Id
		// });
		
	}
	
	onboardCompany = (e, row) => {
		if($(e.target).hasClass('disabled')) return;
		
		const params1={
			invitedId:row.Carrier_Invitation_Id,
			eldProviderId:row.ELD_Provider_Id
		}
		EldOnboardedService(params1).then( (response) => {
			if(response.status == "OK"){
			   toastr.success('Success', "Eld onboarded");
				   this.getCompany();
			}
			else{
			   toastr.error('Error', "Unable to onboard ELD");
			}
		});
		////console.log(row.Company_Id, row.ELD_Provider_Id);
	}

	saveVerification = () => {
		let form = document.getElementById("verificationData");

		let verifyData = formData(form);
		if(this.state.verificationYesNo){
			if(this.state.verificationYesNo == 0){
				if(!this.state.verifiedStatusId){
					toastr.error('Error', "Please select status.");
					return;
				}
			}
		} else {
			toastr.error('Error', "Please choose verified or not verified");
			return;
		}	
		
		const params1={
			invitedId:verifyData.Carrier_Invitation_Id,
			eldProviderId:verifyData.Eld_Provider_Id,
			driverData:verifyData.driver_data,
			locationData:verifyData.location_data,
			vehicleData:verifyData.vehicle_data,
			hosData:verifyData.hos_data,
			dvirData:verifyData.dvir_data,
			verifiedRemarks:verifyData.remark,
			verifiedByName:verifyData.verifiedby,
			verificationStatus:verifyData.verificationStatus ,
			verificationStatusId:this.state.verifiedStatusId
		 }
		 ////console.log(params1);
		 
		 VerifyCompanyService(params1).then( (response) => {
			 if(response.status == "OK"){
				toastr.success('Success', "Verification info. saved");
					this.getCompany();
			 }
			 else{
				toastr.error('Error', "Unable to save verification info.");
			 }
		 });

		this.closeVerification();
	}

	refreshCarrier=()=>
	{
		
		this.getCompany();
	}
	

	closeVerification = () => {		
		$(".btnVerify").addClass('disabled');
		this.setState({
			...this.state,
			IsOpenVerify:false,
			verifiedStatusId:'',
			verificationYesNo:null
		});		
	}

	loadTabs = () => {
		if(this.props.companyTypeId == 0){
			return(
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true">CARRIERS</a>
					</li>
						<li className="nav-item">
						<a className="nav-link" id="mycarriers-tab" data-toggle="tab" href="#mycarriers" role="tab" aria-controls="mycarriers" aria-selected="false">MY CARRIERS</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" id="invited-tab" data-toggle="tab" href="#invited" role="tab" aria-controls="invited" aria-selected="false">Invited</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" id="invited-tab" data-toggle="tab" href="#responded" role="tab" aria-controls="invited" aria-selected="false">RESPONDED</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" id="invited-tab" data-toggle="tab" href="#verified" role="tab" aria-controls="invited" aria-selected="false">VERIFIED</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" id="onboarded-tab" data-toggle="tab" href="#onboarded" role="tab" aria-controls="onboarded" aria-selected="false">Onboarded</a>
					</li>

					
					<div className="flex-left-auto">
					   <ul className="ul-horizontal tab-menus">						
							<li>
								<a href="#" className="btn btn-primary btn-sm" onClick={this.refreshCarrier} ><i class="icofont-refresh"></i> Refresh</a>
							</li>
							<li>
								<a href="#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-example-modal-lg" >Add Carrier</a>
							</li>
							{/* <li>
								<a href="#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-example-modal-lg" >Add Carrier</a>
							</li> */}
						</ul>
					</div>
				</ul>
			);
		}
		else{
			return(
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true">CARRIERS</a>
					</li>
						<li className="nav-item">
						<a className="nav-link" id="mycarriers-tab" data-toggle="tab" href="#mycarriers" role="tab" aria-controls="mycarriers" aria-selected="false">MY CARRIERS</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" id="invited-tab" data-toggle="tab" href="#invited" role="tab" aria-controls="invited" aria-selected="false">Invited</a>
					</li>					
					<li className="nav-item">
						<a className="nav-link" id="onboarded-tab" data-toggle="tab" href="#onboarded" role="tab" aria-controls="onboarded" aria-selected="false">Onboarded</a>
					</li>

					
					<div className="flex-left-auto">
						<ul className="ul-horizontal tab-menus">
							<li>
								<a href="#" className="btn btn-primary btn-sm" onClick={this.refreshCarrier} ><i class="icofont-refresh"></i> Refresh</a>
							</li>
							<li>
								<a href="#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-example-modal-lg" >Add Carrier</a>
							</li>
					    </ul>
					</div>
				</ul>
			);
		}
	}

	// downloadPdf = (e, row) => {
	// 	e.preventDefault();
	// 	//console.log(row.Invited_Id)
	// 	const BASE_URL= process.env.REACT_APP_BASE_URL;
    // 	const FILE_PATH = BASE_URL+'publicresource/download/cob-pdf/?cobKey='+row.invite_token
	// 	window.open(FILE_PATH);
	// }

	downloadPdf = (e, Carrier_Invitation_Id) => {
		e.preventDefault();
		let param = {
			payload:{
				carrierInvitationId:Carrier_Invitation_Id
			}
		}
		getPdfDownloadLink(param).then((response) => {
			if(response && response.status && response.status==1){
				window.open(response.message);
			} else {
				toastr.error('Error', "Unable to find the document");
			}
		});
	}

	downloadOnboardedPdf = (e, Company_Misc_Id) => {
		e.preventDefault();
		let param = {
			payload:{
				companyMiscId:Company_Misc_Id
			}
		}
		getOnboardPdfDownloadLink(param).then((response) => {
			if(response && response.status && response.status==1){
				window.open(response.message);
			} else {
				toastr.error('Error', "Unable to find the document");
			}
		});
	}

	set_Status = (e) =>{        
        this.setState({
            ...this.state,
            verifiedStatusId:e.target.value
        });
	}
	
	statusChange = (e) => {
		this.setState({
			...this.state,
			cobStatus:e.target.value
		});
	}

	renderStausList = () => {
		let statusList = [];

		if(this.state.statusList && this.state.statusList.length>0){
			statusList = this.state.statusList.map((data,index) =>{
				return <option value={data.verificationStatusId}>{data.verificationStatusName}</option>
			});
		}

		return(<div className="float-right pr-2">
		<div className="float-left pt-2">Status &nbsp;</div>											
		<div className="float-left">
		<select onChange={(e) => {this.statusChange(e)}} id="cobstatus" className="form-control" value={this.state.cobStatus}>
		<option value="">All</option>
		{statusList}
		</select></div></div>);
	}

	set_verificationStatus = (e) => {
		this.setState({
			...this.state,
			verificationYesNo:e.target.value
		});
	}

	changeStatus = (e, companyEldAuthenticationId) =>{
		let param = {
			companyEldAuthenticationId:companyEldAuthenticationId
		};

		let el = e.target;
		setELDActiveStatus(param).then((response) =>{
			if(response){
				if(response.status){
					let title = el.getAttribute("title");
					if(title == 'Deactivate'){
						el.setAttribute("title", 'Activate');
						$(el).removeClass('icofont-eye-blocked').addClass('icofont-eye');
					} else {
						el.setAttribute("title", 'Deactivate');
						$(el).removeClass('icofont-eye').addClass('icofont-eye-blocked');
					}
				} else {
					toastr.error('Oops!', response.message)
				}
			} else {
				toastr.error('Oops!', 'Unable to change status')
			}
		});
		
		
	}

	changeExpose = (e, companyEldAuthenticationId) =>{
		let param = {
			companyEldAuthenticationId:companyEldAuthenticationId
		};

		let el = e.target;
		setELDActiveStatus(param).then((response) =>{
			if(response){
				if(response.status){
					let title = el.getAttribute("title");
					if(title == 'Turn off expose'){
						el.setAttribute("title", 'Turn on expose');
						$(el).removeClass('icofont-unlock').addClass('icofont-unlocked');			
					} else {
						el.setAttribute("title", 'Turn off expose');
						$(el).removeClass('icofont-unlocked').addClass('icofont-unlock');			
					}
				} else {
					toastr.error('Oops!', response.message)
				}
			} else {
				toastr.error('Oops!', 'Unable to change status')
			}
		});
		
	}

	delCompany = (companyId) =>{
		let param={
			companyId:companyId			
		}
		updateCompanyStatusService(param).then((response) =>{
			if(response && response.status){
				toastr.success('Success!', 'Carrier deleted successfully');
				this.getCompany();
			} else {
				toastr.error('Oops!', 'Unable to remove carrier');
			}
		})		
	}

	enableRedirectionStatus = (e, companyEldAuthenticationId) =>{
		let param = {
			companyEldAuthenticationId:companyEldAuthenticationId
		}
		setRedirectionStatusService(param).then((response)=>{
			if(response && response.status){
				if(response.status==true){
					if(response.message){
						toastr.success("Success!", response.message);
					} else{
						toastr.success("Success!", "Redirection enabled successfully");
					}
				}
				this.getCompany();
			} else{
				toastr.error('Oops!', 'Unable to enable redirection');
			}
			console.log(response);
		});
	}

	render()
	{		
		let carriers = [];
		let mycarriers = [];
		let invited=[];
		let responded=[];
		let verified=[];
		let onboarded=[];
		let reInvitBtn ='';

		let carrier_nodata="";
		
		if(this.props.loading){
			carrier_nodata = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>			
		} 
		else {
			carrier_nodata = "No Data Found";
			if(this.props.searchlists.companyList)
			{
				if(this.props.searchlists.companyList.length>0)
				{
					carriers = mycarriers = [];
					carriers = this.props.searchlists.companyList.map(
						(row,index) =>{
							var ispreferred = "far fa-star ";
							if(row.isPreferredCompany === 1){
								ispreferred += "staricon";
							}
							return({
								id:row.Company_Id,
								Company_Id:row.Company_Id,
								//staricon:<a title="Favourite" href="#" onClick={(e) => {this.favourite(e, row.Company_Id)}} className={ispreferred}></a>,
								Company_Name:row.Company_Name,
								Country_Code:row.Country_Code,
								//Speciality_Type_Name:row.Speciality_Type_Name,
								State_Name:row.State_Name,
								isPreferredCompany:row.isPreferredCompany,
								Current_Zip:row.Zipcode,								
								Phone_Number:row.Phone_Number,
								Company_Email:row.Company_Email,
								City_Name:row.City_Name,						
								isPreferredCompany:row.isPreferredCompany
							});
						}
					);
				}
			}
			else
			if(this.props.preferredCompanyList.length > 0)
			{
				carriers = mycarriers = [];
				carriers = this.props.preferredCompanyList.map(
					(row,index) =>{
						var ispreferred = "far fa-star ";
						if(row.isPreferredCompany === 1){
							ispreferred += "staricon";
						}

						return({
							id:row.Company_Id,
							Company_Id:row.Company_Id,
							//staricon:<a title="Favourite" href="#" onClick={(e) => {this.favourite(e, row.Company_Id)}} className={ispreferred}></a>,
							Company_Name:row.Company_Name,
							Country_Code:row.Country_Code,
							//Speciality_Type_Name:row.Speciality_Type_Name,
							State_Name:row.State_Name,
							isPreferredCompany:row.isPreferredCompany,
							Current_Zip:row.Zipcode,
							Phone_Number: (row.Phone_Number != null && row.Phone_Number !== ''?<span>
							<i data-tip data-for={"carriers"+index} id="lanehistoryclass" className="icofont-phone-circle"></i>
								<ReactTooltip id={"carriers"+index} >
									<p>Phone : {row.Phone_Number}</p>
								</ReactTooltip>
							</span>:''),
							Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
							<i data-tip data-for={"carriersemail"+row.Company_Email} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"carriersemail"+row.Company_Email} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>:''),
							City_Name:row.City_Name,						
							isPreferredCompany:row.isPreferredCompany
						});
					}
				);

				mycarriers = this.props.preferredCompanyList.map((row, index) =>{
					//console.log(row.isPreferredCompany, index);				
					if(row.isPreferredCompany === 1){
						//console.log(row);
						let invitBtn = <button onClick={(e)=>{this.InviteCompany(e, row);}} id={"btnInvite_"+row.Company_Id} className="btn btn-primary btn-sm btnInvite disabled" >Invite</button>;
						if(row.Is_Invited == 0 && row.Invitation_Expired == 0){							
							invitBtn = <span>&nbsp;</span>
						}

						let delBtn = <a href="#" className="icofont-trash icofont-action-buttons" onClick={(e) =>{e.preventDefault(); this.delCompany(row.Company_Id)}} ></a>

						let actions = <Fragment>{invitBtn} {delBtn}</Fragment>;

						return({
							id:row.Company_Id,
							Company_Id:row.Company_Id,
							staricon:<a title="Favourite" href="#" onClick={(e) => {this.favourite(e, row.Company_Id)}} className="far fa-star staricon"></a>,
							Company_Name:row.Company_Name,
							Country_Code:row.Country_Code,
							//Speciality_Type_Name:row.Speciality_Type_Name,
							State_Name:row.State_Name,
							isPreferredCompany:row.isPreferredCompany,
							Current_Zip:row.Zipcode,
							Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
								<i data-tip data-for={"mycarriers"+index} id="lanehistoryclass" className="icofont-phone-circle"></i>
								<ReactTooltip id={"mycarriers"+index} >
									<p>Phone : {row.Phone_Number}</p>
								</ReactTooltip>
							</span>:''),
							Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
							<i data-tip data-for={"mycarriersemail"+index} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"mycarriersemail"+index} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>:''),
							City_Name:row.City_Name,						
							isPreferredCompany:row.isPreferredCompany,
							btnInvite:actions
						});
						
					}					
				});	
			}

			if(this.props.invitedCompany.length>0){
				invited = this.props.invitedCompany.map((row, index) =>{
					
					let stringLoadDate="";
					if(row.Expired_On!="" && row.Expired_On != null)
					{
						stringLoadDate=moment(row.Expired_On).format('MM-DD-YYYY');
					}
					return({
						id:row.Carrier_ELD_Authentication_Id,
						Company_Id:row.Company_Id,						
						Company_Name:row.Company_Name,
						Country_Code:row.Country_Code,
						//Speciality_Type_Name:row.Speciality_Type_Name,
						State_Name:row.State_Name,
						Current_Zip:row.Current_Zip,
						Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
							<i data-tip data-for={"invitedPhone"+index} id="lanehistoryclass" className="icofont-phone-circle"></i>
							<ReactTooltip id={"invitedPhone"+index} >
								<p>Phone : {row.Phone_Number}</p>
							</ReactTooltip>
						 </span>:''),
						Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
						<i data-tip data-for={"invited"+index} id="lanehistoryclass" className="icofont-email"></i>
							<ReactTooltip id={"invited"+index} >
								<p>Email : {row.Company_Email}</p>
							</ReactTooltip>
						</span>:''),
						City_Name:row.City_Name,
						expiry_date:stringLoadDate,
						Invited_by:row.Invited_by
					});
				});
			}

			if(this.props.eldCompanyList.length>0){
				responded = this.props.eldCompanyList.map((row, index) =>{
					if((row.is_onboarded == 0 && row.is_verified == 0 ) && (this.state.cobStatus=='' || this.state.cobStatus==row.Verification_Status_Id ) ){
						if((row.verify_success_failure || row.verify_success_failure==0)){
							reInvitBtn = <button onClick={(e)=>{this.ReInviteCompany(e, row);}} id={"btnReInvite_"+row.Carrier_ELD_Authentication_Id} className="btn btn-primary btn-sm btnReInvite disabled" >Re-Invite</button>;
						} else {
							reInvitBtn = <button onClick={(e)=>{this.VerifyCompany(e, row);}} id={"btnVerify_"+row.Carrier_ELD_Authentication_Id} className={'btn btn-primary btn-sm btnVerify disabled'} >Verify</button>
						}
						
						

						let stringLoadDate="";
						if(row.Updated_Time!="" && row.Updated_Time!=null)
						{
							stringLoadDate=moment(row.Updated_Time).format('MM-DD-YYYY');
						}								
						return({
							id:row.Carrier_ELD_Authentication_Id,
							Carrier_Invitation_Id:row.Carrier_Invitation_Id,							
							Company_Name:row.Company_Name,
							Country_Code:row.Country_Code,
							Speciality_Type_Name:row.Speciality_Type_Name,
							invited_by:row.invited_by,
							State_Name:row.State_Name,
							Current_Zip:row.Current_Zip,
							Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
								<i data-tip data-for={"resphonenumber"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
								<ReactTooltip id={"resphonenumber"+row.Carrier_ELD_Authentication_Id} >
									<p>Phone : {row.Phone_Number}</p>
								</ReactTooltip>
								</span>:''),
							Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
							<i data-tip data-for={"resemail"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"resemail"+row.Carrier_ELD_Authentication_Id} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>:''),
							City_Name:row.City_Name,						
							isPreferredCompany:0,
							mc_no:row.Carrier_MC_Number,
							dot_no:row.Carrier_DOT_Number,
							ELD_Provider_Name:row.ELD_Provider_Name,
							Eld_Provider_Id:row.ELD_Provider_Id,				
							updated_date:stringLoadDate,
							Verification_Status_Name:row.Verification_Status_Name,
							verify_success_failure:(row.verify_success_failure==0 ? 'Failure':''),
							btnVerify:<Fragment>
								{reInvitBtn}
								<button onClick={(e)=>{this.downloadPdf(e, row.Carrier_Invitation_Id);}} className="btn cobpdf" title="Download PDF" >
									<i className="icofont-file-pdf"></i>
								</button>
							</Fragment>
						});
					}	
				});

				verified = this.props.eldCompanyList.map((row, index) =>{
					//if(row.is_onboarded == 0 && row.is_verified == 1 ){
					if((row.is_onboarded == 0 && row.is_verified == 1)){													
						return({
							id:row.Carrier_ELD_Authentication_Id,
							Carrier_Invitation_Id:row.Carrier_Invitation_Id,
							Company_Name:row.Company_Name,
							Country_Code:row.Country_Code,
							Speciality_Type_Name:row.Speciality_Type_Name,
							invited_by:row.invited_by,
							State_Name:row.State_Name,
							Current_Zip:row.Current_Zip,
							Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
							<i data-tip data-for={"verified"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
							<ReactTooltip id={"verified"+row.Carrier_ELD_Authentication_Id} >
								<p>Phone : {row.Phone_Number}</p>
							</ReactTooltip>
							</span>:''),
							Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
							<i data-tip data-for={"verifiedemail"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"verifiedemail"+row.Carrier_ELD_Authentication_Id} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>:''),
							City_Name:row.City_Name,
							mc_no:row.Carrier_MC_Number,
							dot_no:row.Carrier_DOT_Number,
							ELD_Provider_Name:row.ELD_Provider_Name,
							Eld_Provider_Id:row.ELD_Provider_Id,		
							// Verification_Status_Name:row.Verification_Status_Name,
							// verify_success_failure:(row.verify_success_failure==1 ? 'Success':'Failure'),
							btnOnBoard:<Fragment>
								<button onClick={(e)=>{this.onboardCompany(e, row);}} id={"btnOnBoard_"+row.Carrier_ELD_Authentication_Id} className="btn btn-primary btn-sm btnOnBoard disabled" >Onboard</button>
								<button onClick={(e)=>{this.downloadPdf(e, row.Carrier_Invitation_Id);}} className="btn cobpdf" title="Download PDF" >
										<i className="icofont-file-pdf"></i>
									</button>
							</Fragment>
						});
					}	
				});

				// onboarded = this.props.eldCompanyList.map((row, index) =>{
				// 	if(row.is_onboarded == 1){								
				// 		return({
				// 			id:index,
				// 			Carrier_Invitation_Id:row.Carrier_Invitation_Id,
				// 			Company_Name:row.Company_Name,
				// 			Country_Code:row.Country_Code,
				// 			Speciality_Type_Name:row.Speciality_Type_Name,
				// 			State_Name:row.State_Name,
				// 			Current_Zip:row.Current_Zip,
				// 			Phone_Number:<span>
				// 			<i data-tip data-for={"onboardedphonenumber"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
				// 			<ReactTooltip id={"onboardedphonenumber"+row.Carrier_ELD_Authentication_Id} >
				// 				<p>Phone : {row.Phone_Number}</p>
				// 			</ReactTooltip>
				// 			</span>,
				// 			Company_Email:<span>
				// 			<i data-tip data-for={"onboarded"+row.Carrier_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-email"></i>
				// 				<ReactTooltip id={"onboarded"+row.Carrier_ELD_Authentication_Id} >
				// 					<p>Email : {row.Company_Email}</p>
				// 				</ReactTooltip>
				// 			</span>,
				// 			City_Name:row.City_Name,
				// 			mc_no:row.Carrier_MC_Number,
				// 			dot_no:row.Carrier_DOT_Number,
				// 			ELD_Provider_Name:row.ELD_Provider_Name,
				// 			Eld_Provider_Id:row.ELD_Provider_Id,
				// 			btnPDFDownload:<button onClick={(e)=>{this.downloadPdf(e, row.Carrier_Invitation_Id);}} className="btn cobpdf" title="Download PDF" >
				// 				<i className="icofont-file-pdf"></i>
				// 			</button>
				// 		});
				// 	}	
				// });
			}
		}
		
		onboarded = this.state.onboardedData.map((row, index) =>{
			
			let exposeStatus = '';
			let activStatus = '';

			let exposeStatusLabel = '';
			let activStatusLabel = '';
			let redirection='';

			if(row.Is_Exposable){
				exposeStatus = 'icofont-unlock';				
				exposeStatusLabel = 'Turn off expose';
			} else {
				exposeStatus = 'icofont-unlocked';
				exposeStatusLabel = 'Turn on expose';
			}

			if(row.Is_Active){
				activStatus = 'icofont-eye-blocked';
				activStatusLabel='Deactivate'
			} else {
				activStatus = 'icofont-eye';
				activStatusLabel='Activate'
			}

			if(row.Is_Active==0 && row.Is_Onboarded==1){
				redirection = <button onClick={(e)=>{this.enableRedirectionStatus(e, row.Company_ELD_Authentication_Id);}} className="btn cobpdf" title="Enable Redirection" >
				<i className="icofont-redo"></i>
			</button>
			//<button enableRedirectionStatus>Activate</button>;
			}

			


			return({
				id:index,
				Company_Misc_Id:row.Company_Misc_Id,
				Company_Name:row.Company_Name,
				//Country_Code:row.Country_Code,
				Speciality_Type_Name:row.Speciality_Type_Name,
				State:row.State,
				Zipcode:row.Zipcode,
				Phone_Number:(row.Phone_Number != null && row.Phone_Number !== ''?<span>
				<i data-tip data-for={"onboardedphonenumber"+row.Company_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
				<ReactTooltip id={"onboardedphonenumber"+row.Company_ELD_Authentication_Id} >
					<p>Phone : {row.Phone_Number}</p>
				</ReactTooltip>
				</span>:''),
				Company_Email: (row.Company_Email != null && row.Company_Email !== ''?<span>
				<i data-tip data-for={"onboarded"+row.Company_ELD_Authentication_Id} id="lanehistoryclass" className="icofont-email"></i>
					<ReactTooltip id={"onboarded"+row.Company_ELD_Authentication_Id} >
						<p>Email : {row.Company_Email}</p>
					</ReactTooltip>
				</span>:''),
				City:row.City,
				mc_no:row.Carrier_MC_Number,
				dot_no:row.Carrier_DOT_Number,
				ELD_Provider_Name:row.ELD_Provider_Name,
				Eld_Provider_Id:row.ELD_Provider_Id,
				btnPDFDownload:<Fragment>
					<button onClick={(e)=>{this.downloadOnboardedPdf(e, row.Company_Misc_Id);}} className="btn cobpdf" title="Download PDF" >
						<i className="icofont-file-pdf"></i>
					</button>
					{redirection}
					{/* <button onClick={(e)=>{this.changeStatus(e, row.Company_ELD_Authentication_Id);}} className={`btn icofont-action-buttons ${activStatus}`} title={activStatusLabel} >
					</button>
					<button onClick={(e)=>{this.changeExpose(e, row.Company_ELD_Authentication_Id);}} className={`btn icofont-action-buttons ${exposeStatus}`} title={exposeStatusLabel} >
					</button> */}
				</Fragment>
			});
		});
		
		const { SearchBar } = Search;
		const columns=[	
		   {
				text:'',
				dataField:'staricon',
				clickToSelect: false
		   },
		   {
			   text:'Carrier Name',
			   dataField:'Company_Name',
			   sort:true
		   },
		   {
			   text:'Phone',
			   dataField:'Phone_Number',
			   sort:true
		   },
		   {
			   text:'Email',
			   dataField:'Company_Email',
			   sort:true
		   },
		   {
			   text:'City',
			   dataField:'City_Name',
			   sort:true
		   },
		   {
			   text:'Zip',
			   dataField:'Current_Zip',
			   sort:true
		   },
		   {
			   text:'State',
			   dataField:'State_Name',
			   sort:true
		   },
		//    {
		// 	  text:'Speciality',
		// 	  dataField:'Speciality_Type_Name',
		// 	  sort:true
		//    },
		   {
			  text:'Is Preferred Company',
			  dataField:'isPreferredCompany',
			  hidden:true
		   }
		   
		]

		const myCarColumns=[	
			// {
			// 	 text:'',
			// 	 dataField:'staricon',
			// 	 clickToSelect: false
			// },
			{
				text:'Carrier Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},
			{
				text:'City',
				dataField:'City_Name',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Current_Zip',
				sort:true
			},
			{
				text:'State',
				dataField:'State_Name',
				sort:true
			},
			// {
			//    text:'Speciality',
			//    dataField:'Speciality_Type_Name',
			//    sort:true
			// },
			{
			   text:'Is Preferred Company',
			   dataField:'isPreferredCompany',
			   hidden:true
			},
			{
				text:'',
				dataField:'btnInvite',
				clickToSelect: true
		   }
			
		 ];

		 const invitedColumns=[
			{
				text:'Carrier Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},
			{
				text:'City',
				dataField:'City_Name',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Current_Zip',
				sort:true
			},
			{
				text:'State',
				dataField:'State_Name',
				sort:true
			},
			// {
			//    text:'Speciality',
			//    dataField:'Speciality_Type_Name',
			//    sort:true
			// },
			{
				text:'Invited by',
				dataField:'Invited_by',
				sort:true
			},									
			{
				text:'Expiry Date',
				dataField:'expiry_date',
				sort:true
			}			
		 ];

		 const respondedColumns=[
			{
				text:'Carrier Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},						
			{
				text:'City',
				dataField:'City_Name',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Current_Zip',
				sort:true
			},
			{
				text:'State',
				dataField:'State_Name',
				sort:true
			},
			{
				text:'Invited by',
				dataField:'invited_by',
				sort:true
			 },	
			{
				text:'MC #',
				dataField:'mc_no',
				sort:true
			},
			{
				text:'DOT #',
				dataField:'dot_no',
				sort:true
			},
			{
				text:'ELD Provider',
				dataField:'ELD_Provider_Name',
				sort:true
			},
			{
				text:'Updated Date',
				dataField:'updated_date',
				sort:true
			 },
			//  {
			// 	text:'Status',
			// 	dataField:'verify_success_failure',
			// 	sort:true
			// },
			{
				text:'Status',
				dataField:'Verification_Status_Name',
				sort:true
			},
			{
				text:'',
				dataField:'btnVerify',
				clickToSelect: true
		   }
			
		 ];

		 const verifiedColumns=[	
			// {
			// 	 text:'',
			// 	 dataField:'staricon',
			// 	 clickToSelect: false
			// },
			{
				text:'Carrier Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},			
			{
				text:'City',
				dataField:'City_Name',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Current_Zip',
				sort:true
			},
			{
				text:'State',
				dataField:'State_Name',
				sort:true
			},
			{
				text:'Invited by',
				dataField:'invited_by',
				sort:true
			 },					
			{
				text:'MC #',
				dataField:'mc_no',
				sort:true
			},
			{
				text:'DOT #',
				dataField:'dot_no',
				sort:true
			},
			{
				text:'ELD Provider',
				dataField:'ELD_Provider_Name',
				sort:true
			},
			// {
			// 	text:'Status',
			// 	dataField:'verify_success_failure',
			// 	sort:true
			// },
			// {
			// 	text:'Failure Reason',
			// 	dataField:'Verification_Status_Name',
			// 	sort:true
			// },
			{
				text:'',
				dataField:'btnOnBoard',
				clickToSelect: true
		   }
			
		 ];

		 const onboardColumns=[	
			// {
			// 	 text:'',
			// 	 dataField:'staricon',
			// 	 clickToSelect: false
			// },
			{
				text:'Carrier Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},			
			{
				text:'City',
				dataField:'City',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Zipcode',
				sort:true
			},
			{
				text:'State',
				dataField:'State',
				sort:true
			},
			// {
			// 	text:'Speciality',
			// 	dataField:'Speciality_Type_Name',
			// 	sort:true
			// },					
			{
				text:'MC #',
				dataField:'mc_no',
				sort:true
			},
			{
				text:'DOT #',
				dataField:'dot_no',
				sort:true
			},
			{
				text:'ELD Provider',
				dataField:'ELD_Provider_Name',
				sort:true
			},
			{
				 text:'',
				 dataField:'btnPDFDownload',
				 sort:false
			}			
		 ];

		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: 'First',
			prePageText: 'Back',
			nextPageText: 'Next',
			lastPageText: 'Last',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };

		  ////console.log(responded);
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<div className="row no-gutters">
					<div className="col-md-12">
						{this.loadTabs()}
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="carriers" role="tabpanel" aria-labelledby="carriers-tab">
						  	<ToolkitProvider
									keyField="id"
									data={ carriers }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
						  	<div className="tab-pane fade" id="mycarriers" role="tabpanel" aria-labelledby="mycarriers-tab">				  			
							  <ToolkitProvider
									keyField="id"
									data={ mycarriers }
									columns={ myCarColumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											selectRow={ { mode: 'radio',
											onSelect: (row, isSelect, rowIndex, e) => {
												this.myCrRowSel(row, isSelect, rowIndex, e, 'invite');
											}, selected:[]  } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
								
							<div className="tab-pane fade" id="invited" role="tabpanel" aria-labelledby="invited-tab">
							  <ToolkitProvider
									keyField="id"
									data={ invited }
									columns={ invitedColumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
							</div>
							<div className="tab-pane fade" id="responded" role="tabpanel" aria-labelledby="responded-tab">				  			
								<ToolkitProvider
									keyField="id"
									data={ responded }
									columns={ respondedColumns }
									search
								>
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										{this.renderStausList()}
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											selectRow={ { mode: 'radio',
											onSelect: (row, isSelect, rowIndex, e) => {
												this.myCrRowSel(row, isSelect, rowIndex, e, 'verify');
											}, selected:[]  } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
							</div>
							<div className="tab-pane fade" id="verified" role="tabpanel" aria-labelledby="verified-tab">				  			
								<ToolkitProvider
									keyField="id"
									data={ verified }
									columns={ verifiedColumns }
									search
								>
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />										
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											selectRow={ { mode: 'radio',
											onSelect: (row, isSelect, rowIndex, e) => {
												this.myCrRowSel(row, isSelect, rowIndex, e, 'onboard');
											}, selected:[]  } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
							</div>
								<div className="tab-pane fade" id="onboarded" role="tabpanel" aria-labelledby="onboarded-tab">
								<ToolkitProvider
									keyField="id"
									data={ onboarded }
									columns={ onboardColumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
								</div>
						  	<div>
                                <CarrierNew statelist={this.props.statelist} country={this.props.country} specialitytypelist={this.props.specialitytypelist} eldproviderlist={this.props.eldproviderlist}/>
						  	    <EditCarrierPopup modaldata={this.state.modaldata}/>
						  	</div>
						</div>
					</div>
				</div>

				<Modal show={this.state.IsOpenVerify} id="verificationData">
      				<ModalHeader>
        				<ModalTitle>Verification</ModalTitle>
      				</ModalHeader>
      				<ModalBody>
						<div className="row">
							<div className="col">Driver Data</div>
							<div className="col">
								<input type="checkbox" className="json-col" name="driver_data" defaultChecked={this.state.driver_data_check_status}></input>
							</div>
							<div className="col">Location Data</div>
							<div className="col">
								<input type="checkbox" className="json-col" name="location_data" defaultChecked={this.state.location_data_check_status}></input>
							</div>
						</div>
						<p>&nbsp;</p>
						<div className="row">
							<div className="col">Vehicle Data</div>
							<div className="col">
								<input type="checkbox" className="json-col" name="vehicle_data" defaultChecked={this.state.vehicle_data_check_status}></input>
							</div>
							<div className="col">HOS Data</div>
							<div className="col">
								<input type="checkbox" className="json-col" name="hos_data" defaultChecked={this.state.hos_data_check_status}></input>
							</div>
						</div>
						<p>&nbsp;</p>
						<div className="row">
							<div className="col">Dvir Data</div>
							<div className="col">
								<input type="checkbox" className="json-col dvirdata" name="dvir_data" defaultChecked={this.state.dvir_data_check_status}></input>
							</div>
							<div className="col">&nbsp;</div>
							<div className="col">&nbsp;</div>							
						</div>
						<p>&nbsp;</p>
						<div className="row">
							<div className="col">Verified</div>
							<div className="col">
								<input type="radio" className="json-col" name="verificationStatus" value="1" checked={this.state.verificationYesNo==1} onChange={(e)=>{this.set_verificationStatus(e)}}></input>
							</div>
							<div className="col">Not Verified</div>
							<div className="col">
								<input type="radio" className="json-col" name="verificationStatus" value="0" checked={this.state.verificationYesNo==0} onChange={(e)=>{this.set_verificationStatus(e)}}></input>
							</div>
						</div>
						<p>&nbsp;</p>

						<div className={`row  ${this.state.verificationYesNo==0?'':'d-none'}`}>
							<div className="col">
								<label>Status</label>
								<select name="verifiedStatusId"  className="form-control" onChange={(e) =>{this.set_Status(e)}} value={this.state.verifiedStatusId}>                               
									<option value="">Select</option> 
									{
										this.state.statusList?(this.state.statusList.map(function(status, index) { 
											return(
												<option key={index} value={status.verificationStatusId}>{status.verificationStatusName}</option>
										)          
									})):(<option></option>)
									}                 
								</select>
							</div>
						</div>
						<p>&nbsp;</p>						
						<div className="row">
							<div className="col">
								Remark <input type="text" className="form-control json-col" name="remark"></input>								
							</div>
						</div>
						<p>&nbsp;</p>
						<div className="row">
							<div className="col">
							Verified by <input type="text" className="form-control json-col" name="verifiedby"></input>
								<input type="hidden" value={this.state.Carrier_Invitation_Id} className="json-col" name="Carrier_Invitation_Id"></input>
								<input type="hidden" value={this.state.Eld_Provider_Id} className="json-col" name="Eld_Provider_Id"></input>
							</div>
						</div>
					</ModalBody>
      				<ModalFooter>
					  <button type="button" className="btn btn-primary"  onClick={() => {this.saveVerification()}}>Submit</button>
					  <button type="button" className="btn btn-primary" onClick={() => {this.closeVerification()}}>Close</button>
					</ModalFooter>
    			</Modal>
			</article>
		)
}
}
const mapStateToProps = (state) => {
	////console.log(state);
	let preferredCompanyList = [];
	let eldCompanyList = [];
	let invitedCompany = [];
	if(state.companyReducer.preferredCompanyList.companyList)
	{
		preferredCompanyList = state.companyReducer.preferredCompanyList.companyList;
	}
	if(state.companyReducer.preferredCompanyList.eldCompanyList)
	{
		eldCompanyList = state.companyReducer.preferredCompanyList.eldCompanyList;
	}
	if(state.companyReducer.preferredCompanyList.invitedCompany)
	{
		invitedCompany = state.companyReducer.preferredCompanyList.invitedCompany;
	}
		////console.log(state.companyReducer.preferredCompanyList);
	return{
	   preferredCompanyList : preferredCompanyList,
	   eldCompanyList:	eldCompanyList,
	   invitedCompany: invitedCompany,
	   CarrierList	: state.loadReducer.companyList,
	   loading : state.companyReducer.loading,
	   data: state.companyReducer.data,
	   searchlists:state.companyReducer.data,
	   carriersearchFlag:state.companyReducer.carriersearchFlag,
	   carrierAddFlag:state.companyReducer.carrierAddFlag,
	   preference_changes : state.companyReducer.preference_changes,
	   carrierbulkuploadFlag:state.companyReducer.carrierbulkuploadflag
	}    
}
export default connect(mapStateToProps)(BrokersCarrierList)
