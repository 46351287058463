import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { contactListService,addContactService,editContactService,deleteContactService } from '../services/contactService';
import * as types from '../actions';

async function fetchAsync(func) {
    const response = await func();
    if (response.ok) {
        return await response.json();
    }
    throw new Error("Unexpected error!!!");
}

function* ListContacts(payload) { 
    try {   
            const response = yield call( contactListService, payload );
            yield put({type: types.LIST_CONTACT_DATA_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.LIST_CONTACT_DATA_ERROR, error })
    }
} 

function* AddContacts(payload) { 
    try {   
            const response = yield call( addContactService, payload );   
            yield put({type: types.ADD_CONTACT_DATA_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.ADD_CONTACT_DATA_ERROR, error })
    }
} 

function* editContactData(payload) { 
    try {   
            const response = yield call( editContactService, payload );  
            yield put({type: types.EDIT_CONTACT_DATA_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.EDIT_CONTACT_DATA_ERROR, error })
    }
}

function* deleteContactData(payload) { 
    try {   
            const response = yield call( deleteContactService, payload );  
            yield put({type: types.DELETE_CONTACT_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.DELETE_CONTACT_ERROR, error })
    }
}


export function* contactSaga() {
    yield takeEvery(types.LIST_CONTACT_DATA, ListContacts);
    yield takeEvery(types.ADD_CONTACT_DATA, AddContacts);
    yield takeEvery(types.EDIT_CONTACT_DATA, editContactData);
    yield takeEvery(types.DELETE_CONTACT, deleteContactData);
}