import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import CarrierOnboardHome from './CarrierOnboardHome';
import CarrierOnboardElds from './CarrierOnboardElds';
import ThankYouPage from './ThankYouPage';

import {carrierOnboarKeyCheckService, saveCarrierOnboardAuthService} from '../../services/carrierService';
class CarrierOnboard extends Component{
    constructor(props)
	{
        super(props);
        this.state={
            isReady:false,
            page:1,            
            onboardData:{
                verificationKey:'',
                carrier_name:'',
                mc_no:'',
                dot_no:''
            }
        }        
    }


    getUrlParams = () => {
        let urlparam = this.props.location.search.substring(1);
        urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        return urlparam;
    }

    invalidError = () => {
        toastr.error('Invalid', 'Verification key not valid');
        setTimeout(
            function() {                                    
                this.props.history.push("/login");
            }
            .bind(this),
            1000
        );
    }

    componentDidMount = () => {
        if(this.props.location.search){
            let urlparam = this.getUrlParams();
            if(urlparam.invitationkey){
                let app_title = process.env.REACT_APP_TITLE;
                document.title = app_title + " - Carrier Onboard";

                const params1={
                    verificationKey:urlparam.invitationkey
                 }
                 
                carrierOnboarKeyCheckService(params1).then( (response) => {
                    if(response.status){
                        if(response.status==1){
                            this.setState({
                                isReady:true,
                                onboardData:{
                                    verificationKey:urlparam.invitationkey,
                                    carrier_name:response.CarrierOnBoarding.companyName,
                                    mc_no:response.CarrierOnBoarding.carrierMCNumber,
                                    dot_no:response.CarrierOnBoarding.carrierDOTNumber
                                }
                            });
                        }
                        else{
                            this.invalidError();
                        }
                    }
                    else{
                        this.invalidError();
                    }
                    console.log(response);
                    
                });
            }
            else{
                this.props.history.push("/login");
            } 
        }
        else{
            this.props.history.push("/login");
        }
    }

    onAllow = (homeData) => {        
        this.setState({
            ...this.state,
            isReady:true,
            onboardData:{
                ...this.state.onboardData,
                carrier_name:homeData.carrier,
                mc_no:homeData.mc_no,
                dot_no:homeData.dot_no
            },
            page:2
        });
    }

    onAuthorize = (eldData) => {
        console.log(eldData, this.state.onboardData);
        let param={
            onBoardVerificationKey:this.state.onboardData.verificationKey,
            carrierMCNumber:this.state.onboardData.mc_no,
            carrierDOTNumber:this.state.onboardData.dot_no,
            companyName:this.state.onboardData.carrier_name,
            eLDProviderId:eldData.eldid,
            eLDUsername:eldData.username,
            eLDPassword:eldData.usrpwd
        }
        saveCarrierOnboardAuthService(param).then((response) => {
            if(response.status){
                if(response.status==1){
                    this.setState({
                        ...this.state,
                        page:3
                    });
                }
                else{
                    this.invalidError();
                }
            }
            else{
                this.invalidError();
            }
            console.log(response);
        });
    }

    carrierOnboardHome = (cprops) => {
        if(this.state.page==1){
            return(<CarrierOnboardHome onboardData={cprops} onAllow={this.onAllow} />);
        }
        else if(this.state.page==2){
            return(<CarrierOnboardElds onAuthorize={this.onAuthorize} />);
        }
        else if(this.state.page==3){
            return(<ThankYouPage />);
        }
        else{
            return(
                <Fragment></Fragment>
            );
        }
    }

    render = () => {
        console.log(this.state);
        if(this.state.isReady){
            return(
                <Fragment>
                    {this.carrierOnboardHome(this.state.onboardData)}
                </Fragment>
            );
        }
        else{
            return(
                <Fragment></Fragment>
            );
        }
    }
}

export default CarrierOnboard;