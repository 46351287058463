import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();


export const PreferredCompanyListByTypeService = (request) => {
    console.log(request.payload,"companyresource")
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    //const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/preferred_companylistbytype?companytypeid='+request.payload.companytypeid+'&searchFlag='+request.payload.SearchFlag+'&dOTNumber='+request.payload.dotNumber+'&mcnumber='+request.payload.mcNumber+'&companyname='+request.payload.carrierName; 
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/preferred_companylistbytype?companytypeid='+request.payload.companytypeid
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const PreferredCompanyListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/preferred_companylist'; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const ChangePreferenceService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/company/ChangeCompanyPreference?companyId=' + request.payload.companyId; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const InviteCompanyService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/company/invitecompany?companyId=' + request.companyId + "&templateId=" + request.templateId; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const ReInviteCompanyService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/company/reinvitecompany?carrierEldAuthenticationId=' + request.Carrier_ELD_Authentication_Id; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}


export const VerifyCompanyService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/save_eld_data_verification_info'; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",  
        headers: authHeader(),
        body:JSON.stringify(request),
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const EldOnboardedService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/move_to_onboarded?invitedId=' + request.invitedId + '&eldProviderId=' + request.eldProviderId; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const GetInvitedCompanyByTypeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/invited_companylist_by_type?companytypeid='+request.companytypeid; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const GetInvitedCompanyService = () => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/invited_companylist'; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const carrierSearchService = (request) => {
    console.log(request.payload,"companyresource")
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/preferred_companylistbytype?companytypeid='+request.payload.companytypeid+'&searchFlag='+request.payload.SearchFlag+'&dOTNumber='+request.payload.dotNumber+'&mcnumber='+request.payload.mcNumber+'&companyname='+request.payload.carrierName; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        if(responseData.companyList.length>0)
        {
            responseData["message"]="searchlist Found";      
        }
        else{
            responseData["message"]="No searchlist Found";    
        }
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const carrierAddService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/company/carrieradd'
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "POST",  
        headers: authHeader(),
        body:JSON.stringify(request.payload),
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const isOnboarded = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/company/is_onboarded'; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const companyNameListService = (request) => {    
    //console.log(request,"requestrequest")
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/companyidnamelistbytype?companytypeid='+request.payload.companytypeid 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const carrierNameListService = (request) => {    
    console.log(request,"requestrequest")
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'companyresource/companyidnamelistbytype?companytypeid='+request.companytypeid 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const getCompanyByTypeService = (request) => {
    console.log(request,"requestrequest")
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'admin/company/list?companyTypeId='+request.companytypeid 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const carrierBulkUploadService = (request) => {
    const formData = new FormData();
    const fileField = document.querySelector('input[id="carrierbulkupload"]');
    let authToken = localStorage.getItem('authToken');
    formData.append('file', request.payload.bulkuploadfilename); 
    const BASE_URL= process.env.REACT_APP_BASE_URL;   
    const LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT = BASE_URL+'companyupload/bulk';
    return fetch(LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT,{
        method: 'POST',
        body: formData,
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken 
        }       
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const getPdfDownloadLink = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL; 
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'publicresource/download/cob_pdf/?carrierInvitationId=' + request.payload.carrierInvitationId;
    return fetch(UPDATE_TRUCK_API_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const verificationcheckService = (request) => {
    console.log(request)    ;
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/getelddatasharinginfo?carrierInvitedId='+request.carrierInvitedId 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {        
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getVerificationStatus = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'verificationstatus/get';
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {        
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const onboardedCompanyListService = (request) => {
    console.log(request)    ;
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL + 'companyresource/onboardedcompanylist'
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {        
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const getOnboardPdfDownloadLink = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL; 
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'publicresource/download/cob_onboarded_pdf/?companyMiscId=' + request.payload.companyMiscId;
    return fetch(UPDATE_TRUCK_API_ENDPOINT,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const planUpgradeByTypeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyresource/update/subscriptionplan'  
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const setELDActiveStatus = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyeldauthentication/set/activestatus?companyEldAuthenticationId='+request.companyEldAuthenticationId
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });

}

export const setELDExposableStatus = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyeldauthentication/set/exposablestatus?companyEldAuthenticationId='+request.companyEldAuthenticationId
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });

}

export const getInviteTemplatetypeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL+'emailtemplateresource/templatetype/' + request.payload.typename + '?companyId=' + request.payload.companyId; 
    return fetch(COMPANY_LIST_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const saveCompanyShortCodeService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'companyresource/update/companyshortcode?companyId='+request.companyId+"&companyShortCode="+request.companyShortCode;
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const updateCompanyStatusService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const COMPANY_AUTH_ELD_ENDPOINT = BASE_URL+'companyresource/update/changestatus?companyId='+request.companyId;
  
    return fetch(COMPANY_AUTH_ELD_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const enableDisableCobStatusService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyresource/update/cobstatus?companyId='+request.companyId;
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const setDefaultService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyresource/set/carrieronboarding?companyId='+request.companyId;
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}


export const setRedirectionStatusService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL+'companyeldauthentication/set/activestatus?companyEldAuthenticationId='+request.companyEldAuthenticationId;
    return fetch(LOAD_DATA_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}




