import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import {formData} from './../../helpers/formData';
import $ from 'jquery';
import {getRolesAndAccessService} from '../../services/userRoleService';
import {roleAddService} from './../../services/userService';
class AddRolePopUp extends Component {
    constructor(props) {
        super(props);
        this.state={
            companies:[]
        }
    }

    componentDidUpdate = (prevProps, prevState)=>{
        if(this.props.newRolePopup != prevProps.newRolePopup)
        {
            this.setState({
                ...this.state,
                companies:this.props.companies
            })
            $("#new_role .companyTypeId").val(this.props.companyTypeId)
        }
    }

    renderCompanyType = () => {

        let companyType = this.props.companyTypes.map((row, index) => {
            return <option key={index} value={row.companyTypeId}>{row.companyTypeName}</option>
        });
        return (
            <select className="form-control companyTypeId" onChange={(e) =>{this.changeCompanyType(e)}} >
                {companyType}
            </select>);
    }

    renderCompany = () => {
        let company = this.state.companies.map((row, index) => {
            return <option key={index} value={row.companyId}>{row.companyName}</option>
        });
        return (
            <select className="form-control json-col" name="companyId" >
                {company}
            </select>);
    }

    changeCompanyType = (e) =>{
        let companyTypeId = e.target.value;
        getRolesAndAccessService({companyTypeId:companyTypeId}).then((response) =>{
            let company=[];
                        
            if(response && response.companyList && response.companyList.length>0){
                company = response.companyList.map((row, index) =>{
                    return {
                        companyId:row.companyId,
                        companyName:row.companyName
                    }
                });
            }

                     

            this.setState({
                ...this.state,                
                companies:company
            });
        });
    }

    closeThis=(arg)=>{
        this.props.closeNewRole(arg);
        this.setState({
            ...this.state,                
            companyTypeId:''
        });
        
    }

    saveNewRole = () => {
        $("#new_role").validate({
            rules:{
                companyId:{
                    required:true
                },
                roleName:{
                    required:true
                },
                roleDesc:{
                    required:true
                }
            },
            messages:{
                companyId:{
                    required:'Select company'
                },
                roleName:{
                    required:'Enter role name'
                },
                roleDesc:{
                    required:'Enter role description'
                }
            },errorPlacement: function(error, element) {
                if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        if($("#new_role").valid())
        {
            let form = document.getElementById("new_role");
            let roleData = formData(form);
            console.log(roleData);
            let param={
                payload:roleData
            }
            roleAddService(param).then((response) =>{
                console.log(response);
                if(response && response.status){
                    if (response.status == "ALREADY_REPORTED") {
                        toastr.info('Info', 'Role Already Exists!');
                                
                    } else if (response.status == "CREATED") {
                        toastr.success('Success', 'Role Created Successfully!');
                    }
                }
            })
        }
    }

    render = () =>{
        return(
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.props.newRolePopup} id="newrole">
                <ModalHeader>
                <ModalTitle>ADD ROLE</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form id="new_role">
                        <div className="row">
                            <div className="col form-group">
                                <label>Company Type</label>
                                {this.renderCompanyType()}
                            </div>
                            <div className="col form-group">
                                <label>Company</label>
                                {this.renderCompany()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>Role Name</label>
                                <input type="text" className="form-control requiredfield json-col" placeholder="Role Name" name="roleName" />
                            </div>
                            <div className="col form-group">
                                <label>Role Description</label>
                                <input type="text" className="form-control requiredfield json-col" placeholder="Role Description" name="roleDesc" />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary"  onClick={(e) => {this.saveNewRole()}}>Save</button>
                    <button type="button" className="btn btn-primary" onClick={(e) => {this.closeThis(0)}}>Close</button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddRolePopUp;