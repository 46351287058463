import { put, call, takeEvery } from 'redux-saga/effects';
import { 
    statelistservice, 
    countrylistservice, 
    assetlistservice, 
    loadstatusservice, 
    loadpointtypeservice, 
    commondata,
    eldproviderservice,
    trucktypeservice,
    fueltypeservice, 
    drivertypeservice,
    specialitytypeservice,
    carrierNameservice,
    accessorialsservice,
    subscriptionplansservice,
    documenttypeservice,
    loadservice ,
    mapboxCredentials ,
    customerTypeService, 
    getTimeZones
} from '../services/commonService';
import * as types from '../actions';

//Country List
async function fetchCountryAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}
//Asset type List
async function fetchAssetAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}
//Load status List
async function fetchloadstatusAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}
//Load point type List
async function fetchloadPointTypeAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}
//Common Lists
async function fetchCommonListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//Eld providers List
async function fetchEldListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//Driver List
async function fetchdriverListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//Truck Type List
async function fetchtruckListAsync(func) {
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchfuelListAsync(func) { 
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//Speciality type list
async function fetchspecialListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//CarrierName list
async function fetchcarriernameListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//accessorials list
async function fetchaccessorialsListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//cityList
async function fetchcityListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}
//ZIpList
async function fetchZipListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

//SUBSCRIPTION PLAN  
async function fetchSubscriptionlistAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchdocumentListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchloadListAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchMapboxdetails(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchCompanytypedropdown (func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

async function fetchTimeZonesAsync(func) {  
    const response = await func();
    if (response) {
        return await response;
    }
    throw new Error("Unexpected error!!!");
}

function* fetchCommonData() { 
    try {
            const statelist = yield fetchCommonListAsync(statelistservice);            
            const country = yield fetchCountryAsync(countrylistservice);           
            const assets = yield fetchAssetAsync(assetlistservice);            
            const loadstatus = yield fetchloadstatusAsync(loadstatusservice);            
            const loadpoint = yield fetchloadPointTypeAsync(loadpointtypeservice); 
            const eldproviderlist = yield fetchEldListAsync(eldproviderservice);  
            const trucktypelist = yield fetchtruckListAsync(trucktypeservice);  
            const fueltypelist = yield fetchfuelListAsync(fueltypeservice);
            const drivertypelist = yield fetchdriverListAsync(drivertypeservice); 
            const specialitytypelist = yield fetchspecialListAsync(specialitytypeservice);
            const carriernamelist = yield fetchcarriernameListAsync(carrierNameservice);
            const accessorialslist = yield fetchaccessorialsListAsync(accessorialsservice);
            const subscriptionplanlist = yield fetchSubscriptionlistAsync(subscriptionplansservice);
            const documenttypelist = yield fetchdocumentListAsync(documenttypeservice);
            const loadlist = yield fetchloadListAsync(loadservice);
            const mapboxDetails = yield fetchMapboxdetails(mapboxCredentials);
            const Companytypedropdown = yield fetchCompanytypedropdown(customerTypeService);
            const timeZones = yield fetchTimeZonesAsync(getTimeZones);
            //const citylist = yield fetchcityListAsync(cityListservice);
            //const ziplist = yield fetchZipListAsync(ziplistsservice);
            
            yield put({
                type: types.COMMON_LIST_SUCCESS, 
                statelist: statelist, 
                country: country, 
                assets: assets, 
                loadstatus: loadstatus, 
                loadpoint: loadpoint,
                eldproviderlist:eldproviderlist,
                trucktypelist:trucktypelist, 
                fueltypelist:fueltypelist,
                drivertypelist:drivertypelist,
                specialitytypelist:specialitytypelist,
                carriernamelist:carriernamelist,
                accessorialslist:accessorialslist,
                subscriptionplanlist:subscriptionplanlist,
                documenttypelist:documenttypelist,
                loadlist:loadlist,
                mapboxDetails:mapboxDetails,
                Companytypedropdown: Companytypedropdown,
                timeZones:timeZones
            });
    } catch (error) {
            yield put({ type: types.COMMON_LIST_ERROR, error })
    }
}
export function* commonlist() {   
    yield takeEvery(types.COMMON_LIST, fetchCommonData);
}