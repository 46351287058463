import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../truckergig-logo.png';
import { connect } from 'react-redux';
import { loginUserAction } from '../../actions/authActions';
import { toastr } from 'react-redux-toastr';
require('dotenv').config();

class AppLogin extends Component{
	constructor(props){
		super(props);

		this.state = {
			fields: {},
			errors: {
				error:''
			}
		}
	}
	componentDidMount() {
		let app_title = process.env.REACT_APP_TITLE;
		app_title = app_title + " - Login";
		document.title = app_title;
		let msg = localStorage.getItem("logmsg");
		if(msg){
			toastr.info('Oops', msg);
		}		
		localStorage.clear();
	}

	changeHandler=(e)=>{
		this.setState({
			[e.target.id]:e.target.value,
		});
	}

	handleSubmit = (e) =>{
		e.preventDefault();

	    let UserName = e.target.UserName.value;
		let Password = e.target.Password.value;

		const data = {
	      	UserName, Password
	    };

		this.props.dispatch(loginUserAction(data));
		
	}
	render(){
		var data=this.props.data;
		var errors= [];
		if(data) {
			// var menuList = Object.keys(data).map(function(key) {
			// 	if(key=='Components'){
			// 		return data[key];
			// 	}
			// 	else return false;
			// });
			var menuList=[];
			if(data.hasOwnProperty('Components')){
				menuList=data.Components;
			}
			if(data.status != "BAD_REQUEST") {
				if (this.props.userAuthenticated == true ) {
					localStorage.setItem('userMenu', menuList);
					localStorage.setItem('companyId', data.CompanyId);
					localStorage.setItem('userName', data.UserName);
					localStorage.setItem('authToken', data.AuthToken);
					localStorage.setItem('Company_Name', data.Company_Name);	
					localStorage.setItem('Password', data.Password);				
					localStorage.setItem('IsLoadDriverShow', data.IsLoadDriverShow);	
					localStorage.setItem('userType', data.UserType.toLowerCase());
					localStorage.setItem('GOOGLE_MAP_KEY',data.GOOGLE_MAP_KEY);
					localStorage.setItem('SUBSCRIPTION_PLAN_ID',data.SUBSCRIPTION_PLAN_ID);
					localStorage.setItem('mapboxKey',data.MAPBOX_API_KEY);
					window.location = '/'+ data.UserType.toLowerCase() +'/dashboard';				
				}
			}
			else
			{
				errors["error"]=data.message
				//errors["error"]="Invalid username / password"
			}
		}
			
		return(	
			<section className="masterhead">
		  		<div className="container h-100">
		    		<div className="row h-100 align-items-center">
		      			<div className="col-md-4 offset-md-4 text-center">	        				
							<form name="" onSubmit={ this.handleSubmit } className="shadow">
								<div className="login-logo">
									<img src={ logo }/>
								</div>
								<div className="form-group">
									<input type="text" name="UserName" id="UserName" className="form-control" placeholder="Username"   />
								</div>
								<div className="form-group">
									<input type="password" name="Password" id="Password" className="form-control" placeholder="Password"  />
									<span style={{color: "red"}}>{errors["error"]}</span>
								</div>				
								<div>
									<input type="submit" value="Login" className="btn btn-primary btn-lg btn-block"/>
								</div>
							</form>
							<Link to="/forgotpwd" className="btn btn-link">Forgot Password</Link>
						</div>			      			
		    		</div>
		  		</div>
		  		{/* <a href="https://staging.truckergig.com/" className="web-site">Go to website</a> */}
			</section>
		)
	}
}

// const mapStateToProps = (response) => ({response});
const mapStateToProps = state => ({
    data: state.authReducer.data,
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userAuthenticated: state.authReducer.userAuthenticated
});

export default connect(mapStateToProps)(AppLogin);
//export default AppLogin