import React, { Component, Fragment } from 'react';
import MainMenu from '../widgets/MainMenu';
import RoleMenu from './RoleMenu';
import AppUser from './AppUser';
import logo from '../../truckergig-logo.png';
//import logo from '../../TruckerCloud_white.png'
import { withRouter } from "react-router";
import { history } from '../../helpers/history';

class TopBar extends Component{

	constructor(props) {
		super(props);
		let userType = localStorage.getItem('userType');
		let activeMainMenu = (userType == "adminuser") ? "Admin" : "";
		this.state={
			loggedUser:{
				companyId:localStorage.getItem('companyId'),
				userName:localStorage.getItem('userName'),
				userType:localStorage.getItem('userType'),
			},
			userMenu:localStorage.getItem('userMenu'),
			activeMainMenu:activeMainMenu
		}
	}

	componentDidMount = () => {
		//console.log('componentDidMount');
		this.dashboardpage(null);
	}

	dashboardpage = (e) => {
		if(e)	e.preventDefault();
		var userType = localStorage.getItem('userType');
		var url = "/"+userType+"/dashboard";
		history.push(url);
	}

	loadSubMenu = (menu1) => {		
		console.log(menu1);
		if(menu1 == "Admin"){
		this.setState({
				...this.state,
				userMenu:localStorage.getItem('userMenu'),
				activeMainMenu:'Admin'
			});
			history.push("/adminuser/dashboard");
		} else if(menu1 == "Driver"){
			this.setState({
				...this.state,
				userMenu:'[driver_list]',
				activeMainMenu:'Driver'
			});
			history.push("/adminuser/driver/driver_list");
		} else if(menu1 == "Carrier"){
			this.setState({
				...this.state,
				userMenu:'[load,trucks,opt_in/out]',
				activeMainMenu:'Carrier'
			});
			history.push("/adminuser/carrier/load");
		} else if(menu1 == "Broker"){
			this.setState({
				...this.state,
				userMenu:'[load]',
				activeMainMenu:'Broker'
			});
			history.push("/adminuser/broker/load");
		} else if(menu1 == "Shipper"){
			this.setState({
				...this.state,
				userMenu:'[dispatched,delivered]',
				activeMainMenu:'Shipper'
			});
			history.push("/adminuser/shipper/dispatched");
		} else if(menu1 == "Reports"){
			this.setState({
				...this.state,
				//userMenu:'[load_summary,trip_registration_summary,truck_summary,file_size_summary,db_records_summary,hos_summary]',
				userMenu:'',
				activeMainMenu:'Reports'
			});
			//history.push("/adminuser/reports/load_summary");
		} else if(menu1 == "Users"){
			this.setState({
				...this.state,
				userMenu:'[users, menus, roles, permissions]',
				activeMainMenu:'Users'
			});
			history.push("/adminuser/users/users");
		}
	}
	
	render(){
		console.log('UserMenu ::',this.state.userMenu)
		const {pathname} = this.props.location;
		let urlArray=pathname.split("/").map(String);
		let menuList=[];
		if(pathname=="/broker/drivers")
		{
			menuList="";  
		}
		if(pathname=="/shipper/drivers")
		{
			menuList="";  
		}
		else if(urlArray[2]!='carrier-drivers') 
		{
			menuList=this.state.userMenu;
		}
		else
		{
			menuList="";  
		}		 
		return(
			<Fragment>
				<header>
					<nav className="navbar navbar-expand-lg navbar-light bg-light">
					  	<a onClick={(e) => {e.preventDefault(); this.loadSubMenu("Admin")}} className="navbar-brand" href="#">
					  		<img src={logo} />
					  	</a>
					  	<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					    	<span className="navbar-toggler-icon"></span>
					  	</button>
					  	<div className="collapse navbar-collapse" id="navbarSupportedContent">
						  	<RoleMenu activeMainMenu={this.state.activeMainMenu} roleMenuClick={this.loadSubMenu}/>
						    <AppUser loggedUser={this.state.loggedUser}/>
					  	</div>
					</nav>		
				</header>
				<MainMenu activeMainMenu={this.state.activeMainMenu} userMenu={menuList} userType={this.state.loggedUser.userType}/>
			</Fragment>
		)
	}

}

export default withRouter(TopBar)
