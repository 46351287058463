import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { 
    dashboardService,
    trackingListService, 
    eldAssetListService, 
    saveVisualGeofence, 
    fetchVisualGeofence,
    updateVisualGeofence,
    deleteVisualGeofence 
} from '../services/dashboardService';
import * as types from '../actions';

// export function* dashboardSaga() {
//      try {
//      const response = yield call(dashboardService);
//      yield put({type: types.GET_DASHBOARD_DATA_SUCCESS, response});
//      } 
//      catch(error) {
//      yield put({ type: types.GET_DASHBOARD_DATA_ERROR, error })
//      }
// }

async function fetchAsync(func) {
    const response = await func();

    if (response.ok) {
        return await response.json();
    }

    throw new Error("Unexpected error!!!");
}

function* fetchData(payload) {
    try {
        const response = yield call (dashboardService, payload);
        yield put({type: types.GET_DASHBOARD_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_DASHBOARD_DATA_ERROR, error: e.message});
    }
}

export function* dashboardSaga(payload) {
    // Allows concurrent fetches of users
    yield takeEvery(types.GET_DASHBOARD_DATA, fetchData);

    // Does not allow concurrent fetches of users
    // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}
// async function fetchAsyncWithPayload(func,payload) {
//     const response = await func(payload);
//     if (response.ok) {
//         return await response.json();
//     }
//     throw new Error("Unexpected error!!!");
// }
// function* fetchTrackingData(payload) {
//     try {
//         const response = yield fetchAsyncWithPayload(trackingListService,payload);
//         yield put({type: types.GET_TRACKING_LIST_SUCCESS, exportData: response.Date_List});
//     } catch (e) {
//         yield put({type: types.GET_TRACKING_LIST_ERROR, error: e.message});
//     }
// }
// export function* trackingListSaga() {
//     // Allows concurrent fetches of users
//     yield takeEvery(types.GET_TRACKING_LIST, fetchTrackingData);

//     // Does not allow concurrent fetches of users
//     // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
// }

async function fetchAsyncWithPayload(func,payload) {
    const response = await func(payload);
    if (response.ok) {
        return await response.json();
    }
    throw new Error("Unexpected error!!!");
}

function* fetchTrackingData(payload) {
    try {    
        const response = yield call(trackingListService,payload);
        yield put({type: types.GET_TRACKING_LIST_SUCCESS, exportData: response.Date_List, responseData: payload.payload});
    } catch (e) {
        yield put({type: types.GET_TRACKING_LIST_ERROR, error: e.message});
    }
}
export function* trackingListSaga() {
    // Allows concurrent fetches of users
    yield takeEvery(types.GET_TRACKING_LIST, fetchTrackingData);

    // Does not allow concurrent fetches of users
    // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}
// Get Eld providers Assets
function* fetchEldAssets(payload) {
    try {
        const response = yield call(eldAssetListService,payload);
        yield put({type: types.GET_ELD_ASSEST_LIST_SUCCESS, dashboard: response});
    } catch (e) {
        yield put({type: types.GET_ELD_ASSEST_LIST_ERROR, error: e.message});
    }
}
export function* eldAssetsListSaga() {
    // Allows concurrent fetches of users
    yield takeLatest(types.GET_ELD_ASSEST_LIST, fetchEldAssets);
}
function* addVisualfence(payload) {
    try {        
        const response = yield call(saveVisualGeofence,payload);      
        yield put({type: types.POST_FENCE_SUCCESS, fenceData: response});
    } 
    catch (e) {
        yield put({type: types.POST_FENCE_ERROR, error: e.message});
    }
}
export function* visualFenceSaga() {
    // Allows concurrent fetches of users
    yield takeEvery(types.POST_FENCE_NEW, addVisualfence);
}

function* fetchVisualfence(payload) {
    try {        
        const response = yield call(fetchVisualGeofence, payload);     
        yield put({type: types.FETCH_FENCE_SUCCESS, fetchFenceData: response});
    } 
    catch (e) {
        yield put({type: types.FETCH_FENCE_ERROR, error: e.message});
    }
}
export function* fetchvisualFenceSaga() {
    // Allows concurrent fetches of users
    yield takeEvery(types.FETCH_FENCE, fetchVisualfence);
}

function* updateVisualfence(payload) {
    try {        
        const response = yield call(updateVisualGeofence, payload); 
        yield put({type: types.UPDATE_FENCE_SUCCESS, updateData: response});
    } 
    catch (e) {
        yield put({type: types.UPDATE_FENCE_ERROR, error: e.message});
    }
}
export function* updatevisualFenceSaga() {
    yield takeEvery(types.UPDATE_FENCE, updateVisualfence);
}

function* deleteVisualfence(payload) {
    try {        
        const response = yield call(deleteVisualGeofence, payload); 
        yield put({type: types.DELETE_FENCE_SUCCESS, deleteData: response});
    } 
    catch (e) {
        yield put({type: types.DELETE_FENCE_ERROR, error: e.message});
    }
}
export function* deletevisualFenceSaga() {
    yield takeEvery(types.DELETE_FENCE, deleteVisualfence);
}