import React, { Component, Fragment } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Loader from './../../common/loader';
import moment from 'moment';
const containerStyle = {
    width: '100%',
    height: '100%'
  };

class PopupMapGoogle extends Component {
    constructor(props) {
      super(props);
      this.state={
          isReady:true,
          markerPopupShow:false,
          infwCenter:{
            lat:41,
            lng:87
          },
          data:[{            
        }],
        truckInfo:{}
      }
    }
    componentDidUpdate(){
    }
    componentWillReceiveProps () {
    }
    handleCloseCall = () => {
        this.setState({
            ...this.state,
            markerPopupShow:false
        });
    }
  infClose = () => {
        this.setState({
            ...this.state,
            markerPopupShow:false
    });
    }
    onClickMarker = (e, data) =>{
        let infwCenter = {
            lat:parseFloat(data.latitude),
            lng:parseFloat(data.longitude)
        }
        this.setState({
            ...this.state,
            markerPopupShow:true,
            infwCenter:infwCenter
        });
    }
    onMarkerHover = (e, data) => {
        let infwCenter = {
            lat:parseFloat(data.latitude),
            lng:parseFloat(data.longitude)
        }
        let speed = parseFloat(data.speed);
        console.log("Speed  =>"+speed );
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }   
        var popupDate="";
        if(data.date!=null)
        {
            popupDate=moment(data.date).format('MM-DD-YYYY T: h:mm a');
        }
        else
        {
            popupDate= "";
        }
        let truckData={
            truckNo:data.truckNo,
            date:popupDate,
            speed:speed,
            location:data.location
        }
        this.setState({
            ...this.state,
            markerPopupShow:true,
            infwCenter:infwCenter,
            truckInfo:truckData
        });
     };
    
    loadMarker = () =>{

        return this.props.data.map((row, index)=>{
            let pos = {
                lat:parseFloat(row.latitude),
                lng:parseFloat(row.longitude)
            };
            //let icon ='http://maps.google.com/mapfiles/ms/icons/red.png';
            
            //if(index == 1)
              //  icon = 'http://maps.google.com/mapfiles/ms/icons/green.png';
            return <Marker 
                   key={index} icon='http://maps.google.com/mapfiles/ms/icons/red.png' 
                   //key={index} icon 
                   position={pos} 
                   onMouseOver={e => this.onMarkerHover(e, row)}
                   onClick={(e) =>{this.onClickMarker(e, row)}} />
        });
        
    }

    render = () =>{
        if(this.state.isReady){
            return (
                <LoadScript
                    googleMapsApiKey={this.props.mapKey}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={8}
                        center={this.props.mapCenter}
                    >
                        {this.loadMarker()}                        
                        
                        { this.state.markerPopupShow && 
                      <InfoWindow                            
                          position={this.state.infwCenter}
                          onCloseClick={()=>{this.infClose()}}
                      ><span>                                            
                          <p>Truck No: {this.state.truckInfo.truckNo}</p>
                          <p>Date: {this.state.truckInfo.date}</p>
                          <p>Speed: {this.state.truckInfo.speed}</p>
                          <p>Location: {this.state.truckInfo.location}</p>    
                          
                          </span>
                      </InfoWindow>
                      }
                    </GoogleMap>
                </LoadScript>
            )
        } else {
            return <Loader />
        }
    }
}
export default PopupMapGoogle;