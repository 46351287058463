import * as types from '../actions';

const initialState = {
    loading: false,    
    data: [],
}
export default function(state = initialState, action) {  
    switch(action.type) {
        case types.ADMIN_LOAD_LIST:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ADMIN_LOAD_LIST_SUCCESS:
            return { 
                ...state,
                data: action.data,               
                loading: false,
            };
        case types.ADMIN_LOAD_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.ADMIN_TRUCK_LIST:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ADMIN_TRUCK_LIST_SUCCESS:
            return { 
                ...state,
                data: action.data,                
                loading: false,
            };
        case types.ADMIN_TRUCK_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};