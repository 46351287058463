import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import $ from 'jquery';
import moment from 'moment';
import 'jquery-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { loadStatusAction,loadReportAction,setgoFlag } from '../../../actions/reportAction';
import {companyNameListService} from '../../../services/companyService';
import {sendMailloadDetailsReportService} from '../../../services/reportService';
import {formData} from '../../../helpers/formData';
import {getMinDate, getMaxDate} from '../../../helpers/appUtil';
import * as FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import * as XLSX from 'xlsx';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

$.validator.addMethod('multiEmail', function(value, element) {
    if (this.optional(element)) {
        return true;
    } else {
        var valid = true;

        $.each($.trim(value).replace(/,$/, '').split(','), $.proxy(function (index, email) {
            if (!$.validator.methods.email.call(this, $.trim(email), element)) {
                valid = false;
            }
        }, this));

        return valid; 
    }
}, 'One or more email addresses are invalid');
class loadReports extends Component {
    constructor(props){
        super(props);
        let date1 =new Date();
        this.state = {
            export : false,
            go :  false,
            activeFilter:'TODAY',
            fromDate:date1,
            toDate:date1,
            companyList:[],
            tableColumns:[	
                {
                    text:'Load',
                    dataField:'loadNumber',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Shipper Reference',
                    dataField:'shipperReferenceNumber',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Shipper',
                    dataField:'shipperName',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Broker',
                    dataField:'brokerName',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Carrier',
                    dataField:'carrierName',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Origin',
                    dataField:'orginAddress',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Destination',
                    dataField:'destinationAddress',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Dispatch',
                    dataField:'dispatchDateTime',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Vehicle',
                    dataField:'assetNumber',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Shipper Status',
                    dataField:'shipperLoadstatus',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Broker Status',
                    dataField:'brokerLoadstatus',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Carrier Status',
                    dataField:'carrierLoadstatus',
                    sort:true,
                    isVisible:true
                },
                {
                    text:'Posted on TRC',
                    dataField:'stringLoadDate',
                    sort:true,
                    isVisible:true
                },        
                {
                    text:'Last Modified On',
                    dataField:'updatedTime',
                    sort:true,
                    isVisible:true
                },
                
               ],
            reportData:{},
            mailBodyContent:''
        };

        // this.customertype=[
        //     {id:"1",name:"Shipper"},
        //     {id:"2",name:"Broker"},
        //     {id:"3",name:"Carrier"}
        // ];
    }

    componentDidMount() {		
        document.title = 'Load Summary';
        $("#frmLoadRpt .range-fields").hide();
        //this.props.dispatch(loadStatusAction());        
	}

    setCustomerType = () =>{
        let options = [];
        console.log(this.props.customertype)
        if(this.props.customertype){
            if(this.props.customertype.data){
            options = this.props.customertype.data.map((row, index) =>{
                return(<option value={row.id} key={index}>{row.name}</option>);
            });
        }
    }
        return(<select className="form-control json-col" name="customerType" onChange={(e) => {this.customertypechange(e)}}>
            <option value="">Select Customer Type</option>
            {options}
        </select>)
    }

    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                payload:{
                    companytypeid:e.target.value
                }
             }
             companyNameListService(params1).then((response) =>{
                let companyList=[];
                if(response.companyList){
                    companyList = response.companyList;
                }
                this.setState({
                    ...this.state,
                    companyList:companyList
                });
             });            
        } else {
            this.setState({
                ...this.state,
                companyList:[]
            });
        }
    }

    setCustomer = () =>{
        let options = [];
        if(this.state.companyList){
            options = this.state.companyList.map((row, index) =>{
                return(<option value={row.Company_Id} key={index}>{row.Company_Name}</option>);
            });
        }
        return(<select className="form-control json-col" name="companyId">
            <option value="0">All</option>
            {options}
        </select>)
    }

    submitForm = (e, exports, go) =>{
        e.preventDefault();        
        $("#frmLoadRpt").validate({
            rules:{
                fromDate:{
                 required:true
                },
                toDate:{
                    required:true
                },
                // loadStatus : {
                //     required: true
                // },
                customerType:{
                    required:true
                }
            },
            messages:{
                fromDate:{
                    required:'From Date can not be left blank.!'
                },
                toDate:{
                    required:'To Date can not be left blank.!'
                },
                // loadStatus : {
                //     digits: 'Select Load Status'
                // },
                customerType:{
                    required:'Select customer type'
                }
            },errorPlacement: function(error, element) {
                if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        if( $("#frmLoadRpt").valid()){
            let form = document.getElementById("frmLoadRpt");
            let loadData = formData(form);
            console.log(loadData);
            loadData.fromDate=(loadData.fromDate) ? (moment(loadData.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            loadData.toDate=(loadData.toDate) ? (moment(loadData.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD HH:mm:ss')) : ("");
            loadData["exportFlg"]=exports;
            loadData["goFlg"]=go;
            console.log(loadData);
            this.props.dispatch(loadReportAction(loadData));
            this.setState({
                ...this.state,
                export : exports,
                go : go,
                reportData:loadData
            }); 
        }
    }

    filterChange = (e, type) =>{
        let fromDate=null;
        let toDate=null;
        
        if(type=="TODAY"){            
            fromDate=moment().startOf('day').toDate();
            toDate=moment().endOf('day').toDate();
        } else if(type=="WEEK"){                
            fromDate=moment().startOf('week').toDate();
            toDate=moment().endOf('week').toDate();
        } else if(type=="MONTH"){                
            fromDate=moment().startOf('month').toDate();
            toDate=moment().endOf('month').toDate();
        } else {
            fromDate=this.state.fromDate;
            toDate=this.state.toDate;
        }
        
        this.setState({
            ...this.state,
            activeFilter:type,
            fromDate:fromDate,
            toDate:toDate
        });
    }

    // setLoadStatus = () =>{
    //     let options = [];
    //     if(this.props.loadStatusList){
    //         console.log(this.props.loadStatusList);
    //         options = this.props.loadStatusList.map((row, index) =>{
    //             return(<option value={row.id} key={index}>{row.name}</option>);
    //         });
    //     }
    //     return(<select className="form-control json-col" name="loadStatus">
    //         <option value="">Select Load Status</option>
    //         {options}
    //     </select>)
    // }

    setTableColumns = (e, flag) => {
        e.preventDefault(); 
        if(flag == 1){
            let form = document.getElementById("frmRptColumn");
            let loadData = formData(form);
            
            for(let i=0; i<this.state.tableColumns.length; i++){
                this.state.tableColumns[i].isVisible = loadData[this.state.tableColumns[i].dataField];
            }
        }
        this.setState({
            ...this.state,
            tableColumns:this.state.tableColumns
        });
    }

    setShowHideColumns = (e, index) =>{
        this.state.tableColumns[index].isVisible=!this.state.tableColumns[index].isVisible;
        this.setState({
            ...this.state,
            tableColumns:this.state.tableColumns
        });

    }

    showHideColumns = () => {
        console.log(this.state.tableColumns);
        let columns = this.state.tableColumns.map((row, index) => {            
            return(
                <div className="dropdown-item" key={index}>
                    <input key={row.dataField} type="checkbox" className="json-col" name={row.dataField} checked={row.isVisible} onChange={(e) =>{this.setShowHideColumns(e,index); }} /> {row.text}
                </div>
            );
        });
        return(
            <div className="dropdown btn-export float-right d-inline-block pr-2">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Show/Hide Columns
                </button>
                <form id="frmRptColumn" >
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {columns}
                        {/* <button className="btn btn-secondary" onClick={(e) =>{this.setTableColumns(e,1)}}>OK</button>
                        <button className="btn btn-default" onClick={(e) =>{this.setTableColumns(e,0)}}>OK</button> */}
                    </div>
                </form>
            </div>
        );
    }

    sendMail = () => {        
        $("#frmEmailSend").validate({
            rules:{
                toEmail:{
                    required:true,
                    multiEmail:true
                },
                subject:{
                    required:true
                },
                bodyContent:{
                    required:true
                }
            },
            messages:{
                toEmail:{
                    required:'Please specify a recipient.!',
                    multiEmail:'Recipient is not valid.!'
                },
                subject:{
                    required:'Subject can not be left blank.!'
                },
                bodyContent:{
                    required:'Description can not be left blank.!'
                }
            }
        });

        if( $("#frmEmailSend").valid()){
            let form = document.getElementById("frmEmailSend");
            let loadData = formData(form);
            loadData["bodyContent"] = this.state.mailBodyContent
            let params={
                payload:{
                    reportData:this.state.reportData,
                    sendEmailData:loadData
                }
            };
            console.log(params);
            sendMailloadDetailsReportService(params).then((response) => {
                console.log(response);
                if(response.status){
                    toastr.success('Email sent', 'Report sent successfully');
                } else {
                    toastr.error('Email not sent', 'Unable to send report');
                }
            });
            this.setState({
                ...this.state,
                mailBoxOpen:false,
                mailBodyContent:''
            });

        }
    }

    openMailBox = () => {
        if(this.props.data.loadInfoList.length==0){
            toastr.info('Info', 'No Data Available!');
            return;
        }        
        this.setState({
            ...this.state,
            mailBoxOpen:true,
            mailBodyContent:''
        });
        
    }

    closeMail = () => {
        document.getElementById("frmEmailSend").reset();
        this.setState({
            ...this.state,
            mailBoxOpen:false,
            mailBodyContent:''
        });
    }

    mailBodyContentChange = (event, editor) => {
        let mailBodyContent = editor.getData();
        this.setState({
            ...this.state,
            mailBodyContent:mailBodyContent
        });
    }
    
    getDateLabel = () => {
        
        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        console.log(fromDate,toDate)
        return(
            <div className="row mt-4 mb-4">
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                        Date {fromDate}
                    </div>
                    <div className={`col font-weight-bold ${(this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                          Date {fromDate} to {toDate}
                    </div>
                </div> 
        );
    
}

    dateRangeChange = (event, picker) =>{
        this.setState({
            ...this.state,
            fromDate:picker.startDate.toDate(),
            toDate:picker.endDate.toDate()
        });
    }
    
    render() {
        var indicationhtml = "No Data Found"
        if(this.props.data.status == 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}  
		if(this.props.exportFlg == true)
		{	
            if(this.props.data.loadInfoList !== undefined)
            {
                if(this.props.data.loadInfoList.length > 0)
                {
                    this.props.dispatch(setgoFlag(false));		
                    let csvData = this.props.data.loadInfoList.map((row,index) =>{
                        var stringLoadDate="";
                        if(row.stringLoadDate!="")
                        {
                            stringLoadDate=moment(row.loadDate).format('MM-DD-YYYY');
                        }
                        else
                        {
                            stringLoadDate= "";
                        }

                        return({
                            Load:row.loadNumber,
                            Shipper_Reference:row.shipperReferenceNumber,
                            Shipper:row.shipperName,
                            Broker:row.brokerName,
                            Carrier:row.carrierName,
                            Origin:row.originAddress,
                            Destination:row.destinationAddress,
                            Dispatch:row.dispatchDateTime,
                            Vehicle:row.vehicleId,
                            Shipper_Status:row.shipperLoadstatus,
                            Broker_Status:row.brokerLoadstatus,
                            Carrier_Status:row.carrierLoadstatus,
                            Posted_on_TRC:stringLoadDate,
                            Last_Modified_on:row.updatedTime,
                        })
                    });
                    //csvData = this.props.data.loadInfoList;	
                    let fileName = 'LoadReport';
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    const ws = XLSX.utils.json_to_sheet(csvData);
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, fileName + fileExtension);
                }
                else{
                    toastr.info('Info', 'No Data Available!');
                }
            }
        }
        var LoadReports=[];
		//if(this.props.goFlg == true)
		//{	
           // console.log("Get List")
            if(this.props.loading){
                var indicationhtml = 
                <div className="spinner-border" role="status">
                 <div className="row react-bs-table-no-data"></div>
                 </div>
            }else
            {
            if(this.props.data.loadInfoList !== undefined)
            {
                if(this.props.data.loadInfoList.length > 0)
                {
                    LoadReports = this.props.data.loadInfoList.map(
                        row =>{                          
                            var stringLoadDate="";
                            if(row.stringLoadDate!="")
                            {
                                stringLoadDate=moment(row.loadDate).format('MM-DD-YYYY');
                            }
                            else
                            {
                                stringLoadDate= "";
                            }
                            return({
                                id:row.loadId,
                                loadNumber:row.loadNumber,
                                assetNumber:row.vehicleId,
                                shipperReferenceNumber:row.shipperReferenceNumber,
                                stringLoadDate:stringLoadDate,
                                shipperName:row.shipperName,
                                carrierName:row.carrierName,
                                brokerName:row.brokerName,
                                createdTime:row.createdTime,
                                updatedTime:row.updatedTime,
                                orginAddress:row.originAddress,
                                destinationAddress:row.destinationAddress,
                                brokerLoadstatus:row.brokerLoadstatus,
                                carrierLoadstatus:row.carrierLoadstatus,
                                shipperLoadstatus:row.shipperLoadstatus,
                                dispatchDateTime:row.dispatchDateTime
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    var indicationhtml="No Data Found";
                }
            }
           } 
       // }
        const { SearchBar } = Search;        
        
		const columns=this.state.tableColumns.filter((row) => row.isVisible);
       console.log(columns);
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };

        let fromDate = moment(this.state.fromDate).format('MM-DD-YYYY');
        let toDate = moment(this.state.toDate).format('MM-DD-YYYY');
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Load Summary</h3> <br></br>
                <form id="frmLoadRpt" >
                    <div className="row">
                        <div className="col">
                            <button type="button" className={(this.state.activeFilter == "TODAY")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'TODAY')}}>Today</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "WEEK")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'WEEK')}}>This Week</button> &nbsp; &nbsp;
                            <button type="button" className={(this.state.activeFilter == "MONTH")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'MONTH')}}>This Month</button> &nbsp; &nbsp;
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.fromDate, endDate: this.state.toDate, locale: {format: 'MM-DD-YYYY'} }}
                                onApply={(event, picker)=>{this.dateRangeChange(event, picker)}} onShow={(event, picker)=>{this.dateRangeChange(event, picker)}}
                            >
                                <button type="button" className={(this.state.activeFilter == "RANGE")? "btn btn-primary" : "btn btn-light"} onClick={(e) =>{this.filterChange(e, 'RANGE')}}>Date Range</button>
                            </DateRangePicker>
                        </div>
                    </div>
                    {this.getDateLabel()} 
    
                    <div className="row">
                        <div className="form-group col col-lg-3">
                            <label>Customer Type</label>
                            {this.setCustomerType()}                         
                        </div>

                        <div className="form-group col col-lg-3">
                            <label>Customer</label>
                            {this.setCustomer()}                        
                        </div>
                        {/* <div className="form-group col col-lg-3">
                            <label>Load Status</label>
                            {this.setLoadStatus()}                    
                        </div> */}
                        <div className="form-group col col-lg-2 date-picker range-fields">
                            <label>From Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="fromDate" placeholder="Enter from date" value={this.state.fromDate} onChange={value => this.setState({ ...this.state, fromDate:value })} max={getMaxDate(this.state.toDate)}/>                    
                            <input hidden className="error_place" defaultValue=""/>
                        </div>
                        <div className="form-group col col-lg-2 date-picker range-fields">
                            <label>To Date</label>
                            <DateTimePicker time={false} format={'MM-DD-YYYY'} containerClassName="json-col" name="toDate" placeholder="Enter to date" value={this.state.toDate} onChange={value => this.setState({ ...this.state, toDate:value })} min={getMinDate(this.state.fromDate)}/>                        
                            <input hidden className="error_place" defaultValue="" />
                        </div>                        
                        <div className="form-group col col-lg-2">
                            <button type="button" value="searchbtn" onClick={(e) =>{this.submitForm(e,false,true)}}  className="btn btn-secondary searchbtn">Get</button>&nbsp;
                            <button type="button" value="searchbtn" onClick={(e) =>{this.submitForm(e,true,false)}} className="btn btn-secondary searchbtn" >Export</button>
                        </div>
                    </div>
                    <div className="row">
                        <p>&nbsp;</p>
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "RANGE")? 'd-none' : ''} ${(this.state.activeFilter == "TODAY")? '':'d-none'}`}>
                            Date {fromDate}
                        </div>
                        <div className={`col font-weight-bold ${(this.state.activeFilter == "RANGE" || this.state.activeFilter == "TODAY")? 'd-none' : ''} `}>
                            Date {fromDate} to {toDate}
                        </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                                <div className="btn-export float-left d-inline-block pr-2 font-weight-bold">Count : {LoadReports.length}</div>
                             <SearchBar { ...props.searchProps } /> 
                             {this.showHideColumns()}
                             <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.openMailBox()}} className='btn btn-primary' >Send as Email</button></div>
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    <Modal show={this.state.mailBoxOpen}>
                        <ModalHeader>
                            <ModalTitle>Send Email</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <form id="frmEmailSend">
                                <div className="row">
                                    <div className="col form-group">
                                        <label >To</label>
                                        <input type="text" className="form-control json-col" placeholder="To" name="toEmail" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-group">
                                        <label >Subject</label>
                                        <input type="text" className="form-control json-col" placeholder="Subject" name="subject" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-group">
                                        <label >Description</label>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={this.state.mailBodyContent}
                                            onChange={this.mailBodyContentChange} 
                                            onReady={editor => {
                                                editor.editing.view.change(writer => {
                                                  writer.setStyle(
                                                    "height",
                                                    "100px",
                                                    editor.editing.view.document.getRoot()
                                                  );
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-primary"  onClick={() => {this.sendMail()}}>Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => {this.closeMail()}}>Close</button>
                        </ModalFooter>
                    </Modal>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
        loadStatusList : state.reportReducer.loadStatusList,
		exportFlg: state.reportReducer.exportFlg,
		goFlg: state.reportReducer.goFlg
    }
  }
  
  export default connect(mapStateToProps)(loadReports);