import React, { Component, Fragment } from 'react';
import Loader from '../../common/loader';
import {companyNameListService} from '../../../services/companyService';
import AdminCarrierTruckList from './truckList';
class AdminCarrierTrucks extends Component{
    constructor(props){
        super(props);

        this.state ={
            isReady:false,
            companyList:[],
            selectedComapny:3
		}
    }

    componentDidMount = () => {
        document.title = 'Carrier Trucks';
        let param = {
            payload:{
                companytypeid:3
            }
        }
        companyNameListService(param).then((response) =>{
            console.log(response);
            if(response.companyList){
                this.setState({
                    ...this.state,
                    companyList:response.companyList,
                    isReady:true
                });
            }
        });
    }

    changeComapny = (e) => {
        let selectedComapny = e.target.value;
        console.log(selectedComapny);
        this.setState({
            ...this.state,
            selectedComapny:selectedComapny
        });
    }

    loadCarrierCompany = () => {
        let options = this.state.companyList.map((row, index) => {
            return <option key={row.Company_Id} value={row.Company_Id}>{row.Company_Name}</option>;
        });

        return <select value={this.state.selectedComapny} 
        onChange = {(e) => {this.changeComapny(e)}} 
        className="form-control">{options}</select>
    }

    render = () => {                
        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded">  
         	      <div className="row no-gutters">
					<div className="col-md-6">
                        <h5 className="text-right pt-2 pr-2">Carrier :</h5>
                    </div>
                    <div className="col-md-2">
                        {this.loadCarrierCompany()}
                    </div>
                
                  </div>
                  <p>&nbsp;</p>
                  <div className="row no-gutters">
					<div className="col-md-12">
                        <AdminCarrierTruckList selectedComapny={this.state.selectedComapny} />
                    </div>
                </div>
               </article>
            )
        } else {
			return(
				<Loader />
			);
        }
    }
}

export default AdminCarrierTrucks;