import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import * as types from '../actions';

import {PreferredCompanyListByTypeService, PreferredCompanyListService, ChangePreferenceService,carrierSearchService,carrierAddService,companyNameListService,carrierBulkUploadService,planUpgradeByTypeService} from '../services/companyService';
import { changePreference } from '../actions/companyActions';


function* PreferredCompanyListByType(payload) { 
    try {   
        const response = yield call( PreferredCompanyListByTypeService, payload );
        yield put({type: types.PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.PREFERRED_COMPANY_LIST_BY_TYPE_ERROR, error })
    }
}

function* PreferredCompanyList(payload) { 
    try {   
        const response = yield call( PreferredCompanyListService, payload );
        yield put({type: types.PREFERRED_COMPANY_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.PREFERRED_COMPANY_LIST_ERROR, error })
    }
}

function* ChangePreference(payload){
    try{
        const response = yield call( ChangePreferenceService, payload );
        yield put({type: types.CHANGE_PREFERRED_RESPONSE_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CHANGE_PREFERRED_RESPONSE_FAILED, error })
    }
}

function* CarrierSearch(payload){
    try{
        const response = yield call( carrierSearchService, payload );
        yield put({type: types.CARRIER_SEARCH_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_SEARCH_ERROR, error })
    }
}

function* Carrieradd(payload){
    try{
        const response = yield call( carrierAddService, payload );
        yield put({type: types.CARRIER_ADD_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_ADD_ERROR, error })
    }
}

function* companyNameList(payload){
    try{
        const response = yield call( companyNameListService, payload );
        yield put({type: types.COMPANY_NAME_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.COMPANY_NAME_LIST_ERROR, error })
    }
}

function* carrierBulkUpload(payload){
    try{
        const response = yield call( carrierBulkUploadService, payload );
        yield put({type: types.CARRIER_BULK_UPLOAD_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_BULK_UPLOAD_ERROR, error })
    }
}

function* upgradePlanSaga(payload) {
    try {
        const response = yield call( planUpgradeByTypeService, payload );        
        yield put({type: types.PLAN_UPGRADE_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.PLAN_UPGRADE_ERROR, error: e.message});
    }
}

export function* companySaga() {
    yield takeEvery(types.PREFERRED_COMPANY_LIST_BY_TYPE, PreferredCompanyListByType);
    yield takeEvery(types.PREFERRED_COMPANY_LIST, PreferredCompanyList);
    yield takeEvery(types.CHANGE_PREFERRED_REQUEST, ChangePreference);
    yield takeEvery(types.CARRIER_SEARCH, CarrierSearch);
    yield takeEvery(types.CARRIER_ADD, Carrieradd);
    yield takeEvery(types.COMPANY_NAME_LIST, companyNameList);
    yield takeEvery(types.CARRIER_BULK_UPLOAD, carrierBulkUpload);
    yield takeEvery(types.PLAN_UPGRADE, upgradePlanSaga);
}