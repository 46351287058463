import * as types from './index';

export const dashboardAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA,
		  payload: payload
	}
};
export const trackingList = (payload) => {
	return {
		type: types.GET_TRACKING_LIST,
		payload: payload
	}
};
export const eldAssetList = (id) => {	
	return {
		type: types.GET_ELD_ASSEST_LIST,
		payload : id
	}
};

export const setgoFlag = (flag) => {
    return {
        type: types.SET_GOFLAG,
        flag : flag
    }
};

export const createFence = (fenceData) => {
  	return {
    	type: types.POST_FENCE_NEW,
    	fenceData
  	}
};

export const fetchFence = (fetchFenceData) => {
  	return {
    	type: types.FETCH_FENCE,
    	fetchFenceData
  	}
};

export const updateFence = (updateData) => {
  	return {
    	type: types.UPDATE_FENCE,
    	updateData
  	}
};
export const deleteFence = (deleteData) => {
  	return {
    	type: types.DELETE_FENCE,
    	deleteData
  	}
};