import React, { Component, Fragment } from 'react';
import Loader from './loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {getAppSettingsService, saveAppSettingsService} from '../../services/adminService';
import { toastr } from 'react-redux-toastr';

class AdminAppSettings extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            appSettings:[]
        }
    }

    componentDidMount = () => {
        document.title = 'Application Settings';
        this.getAppSettings();
    }

    getAppSettings = () => {
        getAppSettingsService().then((response) =>{
            console.log(response);
            if(response.appSettings){
                this.setState({
                    ...this.state,
                    isReady:true,
                    appSettings:response.appSettings
                });
            }
        });
    }

    saveSetting = (e, settingId) => {
        console.log(settingId);
        let dataValue = document.getElementById("dataValue_"+settingId);
        console.log(dataValue.value);
        let param = {
            payload:{
                settingId:settingId,
                dataValue:dataValue.value
            }
        };
        saveAppSettingsService(param).then((response) =>{
            console.log(response);
            if(response.status){
                if(response.status == 1){
                    toastr.success('Saved', 'Setting saved successfully');
                } else if(response.status == 0) {
                    toastr.info('Not found', 'Setting not found');
                } else {
                    toastr.error('Not saved', 'Unable to save setting');
                }
            } else {
                toastr.error('Not saved', 'Unable to save setting');
            }

        });
    }

    getRowData = () => {
        if(this.state.appSettings.length==0){
            return [];
        } else {
            return this.state.appSettings.map(row =>{
                return({
                id:row.applicationSettingId,
                title:row.title,
                dataValue:row.dataValue,
                dataInput:<input type="text" id={"dataValue_"+row.applicationSettingId} className="form-control" defaultValue={row.dataValue}></input>,
                action:<button className="btn btn-primary" onClick={(e) =>{ this.saveSetting(e, row.applicationSettingId) }} >Save</button>
                });
            });
        }
    }

    getTableColumns = () => {
        return [
            {
				text:'Title',
				dataField:'title',
                sort:true,
                style:{width: '20%'}
            },
            {
				text:'Data Value',
				dataField:'dataInput'
            },
            {
				text:'Action',
                dataField:'action',
                style:{width: '60px'}
            }
        ]
    }

    render = () => {
        if(this.state.isReady){
            const { SearchBar } = Search;
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
        );
        
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,      
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };

        const defaultSorted = [{
            dataField: 'companyName',
            order: 'asc'
          }];

        return(
                <article className="shadow bg-white rounded" id="onboardpage">
                    <div className="row">
                        <div className="col">
                            <h3>Application Settings</h3>
                        </div>
                    </div>
                    <article className="table-data truck-data shadow bg-white rounded">
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                    >
                                    {
                                    props => (
                                        <div>                                            
                                            <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>                                            
                                        <BootstrapTable
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                            defaultSorted={defaultSorted}
                                        />
                                        </div>
                                    )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </article>
                </article>
            );
        } else {
            return(<Loader />);
        }
        
    }
}

export default AdminAppSettings;