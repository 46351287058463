import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {getemailAccountsService, verifyEmailAccount} from '../../services/commonService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastr } from 'react-redux-toastr';
import Loader from './loader';
import {formData} from '../../helpers/formData';
import $ from 'jquery';
import 'jquery-validation';
class EmailAccounts extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            AccountsList:[],
            accountId:'',
            verifyAccountPopup:false
        }
    }

    componentDidMount = () => {
        document.title = 'Email Accounts';
        this.getEmailAccounts();
    }

   
    getEmailAccounts = () =>{
        getemailAccountsService({companytypeid:1}).then((response) => {

            let accounts = [];
   
            if(response){
                accounts = response.map((data,index) =>{
                    return{
                        senderName:data.senderName,
                        senderEmail:data.senderEmail,                        
                        host:data.host,                        
                        port:data.port,   
                        accountId:data.carrierEmailAccountSettingId,                
                        isVerified:data.isVerified,
                        userName:data.userName,
                        companyName:((data.company && data.company.companyName) ? data.company.companyName : '')
                    }
                });
            }
            this.setState({
                ...this.state,
                AccountsList:accounts,
                pageReady:true,
                accountId:'',
                verifyAccountPopup:false
            });
          
        });
    }


    verifyEmail = (e, data) => {
        this.setState({
            ...this.state,
            accountId:data.accountId,
            verifyAccountPopup:true
        });
        

    }

    closeThis = (arg) => {
        if(arg==1){
            this.getEmailAccounts();
        } else {
            this.setState({
                ...this.state,
                accountId:'',
                verifyAccountPopup:false
            });
        }
    }
    
    getEmailAccountColumns = () =>{
        return(
            [
                
                {
                    text:'Company Name',
                    dataField:'companyName',
                    sort:true
                },
                {
                    text:'Sender Name',
                    dataField:'senderName',
                    sort:true
                },
                {
                    text:'Sender Email',
                    dataField:'senderEmail',
                    sort:true
                },
                {
                    text:'User Name',
                    dataField:'userName',
                    sort:true
                },              
                // {
                //     text:'Host',
                //     dataField:'host',
                //     sort:true
                // },
                //  {
                //     text:'Port',
                //     dataField:'port',
                //     sort:true
                // },
                {
                    text:'Is Verified',
                    dataField:'isVerified',
                    sort:true
                },                
                {
                    text:'Actions',
                    dataField:'actions',
                    sort:true
                },
               
            ]
        );
    }

    getEmailAccountData = () =>{
        let templateData=[];
        templateData=this.state.AccountsList.map((data,index) =>{
            console.log(data);
            return {
                companyName:data.companyName,
                senderName:data.senderName,
                senderEmail:data.senderEmail,
                userName:data.userName,                        
                host:data.host,                        
                port:data.port,
                isVerified:(data.isVerified==1?'Yes':'No') ,  
                actions:(!data.isVerified?<Fragment>
                    <button className="btn btn-primary btn-sm" onClick={(e) =>{this.verifyEmail(e,data)}}>Verify</button>
                </Fragment>:'')
            }
        });
        return templateData;
    }

    loadPage = () => {
        if(this.state.pageReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
              );
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal
            };
            return(
                <ToolkitProvider
                    keyField="user_id"
                    data={ this.getEmailAccountData() }
                    columns={ this.getEmailAccountColumns() }
                    search
                    >
                    {
                    props => (
                        <div>
                        <SearchBar { ...props.searchProps } />
                        <BootstrapTable bootstrap4 
                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={"No data found"}  hover 
                                bordered={ false } />
                        </div>
                    )
                    }
                </ToolkitProvider>
            );
        } else {
            return(<Loader/>);
        }
    }

    set_userName = (e) =>{
        this.setState({
            ...this.state,
            userName:e.target.value
        });
    }

    set_password = (e) =>{
        this.setState({
            ...this.state,
            password:e.target.value
        });
    }

    verifyEmailAccount = (e) =>{
        $("#frm_verifypopup").validate({
            rules:{                
                userName:{
                 required:true
                },
                password:{
                    required:true
                }
            },
            messages:{               
                userName:{
                    required:'User name can not be left blank.!'
                },
                password:{
                    required:'Password can not be left blank.!'
                }
            },errorPlacement: function(error, element) {
                if (element.attr("name") == "fromDate" || element.attr("name") == "toDate") {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        if($("#frm_verifypopup").valid())
        {
            let form = document.getElementById("frm_verifypopup");
            let loadData = formData(form);
            
           // console.log(loadData);
            

            verifyEmailAccount(loadData).then((response) =>{
                console.log('api response :',response);
                if(response.status){
                    if(response.status == "CREATED"){
                        toastr.success('Success', response.message);
                        this.closeThis(1);
                    } else {
                        toastr.error('Oops!', response.message);
                    }
                }
            });
        }
    }

    render = () =>{ 
        return(
            <article className="table-data truck-data shadow bg-white rounded">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Email Accounts</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    {this.loadPage()}
                    </div>
                </div>
                <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.state.verifyAccountPopup} id="verifypopup">
                    <ModalHeader>
                        <ModalTitle>{this.props.title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <form id="frm_verifypopup">
                            <div className="row">                               
                                <div className="col form-group">
                                    <label>User Name</label>
                                    <input type="text" className="form-control requiredfield json-col" placeholder="User Name" name="userName" onChange={(e) =>{this.set_userName(e)}} value={this.state.userName} />
                                </div>
                                <div className="col form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control requiredfield json-col" placeholder="Password" name="password" onChange={(e) =>{this.set_password(e)}} value={this.state.senderEmail} />
                                </div>                            
                                
                            </div>
                            
                            <input type="hidden" className="json-col" name="id" value={this.state.accountId} />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary"  onClick={(e) => {this.verifyEmailAccount(e)}}>Verify</button>
                        <button type="button" className="btn btn-primary" onClick={() => {this.closeThis(0)}}>Close</button>
                    </ModalFooter>
                </Modal>
            </article>
            );       
    }
}

export default EmailAccounts;